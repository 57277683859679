import React from 'react';
import { Popover } from 'antd';
import { v4 as uuid } from 'uuid';
const FilterOperator = ({
  id,
  open,
  selected,
  children,
  onClick = () => {},
  onOpenChange = () => {},
  placement = 'bottomLeft',
}) => {
  const ops = [
    { id: uuid(), label: 'and' },
    { id: uuid(), label: 'or' },
  ];
  return (
    <Popover
      open={open}
      onOpenChange={() => onOpenChange()}
      trigger={'click'}
      arrow={false}
      placement={placement}
      content={
        <div className="crm-dropdown p-[.4vw] text-[.7vw] text-gray-600 font-[500] rounded-md *:cursor-pointer space-y-1-">
          {ops.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <p
                  className={`first:border-b-first:border-b-gray-200 capitalize px-[1.2vw] py-[.5vw] rounded-md duration-200 ${
                    selected === item.label
                      ? 'bg-gray-200 '
                      : 'hover:bg-gray-100'
                  }`}
                  data-id={id}
                  onClick={(e) => {
                    onClick(e, item.label);
                  }}
                >
                  {item.label}
                </p>
                <span
                  className={`${
                    i + 1 < ops.length &&
                    'border-b my-[.2vw] block border-b-fray-200'
                  }`}
                />
              </React.Fragment>
            );
          })}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default FilterOperator;
