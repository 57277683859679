import AspApi from '../../../api/ApiConfig';

export const SignUpApi = async (payLoad) => {
  const res = await AspApi.post('/api/v1/user/signup', payLoad);
  return res;
};

export const ResendApi = async (payLoad) => {
  const res = await AspApi.post('api/v1/user/email_verificaiton_code', payLoad);
  return res;
};

export const EmailOTPValidation = async (payLoad) => {
  const res = await AspApi.post(
    'api/v1/user/email_verificaiton_code_validate',
    payLoad
  );
  return res;
};

export const ChangePassword = async (payLoad) => {
  const res = await AspApi.post('api/v1/user/set_password', payLoad);
  return res;
};
