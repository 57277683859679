import React, { useReducer, useState } from 'react';
import '../../../../styles/templateList.css';

// API
import { deleteTemplate, synctSingleTemplate } from '../../../../api/Api';

// assets
import { ICONS } from 'assets/icons';
import { images } from 'assets/images';
import Delete from 'assets/customSVG/Delete';
import Eye from 'assets/customSVG/Eye';
import Edit from 'assets/customSVG/Edit';
import Duplicate from 'assets/customSVG/Duplicate';

// utils
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Modal, Tooltip } from 'antd';

// helper
import { capitalizeFirstLetter } from 'helper/capitalizeFirstLetter';
import { getLanguageName } from 'helper/setLanguageCode';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../../test/jest-redux-hooks';
import { updateTemplateDetails } from '../../../../../../reduxToolkit/templateSlice';
import { updateToggleToast } from '../../../../../../reduxToolkit/appSlice';

// hooks
import useToggle from 'hooks/useToggle';
// reducers
import {
  INITIAL_STATE,
  templateReducer,
} from 'hooks/templates/templateReducers';

// components
import TemplateStatus from 'components/commonComponents/templates/TemplateStatus';
import Header from 'components/commonComponents/modals/Header';
import Alert from 'components/commonComponents/modals/Alert';
import TemplatePreview from 'components/commonComponents/templates/TemplatePreview';

const allowedEditStatus = ['DRAFT', 'APPROVED', 'REJECTED', 10, 0];
const allowedConditonEditStatus = ['REJECTED', 'APPROVED'];
const allowedDeleteStatus = ['DRAFT', 'APPROVED', 'REJECTED', , 0];
const allowedPreviewStatus = [
  'DRAFT',
  'APPROVED',
  'REJECTED',
  'IN_REVIEW',
  'PENDING',
  10,
  0,
];

export default function TemplateListCard({
  data,
  updateSingleTemplateData,
  loadTemplateList,
  isLoading,
  isLoadingNextPage,
}) {
  const navigate = useNavigate();

  const channelsData = useAspSelector((state) => state?.Channel?.data);
  const { currentBrand, toggleToast } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  const [syncing, setSyncing] = useToggle(false);
  const [showSyncTooltip, setShowSyncTooltip] = useToggle(false);
  const [isModelOpen, setIsModelOpen] = useToggle(false);
  const [isDeleteModal, setIsDeleteModal] = useToggle(false);
  const [isDelete, setIsDelete] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = templateData;

  // handle edit template
  const handleEdit = (item) => {
    dispatch(updateTemplateDetails({ data: item }));
    navigate('/user/channels/whatsapp/edit-template/template-details');
  };

  // handle duplicate template
  const handleDuplicate = (item) => {
    let datas = { ...item };
    // datas.name = `${item.name}_1`;
    datas.name = ``;
    dispatch(updateTemplateDetails({ data: datas }));
    navigate('/user/channels/whatsapp/duplicate-template/template-details');
  };

  // sync template
  const syncTemplate = async () => {
    setSyncing(true);
    setShowSyncTooltip(false);
    try {
      const res = await synctSingleTemplate(currentBrand?.brand_id, data?.name);
      updateSingleTemplateData(res?.data?.template?.[0]);
    } catch (e) {
      console.warn(e);
    } finally {
      setSyncing(false);
    }
  };

  const handleTemplateDelete = async () => {
    setIsDelete(true);
    try {
      const res = await deleteTemplate({ name: data?.name });

      if (res?.status === 200) {
        setSelectedTemplate(null);
        setIsDeleteModal(false);
        loadTemplateList();
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Template deleted successfully',
              status: 'Success',
              duration: '',
            },
          ])
        );
        return;
      }
      if (res?.status !== 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Unable to delete template. Please try again!',
              status: 'Error',
              duration: '',
            },
          ])
        );
        return;
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsDelete(false);
    }
  };

  // const lastupdated = moment?.duration(data?.meta_modified_at)?.hours();

  let days = moment().diff(moment(data?.meta_modified_at), 'days');

  const allowToEdit =
    allowedConditonEditStatus.includes(data?.status) && days > 0;

  const trStyle = 'p-[0.5vw] font-md weight-medium';
  const tdStyle =
    'w-[18vw] pl-[1vw] pr-[0.5vw] font-md text-[var(--contentText)]';

  return (
    <>
      <tr
        className={`w-full border-[0.8vw] !rounded-[0.4vw] overflow-hidden group/container ${
          syncing ? 'cursor-not-allowed' : ''
        }`}
      >
        <td className='min-w-[25vw] max-w-[35vw] h-[4.5vw]'>
          <div
            className={`${trStyle} w-full h-full flex flex-col gap-[0.5vw] rounded-l-[0.4vw] z-40`}
          >
            <div
              className={`h-[1.6vw] flex items-center gap-[1vw]`}
              onClick={(e) => {
                if (!syncing) {
                  e.stopPropagation();
                }
              }}
            >
              <h4
                className={`font-normal weight-medium text-[var(--contentText)]`}
                onClick={(e) => {
                  if (!syncing) {
                    e.stopPropagation();
                    handleEdit(data);
                  }
                }}
              >
                {data?.name}
              </h4>
              <div className='w-fit flex items-center gap-[0.6vw] z-50'>
                {data?.status !== 'DRAFT' && (
                  <Tooltip
                    trigger={syncing ? [] : ['hover']}
                    title='Sync template'
                    placement='top'
                    className='!text-[0.2vw]'
                    open={showSyncTooltip}
                    onOpenChange={(open) => {
                      setShowSyncTooltip(open);
                    }}
                  >
                    <div
                      // className={`size-[1.4vw] rounded-[0.3vw] flex items-center justify-center cursor-pointer hover:bg-[var(--BG-25)]`}
                      className={`size-[1.4vw] rounded-[0.3vw] flex items-center justify-center hover:bg-[var(--BG-25)] ${
                        syncing ? 'cursor-not-allowed' : 'cursor-pointer'
                      }`}
                      onClick={() => {
                        if (!syncing) {
                          syncTemplate();
                        }
                      }}
                    >
                      <img
                        src={ICONS?.syncRefresh}
                        alt='syncRefresh'
                        className={`size-[0.9vw] opacity-70 ${
                          syncing ? 'animate-spin' : 'animate-none'
                        }`}
                      />
                    </div>
                  </Tooltip>
                )}
                {allowedEditStatus.includes(data?.status) && (
                  <div className='opacity-0 group-hover/container:!opacity-100 transition-opacity duration-300 ease-linear group/eye'>
                    <Tooltip
                      trigger={['hover']}
                      title={
                        data?.status === 'DRAFT'
                          ? 'Edit'
                          : allowToEdit
                          ? 'Edit'
                          : 'Edit will be available after 24hrs'
                      }
                      placement='top'
                      className='!text-[0.2vw]'
                    >
                      <button
                        className={`size-[1.4vw] border-[0.08vw] bg-transparent border-transparent flex items-center justify-center rounded-[0.3vw] group-hover/eye:!bg-slate-100 ${
                          syncing
                            ? 'cursor-not-allowed'
                            : !syncing &&
                              data?.status !== 'DRAFT' &&
                              !allowToEdit
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`}
                        onClick={() => {
                          if (
                            !syncing &&
                            data?.status !== 'DRAFT' &&
                            allowToEdit
                          ) {
                            handleEdit(data);
                          }
                        }}
                      >
                        <Edit
                          width='0.8vw'
                          height='0.8vw'
                          color={''}
                          strokeWidth={'1.7'}
                          className='stroke-[#898E99] group-hover/eye:stroke-[var(--contentText)]'
                        />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {allowedPreviewStatus.includes(data?.status) && (
                  <div className='opacity-0 group-hover/container:!opacity-100 transition-opacity duration-300 ease-linear group/eye'>
                    <Tooltip
                      trigger={['hover']}
                      title={'Preview'}
                      placement='top'
                      className='!text-[0.2vw]'
                    >
                      {' '}
                      <button
                        className={`size-[1.4vw] border-[0.08vw] bg-transparent border-transparent flex items-center justify-center rounded-[0.3vw] ${
                          syncing
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer group-hover/eye:!bg-slate-100'
                        }`}
                        onClick={() => {
                          if (!syncing) {
                            templateDispatch({
                              type: 'update_template',
                              data: data,
                            });
                            setIsModelOpen(true);
                            setSelectedTemplate(data);
                          }
                        }}
                      >
                        <Eye
                          width='1.1vw'
                          height='1.1vw'
                          color=''
                          className={`stroke-[#898E99] ${
                            !isLoading && !isLoadingNextPage && !syncing
                              ? 'group-hover/eye:stroke-[var(--contentText)]'
                              : ''
                          }`}
                        />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {!['DRAFT'].includes(data?.status) && (
                  <div className='opacity-0 group-hover/container:!opacity-100 transition-opacity duration-300 ease-linear group/duplicate'>
                    <Tooltip
                      trigger={['hover']}
                      title={'Duplicate'}
                      placement='top'
                      className='!text-[0.2vw]'
                    >
                      <button
                        className={`size-[1.4vw] border-[0.08vw] bg-transparent border-transparent flex items-center justify-center rounded-[0.3vw] group-hover/duplicate:!bg-slate-100 ${
                          syncing ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={() => {
                          if (!syncing) handleDuplicate(data);
                        }}
                      >
                        <Duplicate
                          color={''}
                          className={
                            'fill-[#898E99] group-hover/duplicate:fill-[var(--contentText)]'
                          }
                        />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {allowedDeleteStatus?.includes(data?.status) && (
                  <div className='opacity-0 group-hover/container:!opacity-100 transition-opacity duration-300 ease-linear group/delete'>
                    <Tooltip
                      trigger={['hover']}
                      title={'Delete'}
                      placement='top'
                      className='!text-[0.2vw]'
                    >
                      <button
                        className={`size-[1.4vw] border-[0.08vw] bg-transparent border-transparent flex items-center justify-center rounded-[0.3vw] group-hover/delete:!bg-red-200 ${
                          syncing ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={() => {
                          if (!syncing) {
                            setIsDeleteModal(true);
                            setSelectedTemplate(data);
                          }
                        }}
                      >
                        <Delete
                          width='0.95vw'
                          height='0.95vw'
                          className={
                            'stroke-[#898E99] group-hover/delete:stroke-red-500'
                          }
                          color=''
                        />
                      </button>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div
                className={`w-full h-full contents-[""] ${
                  syncing ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={(e) => {
                  if (!syncing) {
                    e.stopPropagation();
                    handleEdit(data);
                  }
                }}
              ></div>
            </div>
            <div
              className='flex items-center gap-[0.4vw] font-md text-[var(--font-600)]'
              onClick={(e) => {
                if (!syncing) {
                  e.stopPropagation();
                  handleEdit(data);
                }
              }}
            >
              <span>last updated @</span>
              <img
                src={ICONS?.campaignCalendar}
                alt={'campaignDate'}
                className='w-[0.8vw] h-[0.8vw]'
              />
              <span>
                {moment(data?.meta_modified_at).utc().format('MMMM DD hh:mm a')}
              </span>
            </div>
          </div>
        </td>
        <td
          className={`${tdStyle} w-full h-[4.5vw] flex items-center justify-center`}
        >
          <TemplateStatus id={data?.name} status={data?.status} />
        </td>
        <td className={`${tdStyle}`}>
          {capitalizeFirstLetter(data?.category)}
        </td>
        <td className={`${tdStyle}`}>{getLanguageName(data?.language)}</td>
        {/* <td className={`${tdStyle}`}>0</td>
            <td className={`${tdStyle}`}>0</td>
             <td className={`${tdStyle}`}>
               {data?.message_sent ?? 0}
             </td>
             <td className={`${tdStyle}`}>
               {data?.message_delivered ?? 0}
             </td>
             <td className={`${tdStyle}`}>
               {data?.message_read ?? 0}
             </td> */}
      </tr>
      <Alert
        open={isDeleteModal}
        setOpen={setIsDeleteModal}
        content={'Are you sure you want to delete your template?'}
        yesContent={'Yes'}
        noContent={'No'}
        yesLoading={isDelete}
        handleYes={async () => {
          await handleTemplateDelete(selectedTemplate);
        }}
        handleNo={() => {
          if (!syncing) {
            setIsDeleteModal(false);
            setSelectedTemplate(null);
          }
        }}
      />

      <Modal
        mask
        centered
        open={isModelOpen}
        closable={false}
        onCancel={() => setIsModelOpen(false)}
        width={'20vw'}
        maskClosable
        wrapClassName={'bg-[#00000095]'}
        rootClassName='templatePreviewModal'
        footer={null}
        style={{
          width: 'auto',
          borderRadius: 20,
          boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
        }}
        afterOpenChange={(open) => setIsModelOpen(open)}
        destroyOnClose
      >
        <div className='w-full min-h-[65vh] max-h-[70vh] h-full'>
          <Header
            title='Preview'
            rootStyle='px-[1.6vw] py-[0.45vw]'
            titleStyle='text-[var(--font-600)]'
            handleCancel={() => {
              setIsModelOpen(false);
            }}
          />
          <div className='w-[18vw] min-h-[57vh] max-h-[59vh] mx-auto border-[0.4vw] border-[var(--white)] rounded-[2vw] my-[0.7vw] shadow-[0px_4px_30px_0px_#2D303620]'>
            <div className='h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]'>
              <div className='size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs'>
                Logo
              </div>
              <div className='text-[var(--white)]'>
                <h3 className='font-md weight-bold capitalize leading-[1.2vw]'>
                  {channelsData?.whatsapp_business_account_name}
                </h3>
              </div>
            </div>
            <TemplatePreview
              header={header}
              headerVariable={headerVariable}
              body={body}
              bodyVariables={bodyVariables}
              footer={footer}
              buttons={buttons}
              buttonsOrder={buttonsOrder}
              ctaButtons={ctaButtons}
              customButtons={customButtons}
              data={selectedTemplate}
              className={'rounded-none min-h-[45vh] max-h-[45vh]'}
              drawerType='small'
            />
            <img
              src={images?.TemplatePreviewBottom}
              alt='send msg'
              className='w-[17.35vw] h-[2.3vw] rounded-b-[1.5vw]'
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
