import React, { useState, useEffect } from 'react';
import './appLayout.css';

import { useNavigate, useLocation } from 'react-router-dom';
import { navigationConst } from '../../constant/app/appLayout';

import Home from '../../assets/customSVG/navHome';
import Channels from '../../assets/customSVG/navChannels';
import Campaigns from '../../assets/customSVG/navCampaign';
import Calendar from '../../assets/customSVG/navCalendar';
import Inbox from '../../assets/customSVG/navInbox';
import Settings from '../../assets/customSVG/Settings';

import Avatar from '../../components/commonComponents/avatar/Avatar';
import { useAspDispatch, useAspSelector } from '../../test/jest-redux-hooks';
import { setPageNavigate } from '../../reduxToolkit/CampaignSlice';

// style
const avatarStyle = {
  height: '38px',
  width: '38px',
  background: '#ffe6b3',
  color: 'var(--textBlack)',
  fontSize: '1.2vw',
  fontWeight: ' 400 !important',
};

function SideBar({ isModalVisible, setIsModalVisible, activeNav = '' }) {
  const [navList, setNavList] = useState(navigationConst);
  const dispatch = useAspDispatch();

  const { currentUser } = useAspSelector((state) => state?.app);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    updateNavListState(pathname);
  }, [pathname]);

  const navigateModule = (item) => {
    updateNavListState(item?.routeUrl);
    item?.navName === 'Channels'
      ? navigate('/user/channels/whatsapp')
      : item?.navName === 'Home'
      ? navigate('/user/home')
      : navigate(`.${item?.routeUrl}`);
  };
  const updateNavListState = (path) => {
    setNavList((prev) => {
      const newState = prev?.map((prevItem) => {
        if (
          path?.includes(prevItem?.routeUrl) ||
          (prevItem?.navName === 'Campaigns' &&
            (path?.includes('/user/campaign/') ||
              path?.includes('/user/api') ||
              path?.includes('/user/ContactApi')))
        ) {
          return { ...prevItem, selected: true };
        } else {
          return { ...prevItem, selected: false };
        }
      });
      return newState;
    });
  };

  return (
    <nav className='side_bar_container flex-column space-between py-4  '>
      <div className=''>
        {navList?.map((item) => (
          <div
            key={item?.id}
            // className="navBox"
            className={`navBox ${item?.selected ? 'active-listText1' : ''}`}
            onClick={() => {
              navigateModule(item);
              if (item?.navName === 'Campaigns') {
                dispatch(setPageNavigate('All Campaigns'));
              }
            }}
          >
            {item?.navName === 'Home' && (
              <Home
                color={item?.selected ? 'white' : '#2d3036'}
                width={'20'}
                height={'19'}
              />
            )}
            {item?.navName === 'Campaigns' && (
              <Campaigns
                color={item?.selected ? 'white' : '#2d3036'}
                width={'22'}
                height={'21'}
              />
            )}

            {item?.navName === 'Channels' && (
              <Channels
                color={item?.selected ? 'white' : '#2d3036'}
                width={'22'}
                height={'21'}
              />
            )}

            {item?.navName === 'Calendar' && (
              <Calendar
                color={item?.selected ? 'white' : '#2d3036'}
                width={'22'}
                height={'21'}
              />
            )}
            {item?.navName === 'CRM' && (
              <CRM
                color={item?.selected ? 'white' : '#2d3036'}
                width={'22'}
                height={'21'}
              />
            )}

            {item?.navName === 'Inbox' && (
              <Inbox
                color={item?.selected ? 'white' : '#2d3036'}
                width={'22'}
                height={'21'}
              />
            )}

            {item?.navName === 'Settings' && (
              <Settings
                color={item?.selected ? 'white' : '#2d3036'}
                width={'22'}
                height={'21'}
              />
            )}
            <span
              className='navName'
              style={{ color: item?.selected ? 'white' : '#2d3036' }}
            >
              {item?.navName}
            </span>
          </div>
        ))}
      </div>

      <div className='flex-row justify-center align-center log_out '>
        <Avatar
          style={avatarStyle}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
    </nav>
  );
}

export default SideBar;
