import { createSlice } from '@reduxjs/toolkit';

import { MessageList } from 'constant/app/channel/tabl';

const initialState = {
  channelList: [],
  whatsUpAuthData: {},
  templateDetails: { category: '', name: '', language: [] },
  details: null,
  isSubmenuOpen: false,
  data: [],
  tableColumn: MessageList,
};

export const slice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    // update: (state, action) => {
    //   const { key, value } = action.payload;
    //   // it will update the category
    //   if (key === 'category') {
    //     state.[key] =
    //       marketingTypes.includes(value) || value === 'MARKETING'
    //         ? 'MARKETING'
    //         : (utilityTypes.includes(value) || value === 'UTILITY') &&
    //           'UTILITY';
    //     state.categoryType = value;
    //   }
    //   // update or replace the generic value to the state based on the key passed
    //   else {
    //     state[key] = value;
    //   }
    // },
    // updateLanguage: (state, action) => {
    //   const value = action.payload;
    //   if (value) {
    //     const formatedValue = { [value]: {} };
    //     state.currentLanuage = value;
    //     state.language = [formatedValue];
    //     const language = state?.language?.[0]
    //       ? Object?.keys(state?.language?.[0])
    //       : '';
    //     const converted = getLanguageName(language);
    //     state.currentLanuage = [converted];
    //   } else {
    //     state.language = [];
    //   }
    // },
    updateChannelList: (state, action) => {
      state.channelList = action.payload;
    },
    updateWhatsUpData: (state, action) => {
      state.whatsUpAuthData = action.payload;
    },
    updateTemplateDetails: (state, action) => {
      state.templateDetails = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    toggleSubmenu: (state) => {
      state.isSubmenuOpen = !state.isSubmenuOpen;
    },
    updateTabColumn: (state, action) => {
      state.tableColumn = action.payload;
    },
    // updateTemplateDetails: (state, action) => {
    //   const { data } = action.payload;
    //   state.details = data;

    //   state.category = data.category;
    //   state.name = data.name;
    //   state.language = [data.language];
    // },
    // resetTemplateDetails: (state, action) => {
    //   state.category = '';
    //   state.categoryType = '';
    //   state.name = '';
    //   state.language = [];
    // },
  },
});

export const {
  update,
  updateLanguage,
  updateChannelList,
  updateWhatsUpData,
  updateTemplateDetails,
  setData,
  updateTabColumn,
  toggleSubmenu,
} = slice.actions;

export default slice.reducer;
