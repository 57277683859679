import ModelContent from './ModelContent';
const Model = ({
  active,
  buttonLabel,
  headerLabel,
  sideNavLabel,
  onClick,
  children,
  Button,
  showBack,
  backAction,
  ModelSideNav,
}) => {
  const isSideNavPresent = sideNavLabel || ModelSideNav;
  return (
    <div>
      {Button && Button}
      {buttonLabel && <button onClick={onClick}>{buttonLabel}</button>}
      <div
        className={`${
          !active
            ? 'scale-0- opacity-0 -z-[50]'
            : 'scale-100- opacity-100 z-[500]'
        } transition-all duration-300 fixed bg-[#00000031] top-0 left-0 h-screen w-screen p-4 grid origin-center`}
        onClick={onClick}
      >
        <div
          className="bg-white w-[60vw] h-[75vh] ms-[20vw] mt-[10vh] rounded-xl overflow-hidden"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ModelContent
            showSidebar={isSideNavPresent}
            sideNavLabel={sideNavLabel}
            ModelSideNav={ModelSideNav}
            headerLabel={headerLabel}
            Toggle={onClick}
            children={children}
            showBack={showBack}
            backAction={backAction}
            // mainClassName="p-[1vw]"
          />
        </div>
      </div>
    </div>
  );
};

export default Model;
