export function timeSince(date, type = 'full', isAgo = true) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1 && type === 'full') {
    return Math.floor(interval) + ` years ${isAgo ? 'ago' : ''}`;
  } else if (interval > 1 && type === 'single') {
    return Math.floor(interval) + `y ${isAgo ? 'ago' : ''}`;
  }

  interval = seconds / 2592000;
  if (interval > 1 && type === 'full') {
    return Math.floor(interval) + ` months ${isAgo ? 'ago' : ''}`;
  } else if (interval > 1 && type === 'single') {
    return Math.floor(interval) + `m ${isAgo ? 'ago' : ''}`;
  }

  interval = seconds / 86400;
  if (interval > 1 && type === 'full') {
    return Math.floor(interval) + ` days ${isAgo ? 'ago' : ''}`;
  } else if (interval > 1 && type === 'single') {
    return Math.floor(interval) + `d ${isAgo ? 'ago' : ''}`;
  }

  interval = seconds / 3600;
  if (interval > 1 && type === 'full') {
    return Math.floor(interval) + ` hours ${isAgo ? 'ago' : ''}`;
  } else if (interval > 1 && type === 'single') {
    return Math.floor(interval) + `h ${isAgo ? 'ago' : ''}`;
  }

  interval = seconds / 60;
  if (interval > 1 && type === 'full') {
    return Math.floor(interval) + ` minutes ${isAgo ? 'ago' : ''}`;
  } else if (interval > 1 && type === 'single') {
    return Math.floor(interval) + `m ${isAgo ? 'ago' : ''}`;
  }

  if (type === 'full') {
    return Math.floor(seconds) + ` seconds ${isAgo ? 'ago' : ''}`;
  } else if (interval > 1 && type === 'single') {
    return Math.floor(seconds) + `s ${isAgo ? 'ago' : ''}`;
  }
}
