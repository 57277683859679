import React, { useMemo, useState } from 'react';

import moment from 'moment';

export function useWeekCalender() {
  const [selectedDate, setSelectedDate] = useState(moment().toDate());

  const [week, setWeek] = useState(0);
  let dates = {
    start: null,
    end: null,
  };

  const weeks = useMemo(() => {
    const start = moment().add(week, 'weeks').startOf('week');

    return Array.from({ length: 7 }).map((_, index) => {
      const date = moment(start).add(0, 'week').add(index, 'day');
      if (index === 0) {
        dates = { ...dates, start: date.toDate() };
      } else if (index === 6) {
        dates = { ...dates, end: date.toDate() };
      }

      return {
        weekday: date.format('ddd'),
        date: date.toDate(),
      };
    });
  }, [week]);

  const next = () => setWeek((prev) => prev + 1);
  const prev = () => setWeek((prev) => prev - 1);

  const onChange = (date) => setSelectedDate(moment(date).toDate());

  const resetToToday = () => {
    setWeek(0);
    setSelectedDate(moment().toDate());
  };

  return {
    selectedDate,
    weeks,
    current: moment().toDate(),
    start: dates?.start,
    end: dates?.end,
    next,
    prev,
    resetToToday,
    onChange,
  };
}
