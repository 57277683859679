import React from "react";

import { ICONS } from "../../../../assets/icons";

import { Modal } from "antd";

import { Button } from "../../../../components/form/Button/Button";
import AlertModal from "../../../../components/commonComponents/modal/alert/AlertModal";

const maskStyle = { background: "rgba(45, 48, 54, 0.25)" };

function SelectedCampaignCount({
  count,
  onSelectChange,
  isModalVisible,
  setIsModalVisible,
  handleBulkDelete,
  value,
}) {
  const handleCancel = () => setIsModalVisible(false);

  // const handleYes = () => setIsModalVisible(false);

  const handleNo = () => setIsModalVisible(false);

  return (
    <div className="bg-[var(--background)] py-3 px-2.5 w-100 flex-row align-center space-between">
      <div className="px-3 flex items-center gap-3">
        <button
          onClick={() => {
            onSelectChange(false);
          }}
        >
          <img src={ICONS?.cancelX} alt="exit" />
        </button>
        <div className="font-normal weight-semibold text-[var(--textBlack)] tracking-[0.2px]">
          <p>{count} Campaigns selected</p>
        </div>
      </div>
      <Button
        label={"Delete"}
        size={"small"}
        onClick={() => setIsModalVisible(true)}
      />
      <Modal
        open={isModalVisible}
        footer={null}
        className={"asp-modal-popup-small"}
        closable={false}
        maskStyle={maskStyle}
        centered={true}
        onCancel={handleCancel}
      >
        <AlertModal
          handleCancel={handleCancel}
          handleYes={() => handleBulkDelete()}
          handleNo={handleNo}
          content="Are you sure delete this selected campaigns ?"
          yesName="No, Keep it"
          noName="Yes, delete"
          loading={value}
        />
      </Modal>
    </div>
  );
}

export default SelectedCampaignCount;
