import AspApi from '../../../api/ApiConfig';

export const Login = async (payLoad) =>
  await AspApi.post('api/v1/login', payLoad);

export const otpValidate = async (payLoad) =>
  await AspApi.post('api/v1/otp/validate', payLoad);

export const forgotPassword = async (payLoad) =>
  await AspApi.post('api/v1/forgot_password', payLoad);

export const resetPassword = async (payload) =>
  await AspApi.post('api/v1/change_password', payload);

export const passwordLinkValidate = async (params) =>
  await AspApi.get(`api/v1/password/link_validate?token=${params}`);



//sign-up
export const SignUpApi = async (payLoad) => {
  const res = await AspApi.post('/api/v1/user/signup', payLoad);
  return res;
};

export const ResendApi = async (payLoad) => {
  const res = await AspApi.post('api/v1/user/email_verificaiton_code', payLoad);
  return res;
};

export const EmailOTPValidation = async (payLoad) => {
  const res = await AspApi.post(
    'api/v1/user/email_verificaiton_code_validate',
    payLoad
  );
  return res;
};

export const ChangePassword = async (payLoad) => {
  const res = await AspApi.post('api/v1/user/set_password', payLoad);
  return res;
};

export const emailApi = async (payLoad) => {
  const res = await AspApi.post(
    'api/v1/user/email_verificaiton_validate',
    payLoad
  );
  return res;
};
