import React, { useState } from 'react';
import Dropdown from '../../commonComponents/dropdown';
import Button from '../../commonComponents/button';
import AudienceFile from '../../../../../assets/icons/AudienceFile.svg';
import SampleXLSXFile from '../../../../../assets/SampleXLSXFile.xlsx';

import {
  contactFilters,
  crmFontColor,
  csvInfo,
  primaryColor,
} from '../../../constants';
import { ICONS } from '../../../../../assets/icons';
import redDelete from '../../../../../assets/icons/redelete.svg';
import {
  handleContactModelSelection,
  handelDeleteFile,
  setFiles,
} from '../../../../../reduxToolkit/crmSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import UploadLoading from './UploadLoading';
import CsvInfo from './CsvInfo';

import SelectMergeOption from './SelectMergeOption';

const ImportContacts = ({
  selectOpen,
  setSelectOpen,
  importStep,
  setImportStep,
}) => {
  const [contactsFilters, setContactsFilters] = useState([...contactFilters]);
  // const [selectedContactSource, setSelectedContactSource] = useState(null);
  const { files } = useSelector((store) => store.crm);
  const dispatch = useDispatch();
  const [mergeOption, setMergeOption] = useState('REM');
  const handleModelContactSelection = (id) => {
    // dispatch(handleContactModelSelection({ id }));
    setContactsFilters((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, selected: true };
        }
        return { ...item, selected: false };
      });
    });

    setSelectOpen(false);
  };
  const selectedContactSource = contactsFilters.find((item) => item.selected);
  const Icon = selectedContactSource?.icon;
  return (
    <div className="grid grid-rows-[10fr,1fr] h-[90%]">
      <div className="px-[1vw] w-full text-[.75vw] mt-[1vh] crm-model pb-[1vw]">
        <div>
          <p className="mb-[1vh]">Select a contact source</p>
          <Dropdown
            open={selectOpen}
            heading=""
            data={contactsFilters}
            width="min-w-[43vw]"
            height="max-h-[32vh]"
            containerClassNames="px-[1.6vw]"
            editFilter={handleModelContactSelection}
            button={
              <div className="">
                <div
                  className={` flex justify-between items-center my-[2vh]- py-[1vh] ps-[.5vw] pe-[.7vw] cursor-pointer transition-colors duration-100 text-[0.85vw] border border-gray-200 rounded-lg`}
                  // onClick={() => {}}
                  onClick={() => setSelectOpen(!selectOpen)}
                >
                  <p
                    className={`text-[.73vw] text-[#898E99] flex items-center gap-[.4vw]`}
                  >
                    {Icon && <Icon width="1.3vw" />}

                    {selectedContactSource?.label || 'Select Source'}
                  </p>
                  <span
                    className={`duration-300 ${
                      selectOpen ? '-rotate-180' : 'rotate-0'
                    }`}
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    />
                  </span>
                </div>
              </div>
            }
          />
        </div>
        <div className="flex justify-between mt-[1vw]">
          <p>Upload a single file or multiple file</p>
          <a href={SampleXLSXFile} className="flex items-center gap-[.5vw]">
            <img src={ICONS.AudienceDownloadIcon} alt="" />
            <span className={`text-[${primaryColor}]`}>Download sample</span>
          </a>
        </div>

        <div className="mt-[1.3vw] px-[3.5vw]">
          <div className="border-[.13vw]-border-dashed border-gray-300 rounded-md ">
            <label>
              <input
                onChange={(e) => {
                  // console.log(e.target.files[0]);
                  // console.log(e.target.files[0].name);
                  dispatch(
                    setFiles({
                      file: {
                        id: files.length + 1,
                        fileName: e.target.files[0].name,
                        step: 1,
                        progress: 70,
                        openFileInfo: false,
                        // fileObj: e.target.files[0],
                      },
                    }),
                  );
                }}
                type="file"
                name="file"
                hidden
                accept=".xlsx"
              />
              <span className="text-gray-600 ttest rounded-lg box-border bg-gray-100 w-full- h-full- flex flex-col justify-center items-center gap-[.7vw] py-[2vw] cursor-pointer">
                <span
                  className={`bg-[${primaryColor}] min-w-[4vw]  text-[#fff] rounded-md px-[1vw] text-[.75vw] font-[500] h-[3.7vh] flex items-center transition-all`}
                >
                  Choose file
                </span>
                <span>( Drag & Drop the file to upload )</span>
                {/* <span>.xlsx</span> */}
              </span>
            </label>
          </div>

          {!!files.length &&
            files.map((item, i) => {
              const openFileInfo = item.openFileInfo;
              return (
                <div
                  key={i}
                  className="my-[1vw] loader-shadow rounded-lg py-[.7vw] px-[1vw] flex justify-between"
                >
                  <div className="flex items-center justify-between w-[92%]">
                    <div
                      className={`flex ${
                        openFileInfo ? 'min-w-max' : 'max-w-[8vw]'
                      } items-center gap-[.6vw] duration-1000`}
                    >
                      {/* csv file image */}
                      <img src={AudienceFile} alt="" />
                      <div className="text-[.6vw]">
                        <p className="text-[.7vw] font-[600]">
                          {item.fileName}
                        </p>
                        <div
                          className={`overflow-hidden duration-200 flex items-center justify-start${
                            openFileInfo
                              ? 'opacity-1 max-h-[2vw]'
                              : 'opacity-0 max-h-[0]'
                          }`}
                        >
                          {csvInfo.map((item, i) => (
                            <CsvInfo
                              label={item.label}
                              Icon={item.Icon}
                              isLast={csvInfo.length === i + 1}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                    <UploadLoading
                      progress={item.progress}
                      step={1}
                      isUploading={openFileInfo}
                      // handleUploadAnime={setFiles}
                    />
                  </div>
                  <span
                    onClick={() => {
                      dispatch(handelDeleteFile({ id: item.id }));
                      // console.log(item.id);
                    }}
                    className="flex items-center -ms-[0.5vw]"
                  >
                    <img src={redDelete} alt="" />
                  </span>
                </div>
              );
            })}

          {/* {!files.length && ( */}
          <div
            className={` duration-200 ${
              !!files.length ? 'scale-1' : 'scale-0'
            }`}
          >
            <hr />
            <SelectMergeOption
              selected={mergeOption}
              setSelected={setMergeOption}
            />
          </div>
          {/* )} */}

          {/* <div>
        <input
          type="file"
          name=""
          id=""
          multiple="multiple"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
          </div> */}
        </div>
      </div>
      <div className="sticky bottom-0 ">
        <hr />
        <div className="px-[1vw] py-[.7vw]">
          <Button
            classNames="ms-auto"
            label="Continue"
            disabled={files.length === 0}
            onClick={() => setImportStep(2)}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportContacts;
