import React, { useEffect, useState } from "react";
import "./overviewCalender.css";
import "./weekCalendar.css";
import useCalendar from "../../../hooks/useCalender";
import BoardCards from "../../../modules/calendar/components/BoardCards";
import { rowData } from "../../../constant/app/calender/calender";
import { weekCalendar, weekCalender } from "../../../modules/calendar/api/Api";
import { useAspSelector } from "../../../test/jest-redux-hooks";
import moment from "moment";

function WeekCalendar({
  weekDates,
  pageNavigate,
  weekcalenderList,
  selectedStatus,
}) {
  const { daysArr } = useCalendar();

  const allowDrop = (event) => {
    event.preventDefault();
  };

  // weekcalenderList?.broadcasts?.forEach((broadcast) => {
  //   // broadcast?.segments?.forEach((segment) => {
  //   const scheduleOn = new Date(broadcast?.schedule_on);
  //   const time = scheduleOn.toLocaleTimeString([], {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   });
  //   // });
  // });

  return (
    <div className="flex-row">
      <div className="timimg2">
        <div className="timimg">
          <h2 className="gmt">GMT +05:30</h2>
        </div>
        <div className="flex-column mt-[31px]">
          {rowData.map((timeSlot, index) => (
            <div key={index} className="text-[0.625vw] text-[#898E99] h-[65px]">
              {timeSlot.time}
            </div>
          ))}
        </div>
      </div>

      <table className="weekCalendarWrapper w-100">
        <tbody>
          <tr className=" flex-row">
            {weekDates?.map((item, index) => {
              const isToday = moment(item)
                .utc()
                .startOf("day")
                .isSame(moment().utc().startOf("day"), "day");
              // const isToday = item.toDateString() === new Date().toDateString();
              return (
                // <th key={`th-${item?.date}`} className="weekDayWrapper">
                <th
                  key={`th-${item?.date}-${index}`}
                  className="weekDayWrapper"
                >
                  <div
                    className="weekDays flex-row align-center"
                    style={{
                      color: isToday
                        ? "var(--TextPrimary)"
                        : "var(--textBlack)",
                    }}
                  >
                    <div className="days">{daysArr[index]}</div>
                    <div className="date pl-5">{moment(item).utc().date()}</div>
                    {/* <div className="date pl-5">{item.getDate()}</div> */}
                  </div>
                </th>
              );
            })}
          </tr>

          {rowData.map((timeSlot, rowIndex) => (
            <tr key={`row-${timeSlot.id}-${rowIndex}`} className="flex-row">
              {timeSlot.data.map((item, index) => {
                const currentDate = weekDates[index];

                const filteredBroadcasts = weekcalenderList?.broadcasts?.filter(
                  (broadcast) => {
                    const scheduleOn = moment(broadcast?.schedule_on).utc();
                    const time = moment(broadcast.schedule_on)
                      .utc()
                      .format("hh:mm A")
                      .toUpperCase();

                    const cardTime = time;
                    const cardHour = parseInt(cardTime.split(":")[0]);
                    const cardMinutes = parseInt(cardTime.split(":")[1]);

                    const isCardAM = cardTime.includes("AM");

                    let adjustedHour = cardHour;
                    let adjustedMinutes = cardMinutes;

                    if (cardMinutes >= 1) {
                      adjustedHour++;
                      adjustedMinutes = 0;
                    } else if (cardMinutes > 30) {
                      adjustedMinutes = 30;
                    }

                    if (!isCardAM && cardHour !== 12) {
                      adjustedHour += 12;
                    }

                    if (adjustedHour === 24) {
                      adjustedHour = 0;
                    }

                    adjustedHour %= 24;

                    const extractedDate = moment(scheduleOn)
                      .utc()
                      .format("DD-MM-YYYY")
                      .replace(/\//g, "-");

                    const currentDateFormatted = moment(currentDate)
                      .utc()
                      .format("DD-MM-YYYY")
                      .replace(/\//g, "-");

                    let timeSlotHour = parseInt(timeSlot.time.split(":")[0]);
                    const timeSlotMinutes = parseInt(
                      timeSlot.time.split(":")[1]
                    );
                    if (timeSlotMinutes === 30 && timeSlotHour !== 12) {
                      timeSlotHour++;
                    }

                    const isTimeSlotAM = timeSlot.time.includes("AM");

                    let adjustedTimeSlotHour = timeSlotHour;
                    if (!isTimeSlotAM && timeSlotHour !== 12) {
                      adjustedTimeSlotHour += 12;
                    }

                    if (timeSlotMinutes === 30 && adjustedTimeSlotHour === 24) {
                      adjustedTimeSlotHour = 0;
                    }
                    
                    return (
                      extractedDate === currentDateFormatted &&
                      ((adjustedHour === adjustedTimeSlotHour &&
                        adjustedMinutes <= 30 &&
                        adjustedTimeSlotHour !== 0) ||
                        (adjustedHour === adjustedTimeSlotHour - 1 &&
                          adjustedMinutes > 30 &&
                          adjustedTimeSlotHour !== 0) ||
                        (adjustedHour === 0 &&
                          adjustedTimeSlotHour === 0 &&
                          adjustedMinutes <= 30))
                    );

                  }
                );

                const filteredBroadcastsObject = {};
                filteredBroadcasts?.forEach((broadcast) => {
                  if (broadcast?.broadcast_id !== undefined) {
                    filteredBroadcastsObject[broadcast?.broadcast_id] =
                      broadcast;
                  }
                });

                return (
                  <td
                    key={`cell-${timeSlot.id}-${index}`}
                    className={`${rowIndex === 0 ? "first-cell" : "week1"}`}
                    style={{ width: "calc(100% / 7)" }}
                    // id={currentDate && currentDate.toDateString()}
                  >
                    <BoardCards
                      pageNavigate={pageNavigate}
                      selectedStatus={selectedStatus}
                      card={filteredBroadcastsObject}
                      timeSlot={timeSlot}
                      weekDates={weekDates}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WeekCalendar;
