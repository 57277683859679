import React from 'react';
import './modal.css';

import { ICONS } from '../../../assets/icons/index';
import { images } from '../../../assets/images';

function header({
  handleCancel,
  title,
  content,
  iconPrefix,
  style,
  titleStyle,
}) {
  return (
    <div
      className='flex-row align-center'
      style={{
        borderBottom: '1px solid var(--authDivider)',
        ...style,
      }}
    >
      <div>
        {iconPrefix && (
          <img
            alt='leftImage'
            className='leftImage'
            src={images?.BrandCreditIcon}
          />
        )}
      </div>
      <div className='popup-header flex-row align-center space-between'>
        <div className=' flex-column'>
          {title && (
            <h2 className='popup-header-text' style={titleStyle}>
              {title}
            </h2>
          )}
          {content && <p className='popup-header-content'>{content}</p>}
        </div>

        <img
          className='popup-exit cursor-pointer mr-10'
          src={ICONS.popupX}
          alt='popup'
          onClick={handleCancel}
        />
      </div>
    </div>
  );
}

export default React.memo(header);
