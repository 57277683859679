import { createSlice } from '@reduxjs/toolkit';

import { account_center } from 'constant/app/account/account';

const initialState = {
  toggleToast: [],
  currentUser: null,
  currentBrand: null,
  currentAccount: null,
  globalLoader: false,
};

export const authSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateToggleToast: (state, action) => {
      state.toggleToast = action.payload;
    },
    updateCurrentUser: (state, action) => {
      const account_details = {
        ...action.payload,
        account_created:
          action?.payload?.active_accounts?.length > 0 ? true : false,
        brand_created: false,
      };
      state.currentUser = action.payload;
    },
    updateAccountDetails: (state, action) => {
      state.currentAccount = action?.payload?.currentAccount;
      state.currentBrand = action?.payload?.currentBrand;
    },
    updateCurrentBrand: (state, action) => {
      state.currentBrand = action.payload;
    },
    updateCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },
    updateGlobalLoader: (state, action) => {
      state.globalLoader = action.payload;
    },
    clear: (state, action) => {
      state.toggleToast = action?.payload?.toggleToast;
      state.currentUser = action?.payload?.currentUser;
      state.currentBrand = action?.payload?.currentBrand;
      state.currentAccount = action?.payload?.currentAccount;
      state.globalLoader = action?.payload?.globalLoader;
    },
  },
});

export const {
  updateToggleToast,
  updateCurrentUser,
  updateCurrentBrand,
  updateAccountDetails,
  updateGlobalLoader,
  updateCurrentAccount,
  clear,
} = authSlice.actions;

export default authSlice.reducer;
