import React, { useRef } from 'react';

export default function FileUploader({
  id,
  icon,
  label,
  format,
  handleUploadFiles,
  rootStyle = '',
  labelStyle = '',
  iconStyle = '',
}) {
  const ref = useRef();

  return (
    <button
      key={id}
      className={`w-full flex items-center relative gap-[0.6vw] px-[0.6vw] py-[0.35vw] rounded-[0.37vw] hover:bg-[var(--BG-25)] ${rootStyle}`}
    >
      <input
        type='file'
        accept={
          format
            ? format
            : label?.toUpperCase() === 'IMAGE'
            ? '.jpg, .png'
            : label?.toUpperCase() === 'VIDEO'
            ? '.mp4'
            : label?.toUpperCase() === 'DOCUMENT' && '.pdf'
        }
        onChange={(e) => {
          if (handleUploadFiles) handleUploadFiles(e);
        }}
        id='uploadImg'
        ref={ref}
        hidden
      />

      <label
        for='uploadImg'
        className={`w-full font-md weight-medium text-[var(--contentText)] flex items-center gap-[0.5vw] cursor-pointer ${labelStyle}`}
      >
        {icon && (
          <>
            {typeof icon === 'string' ? (
              <img
                src={icon}
                alt='uploader icon'
                className={`size-[1vw] ${iconStyle}`}
              />
            ) : (
              icon
            )}
          </>
        )}
        {label}
      </label>
    </button>
  );
}
