import React from 'react';

const CustomDelete = ({ color }) => {
  return (
    <div>
      <svg
        width='27'
        height='27'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 10.4706H21M12.75 8H17.25M13.5 18.7059V13.7647M16.5 18.7059V13.7647M17.625 22H12.375C11.5466 22 10.875 21.2626 10.875 20.3529L10.5326 11.3284C10.5148 10.8605 10.8554 10.4706 11.2819 10.4706H18.7181C19.1446 10.4706 19.4852 10.8605 19.4674 11.3284L19.125 20.3529C19.125 21.2626 18.4534 22 17.625 22Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export default CustomDelete;
