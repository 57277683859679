import React from 'react';
import { convertName } from '../../../helpers';
import Dropdown from '../../commonComponents/dropdown';

const Input = ({
  name,
  onChange,
  value,
  label,
  required = false,
  className,
  placeholder,
  type,
  showCountryDropDown = false,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="text-[.7vw] font-[500] capitalize">
          {convertName(label)}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}

      <div className="flex gap-[.6vw] mt-[.2vw]">
        {showCountryDropDown && (
          <Dropdown
            // open={selectOpen}
            heading="select Country"
            data={[{ label: '+91' }]}
            width="min-w-[5vw]"
            height="max-h-[32vh]"
            containerClassNames="px-[1.6vw]"
            // editFilter={}
            button={
              <div className="border border-solid border-gray-200 text-gray-900 text-sm rounded-lg w-[25%] px-[.5vw] py-[.5vw]-  flex justify-between items-center ">
                <span className="text-[.7vw] font-[500] text-slate-500">
                  +91
                </span>
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-gray-400 translate-y-[25%]`}
                />
              </div>
            }
          />
        )}
        <input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          // data-contact="contact"
          {...rest}
          className={`bg-gray-50- border border-solid border-gray-200 text-gray-900 text-[.8vw] rounded-lg w-full px-[.8vw] py-[.5vw] placeholder:font-[400] ${className}`}
          placeholder={placeholder}
          required={required}
        />
      </div>
    </>
  );
};

export default Input;
