import Label from '../../commonComponents/label';
import Search from '../../commonComponents/search';
import { Popover } from 'antd';

const Tags = ({
  toggle,
  className,
  zIndex = 60,
  Button,
  mainWidth = 'w-[21vw]',
  tags,
  setTags = () => {},
  addAction,
  // removeFunction,
  closeAction = () => {},
  placeholder = 'Search or create new tag',
  ...rest
}) => {
  return (
    <Popover
      zIndex={zIndex}
      content={
        <div
          className={`crm-dropdown  rounded-2xl bg-white p-[1vw] left-0 z-[60] ${mainWidth}`}
        >
          <div className="flex flex-wrap gap-[1.5vh]">
            {tags
              .filter((item) => item.selected)
              .map((item) => {
                return (
                  <Label
                    id={item.id}
                    key={item.id}
                    label={item.label}
                    color={item.color || 'red'}
                    closeAction={closeAction}
                  />
                );
              })}
          </div>
          <div className="">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Search placeholder={placeholder} />
            </form>
          </div>
          <div className="flex flex-wrap gap-[1.5vh]">
            {tags
              .filter((item) => !item.selected)
              .map((item) => {
                return (
                  <Label
                    id={item.id}
                    onClick={addAction}
                    showClose={false}
                    key={item.id}
                    label={item.label}
                    color={item.color || 'red'}
                  />
                );
              })}
          </div>
        </div>
      }
      arrow={false}
      {...rest} // will contain open
      placement="bottomLeft"
      trigger="click"
    >
      {Button}
    </Popover>
  );
};

export default Tags;
