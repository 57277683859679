import React from 'react';

// assets
import { ICONS } from '../../../../assets/icons';

export default function QuickReplayButtonPreview({
  customButtons,
  type = 'normal',
}) {
  return (
    <>
      {customButtons?.length > 0 && (
        <div
          className={` text-[#007AFF] font-md weight-medium ${
            type === 'small'
              ? 'px-[0.5vw] py-[0.3vw]'
              : type === 'medium'
              ? 'px-[0.5vw] py-[0.4vw]'
              : 'p-[0.5vw]'
          }`}
        >
          {customButtons?.map((b, i) => {
            return (
              <div
                key={`custom-button-${i}`}
                className={`flex items-center gap-[0.5vw] ${
                  type === 'small'
                    ? 'h-[1.7vw]'
                    : type === 'medium'
                    ? 'h-[2vw]'
                    : 'h-[2.5vw]'
                }`}
              >
                <img
                  src={ICONS?.shareLink}
                  alt='shareBtn'
                  className='size-[0.8vw]'
                />
                <p>{b.text}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
