import React, { useEffect, useRef } from 'react';
import TickCircle from '../../../../../assets/customSVG/TickCricle';

const UploadLoading = ({
  step = 1,
  progress,
  isUploading,
  handleUploadAnime,
  uploading,
  sanityChecksLoading,
  data,
}) => {
  const loaderRef = useRef(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      loaderRef.current.style.setProperty('--progress', `${progress}%`);
    }, 100);

    // return clearTimeout(timeout);
  }, []);
  const uploaded = 100 === 100;
  return (
    <div
      className={` ${
        !isUploading
          ? 'opacity-1 max-h-[2vw] w-initial min-w-max'
          : 'opacity-0 max-h-[0] w-0'
      }`}
    >
      <div className="flex items-center gap-[.8vw]- duration-200">
        <div
          className="progress w-[10vw] me-[0.8vw] duration-200 relative rounded-full h-[.3vw] bg-gray-100 "
          ref={loaderRef}
          // onClick={(e) => {
          //   e.target.style.setProperty('--progress', `${progress}%`);
          // }}
        >
          <div className="bar bg-[var(--primary)] h-full rounded-full">
            <div className="progress-value"></div>
          </div>
          <div className="absolute top-[.8vw] overflow-hidden left-0 text-[0.6vw] h-[1vw] w-[5vw]- flex items-center text-gray-400 bg-red-200- overflow-hidden- duration-200">
            <p
              className={`flex flex-col ${
                step === 1
                  ? 'translate-y-[.83vw]'
                  : step === 2
                  ? '-translate-y-[.1vw]'
                  : '-translate-y-[1.15vw]'
              } duration-200 bg-blue-400-`}
            >
              <span className="">{'File Uploading ...'}</span>
              <span className="">{'Sanity checks'}</span>
              <span className="">{'Sanity checks completed'}</span>
            </p>
          </div>
        </div>

        <div
          className={`max-w-[2vw] min-w-[2vw] h-[1vw] flex relative bg-red-200- h-`}
        >
          <span
            className={`absolute top-0 flex justify-center duration-200 ${
              step > 2 ? 'scale-100 w-full' : 'scale-0 w-0'
            }`}
          >
            <TickCircle width="1vw" stroke="white" fill="#25C277" />
          </span>
          <span
            className={`absolute duration-200 ${
              step <= 2 ? 'scale-100 w-full' : 'scale-0 w-0'
            }`}
          >
            <span className="text-[0.7vw] font-[500]">{100}%</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UploadLoading;
