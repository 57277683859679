import React from "react";
import "../../commonComponents/broadcastoverview/broadcast.css";


const BroadcastOverview = ({ list, Overview }) => {
  const getStatusCount = (item) => {
    switch (item?.title) {
      case "Contact":
        return Overview?.contacts_count || 0;
      case "Delivered":
        return Overview?.delivered_count || 0;
      case "Opened":
        return Overview?.opened_count || 0;
      case "Lead":
        return Overview?.leads_count || 0;
      case "Clicks":
        return Overview?.clicks_count || 0;
      case "Bounced":
        return Overview?.bounced_count || 0;
      default:
        return 0;
    }
  };

  return (
    <div className=" ">
      <div
        className=" flex-row space-between align-center "
        style={{ width: "100% " }}
      >
        <div className="postMain  w-100 ">
          {list?.length && (
            <div className="broadCast-card flex-row space-between align-center ">
              {list?.map((item, index) => (
                <React.Fragment key={item?.id}>
                  <div
                    className="flex-row space-between align-center "
                    style={{}}
                  >
                    <div className="broadcastImage flex-row justify-center">
                      <img src={item?.icon} alt="performance"/>
                    </div>
                    <div
                      className="postCard-left flex-column  "
                      style={{ padding: "10px 10px" }}
                    >
                      <span className="">{item?.title}</span>
                      <span className="">{getStatusCount(item)}</span>
                    </div>
                  </div>

                  <div
                    className={` ${
                      list?.length - 1 !== index ? "borderRight" : ""
                    }`}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BroadcastOverview;
