import React from 'react';

export default function Avatar({ width, height, color, bg = null, className }) {
  return (
    <svg
      width={width ?? '48'}
      height={height ?? '48'}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M47.42 23.71C47.42 36.8047 36.8047 47.42 23.71 47.42C10.6153 47.42 0 36.8047 0 23.71C0 10.6153 10.6153 0 23.71 0C36.8047 0 47.42 10.6153 47.42 23.71Z'
        fill={color ?? 'white'}
      />
      <path
        d='M23.71 0C10.6153 0 0 10.6153 0 23.71C0 36.8044 10.615 47.4187 23.7094 47.4187C36.8038 47.4187 47.4187 36.8038 47.4187 23.7094C47.4187 10.615 36.8044 0 23.71 0ZM23.7094 11.5949C28.398 11.5949 32.2131 15.41 32.2131 20.0987C32.2131 24.7879 28.398 28.6025 23.7094 28.6025C19.0202 28.6025 15.2056 24.7879 15.2056 20.0987C15.2056 15.41 19.0202 11.5949 23.7094 11.5949ZM23.7094 44.1748C17.8426 44.1748 12.5802 41.601 8.97953 37.524C10.6534 34.4945 13.2704 32.0045 16.3821 30.495C17.4406 29.9818 18.6761 29.9902 19.7726 30.5166C21.007 31.1099 22.3316 31.4102 23.7099 31.4102C25.0877 31.4102 26.4128 31.1093 27.6472 30.5166C28.7447 29.9897 29.9802 29.9818 31.0377 30.495C34.1494 32.004 36.7659 34.4945 38.4397 37.524C34.8386 41.6005 29.5761 44.1748 23.7094 44.1748Z'
        fill={bg ?? '#D1D3D8'}
      />
    </svg>
  );
}
