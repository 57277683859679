import React from 'react';

const email = ({ width = '24', height = '24' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="6" fill="#D9D8F4" />
      <path
        d="M5.875 7.35352L12 11.291L18.5625 7.35352M6.75 16.8263C5.7835 16.8263 5 16.0428 5 15.0763V8.22852C5 7.26202 5.7835 6.47852 6.75 6.47852H17.25C18.2165 6.47852 19 7.26202 19 8.22852V15.0763C19 16.0428 18.2165 16.8263 17.25 16.8263H6.75Z"
        stroke="#6940F2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default email;
