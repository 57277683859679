import { useEffect, useState } from 'react';

export function useTemplateVariables(values, valuesArr) {
  const [variables, setVariables] = useState([]);

  // filter all variables
  const variableRegEx = /{{(.*?)}}/g;

  // filter only custom variables
  const customVariablesRegEx = /{{(custom_variable\d+)}}/g;

  // validation patterns
  const variableValidationPattern = [
    /}}{{/g,
    /(.*?){{(.*?)}}}(.*?)/g,
    /(.*?){{{(.*?)}}(.*?)/g,
  ];

  const checkVariable = (value) =>
    value?.toString()?.toLowerCase()?.match(variableRegEx);

  const customVariables = (value) =>
    value?.toString()?.toLowerCase()?.match(customVariablesRegEx);

  const getVariablesFromStr = (str, value) => {
    setVariables([]);
    if (str) {
      const res = checkVariable(str);
      let newVariables = [];

      res?.map((r, i) => {
        const checkvariables = newVariables?.filter((v) => r === v?.title);
        if (checkvariables?.length === 0)
          newVariables?.push({
            id: i + 1,
            title: r,
            value: value?.[i],
          });
      });

      if (newVariables?.length > 0) setVariables(newVariables);
    } else {
      setVariables([]);
    }
  };

  const addVariable = (item, value) => {
    if (variables?.length > 0) {
      const checkVariableExist = variables?.filter((v) => v.title === item);
      if (checkVariableExist?.length === 0) {
        setVariables([
          ...variables,
          {
            id:
              variables?.length > 0
                ? variables?.[variables?.length - 1]?.id + 1
                : 1,
            title: item,
            value: value ?? '',
          },
        ]);
      }
    } else {
      setVariables([
        {
          id:
            variables?.length > 0
              ? variables?.[variables?.length - 1]?.id + 1
              : 1,
          title: item,
          value: value ?? '',
        },
      ]);
    }
  };

  const updateVariables = (id, key, value) => {
    const res = variables?.map((v) => {
      if (v.id === id) return { ...v, [key]: value };
      return v;
    });

    setVariables(res);
  };

  const replaceCustomVariableCount = (value) => {
    var count = 1;
    var updatedStr = value?.replaceAll(
      customVariablesRegEx,
      function (match, variable) {
        return `{{custom_variable${count++}}}`;
      }
    );

    return updatedStr;
  };

  const updateVariablesOrder = (value) => {
    let result = checkVariable(value);
    let list = [];

    result?.map((r, i) => {
      const data = variables?.filter((v) => v.title === r);

      const checkList = list?.filter((l) => l.title === r);
      if (data?.length > 0 && checkList?.length === 0)
        list.push({ ...data?.[0] });
      // need to add when typing
      else if (data?.length === 0 && checkList?.length === 0)
        list.push({
          // id: i + 1,
          title: r,
          value: '',
        });
    });

    setVariables(list);
    return list;
  };

  const checkVariablesAreValid = (value) => {
    const res = variableValidationPattern?.map((p, i) => {
      var updatedStr = value?.toString().toLowerCase().match(p);
      return updatedStr ? true : false;
    });

    return res?.filter((r) => r === true)?.length > 0 ? true : false;
  };

  const replaceVariables = (items) => setVariables(items);

  const getVariablesCount = (value) => {
    let count = 1;
    let updatedStr = value?.replaceAll?.(
      customVariablesRegEx,
      function (match, variable) {
        return `{{${count++}}}`;
      }
    );

    return updatedStr;
  };

  return {
    variables,
    checkVariable,
    customVariables,
    addVariable,
    updateVariables,
    updateVariablesOrder,
    replaceVariables,
    replaceCustomVariableCount,
    checkVariablesAreValid,
    getVariablesCount,
    getVariablesFromStr,
  };
}
