import React, { useEffect, useState } from 'react';
import '../style/AuthForm.css';
import '../style/SignUp.css';

import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ICONS } from '../../../assets/icons/index';
import useToggle from '../../../hooks/useToggle';

import { Form } from 'antd';

import { Button } from '../../../components/form/Button/Button';
import { Input } from '../../../components/form/Input/Input';

import updateToken from '../../../api/updateToken';
import Label from '../../../components/form/label/label';
import { validatePassword } from '../../../constant/auth/authValidation';

import {
  updateCurrentUser,
  updateToggleToast,
} from '../../../reduxToolkit/appSlice';
import { updateAuthToken } from '../../../reduxToolkit/authSlice';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';

import { currentUserDetails } from '../../../api/Api';
import { Login } from '../api/Api';

//style
const inputWidth = {
  width: '100%',
};
const mainContent = {
  width: '55%',
};

// inputRules
const emailRules = [
  {
    // required: true,
    // message: "Email is required",
  },
  {
    // type: "email",
    // message: "Enter valid email address"
  },
];
const passwordRules = [{ validator: validatePassword }];

function AuthForm() {
  const [value, toggleValue] = useToggle(false);
  const [verified, setVerified] = useState(false);

  const dispatch = useAspDispatch();
  const { signUp } = useAspSelector((state) => state.authentication);
  const { toggleToast, currentUser } = useAspSelector((state) => state.app);

  const [form] = Form.useForm();

  const navigate = useNavigate();
  let { pathname } = useLocation();

  const [queryParams] = useSearchParams();
  const validationToken = queryParams?.get('token');
  const validationEmail = localStorage.getItem('verify-email');

  let signUpLink = '/sign-up';

  console.log('login-hi', validationEmail, validationToken);

  //styles
  const button = {
    margin: '25px 0 15px 0',
    width: pathname === '/login' ? '21%' : '',
  };

  useEffect(() => {
    if (validationToken) {
      form.setFieldsValue({ Email: validationEmail });
      signUpLink += `?token=${validationToken}&email=${validationEmail}`;
    }

    console.log('signUpLink-2', signUpLink);

    // const TOKEN = localStorage.getItem('authToken');
    // if (TOKEN) {
    //   if (pathname === '/login') navigate('/user/home');
    //   else if (pathname !== '/reset-password') navigate('/Accounts');
    // } else {
    //   localStorage.clear();
    //   navigate('/login');
    // }
  }, [validationEmail, validationToken]);

  console.log('signUpLink', signUpLink);

  const onFinish = (values) => {
    if (pathname === '/login') {
      const payLoad = {
        email: values?.Email,
        password: values?.password,
      };
      if (validationToken) payLoad['invite_token'] = validationToken;

      toggleValue(true);
      Login(payLoad).then((res) => {
        loginResponseUpdate(res);
      });
    }
  };

  const loginResponseUpdate = async (res) => {
    toggleValue(false);
    if (res?.status === 200) {
      localStorage.setItem('authToken', res?.data?.auth_token);
      updateToken(res?.data?.auth_token);
      dispatch(updateAuthToken(res?.data?.auth_token));
      const user_res = await currentUserDetails().then((res) => {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Login Success',
              status: 'Success',
              duration: '',
            },
          ])
        );
        if (res?.status === 200) {
          dispatch(updateCurrentUser(res?.data));
          return res?.data;
        }

        return null;
      });

      if (user_res?.active_accounts?.length === 0) {
        navigate('/on-boarding');
      } else {
        navigate('/Accounts');
      }

      // setTimeout(() => {
      // dispatch(
      //   updateToggleToast([
      //     ...toggleToast,
      //     {
      //       id: toggleToast?.length + 1,
      //       content: 'Login Success',
      //       status: 'Success',
      //       duration: '',
      //     },
      //   ])
      // );
      // }, 1000);
    } else {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.response?.data?.error,
            status: 'Error',
            duration: '',
          },
        ])
      );
    }
  };

  // useEffect(() => {
  //   if (verified) {
  //     navigate("/Accounts");
  //   }
  // }, [verified]);

  return (
    <div className='step2Wrapper'>
      {/* <Google /> */}
      <Form
        onFinish={onFinish}
        className='step2Wrapper-form mt-[30px]'
        form={form}
        autoComplete='off'
      >
        <div style={mainContent}>
          <Label
            title={pathname === '/login' ? 'Email Address' : 'Email'}
            required
          />
          <Form.Item name='Email' style={inputWidth} rules={emailRules}>
            <Input
              iconPrefix={ICONS.emailBox}
              placeholder={
                pathname === '/login'
                  ? 'Enter email'
                  : 'ex:- yourmail@email.com'
              }
              // disabled={
              //   pathname.includes('/login') && validationToken ? true : false
              // }
            />
          </Form.Item>
          <div style={{ marginTop: '20px' }} />
          <Label title='Password' required />
          <Form.Item name='password' style={inputWidth}>
            <Input
              iconPrefix={ICONS.passwordLock}
              iconSuffixPassword={ICONS.passwordEyeOpen}
              type='password'
              placeholder={pathname === '/login' ? 'Password' : 'password'}
            />
          </Form.Item>

          {pathname === '/login' && (
            <div
              className='forgotPassword'
              onClick={() => navigate('/forgot-password')}
            >
              Forgot Password?
            </div>
          )}
        </div>
        <Button
          size='large'
          type='submit'
          width={'100%'}
          loading={value}
          disabled={value}
          label={pathname === '/login' ? 'Login' : `Create Account`}
          style={button}
        />
      </Form>

      {pathname === '/login' && (
        <div className='flex items-center justify-center gap-1 text-base weight-medium mt-4'>
          <p>Don’t have an account?</p>
          {/* <Link to={signUpLink} className='text-[var(--a)]'>
            Sign up
          </Link> */}
          <button
            type='submit'
            className='text-base weight-semibold flex items-center justify-center text-[var(--a)]'
            onClick={() => {
              if (validationToken)
                navigate({
                  pathname: '/sign-up',
                  search: createSearchParams({
                    token: queryParams?.get('token'),
                  }).toString(),
                });
              else navigate('/sign-up');
            }}
          >
            Sign up
          </button>
        </div>
      )}
    </div>
  );
}

export default AuthForm;
