export const phoneNumbersList = [
  {
    id: 1,
    label: 'All Phone Numbers',
  },
];

export const overview = [
  {
    id: 1,
    label: 'All conversation',
    value: 0,
    values: [
      {
        id: 1,
        label: 'Marketing',
        color: '#AC2F82',
        value: 0,
      },
      {
        id: 2,
        label: 'Utility',
        color: '#F96056',
        value: 0,
      },
      {
        id: 3,
        label: 'Authentication',
        color: '#0ACD95',
        value: 0,
      },
      {
        id: 4,
        label: 'Service',
        color: '#ff0',
        value: 0,
      },
    ],
  },
  {
    id: 2,
    label: 'Free conversation',
    value: 0,
    values: [
      {
        id: 1,
        label: 'Free tier',
        color: '#32CDCD',
        value: 0,
      },
      {
        id: 2,
        label: 'Free entry point',
        color: '#AC2F82',
        value: 0,
      },
    ],
  },
  {
    id: 3,
    label: 'Paid conversation',
    value: 0,
    values: [
      {
        id: 1,
        label: 'Marketing',
        color: '#32CDCD',
        value: 0,
      },
      {
        id: 2,
        label: 'Utility',
        color: '#AC2F82',
        value: 0,
      },
      {
        id: 3,
        label: 'Authentication',
        color: '#F96056',
        value: 0,
      },
      {
        id: 4,
        label: 'Service',
        color: '#0ACD95',
        value: 0,
      },
    ],
  },
  {
    id: 4,
    label: 'Charges',
    value: 0,
    values: [
      {
        id: 1,
        label: 'Marketing',
        color: '#32CDCD',
        value: 0,
      },
      {
        id: 2,
        label: 'Utility',
        color: '#AC2F82',
        value: 0,
      },
      {
        id: 3,
        label: 'Authentication',
        color: '#F96056',
        value: 0,
      },
      {
        id: 4,
        label: 'Service',
        color: '#0ACD95',
        value: 0,
      },
    ],
  },
];
