import React, { useMemo } from 'react';
import { crmFontColor } from '../../../constants';
import Label from '../../commonComponents/label';
import Tick from '../../../../../assets/customSVG/Tick';
import * as crmFilters from '../../../../../assets/customSVG/CrmFilterIcons';
import { Checkbox } from 'antd';
import DefaultAvatar from '../../../../../assets/customSVG/DefaultAvatar';
import Tags from './Tags';
import useTags from '../../../../../hooks/useTags';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SingleContact = ({
  contact: { id, ...rest },
  selectedItems,
  handleSelect, // setSelectedItems
  isTagsDropdownOpen,
  toggleTagsDropdown,
}) => {
  const navigate = useNavigate();
  const selectedTags = useMemo(() => {
    return [];
  }, []);
  const { tags } = useTags(selectedTags);
  const { dynamicColumns } = useSelector((store) => store.crm);
  const currentColumn = selectedItems.find((item) => item.id === id); // to show radio button if the the current column is selected
  return (
    <div
      className={`bg-white grid grid-flow-col rounded-lg py-[1.5vh]- min-w-min text-[.75vw] text-[${crmFontColor}] items-center contacts`}
    >
      {Object.entries(rest).map(([key, val], i) => {
        const curField = dynamicColumns.find((item) => item.label === key);

        if (!curField.selected) return;

        if (!val)
          return (
            <p
              key={i}
              className={`${
                key.includes('opt out') && 'text-center'
              } duration-200 overflow-hidden ${
                curField?.selected ? 'scale-100 w-full' : 'scale-0 w-0'
              }`}
            >
              --
            </p>
          );
        //

        //
        if (key === 'name')
          return (
            <div
              key={i}
              className={`font-[500] capitalize sticky left-0 z-[40] bg-slate-100 contact-select cursor-pointer duration-200`}
              onClick={() => {
                navigate(`${id}`);
              }}
            >
              <div
                className={`rounded-s-lg ps-[0.5vw] py-[1.3vh] bg-white flex items-center gap-2 h-full hover:ps-[2.7vw] duration-200 ${
                  currentColumn.selected && 'ps-[2.7vw]'
                }`}
              >
                <span
                  className={`absolute z-10 left-[1vw]`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(id);
                  }}
                >
                  <Checkbox
                    name={val.name}
                    id={id}
                    checked={currentColumn.selected}
                    // onChange={() => handleSelect(id)}
                  />
                </span>
                <span className="size-[2.2vw] border border-gray-100 overflow-hidden z-20 bg-gray-50 flex justify-center items-center rounded-full">
                  {val.url ? (
                    <img
                      src={val.url}
                      className="size-[2.2vw] object-cover"
                      alt=""
                    />
                  ) : (
                    <DefaultAvatar width="2.2vw" height="2.2vw" />
                  )}
                </span>
                <p>{val.name}</p>
              </div>
            </div>
          );
        //

        //
        if (key === 'tags') {
          return (
            <div
              key={i}
              className={`flex items-center gap-[.5vw] font-medium dut overflow-hidden ${
                curField?.selected
                  ? 'scale-100  min-w-[15vw_!important]'
                  : 'scale-0 w-0'
              }`}
            >
              {val.slice(0, 2).map(({ color, label }) => {
                return <Label key={label} label={label} color={color} />;
              })}

              <Tags
                open={isTagsDropdownOpen === id}
                onOpenChange={() => {
                  // console.log({ e, r });
                  toggleTagsDropdown(id);
                }}
                tags={tags}
                className="right-0 top-[2vw] bg-red-300- crm-dropdown"
                zIndex={2000}
                Button={
                  <span className="bg-gray-200 rounded-full size-6- size-6 text-[.7vw] flex items-center justify-center cursor-pointer">
                    +{val.length - 2}
                  </span>
                }
              />
            </div>
          );
        }
        //

        //
        if (key === 'contact source') {
          const Icon =
            crmFilters[
              `${val.slice(0, 1).toUpperCase()}${val.slice(1, val.length)}Icon`
            ];

          return (
            <div
              key={i}
              className={`flex items-center gap-[.6vw] duration-200 overflow-hidden ${
                curField?.selected ? 'scale-100 w-full' : 'scale-0 w-0'
              }`}
            >
              {/* <img src={crmFilters[`${val}Icon`]} alt={`${val}Icon`} /> */}
              {/* {console.log({ Icon })} */}
              <Icon />
              {val}
            </div>
          );
        }

        //
        if (key.includes('opt out')) {
          return (
            <p
              key={i}
              className={`bg-red-300- flex justify-center duration-200 overflow-hidden ${
                curField?.selected ? 'scale-100 w-full' : 'scale-0 w-0'
              }`}
            >
              <Tick
                stroke={crmFontColor}
                width="1vw"
                height="10"
                strokeWidth="3"
              />
            </p>
          );
        }
        //

        return (
          <p
            key={i}
            className={`line-clamp-1 duration-200 overflow-hidden ${
              curField?.selected ? 'scale-100 w-full' : 'scale-0 w-0'
            }`}
          >
            {val}
          </p>
        );
      })}
    </div>
  );
};

export default SingleContact;
