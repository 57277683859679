import React from "react";

const Group = ({ width = "16", height = "16", fill = "#616874" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8412_63703)">
        <path
          d="M10.9507 7.2621C12.1755 7.2621 13.1569 6.27338 13.1569 5.04853C13.1569 3.82369 12.1755 2.83496 10.9507 2.83496C9.72583 2.83496 8.7371 3.82369 8.7371 5.04853C8.7371 6.27338 9.72583 7.2621 10.9507 7.2621ZM5.04781 7.2621C6.27266 7.2621 7.25401 6.27338 7.25401 5.04853C7.25401 3.82369 6.27266 2.83496 5.04781 2.83496C3.82297 2.83496 2.83424 3.82369 2.83424 5.04853C2.83424 6.27338 3.82297 7.2621 5.04781 7.2621ZM5.04781 8.73782C3.32861 8.73782 -0.117188 9.60111 -0.117188 11.3203V12.4271C-0.117188 12.8329 0.214848 13.165 0.62067 13.165H9.47496C9.88078 13.165 10.2128 12.8329 10.2128 12.4271V11.3203C10.2128 9.60111 6.76702 8.73782 5.04781 8.73782ZM10.9507 8.73782C10.7367 8.73782 10.4932 8.75258 10.2349 8.77471C10.2497 8.78209 10.2571 8.79685 10.2645 8.80423C11.1056 9.41665 11.6885 10.2357 11.6885 11.3203V12.4271C11.6885 12.6854 11.6369 12.9362 11.5557 13.165H15.3778C15.7836 13.165 16.1157 12.8329 16.1157 12.4271V11.3203C16.1157 9.60111 12.6699 8.73782 10.9507 8.73782Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_8412_63703">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Group;
