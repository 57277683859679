import { useEffect, useState } from 'react';

import {
  addLineBreake,
  removeDoubleStyles,
  replaceFormating,
  replaceVariables,
} from 'modules/inbox/helper';

import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getFirtstTypeOfButton,
} from 'components/commonComponents/templates/Buttons/utils';

import { templateLists } from 'api/apiClients/templates';

import { useAspDispatch, useAspSelector } from 'test/jest-redux-hooks';

import { setData, update } from 'reduxToolkit/channelSlice';

import { useSearch } from '../useSearch';
import { useSelect } from '../useSelect';
import useToggle from '../useToggle';

let count = 0;
let page = 1;

export function useTemplate({ limit, status, category }) {
  const { currentBrand } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  // hooks
  const [loading, setLoading] = useToggle(true);
  const [counts, setCounts] = useState(null);
  const [isLoadingNextPage, setIsLoadingNextPage] = useToggle(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedCategory, setSelectedCategory] = useSelect([]);
  const { searchKey, setSearchKey, onChange, clearSearch } = useSearch('');

  const [templates, setTemplates] = useState([]);
  // const [page, setPage] = useState(1);

  const [singleTemplate, setSingleTemplate] = useState({
    headerObj: null,
    bodyObj: null,
    footerObj: null,
    buttons: [],
    ctaButtons: [],
    customButtons: [],
    buttonsOrder: '',
  });

  useEffect(() => {
    page = 1;
    if (limit > 0)
      fetchTemplateLists(true, currentBrand?.brand_id, {
        page: page,
        limit: limit,
        name: searchKey,
        status: status
          ? status
          : selectedStatus?.length > 0
          ? selectedStatus?.length > 1
            ? selectedStatus
                ?.map((s) => {
                  return s.status;
                })
                ?.join(',')
            : selectedStatus?.map((s) => {
                return s.status;
              })?.[0]
          : null,
        category: category
          ? category
          : selectedCategory?.length > 0
          ? selectedCategory?.length > 1
            ? selectedCategory
                ?.map((c) => {
                  return c.status;
                })
                ?.join(',')
            : selectedCategory?.map((c) => {
                return c.status;
              })?.[0]
          : null,
      });
  }, [currentBrand, status, searchKey, selectedStatus, selectedCategory]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     page = 1;
  //     if (limit > 0)
  //       fetchTemplateLists(true, currentBrand?.brand_id, {
  //         page: page,
  //         limit: limit,
  //         name: searchKey,
  //         status: status
  //           ? status
  //           : selectedStatus?.length > 0
  //           ? selectedStatus?.length > 1
  //             ? selectedStatus
  //                 ?.map((s) => {
  //                   return s.status;
  //                 })
  //                 ?.join(',')
  //             : selectedStatus?.map((s) => {
  //                 return s.status;
  //               })?.[0]
  //           : null,
  //         category: category
  //           ? category
  //           : selectedCategory?.length > 0
  //           ? selectedCategory?.length > 1
  //             ? selectedCategory
  //                 ?.map((c) => {
  //                   return c.status;
  //                 })
  //                 ?.join(',')
  //             : selectedCategory?.map((c) => {
  //                 return c.status;
  //               })?.[0]
  //           : null,
  //       });
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [currentBrand, status, selectedStatus, selectedCategory, searchKey]);

  const fetchTemplateLists = async (load, brandId, payload) => {
    if (brandId) {
      if (load) setLoading(true);
      if (!load) setIsLoadingNextPage(true);

      await templateLists(brandId, payload)
        .then((res) => {
          if (res && res.data?.templates?.length > 0) {
            setCounts(res?.data?.templates_overview);
            if (page === 1) setTemplates(res?.data?.templates);
            else setTemplates([...templates, ...res?.data?.templates]);
            count = res?.data?.template_count;
            // whatsapp_business_account_id;
            dispatch(setData(res?.data));
          } else {
            setTemplates([]);
          }
        })
        .finally(() => {
          if (load) setLoading(false);
          if (!load) setIsLoadingNextPage(false);
        });
    } else {
      setTemplates([]);
    }
  };

  const loadTemplateList = async () => {
    page = 1;
    if (limit > 0)
      fetchTemplateLists(true, currentBrand?.brand_id, {
        page: page,
        limit: limit,
        name: searchKey,
        status: status
          ? status
          : selectedStatus?.length > 0
          ? selectedStatus?.length > 1
            ? selectedStatus
                ?.map((s) => {
                  return s.status;
                })
                ?.join(',')
            : selectedStatus?.map((s) => {
                return s.status;
              })?.[0]
          : null,
        category: category
          ? category
          : selectedCategory?.length > 0
          ? selectedCategory?.length > 1
            ? selectedCategory
                ?.map((c) => {
                  return c.status;
                })
                ?.join(',')
            : selectedCategory?.map((c) => {
                return c.status;
              })?.[0]
          : null,
      });
  };

  const updateSingleTemplateData = (data) => {
    const res = templates?.map((t) => {
      if (t?.name === data?.name)
        return {
          ...t,
          category: data?.category,
          components: data?.components,
          // id: data?.id,
          language: data?.language,
          meta_modified_at: data?.last_updated_time,
          name: data?.name,
          rejected_reason: data?.rejected_reason,
          status: data?.status,
        };
      return t;
    });
    setTemplates(res);
  };

  const loadNextPage = () => {
    fetchTemplateLists(false, currentBrand?.brand_id, {
      page: page + 1,
      limit: limit,
      name: searchKey,
      status: status
        ? status
        : selectedStatus?.length > 0
        ? selectedStatus
            ?.map((s) => {
              return s.status;
            })
            ?.join(',')
        : null,
      category: category
        ? category
        : selectedCategory?.length > 0
        ? selectedCategory?.length > 1
          ? selectedCategory
              ?.map((c) => {
                return c.status;
              })
              ?.join(',')
          : selectedCategory?.map((c) => {
              return c.status;
            })?.[0]
        : null,
    });
    page += 1;
  };

  const updateStatus = (item) => {
    const checkIsExist = selectedStatus?.filter(
      (s) => s?.value === item?.value
    );

    if (checkIsExist?.length > 0) {
      const filtered = selectedStatus.filter((s) => s?.value !== item?.value);
      setSelectedStatus(filtered);
      // filtereTemplateListWithStatus(filtered);
    } else {
      const newList = [...selectedStatus, item];
      setSelectedStatus(newList);
      // filtereTemplateListWithStatus(newList);
    }
  };

  const updateCategory = (item) => {
    const checkIsExist = selectedCategory?.filter(
      (s) => s?.value === item?.value
    );

    if (checkIsExist?.length > 0) {
      const filtered = selectedCategory.filter((c) => c?.value !== item?.value);
      setSelectedCategory(filtered);
      // filtereTemplateListWithStatus(filtered);
    } else {
      const newList = [...selectedCategory, item];
      setSelectedCategory(newList);
      // filtereTemplateListWithStatus(newList);
    }
  };

  // single template
  const updateSingleTemplate = (data) => {
    const header = data?.components?.filter((c) => c.type === 'HEADER');
    const body = data?.components?.filter((c) => c.type === 'BODY');
    const footer = data?.components?.filter((c) => c.type === 'FOOTER');
    const buttons = data?.components?.filter((c) => c.type === 'BUTTONS');

    // buttons customization
    const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
      return {
        id: i + 1,
        format:
          b.type === 'QUICK_REPLY'
            ? b.text !== 'Stop promotions'
              ? 'CUSTOM'
              : 'OPT_OUT'
            : b.type,
        ...b,
      };
    });

    const order = getFirtstTypeOfButton(updatedButtons);
    const ctaButtons = updatedButtons?.filter((b) =>
      allowedCtaButtonTypes.includes(b.type)
    );
    const customButtonsWithoutOptout =
      updatedButtons?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text !== 'Stop promotions'
      ) || [];
    const customButtonsWithOptout =
      updatedButtons?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text === 'Stop promotions'
      ) || [];

    const customButtons = [
      ...customButtonsWithoutOptout,
      ...customButtonsWithOptout,
    ];

    let headerText = '';
    if (header?.[0]?.example?.header_text?.[0]) {
      // const newRes = removeDoubleStyles(body?.[0]?.text);
      const res = replaceFormating(header?.[0]?.text);
      headerText = addLineBreake(
        replaceVariables(res, header?.[0]?.example?.header_text)
      );
    } else {
      // const newRes = removeDoubleStyles(body?.[0]?.text);
      headerText = addLineBreake(replaceFormating(header?.[0]?.text));
    }

    let bodyText = '';
    if (body?.[0]?.example?.body_text?.[0]) {
      // const newRes = removeDoubleStyles(body?.[0]?.text);
      const res = replaceFormating(body?.[0]?.text);
      const variableUpdate = replaceVariables(
        res,
        body?.[0]?.example?.body_text?.[0]
      );
      bodyText = addLineBreake(variableUpdate);
    } else {
      // const newRes = removeDoubleStyles(body?.[0]?.text);
      bodyText = addLineBreake(replaceFormating(body?.[0]?.text));
    }

    setSingleTemplate({
      headerObj:
        header?.length > 0 ? { ...header?.[0], text: headerText } : null,
      bodyObj: body?.length > 0 ? { ...body?.[0], text: bodyText } : null,
      footerObj: footer?.length > 0 ? footer?.[0] : null,
      buttons: updatedButtons?.length > 0 ? updatedButtons : [],
      ctaButtons: ctaButtons?.length > 0 ? ctaButtons : [],
      customButtons: customButtons?.length > 0 ? customButtons : [],
      buttonsOrder:
        order === 'ctaButtons' && ctaButtons?.length > 0
          ? 'ctaButtons'
          : order === 'custom' && customButtons?.length > 0
          ? 'custom'
          : '',
    });
  };

  return {
    loading,
    setLoading,
    searchKey,
    setSearchKey,
    onSearch: onChange,
    clearSearch,
    isLoadingNextPage,
    setIsLoadingNextPage,
    counts,
    templates,
    setTemplates,
    fetchTemplateLists,
    loadTemplateList,
    count,
    loadNextPage,
    singleTemplate,
    updateSingleTemplate,
    updateSingleTemplateData,
    selectedStatus,
    setSelectedStatus,
    updateStatus,
    selectedCategory,
    setSelectedCategory,
    updateCategory,
  };
}
