import React from 'react';

function Star({
  width = 20,
  height = 20,
  fill = 'none',
  strokeWidth,
  stroke = '#898E99',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 19'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.91298 1.76159C9.08512 1.4128 9.58248 1.4128 9.75462 1.76159L11.983 6.27669C12.0513 6.4152 12.1834 6.5112 12.3363 6.53341L17.319 7.25744C17.7039 7.31337 17.8576 7.78639 17.5791 8.05788L13.9736 11.5724C13.863 11.6802 13.8125 11.8355 13.8386 11.9878L14.6897 16.9504C14.7555 17.3337 14.3531 17.626 14.0088 17.4451L9.55217 15.102C9.41546 15.0302 9.25214 15.0302 9.11543 15.102L4.65875 17.4451C4.31448 17.626 3.91211 17.3337 3.97786 16.9504L4.82901 11.9878C4.85511 11.8355 4.80464 11.6802 4.69404 11.5724L1.08852 8.05788C0.809994 7.78639 0.963687 7.31337 1.3486 7.25744L6.33131 6.53341C6.48416 6.5112 6.61629 6.4152 6.68465 6.27669L8.91298 1.76159Z'
        stroke={stroke}
        strokeWidth={strokeWidth ?? '1.66667'}
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default Star;
