import React from 'react';

export default function Share({
  width = '18',
  height = '18',
  color = '#898E99',
  strokeWidth = '0.5',
  className = '',
}) {
  return (
    <svg
      width={width ?? '18'}
      height={height ?? '18'}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M16.2803 8.46959L10.2802 2.46959C10.1754 2.36473 10.0417 2.29333 9.89627 2.2644C9.75081 2.23548 9.60003 2.25033 9.46301 2.30708C9.32598 2.36383 9.20886 2.45994 9.12645 2.58325C9.04404 2.70655 9.00003 2.85153 9 2.99984V5.65859C6.95122 5.84818 5.04692 6.79549 3.65977 8.31511C2.27262 9.83474 1.50245 11.8173 1.5 13.8748V14.9998C1.50012 15.1555 1.54868 15.3073 1.63896 15.4342C1.72923 15.561 1.85674 15.6566 2.00379 15.7077C2.15085 15.7588 2.31017 15.7629 2.45965 15.7194C2.60913 15.6759 2.74136 15.5869 2.838 15.4648C3.57277 14.5911 4.47432 13.8725 5.48996 13.3511C6.5056 12.8297 7.61497 12.5159 8.75325 12.4281C8.79075 12.4236 8.8845 12.4161 9 12.4086V14.9998C9.00003 15.1482 9.04404 15.2931 9.12645 15.4164C9.20886 15.5397 9.32598 15.6358 9.46301 15.6926C9.60003 15.7494 9.75081 15.7642 9.89627 15.7353C10.0417 15.7064 10.1754 15.6349 10.2802 15.5301L16.2803 9.53009C16.4209 9.38944 16.4998 9.19871 16.4998 8.99984C16.4998 8.80097 16.4209 8.61024 16.2803 8.46959ZM10.5 13.1893V11.6248C10.5 11.4259 10.421 11.2352 10.2803 11.0945C10.1397 10.9539 9.94891 10.8748 9.75 10.8748C9.55875 10.8748 8.778 10.9123 8.5785 10.9386C6.55715 11.1248 4.63306 11.8928 3.039 13.1496C3.21993 11.4954 4.00465 9.9661 5.24292 8.85446C6.48118 7.74282 8.08596 7.12698 9.75 7.12484C9.94891 7.12484 10.1397 7.04582 10.2803 6.90517C10.421 6.76452 10.5 6.57375 10.5 6.37484V4.81034L14.6895 8.99984L10.5 13.1893Z'
        fill={color}
      />
    </svg>
  );
}
