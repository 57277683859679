import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Error from '../modules/error/Index';

// auth
import Login from '../modules/auth/pages/Login';
import ForgotPassword from '../modules/auth/pages/ForgotPassword';
import ResetPassword from '../modules/auth/pages/ResetPassword';
import AuthLayout from '../layouts/authLayout/Index';
import Stepper from '../modules/auth/pages/Stepper';
import OnBoarding from '../modules/auth/pages/OnBoarding';
import VerifiyEmail from '../modules/auth/pages/VerifyEmail';

import Accounts from '../modules/auth/components/Accounts';
import Resend from '../modules/auth/components/Resend';
import SignUp from '../modules/auth/pages/SignUp';

// app
import AppLayout from '../layouts/appLayout/Index';
import Home from '../modules/home/pages/Home';

// campaign
import Campaign from "../modules/campaign/pages/Campaign";
import CampaignList from "../modules/campaign/components/campaigns/CampaignList";
import Api from "../modules/campaign/components/ApiCampaign/Api";
import ContactApi from "../modules/campaign/components/ApiCampaign/ContactApi";
// import TaskRight from "../modules/Task/pages/TaskRightLayout";

// channels
import ListOfChannels from '../modules/channels/page/Channels';
import Whatsapp from '../modules/channels/page/whatsapp/Whatsapp';
// messageTemplate
import MessageTemplate from '../modules/channels/page/whatsapp/messageTemplate/MessageTemplate';
import CreateTemplateWA from '../modules/channels/components/templates/CreateTemplateWA';
import TemplateDetails from '../modules/channels/components/templates/TemplateDetails';
// phoneNumbers
import PhoneNumberss from '../modules/channels/page/whatsapp/phoneNumbers/Index';
// insights
import Insights from '../modules/channels/page/whatsapp/insights/Insights';

import Broadcast from "../modules/campaign/components/BroadcastSetting/BroadCast";
import BroadcastSetting from "../modules/campaign/components/BroadcastSetting/BroadcastSetting";
import SegmentList from "../modules/campaign/components/BroadcastSetting/BroadcastView/SegmentList";

// calendar
import Calendar from '../modules/calendar/pages/calendar';

//settings
import Settings from '../modules/settings/pages/Settings';
import BrandSettings from '../modules/settings/pages/BrandSettings';

// inbox
import Inbox from '../modules/inbox/pages/Inbox';

// crm
import CrmSidebar from '../modules/crm/pages/CrmHome';
import CrmHome from '../modules/crm/components/main';
import SingleContact from '../modules/crm/pages/SingleContact';

function Index() {
  const TOKEN = localStorage.getItem('authToken');
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='*'
          element={<Navigate to={TOKEN !== null ? '/Accounts' : '/login'} />}
        />
        <Route path='/verify-email' element={<VerifiyEmail />} />

        <Route path='/404' element={<Error />} />
        <Route element={<AuthLayout />}>
          {/* <Route path='/stepper' element={<Stepper />} /> */}

          {/* self serve */}
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/on-boarding' element={<OnBoarding />} />

          <Route path='/resend' element={<Resend />} />

          <Route path='/login' element={<Login />} />
          <Route path='/Accounts' element={<Accounts />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route path='/user/home' element={<Home />}></Route>

          <Route path='/user/campaign' element={<Campaign />}>
            <Route path=':campaign_type'>
              <Route index element={<CampaignList />} />
              <Route path=':id' element={<Broadcast />} />
              <Route path='settings/:id' element={<BroadcastSetting />} />
              <Route path='segment_list/:id' element={<SegmentList />} />
            </Route>
            <Route path='api'>
              <Route index element={<Api />} />
              <Route path=':id' element={<ContactApi />} />
            </Route>

            {/* <Route path="ContactApi/:id" element={<ContactApi />} /> */}

            {/* <Route path="Broadcast/:id" element={<Broadcast />} /> */}
            {/* <Route path="BroadcastSetting/:id" element={<BroadcastSetting />} />
            <Route path="SegmentList/:id" element={<SegmentList />} /> */}
          </Route>

          <Route path='/user/calendar/' element={<Calendar />} />
          <Route path='/user/settings' element={<Settings />}>
            <Route index path='brand-settings' element={<BrandSettings />} />
          </Route>
          <Route path='/user/inbox' element={<Inbox />} />

          {/* channels */}
          <Route path='/user/channels' element={<ListOfChannels />}>
            <Route path='whatsapp' element={<Whatsapp />}>
              <Route path='phonenumbers' element={<PhoneNumberss />} />
              <Route path='message-templates' element={<MessageTemplate />} />
              <Route path='insights' element={<Insights />} />
              {/* Template create, edit and duplicate */}
              <Route path=':template'>
                <Route index element={<CreateTemplateWA />} />
                <Route path='template-details' element={<TemplateDetails />} />
              </Route>
            </Route>
          </Route>

          {/* CRM */}
          <Route path='/user/develop-crm-prod/' element={<CrmSidebar />}>
            <Route index element={<CrmHome />} />
            <Route path=':id' element={<SingleContact />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Index;
