import React from 'react';

const RedCircleInfo = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 10V6.4375M9.5 12.6406V12.6719M16.625 10C16.625 13.935 13.435 17.125 9.5 17.125C5.56497 17.125 2.375 13.935 2.375 10C2.375 6.06497 5.56497 2.875 9.5 2.875C13.435 2.875 16.625 6.06497 16.625 10Z"
        stroke="#F96056"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RedCircleInfo;
