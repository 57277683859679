export const MessageList = [
  { id: 1, name: "Status", selected: true },
  { id: 2, name: "Category", selected: true },
  { id: 3, name: "Language", selected: true },
  { id: 4, name: "Active Broadcast", selected: true },
  {
    id: 5,
    name: "Total Runs",
    selected: true,
  },
  { id: 6, name: "Message Sent", selected: true },
  { id: 7, name: "Delivered", selected: true },
  { id: 8, name: "Message Read", selected: true },
];
