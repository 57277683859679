import React from 'react';

// helper
import { cn } from 'helper/cn';

export default function index({
  icon,
  title,
  content,
  rootClassName = '',
  titleClassName = '',
  contentClassName = '',
  style = '',
  iconStyle = '',
}) {
  return (
    <div className={cn('w-full h-full flex-center', rootClassName)}>
      <div className={cn('w-full text-center ', style)}>
        {icon && (
          <div className='flex-center'>
            {typeof icon === 'string' ? (
              <img
                src={icon}
                alt={icon}
                className={cn('w-auto h-auto', iconStyle)}
              />
            ) : (
              icon
            )}
          </div>
        )}
        <div className='flex-center flex-col'>
          {title && (
            <h2
              className={cn(
                'font-larger weight-bold text-[#2D3036] mt-[0.75vw] mb-[0.4vw]',
                titleClassName
              )}
            >
              {title}
            </h2>
          )}
          {content && (
            <p
              className={cn(
                'font-md weight-medium text-[#616874]',
                contentClassName
              )}
            >
              {content}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
