import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useTags = (selectedTagsIds) => {
  const { tags } = useSelector((store) => store.crm);
  const [globalTags, setGlobalTags] = useState([]);
  useEffect(() => {
    setGlobalTags(
      tags.map((item) => {
        return { ...item, selected: selectedTagsIds.includes(item.id) };
      }),
    );
  }, [selectedTagsIds]);
  return { tags: globalTags, setTags: setGlobalTags };
};

export default useTags;
