import { Popover } from "antd";

import React, { useState } from "react";
import "../../styles/Broadcast.css";

import { SendPeopleList } from "../../../../constant/app/campaign/campaign";
import RadioButton from "../../../../components/form/radio/RadioButton";
import { ICONS } from "../../../../assets/icons";
import { useAspSelector } from "../../../../test/jest-redux-hooks";

const daily_messaging_limit = {
  TIER_50: "50",
  TIER_250: "250",
  TIER_1K: "1k",
  TIER_10K: "10k",
  TIER_100: "100",
  TIER_UNLIMITED: "Unlimited",
};

const quality_rating = {
  UNKNOWN: "High",
  GREEN: "High",
  RED: "Low",
  YELLOW: "Medium"
};

const SendForm = ({
  numberDetails,
  handleClick,
  setSelectedRole,
  selectedRole,
  selectedItemId,
  broadCasts,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const SendPeople = () => {
    return (
      <div className="popover-container flex-row">
        <div>
          {numberDetails?.map((data) => {
            return (
              <div
                className="   flex-column pointer "
                key={data?.id}
                onClick={() => {
                  if (selectedItemId !== data.id) {
                    setSelectedRole(data);
                    setPopoverVisible(true);
                    handleClick(data?.id);
                    setPopoverVisible(false);
                  }
                }}
              >
                <div className=" flex-row w-100 space-between">
                  <div className=" flex-row  w-100  space-between ">
                    <p className="pr-10">{data?.send_from_phone_name} </p>

                    <div
                      className="w-30 flex-row flex-end"
                      style={{
                        transform: "translate(16px, 14px)",
                      }}
                    >
                      {selectedItemId === data?.id && (
                        <RadioButton selected={selectedItemId === data.id} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-row align-center pt-5">
                  <span
                    style={{
                      borderRight: "1px solid #D1D3D8",
                      paddingRight: "15px",
                      height: "16px",
                      marginRight: "5px",
                    }}
                  >
                    <span className="pr-5"> {data?.country_code}</span>
                    {data?.send_from_phone_number}
                  </span>
                  <div
                    className={`TemplateList-quality1 flex-row align-center   ${
                      quality_rating[data?.quality_rating]
                    }`}
                  >
                    <span className="border-q"></span>
                    <span className=" broadcast-status  flex-row align-center justify-center pr-10 pl-5">
                      {quality_rating[data?.quality_rating]}
                    </span>
                    <div>
                      <span
                        className="pl-10 broadcast-status flex-row align-center justify-center "
                        style={{
                          borderLeft: "1px solid #D1D3D8",
                          height: "16px",
                        }}
                      >
                        {daily_messaging_limit[data?.daily_messaging_limit]}
                        /24hr
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className="setting-container flex-column  ">
      <Popover
        content={<SendPeople numberDetails={SendPeopleList} />}
        trigger="click"
        placement="bottom"
        arrow={false}
        className="antd"
        open={popoverVisible}
        onOpenChange={handleVisibleChange}
      >
        <div className="flex-column">
          <span>Send From</span>
          <span>
            Select the WhatsApp number you would like to send the messages.
          </span>
          <div
            className={`message-container flex-row align-center space-between pointer ${
              selectedRole?.send_from_phone_name ||
              broadCasts?.settings?.send_from_phone_name
                ? ""
                : "opacity-50"
            }`}
          >
            <div className="flex-row align-center justify-center pointer">
              {(selectedRole?.send_from_phone_name ||
                broadCasts?.settings?.send_from_phone_name) &&
                (broadCasts?.settings?.send_from_phone_name ||
                  !selectedRole?.send_from_phone_name) && (
                  <img src={ICONS?.whatsApp} alt="whatsappLogo" />
                )}

              <p className="selected-name ">
                {(selectedRole && selectedRole.send_from_phone_name) ||
                (!selectedRole &&
                  broadCasts &&
                  broadCasts.settings &&
                  broadCasts.settings.send_from_phone_name)
                  ? selectedRole
                    ? selectedRole.send_from_phone_name
                    : broadCasts.settings.send_from_phone_name
                  : "Choose WhatsApp Number"}
              </p>
            </div>
            <img
              src={ICONS?.modelDropdownIcon}
              alt="modelDropdownIcon"
              className="pointer"
            />
           
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default SendForm;
