import React from 'react';

export default function Tick({
  width = '18',
  height = '14',
  stroke = '#0ACD95',
  strokeWidth = '2',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4655 1.40039L5.32925 12.6004L1.5332 8.78262"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
