import React, { useEffect, useRef, useState } from 'react';
import Edit from '../../../../assets/customSVG/Edit';
import Close from '../../../../assets/customSVG/Close';
import Tick from '../../../../assets/customSVG/Tick';

const ProfileCard = ({
  label,
  value,
  Icon,
  onChange = () => {},
  editable = true,
}) => {
  const [mouseDown, setMouseDown] = useState(false);
  const editRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    if (editRef.current && !editRef.current.contains(e.target)) {
      setMouseDown(false);
    }
  };
  return (
    <div>
      <p className="capitalize text-[.85vw] font-[500] text-gray-800 mb-[.4vw]">
        {label}
      </p>

      <div
        ref={editRef}
        className={`duration-500 flex items-center w-full border-[0.08vw] rounded-md text-[#616874] text-[.9vw] pe-[.3vw] py-[.3vw] ${
          mouseDown
            ? 'ps-[.5vw] border-[#8B5CF6]'
            : editable
            ? 'contact-edit-cont'
            : 'border-transparent'
        }`}
      >
        {editable ? (
          <>
            {Icon && (
              <span className="me-[.8vw]">
                <Icon width={20} height={20} />
              </span>
            )}
            {mouseDown && editable ? (
              <input
                type="text"
                disabled={!mouseDown}
                className="w-full bg-transparent"
                value={value}
                onChange={onChange}
              />
            ) : (
              <span className="w-full">{value || '--'}</span>
            )}
            {!mouseDown && editable && (
              <span
                className={`duration-200 hover:bg-gray-100 cursor-pointer p-[.3vw] rounded-md input-editIcon`}
                onClick={() => {
                  setMouseDown(true);
                }}
              >
                <Edit width={15} height={15} />
              </span>
            )}
            <div
              className={`flex items-center gap-[.3vw] justify-between *:cursor-pointer *:rounded-md duration-200 ${
                mouseDown && editable ? 'w-[28%]' : 'w-0'
              } overflow-hidden`}
            >
              <span
                className="hover:bg-gray-100 duration-150"
                onClick={() => {
                  setMouseDown(false);
                }}
              >
                <Close
                  circle={false}
                  stroke="gray"
                  // width="15"
                  // height="15"
                  strokeWidth="2"
                />
              </span>
              <span
                className="hover:bg-gray-100 duration-150 px-[.3vw]"
                onClick={() => {
                  setMouseDown(false);
                }}
              >
                <Tick width={15} height={24} />
              </span>
            </div>
          </>
        ) : (
          <span className="w-full">{value || '--'}</span>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
