import React, { useEffect, useRef, useState } from 'react';

// icons
import { ICONS } from '../../../assets/icons';

export default function SearchBar({
  loading = false,
  searchKey,
  setSearchKey,
  onChange,
  placeholder,
  autoFocus = false,
  close = true,
  className = '',
}) {
  const [isInputFocused, setInputFocused] = useState(false);
  const inputRef = useRef();

  const handleCancel = () => {
    setSearchKey('');
    onChange('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [searchKey]);

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setInputFocused(false);
    }
  };

  return (
    <div
      ref={inputRef}
      className={`w-full h-full flex items-center px-[0.6vw] py-[0.5vw] rounded-[0.5vw] overflow-hidden transition-colors duration-200 ease-linear hover:bg-[var(--BG-25)] ${
        isInputFocused || searchKey ? 'bg-[var(--BG-25)]' : ''
      } ${loading ? 'cursor-not-allowed' : 'cursor-pointer'} ${className}`}
    >
      <img
        src={ICONS?.searchIcon}
        alt='search icon'
        className='w-[1vw] h-[1vw]'
      />
      <input
        type='text'
        placeholder={placeholder ?? 'Search...'}
        value={searchKey}
        className={`w-full pl-[0.6vw] pr-[0.3vw] border-none outline-none bg-transparent text-[var(--textBlack)] font-md weight-medium placeholder:text-[var(--font-400)] placeholder:weight-small  ${
          loading ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onChange={(e) => {
          if (!loading && onChange) onChange(e.target.value);
        }}
        onFocus={() => {
          if (!loading) setInputFocused(true);
        }}
        onBlur={() => {
          if (!loading) setInputFocused(false);
        }}
        autoFocus={autoFocus}
        disabled={loading}
      />

      {close && (
        <img
          src={ICONS?.popupX}
          alt='exit'
          className={`w-[1.1vw] h-[1.1vw] rounded-full cursor-pointer transition-transform duration-300 ease-linear ${
            searchKey ? 'scale-125' : 'scale-0'
          }`}
          onClick={() => {
            if (!loading) handleCancel();
          }}
        />
      )}
    </div>
  );
}
