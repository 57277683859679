import React from 'react';

export default function Duplicate({ width, height, color, className }) {
  return (
    <svg
      width={width ?? '14'}
      height={height ?? '15'}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M11.6667 3.61111H10.8889V2.83333C10.8889 2.21449 10.6431 1.621 10.2055 1.18342C9.76789 0.745833 9.17439 0.5 8.55556 0.5H2.33333C1.71449 0.5 1.121 0.745833 0.683418 1.18342C0.245833 1.621 0 2.21449 0 2.83333V9.05556C0 9.67439 0.245833 10.2679 0.683418 10.7055C1.121 11.1431 1.71449 11.3889 2.33333 11.3889H3.11111V12.1667C3.11111 12.7855 3.35694 13.379 3.79453 13.8166C4.23211 14.2542 4.82561 14.5 5.44444 14.5H11.6667C12.2855 14.5 12.879 14.2542 13.3166 13.8166C13.7542 13.379 14 12.7855 14 12.1667V5.94444C14 5.32561 13.7542 4.73211 13.3166 4.29453C12.879 3.85694 12.2855 3.61111 11.6667 3.61111ZM2.33333 9.83333C2.12705 9.83333 1.92922 9.75139 1.78336 9.60553C1.6375 9.45967 1.55556 9.26184 1.55556 9.05556V2.83333C1.55556 2.62705 1.6375 2.42922 1.78336 2.28336C1.92922 2.1375 2.12705 2.05556 2.33333 2.05556H8.55556C8.76184 2.05556 8.95967 2.1375 9.10553 2.28336C9.25139 2.42922 9.33333 2.62705 9.33333 2.83333V3.61111H5.44444C4.82561 3.61111 4.23211 3.85694 3.79453 4.29453C3.35694 4.73211 3.11111 5.32561 3.11111 5.94444V9.83333H2.33333ZM12.4444 12.1667C12.4444 12.3729 12.3625 12.5708 12.2166 12.7166C12.0708 12.8625 11.8729 12.9444 11.6667 12.9444H5.44444C5.23816 12.9444 5.04033 12.8625 4.89447 12.7166C4.74861 12.5708 4.66667 12.3729 4.66667 12.1667V5.94444C4.66667 5.73816 4.74861 5.54033 4.89447 5.39447C5.04033 5.24861 5.23816 5.16667 5.44444 5.16667H11.6667C11.8729 5.16667 12.0708 5.24861 12.2166 5.39447C12.3625 5.54033 12.4444 5.73816 12.4444 5.94444V12.1667Z'
        fill={color ?? '#898E99'}
      />
    </svg>
  );
}
