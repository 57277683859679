import React, { useEffect, useState } from "react";
import { ICONS } from "../../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
// import { setEmail } from "../../../reduxToolkit/authSlice";
import { setSignUpInitial } from "../../../reduxToolkit/authSlice";
import { ResendApi, emailApi } from "../api/Api";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";

const Resend = () => {
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdown, setCountdown] = useState(120);
  const [buttonColor, setButtonColor] = useState("var(--primary)");
  const [buttonTextColor, setButtonTextColor] = useState("#ffffff"); // Initial text color

  const { signUpInitial } = useAspSelector((state) => state.authentication);
  const { toggleToast } = useAspSelector((state) => state.app);

  const navigate = useNavigate();
  const dispatch = useAspDispatch();
  const location = useLocation();

  const handleResendClick = () => {
    const payload = {
      email: signUpInitial?.email,
    };

    
    ResendApi(payload).then((res) => {
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              // content: res?.data?.message,
              content: "Reset link send Successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        // emailApi(payloadToken)
      }
    });
   
    setShowCountdown(true);
    setCountdown(120);
    setButtonColor("#D1D3D8");
    setButtonTextColor("var(--darkgrey)");
  };

  useEffect(() => {
    let timer;

    if (showCountdown) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer);
            setShowCountdown(false);
            setButtonColor("var(--primary)");
            setButtonTextColor("#ffffff");

            return 120;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [showCountdown]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleBackClick = () => {
    navigate("/signUp");
    dispatch(setSignUpInitial(""));
  };

  const handleLoginClick = () => {
    navigate("/login");
  };
  return (
    <div className="Resend_container mt-20">
      <span>An activation link has been sent to your email </span>
      <span className="flex-row justify-center">
        for your account verification.
      </span>
      <p className="Example_email flex-row justify-center">
        {signUpInitial?.email}
      </p>
      <div
        className="flex-row justify-center align-center mb-[20px] pointer "
        onClick={handleBackClick}
      >
        <p className=" change_email flex-row justify-center ">Change Email</p>
        <img
          src={ICONS?.EmailArrowIcon}
          alt="arrow"
          className=" Back_icon ml-5"
        />
      </div>
      <div className=" flex-row justify-center mt-[24px]">
        <button
          onClick={handleResendClick}
          style={{
            backgroundColor: buttonColor,
            color: buttonTextColor, // Set text color dynamically
          }}
          className="resend_button"
        >
          Resend Link
        </button>
      </div>
      <div className="flex-row justify-center ">
        {showCountdown ? (
          <p className="resend_count" style={{ color: "var(--primary)" }}>
            {formatTime(countdown)}
          </p>
        ) : (
          <p className="resend_count m-1.5">00:00</p>
        )}
      </div>
      <div className="mt-10" style={{ borderTop: "0.5px solid #D1D3D8" }}>
        <p className="sign_up_login flex-row align-center justify-center p-3 mt-1.5">
          Already have an account?
          <span className="pl-10 pointer " onClick={handleLoginClick}>
            Login
          </span>
        </p>
      </div>
      <div
        className="flex-row justify-center mt-5  pointer"
        onClick={handleBackClick}
      >
        <img src={ICONS?.BackIcon} alt="back" className="Back_icon mr-5" />
        <p className="Back flex-row ">Back </p>
      </div>
    </div>
  );
};

export default Resend;
