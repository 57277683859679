import React, { useRef } from 'react';
import '../../../../styles/templateList.css';

// utils
import { Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

// components
import NoTemplate from './NoTemplate';
import Loader from 'components/commonComponents/Loader/Index';
import TemplateListCard from './TemplateListCard';

export default function TemplateList({
  templateDetails,
  isLoading,
  isLoadingNextPage,
  count,
  length,
  loadNextPage,
  updateSingleTemplateData,
  loadTemplateList,
}) {
  const templateListContainerRef = useRef(null);

  const headerList = [
    {
      id: 1,
      label: 'Template Name',
    },
    {
      id: 2,
      label: 'Status',
    },
    {
      id: 3,
      label: 'Category',
    },
    {
      id: 4,
      label: 'Language',
    },
    // {
    //   id: 5,
    //   label: 'Active Broadcast',
    // },
    // {
    //   id: 6,
    //   label: 'Total Broadcast',
    // },
    // {
    //   id: 7,
    //   label: 'Message Sent',
    // },
    // {
    //   id: 8,
    //   label: 'Message Delivered',
    // },
    // {
    //   id: 9,
    //   label: 'Message Opened',
    // },
  ];

  return (
    <div className='container'>
      <div
        className='innerContainer listScroll pr-[0.5vw]'
        id='scrollableDiv'
        ref={templateListContainerRef}
      >
        <Skeleton loading={isLoading} active paragraph={{ rows: 7 }}>
          <InfiniteScroll
            dataLength={length}
            next={() => {
              if (!isLoadingNextPage && !isLoading) loadNextPage();
            }}
            hasMore={length < count}
            scrollableTarget='scrollableDiv'
          >
            {templateDetails?.length > 0 ? (
              <table className=''>
                <thead className='!text-[var(--textBlack)]'>
                  <tr>
                    {headerList?.map((item, i) => {
                      return (
                        <th key={i} className='min-w-[15vw]'>
                          {item?.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {templateDetails?.map((item, i) => {
                    return (
                      <TemplateListCard
                        key={`${item?.name}-${i}`}
                        isLoading={isLoading}
                        isLoadingNextPage={isLoadingNextPage}
                        data={item}
                        updateSingleTemplateData={updateSingleTemplateData}
                        loadTemplateList={loadTemplateList}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className='h-[40vh]'>
                <NoTemplate />
              </div>
            )}
          </InfiniteScroll>

          {isLoadingNextPage && (
            <div className='w-full h-[10vh] flex items-center justify-center'>
              <Loader
                Width='2vw'
                Height='2vw'
                loaderBg='4px solid white'
                loaderColor='4px solid var(--primary)'
              />
            </div>
          )}
        </Skeleton>
      </div>
    </div>
  );
}
