import React from 'react';

const Phone = ({ width = '24', height = '24' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="6" fill="#DEFEE4" />
      <path
        d="M18.7616 17.2843C18.7616 17.2843 17.8573 18.1725 17.6357 18.4329C17.2747 18.8181 16.8494 19 16.2918 19C16.2382 19 16.181 19 16.1274 18.9964C15.0659 18.9287 14.0794 18.5149 13.3395 18.1618C11.3165 17.1845 9.54017 15.7969 8.06403 14.0385C6.84523 12.5725 6.03032 11.2171 5.49062 9.76178C5.15822 8.87363 5.0367 8.18166 5.09031 7.52892C5.12605 7.11159 5.28689 6.76561 5.58355 6.46955L6.80234 5.25325C6.97748 5.08917 7.16333 5 7.34562 5C7.57079 5 7.75307 5.13554 7.86745 5.24968C7.87102 5.25325 7.8746 5.25682 7.87817 5.26038C8.0962 5.46369 8.3035 5.67414 8.52152 5.89885C8.63232 6.01299 8.7467 6.12713 8.86107 6.24484L9.83682 7.2186C10.2157 7.59669 10.2157 7.94624 9.83682 8.32433C9.73317 8.42777 9.63309 8.53121 9.52944 8.63108C9.22921 8.93783 9.46506 8.70247 9.15411 8.98068C9.14696 8.98782 9.13981 8.99138 9.13624 8.99852C8.82886 9.30527 8.88604 9.60489 8.95038 9.8082C8.95395 9.8189 8.95753 9.8296 8.9611 9.8403C9.21487 10.4538 9.57229 11.0316 10.1156 11.72L10.1191 11.7236C11.1056 12.9364 12.1457 13.8816 13.293 14.6056C13.4396 14.6984 13.5897 14.7733 13.7326 14.8446C13.8613 14.9088 13.9828 14.9695 14.0865 15.0337C14.1008 15.0408 14.1151 15.0515 14.1294 15.0586C14.2509 15.1193 14.3653 15.1478 14.4832 15.1478C14.7799 15.1478 14.9657 14.9623 15.0265 14.9017L15.7271 14.2025C15.8486 14.0813 16.0416 13.935 16.2668 13.935C16.4884 13.935 16.6707 14.0741 16.7815 14.1954C16.785 14.199 16.785 14.199 16.7886 14.2025L18.758 16.1679C19.1261 16.5317 18.7616 17.2843 18.7616 17.2843Z"
        stroke="#0ACD95"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;
