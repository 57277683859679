import React, { useState } from 'react';
import './search.css';

const Search = ({ placeholder = 'Search or create new tag' }) => {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState('');
  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  const handleChange = (e) => {
    setValue((prev) => {
      if (e.target.value.length <= 20) {
        return e.target.value;
      } else {
        return prev;
      }
    });
  };
  return (
    <div
      className={`flex items-center border ${
        !isFocus ? 'border-white' : 'border-slate-300'
      } overflow-hidden rounded-xl crm-search bg-slate-100 my-[2vh] px-[1vw] flex items-center`}
    >
      <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        onChange={(e) => handleChange(e)}
        type="text"
        name="tagSearch"
        className="w-full ring-0 border-0 py-[.5vw] bg-transparent placeholder:text-gray-400 sm:text-sm sm:leading-6 text-gray-600"
        placeholder={placeholder}
      />
      <span className="text-[.75vw] text-gray-500">{value.length}/20</span>
    </div>
  );
};

export default Search;
