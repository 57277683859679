import React from 'react';

export default function VariableComponent({
  name,
  value,
  placeholder,
  onChange,
  onBlur,
}) {
  return (
    <div className='w-full h-[2.23vw] flex items-center gap-[2vw]'>
      <label className='w-[10vw] font-md weight-bold text-[var(--font-600)]'>
        {name}
      </label>
      <div
        className={`w-[33vw] h-full border-[0.08vw] px-[0.5vw] py-[0.3vw] rounded-[0.4vw] flex items-center ${
          value ? 'border-[var(--card-border)]' : 'border-red-500'
        }`}
      >
        <input
          type='text'
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder ?? 'Enter sample content'}
          className='w-full font-md weight-small'
        />
      </div>
    </div>
  );
}
