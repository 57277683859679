import attachments from "../icons/attachments.svg";
import bold from "../icons/bold.svg";
import email from "../icons/email.svg";
import italic from "../icons/italic.svg";
import noTemplateSelected from "../icons/noTemplateSelected.svg";
import phone from "../icons/phone.svg";
import phoneWithBg from "../icons/phoneWithBg.svg";
import selectTemplate from "../icons/selectTemplate.svg";
import starGroup from "../icons/star-group.svg";
import underline from "../icons/underline.svg";

import BroadcastAddIcon from "./AddSegment.svg";
import AlertIcon from "./Alert.svg";
import AudienceDownloadIcon from "./AudienceDownload.svg";
import AudienceFileIcon from "./AudienceFile.svg";
import BGChevronRightIcon from "./BGchevorn.svg";
import BroadcastRoundGreenTick from "./BGroundTick.svg";
import ActiveIcon from "./Balance.svg";
import BrandArrowIcon from "./BrandArrow.svg";
import BroadcastSegmentsIcon from "./BroadSegments.svg";
import BroadCastSettingIcon from "./Broadcast .svg";
import BroadCastActive from "./BroadcastActive.svg";
import BroadcastCopyIcon from "./BroadcastCopy.svg";
import BroadcastMessageIcon from "./BroadcastMessage.svg";
import BroadcastProfileIcon from "./BroadcastProfile.svg";
import BroadcastReviewIcon from "./BroadcastReview.svg";
import CampaignBudgetIcon from "./Budget .svg";
import BudgetIcon from "./Budget.svg";
import CalenderArrow from "./CalendarArrow.svg";
import calenderAudience from "./CalendarAudience.svg";
import CalenderIcon from "./CalenderIcon.svg";
import BroadcastCameraIcon from "./Camera.svg";
import BroadcastDefineIcon from "./Define.svg";
import BroadcastDelayIcon from "./DelayIcon.svg";
import DeliveredIcon from "./Delivered.svg";
import DigitalAgencies from "./DigitalAgencies.svg";
import Download from "./Download.svg";
import CampaignDraftIcon from "./Draft.svg";
import Education from "./Education.svg";
import EmailArrowIcon from "./EmailArrow.svg";
import Exit from "./ExitIcon.svg";
import BroadcastDownloadIcon from "./Export.svg";
import campaignEye from "./Eye.svg";
import StrikeThrough from "./Font_Strikethrough.svg";
import BroadcastGalleryIcon from "./Gallery.svg";
import BroadcastGreenTickIcon from "./Greentick.svg";
import HealthCare from "./HealthCare.svg";
import Industry from "./Industry.svg";
import BillingGreyWarningIcon from "./Info.svg";
import LeadIcon from "./Lead.svg";
import MaximumIcon from "./MaxIcon.svg";
import BroadcastNewSegmentIcon from "./NewSegmentIcon.svg";
import OpenedIcon from "./Opened.svg";
import BillingPdfIcon from "./PDFIcon.svg";
import CampaignPausedIcon from "./PausedIcon.svg";
import pencil from "./Pencil.svg";
import PersonIcon from "./PersonIcon.svg";
import HeaderPlanIcon from "./PlanIcon.svg";
import BrandPlusIcon from "./PlusIcon.svg";
import BroadcastMickIcon from "./Record Audio.svg";
import BroadcastRedInfoIcon from "./RedCircleInfo.svg";
import BroadcastRedDeleteIcon from "./RedDelete.svg";
import xCircleIcon from "./Rejected.svg";
import AudienceRoundIcon from "./RoundBlus.svg";
import CampaignRunningIcon from "./RunningIcons.svg";
import BroadcastSegmentIcon from "./SegmentIcon.svg";
import BroadcastTickIcon from "./Segmenttick.svg";
import SendingIcon from "./Sending.svg";
import BroadcastWhatsappAddIcon from "./Shape.svg";
import BrandInviteShareIcon from "./ShareIcon.svg";
import SpentIcon from "./Spent.svg";
import StarIcon from "./Stars copy.svg";
import TickGreenIcon from "./TickGreen.svg";
import TickIcon from "./TickIcon.svg";
import BroadcastReplayIcon from "./Vector.svg";
import WhatsApps from "./WhatsApps.svg";
import Aachi from "./aachi.svg";
import brands from "./account_brand.svg";
import accountsArrow from "./accounts_arrow.svg";
import accountsArrowHover from "./accounts_hover_arrow.svg";
import addBGwhite from "./add-bg-white.svg";
import createBrandIcon from "./add-square.svg";
import AddTag from "./addTag.svg";
import addColumn from "./add_column.svg";
import alertIconError from "./alert-triangle-error.svg";
import Aura from "./aura.svg";
import BackIcon from "./backIcon.svg";
import BillingUploadIcon from "./billingUpload.svg";
import BouncedIcon from "./bonuced.svg";
import BroadcastTimerIcon from "./broadcaseClock.svg";
import campaignCalendarIcon from "./calendar-01.svg";
import calenderContact from "./calendarContact.svg";
import calenderDinner from "./calendarDinner.svg";
import calenderScheduled from "./calenderScheduled.svg";
import call from "./call.svg";
import activeCampaign from "./campaign-active 2.svg";
import CampaignActiveIcon from "./campaign-active.svg";
import completedCampaign from "./campaign-completed.svg";
import draftCampaign from "./campaign-draft.svg";
import BroadcastCalendarIcon from "./campaignCalendar.svg";
import modelDropdownIcon from "./model-dropdown.svg";

import checkSquare from "./check-square.svg";
import chevronDown from "./chevron-down.svg";
import chevronRightIconWhite from "./chevron-right-white.svg";
import chevronRightIcon from "./chevron-right.svg";
import chevronGreen from "./chevronGreen.svg";
import BroadcastChevronDownIcon from "./chrveonDown.svg";
import BroadcastChevronUpIcon from "./chrveonup.svg";
import CloseIcon from "./close.svg";
import CampaignCompleteIcon from "./completedIcon.svg";
import configure from "./configure.svg";
import CopyLeft from "./copy-left.svg";
import CopyCodeIcon from "./copyCode.svg";
import delayIcon from "./delay.svg";
import deleteIcon from "./delete.svg";
import dropDownIcon from "./dropdown.svg";
import duplicate from "./duplicate.svg";
import eCommerce from "./eCommerce.svg";
import edit from "./edit.svg";
import noTemplate from "./empty_messages_template 1.svg";
import FilterSelectedIcon from "./filter.svg";
import FMCG from "./fmcg.svg";
import growth from "./growth.svg";
import helpCircle from "./helpCircle.svg";
import IndiaIcon from "./india.svg";
import BrandInviteAddWhiteIcon from "./inviteWhiteAddIcon.svg";
import BroadcastLightChevronDown from "./lightchevrondown.svg";
import BroadcastSegmentDownloadIcon from "./link-external.svg";
import lock from "./lock.svg";
import logoApton from "./logo-apton.svg";
import logoGain from "./logo-gain.svg";
import magnum from "./magnum.svg";
import messageFailed from "./message-failed.svg";
import messageSent from "./message-sent.svg";
import mostPopular from "./mostPopular.svg";
import noBroadcast from "./no-broadcast.svg";
import noMessage from "./no-message.svg";
import NoImageIcon from "./no_image_contact.svg";
import notificationActive from "./notification.svg";
import nounLink from "./noun-link.svg";
import other from "./other.svg";
import pricingArrow from "./pricingArrow.svg";
import redDelete from "./red-delete.svg";
import RedDeleteIcon from "./redelete.svg";
import SeeAllOptionIcon from "./seeall_Icon.svg";
import selected from "./selected.svg";
import send from "./send.svg";
import sentIcon from "./sent.svg";
import shareLink from "./share-link.svg";
import speakerphoneWhite from "./speakerphone-white.svg";
import Speaker from "./speakerphone.svg";
import BroadcastStarIcon from "./star.svg";
import startEndDate from "./start_end_date.svg";
import starter from "./starter.svg";
import stepperRoundIcon from "./stepperRound.svg";
import stepperTickIcon from "./stepperTick.svg";
import syncRefresh from "./sync_refresh.svg";
import travel from "./travel.svg";
import unlock from "./unlock.svg";
import upgradeArrow from "./upgrade-arrow.svg";
import uploadStep from "./uploadStep.svg";
import uploadFile from "./upload_file.svg";
import usage from "./usage.svg";
import BroadcastSegmentIcons from "./variant.svg";
import Vvd from "./vvd.svg";
import whatsApp from "./whatsapp.svg";
import whatsappLeft from "./whatsapp_left.svg";
import xSquare from "./x-square.svg";

import bell from "./home/bell.svg";
import calender from "./home/calender.svg";
import chat from "./home/chat.svg";
import checkDoubleBlue from "./home/check_double_blue.svg";
import checkDoubleOrange from "./home/check_double_orange.svg";
import click from "./home/click.svg";
import key from "./home/key.svg";
import lead from "./home/lead.svg";
import marketing from "./home/marketing.svg";
import noNotification from "./home/no_notification.svg";
import profile from "./home/profile.svg";
import segment from "./home/segment.svg";
import services from "./home/services.svg";
import viewCalender from "./home/view_calender.svg";

export const ICONS = {
  searchLightIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/search_light.svg",
  usageIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/usage_plan.svg",

  HeaderSwitchingCalender:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/header_switching_calendar.svg",
  whiteRightIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/arrowright.svg",
  googleIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/google.svg",
  code: "https://storage.googleapis.com/asp-v1-images/assets/ui/otp.svg",
  leftArrow:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/arrowleft.svg",
  emailBox:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/emailauth.svg",
  passwordLock:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/lockauth.svg",
  passwordEyeOpen:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/eyeauth.png",
  passwordEyeClose:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/eye_closed.png",
  globalIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/global.svg",
  logout: "https://storage.googleapis.com/asp-v1-images/assets/ui/logout.svg",

  popupX: "https://storage.googleapis.com/asp-v1-images/assets/ui/x.svg",

  logout: "https://storage.googleapis.com/asp-v1-images/assets/ui/logout.svg",

  ExitIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/exit_icon.png",
  CircleCheck:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/Circle_Check.svg",
  cancelX:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/cancel_x.svg",
  TaskDownload:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/task_download.svg",
  WhatsappCsvIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/whastappcsv.svg",

  WarningIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/Circle_Warning.svg",
  AlertIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/alert_triangle.png",
  // AlertIcon:
  //   "https://storage.googleapis.com/asp-v1-images/assets/ui/alert_triangle.png",
  TrianglewarningIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/Triangle_Warning.svg",

  searchIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/search.svg",
  CopyText:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/copy_right.svg",

  filterIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/filter.svg",
  campaignCalendar:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/campaignCalendar.svg",
  SelectDropdown:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selectDropdown.svg",
  bell: "https://storage.googleapis.com/asp-v1-images/assets/ui/bell.svg",
  BoldIcon: "https://storage.googleapis.com/asp-v1-images/assets/ui/bold.svg",
  ItalicIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/italics.svg",
  step3EmojiIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/step3_emoji.svg",
  // addBGwhite:
  //   "https://storage.googleapis.com/asp-v1-images/assets/ui/addBGwhite.svg",
  step2PopupIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/step2_popup_information.svg",
  CaretDown:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/caret_down.svg",
  CalendarIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/calendar.svg",
  addBGtransparent:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/addBGtransparent.svg",
  chevronRight:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/chevronRight.png",
  chevronLeft:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/chevronLeft.png",
  navCalendar:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/navCalendar.png",
  dragVertical:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/Drag_Vertical.svg",
  campaignDelete:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/campaignDelete.svg",
  greenTickVerticalSteps:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/greenTickVerticalSteps.svg",

  AtIcon: "https://storage.googleapis.com/asp-v1-images/assets/ui/At.svg",
  ReplayIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/Vector.svg",
  inboxProfile:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/inbox_profile.svg",

  HighAlert:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/highWarning.svg",
  addPlusIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/plus_campaign_add_filter.svg",

  logoGain: logoGain,
  logoApton: logoApton,
  usage: usage,

  upgradeArrow: upgradeArrow,

  // Accounts -Login

  Aachi: Aachi,
  Aura: Aura,
  Vvd: Vvd,
  brands: brands,
  accountsArrow: accountsArrow,
  accountsArrowHover: accountsArrowHover,

  dropDownIcon: dropDownIcon,
  // notification: notification,
  notificationActive: notificationActive,
  helpCircle: helpCircle,
  createBrandIcon: createBrandIcon,

  CloseIcon: CloseIcon,
  modelDropdownIcon: modelDropdownIcon,

  whatsApp: whatsApp,
  chevronDown: chevronDown,
  whatsappLeft: whatsappLeft,
  syncRefresh: syncRefresh,
  configure: configure,

  BudgetIcon: BudgetIcon,
  ActiveIcon: ActiveIcon,

  SpentIcon: SpentIcon,
  xCircleIcon: xCircleIcon,

  lock: lock,
  unlock: unlock,
  edit: edit,
  deleteIcon: deleteIcon,
  duplicate: duplicate,

  Speaker: Speaker,
  StrikeThrough: StrikeThrough,
  StarIcon: StarIcon,

  AddColumn: addColumn,

  //campaign

  speakerphoneWhite: speakerphoneWhite,
  campaignCalendarIcon: campaignCalendarIcon,

  CampaignActiveIcon: CampaignActiveIcon,
  CampaignDraftIcon: CampaignDraftIcon,
  CampaignPausedIcon: CampaignPausedIcon,
  CampaignCompleteIcon: CampaignCompleteIcon,
  CampaignRunningIcon: CampaignRunningIcon,

  pencil: pencil,

  redDelete: redDelete,

  //Broadcast
  noBroadcast: noBroadcast,
  noMessage: noMessage,

  BroadCastSettingIcon: BroadCastSettingIcon,
  BroadcastSegmentsIcon: BroadcastSegmentsIcon,
  BroadcastMessageIcon: BroadcastMessageIcon,
  BroadcastReviewIcon: BroadcastReviewIcon,
  chevronRightIcon: chevronRightIcon,
  BroadcastDefineIcon: BroadcastDefineIcon,

  BGChevronRightIcon: BGChevronRightIcon,

  BroadcastCalendarIcon: BroadcastCalendarIcon,
  BroadcastTimerIcon: BroadcastTimerIcon,

  BroadcastDelayIcon: BroadcastDelayIcon,
  BroadcastSegmentIcon: BroadcastSegmentIcon,
  BroadcastAddIcon: BroadcastAddIcon,

  BroadcastChevronUpIcon: BroadcastChevronUpIcon,
  BroadcastChevronDownIcon: BroadcastChevronDownIcon,

  shareLink: shareLink,
  AddTag: AddTag,
  shareLink: shareLink,
  call: call,
  CopyLeft: CopyLeft,
  nounLink: nounLink,
  xSquare: xSquare,
  checkSquare: checkSquare,

  BroadcastRedDeleteIcon: BroadcastRedDeleteIcon,
  BroadcastTickIcon: BroadcastTickIcon,
  BroadcastProfileIcon: BroadcastProfileIcon,
  BroadcastCopyIcon: BroadcastCopyIcon,
  BroadcastRedInfoIcon: BroadcastRedInfoIcon,
  BroadcastLightChevronDown: BroadcastLightChevronDown,
  MaximumIcon: MaximumIcon,

  RedDeleteIcon: RedDeleteIcon,

  BroadcastReplayIcon: BroadcastReplayIcon,
  BroadcastGreenTickIcon: BroadcastGreenTickIcon,

  BroadcastWhatsappAddIcon: BroadcastWhatsappAddIcon,
  BroadcastCameraIcon: BroadcastCameraIcon,
  BroadcastMickIcon: BroadcastMickIcon,
  BroadcastGalleryIcon: BroadcastGalleryIcon,
  BroadcastSegmentDownloadIcon: BroadcastSegmentDownloadIcon,

  BroadcastRoundGreenTick: BroadcastRoundGreenTick,
  CampaignBudgetIcon: CampaignBudgetIcon,

  BroadcastSegmentIcons: BroadcastSegmentIcons,
  BroadcastStarIcon: BroadcastStarIcon,

  BroadcastDownloadIcon: BroadcastDownloadIcon,

  addBGwhite: addBGwhite,

  IndiaIcon: IndiaIcon,
  BroadcastNewSegmentIcon: BroadcastNewSegmentIcon,

  HeaderPlanIcon: HeaderPlanIcon,

  TickIcon: TickIcon,

  SeeAllOptionIcon: SeeAllOptionIcon,
  CopyCodeIcon: CopyCodeIcon,

  BillingUploadIcon: BillingUploadIcon,
  BillingPdfIcon: BillingPdfIcon,
  campaignEye: campaignEye,
  BrandPlusIcon: BrandPlusIcon,
  BrandInviteShareIcon: BrandInviteShareIcon,
  BillingGreyWarningIcon: BillingGreyWarningIcon,
  BrandArrowIcon: BrandArrowIcon,

  noTemplate: noTemplate,

  send: send,
  chevronRightIconWhite: chevronRightIconWhite,
  alertIconError: alertIconError,
  messageSent: messageSent,
  messageFailed: messageFailed,

  //calender
  CalenderIcon: CalenderIcon,
  CalenderArrow: CalenderArrow,
  calenderAudience: calenderAudience,
  calenderContact: calenderContact,
  calenderDinner: calenderDinner,
  calenderScheduled: calenderScheduled,

  BrandInviteAddWhiteIcon: BrandInviteAddWhiteIcon,

  AudienceRoundIcon: AudienceRoundIcon,
  AudienceDownloadIcon: AudienceDownloadIcon,
  AudienceFileIcon: AudienceFileIcon,

  // home
  Message: chat,
  Bell: bell,
  Marketing: marketing,
  Services: services,
  Key: key,
  Profile: profile,
  Lead: lead,
  Click: click,
  Delivered: checkDoubleBlue,
  Opened: checkDoubleOrange,
  Segment: segment,
  ViewCalender: viewCalender,
  Calender: calender,
  NoNotification: noNotification,
  Selected: selected,

  //  inbox
  Email: email,
  PhoneWithBg: phoneWithBg,
  Bold: bold,
  Italic: italic,
  Underline: underline,
  StarGroup: starGroup,
  SelectTemplate: selectTemplate,
  Attachments: attachments,
  NoTemplateSelected: noTemplateSelected,

  WhatsApps: WhatsApps,

  chevronGreen: chevronGreen,

  delayIcon: delayIcon,

  AlertIcon: AlertIcon,

  //contact-view
  FilterSelectedIcon: FilterSelectedIcon,

  DeliveredIcon: DeliveredIcon,
  sentIcon: sentIcon,
  OpenedIcon: OpenedIcon,
  LeadIcon: LeadIcon,
  BouncedIcon: BouncedIcon,
  SendingIcon: SendingIcon,
  Download: Download,
  NoImageIcon: NoImageIcon,

  BroadCastActive: BroadCastActive,

  //stepper
  uploadStep: uploadStep,
  HealthCare: HealthCare,
  eCommerce: eCommerce,
  DigitalAgencies: DigitalAgencies,
  Education: Education,
  stepperTickIcon: stepperTickIcon,
  stepperRoundIcon: stepperRoundIcon,
  Industry: Industry,
  FMCG: FMCG,
  travel: travel,
  other: other,
  Exit: Exit,
  TickGreenIcon: TickGreenIcon,

  //signup
  PersonIcon: PersonIcon,
  EmailArrowIcon: EmailArrowIcon,
  BackIcon: BackIcon,

  //Calendar

  uploadFile: uploadFile,
  startEndDate: startEndDate,

  // pricing
  Starter: starter,
  Growth: growth,
  Magnum: magnum,
  MostPopular: mostPopular,
  PricingArrow: pricingArrow,
};
