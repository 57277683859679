// timeUtils.js
export const getTimeString = (hours, minutes, periods) => {
  if (!hours || !minutes || !periods) {
    return "";
  }

  let formattedHours = parseInt(hours);
  if (periods === "PM" && formattedHours !== 12) {
    formattedHours += 12;
  } else if (periods === "AM" && formattedHours === 12) {
    formattedHours = 0;
  }

  const timeString = `${formattedHours
    .toString()
    .padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;

  return timeString;
};

export const formatTimeWithAmPm = (timeString) => {
  if (!timeString || typeof timeString !== "string") {
    return "";
  }

  const [hours, minutes] = timeString.split(":").map((str) => parseInt(str));
  if (isNaN(hours) || isNaN(minutes)) {
    return "";
  }

  const formattedHours = hours % 12 || 12;
  const amPm = hours >= 12 ? "PM" : "AM";
  return `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${amPm}`;
};
