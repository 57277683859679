export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) return false;

  return true;
};

export const shortenEmail = (value) => {
  const email = value.split('@');
  const domain = email?.[1]?.split('.');

  return `${email?.[0].slice(0, 3)}*****@${email?.[1]?.slice(0, 2)}*****.${
    domain?.[1]
  }`;
};

export const removeSpace = (str) => str.replace(/ /g, '');
