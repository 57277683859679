import AspApi from '../../../api/ApiConfig';

export const CreateAccount = async (payload) => {
  const res = await AspApi.post('/api/v1/account/create', payload);
  return res;
};

export const CreateBrand = async (payload) => {
  const res = await AspApi.post('/api/v1/brand/create', payload);
  return res;
};

export const WhatsappOTPSent = async (payload) => {
  const res = await AspApi.post('/api/v1/user/sent_whatsapp_otp', payload);
  return res;
};

export const InviteTeamMembers = async (payload) => {
  const res = await AspApi.post('/api/v1/brand/invite_users', payload);
  return res;
};

export const InviteTokenValidateWithLogin = async (payload) => {
  const res = await AspApi.post(
    '/api/v1/brand/validate_intitation_token',
    payload
  );
  return res;
};

export const InviteTokenValidateWithoutLogin = async (payload) => {
  const res = await AspApi.post(
    '/api/v1/invite/validate_invitation_token',
    payload
  );
  return res;
};
