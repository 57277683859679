
import React from 'react';

function navCalendar({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.75 15.7202V15.6428M10.25 15.7202V15.6428M10.25 11.5286V11.4512M14.25 11.5286V11.4512M2.75 7.41425H16.75M4.55952 1.5V3.04304M14.75 1.5V3.04285M14.75 3.04285H4.75C3.09315 3.04285 1.75 4.42436 1.75 6.12855V16.4143C1.75 18.1185 3.09315 19.5 4.75 19.5H14.75C16.4069 19.5 17.75 18.1185 17.75 16.4143L17.75 6.12855C17.75 4.42436 16.4069 3.04285 14.75 3.04285Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default navCalendar;
