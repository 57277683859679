import React from 'react';

export default function Settings({
  width,
  height,
  color,
  strokeWidth = '1.5',
  className,
}) {
  return (
    <svg
      width={width ?? '16'}
      height={height ?? '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.30379 2.02377C8.97128 0.658745 7.02872 0.658745 6.69621 2.02377C6.64657 2.22888 6.5492 2.41938 6.41202 2.57976C6.27485 2.74014 6.10174 2.86586 5.9068 2.9467C5.71185 3.02755 5.50058 3.06122 5.29016 3.04499C5.07974 3.02875 4.87613 2.96307 4.6959 2.85328C3.49536 2.12177 2.12157 3.49554 2.85309 4.69606C3.3256 5.47132 2.90647 6.48284 2.02444 6.69721C0.658521 7.02884 0.658521 8.97225 2.02444 9.30301C2.22961 9.35271 2.42015 9.45017 2.58053 9.58745C2.74091 9.72472 2.8666 9.89794 2.94736 10.093C3.02813 10.288 3.06169 10.4994 3.04531 10.7099C3.02892 10.9203 2.96306 11.124 2.85309 11.3042C2.12157 12.5047 3.49536 13.8785 4.6959 13.1469C4.8761 13.037 5.07973 12.9711 5.2902 12.9547C5.50068 12.9383 5.71204 12.9719 5.90709 13.0527C6.10214 13.1334 6.27536 13.2591 6.41264 13.4195C6.54992 13.5799 6.64738 13.7704 6.69708 13.9756C7.02872 15.3415 8.97216 15.3415 9.30292 13.9756C9.35279 13.7705 9.45035 13.5801 9.58766 13.4198C9.72496 13.2596 9.89815 13.134 10.0931 13.0532C10.2881 12.9725 10.4994 12.9389 10.7098 12.9552C10.9203 12.9715 11.1239 13.0372 11.3041 13.1469C12.5046 13.8785 13.8784 12.5047 13.1469 11.3042C13.0371 11.1239 12.9714 10.9203 12.9551 10.7099C12.9389 10.4995 12.9725 10.2882 13.0532 10.0932C13.1339 9.89823 13.2596 9.72505 13.4198 9.58774C13.5801 9.45043 13.7705 9.35288 13.9756 9.30301C15.3415 8.97137 15.3415 7.02797 13.9756 6.69721C13.7704 6.64751 13.5799 6.55005 13.4195 6.41277C13.2591 6.27549 13.1334 6.10228 13.0526 5.90723C12.9719 5.71219 12.9383 5.50082 12.9547 5.29035C12.9711 5.07988 13.0369 4.87626 13.1469 4.69606C13.8784 3.49554 12.5046 2.12177 11.3041 2.85328C11.1239 2.96325 10.9203 3.02911 10.7098 3.04549C10.4993 3.06187 10.288 3.02832 10.0929 2.94755C9.89786 2.86679 9.72464 2.7411 9.58736 2.58072C9.45008 2.42034 9.35262 2.22981 9.30292 2.02464L9.30379 2.02377Z'
        stroke={color ?? '#898E99'}
        strokeWidth={strokeWidth}
      />
      <path
        d='M9.55556 8C9.55556 8.85911 8.85911 9.55556 8 9.55556C7.14089 9.55556 6.44444 8.85911 6.44444 8C6.44444 7.14089 7.14089 6.44444 8 6.44444C8.85911 6.44444 9.55556 7.14089 9.55556 8Z'
        stroke={color ?? '#898E99'}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
