import React from "react";
import "../../../styles/Broadcast.css";
import { ICONS } from "../../../../../assets/icons";


const SendFormView = ({ segmentSettings }) => {
  return (
    <div className="setting-container flex-column  ">
      <div className="flex-column">
        <span>Send From</span>
        <span>
          Select the WhatsaApp number you would like to send the messages.
        </span>
        <div className="message-container flex-row align-center space-between">
          <div className="flex-row align-center justify-center">
            <img src={ICONS?.whatsApp} alt="whatsappLogo" />

            <p className="selected-name pl-10">
              {segmentSettings?.send_from_phone_name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendFormView;
