import { useState } from "react";

const daysShortArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const daysArr = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNamesArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const shortMonthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const useCalendar = (daysShort = daysShortArr, monthNames = monthNamesArr) => {
  const today = new Date();
  const todayFormatted = `${today.getDate()}-${
    today.getMonth() + 1
  }-${today.getFullYear()}`;
  const [selectedDate, setSelectedDate] = useState(today);

  const selectedMonthLastDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  );
  const prevMonthLastDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    0
  );
  const daysInMonth = selectedMonthLastDate.getDate();
  const firstDayInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  ).getDay();
  const startingPoint = daysShortArr.indexOf(daysShortArr[firstDayInMonth]);
  let prevMonthStartingPoint =
    prevMonthLastDate.getDate() -
    daysShortArr.indexOf(daysShortArr[firstDayInMonth]) +
    1;
  let currentMonthCounter = 1;
  let nextMonthCounter = 1;

  const rows = 6;
  const cols = 7;
  const calendarRows = {};

  for (let i = 1; i < rows + 1; i++) {
    for (let j = 1; j < cols + 1; j++) {
      if (!calendarRows[i]) {
        calendarRows[i] = [];
      }

      // Check if the current cell represents the current date
      const isCurrentDate =
        currentMonthCounter === today.getDate() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getFullYear() === today.getFullYear();

      if (i === 1) {
        if (j < startingPoint + 1) {
          calendarRows[i] = [
            ...calendarRows[i],
            {
              classes: "in-prev-month",
              date: `${prevMonthStartingPoint}-${
                selectedDate.getMonth() === 0 ? 12 : selectedDate.getMonth()
              }-${
                selectedDate.getMonth() === 0
                  ? selectedDate.getFullYear() - 1
                  : selectedDate.getFullYear()
              }`,
              value: prevMonthStartingPoint,
            },
          ];
          prevMonthStartingPoint++;
        } else {
          calendarRows[i] = [
            ...calendarRows[i],
            {
              classes: isCurrentDate ? "current-date" : "",
              date: `${currentMonthCounter}-${
                selectedDate.getMonth() + 1
              }-${selectedDate.getFullYear()}`,
              value: currentMonthCounter,
            },
          ];
          currentMonthCounter++;
        }
      } else if (i > 1 && currentMonthCounter < daysInMonth + 1) {
        calendarRows[i] = [
          ...calendarRows[i],
          {
            classes: isCurrentDate ? "current-date" : "",
            date: `${currentMonthCounter}-${
              selectedDate.getMonth() + 1
            }-${selectedDate.getFullYear()}`,
            value: currentMonthCounter,
          },
        ];
        currentMonthCounter++;
      } else {
        calendarRows[i] = [
          ...calendarRows[i],
          {
            classes: "in-next-month",
            date: `${nextMonthCounter}-${
              selectedDate.getMonth() + 2 === 13
                ? 1
                : selectedDate.getMonth() + 2
            }-${
              selectedDate.getMonth() + 2 === 13
                ? selectedDate.getFullYear() + 1
                : selectedDate.getFullYear()
            }`,
            value: nextMonthCounter,
          },
        ];
        nextMonthCounter++;
      }
    }
  }

  const getPrevMonth = () => {
    setSelectedDate(
      (prevValue) =>
        new Date(prevValue.getFullYear(), prevValue.getMonth() - 1, 1)
    );
  };
  const getNextMonth = () => {
    setSelectedDate(
      (prevValue) =>
        new Date(prevValue.getFullYear(), prevValue.getMonth() + 1, 1)
    );
  };

  const getPrevWeek = () => {
    setSelectedDate(
      (prevValue) =>
        new Date(
          prevValue.getFullYear(),
          prevValue.getMonth(),
          prevValue.getDate() - 7
        )
    );
  };

  const getNextWeek = () => {
    setSelectedDate(
      (prevValue) =>
        new Date(
          prevValue.getFullYear(),
          prevValue.getMonth(),
          prevValue.getDate() + 7
        )
    );
  };

  return {
    daysShort,
    monthNames,
    todayFormatted,
    calendarRows,
    selectedDate,
    getPrevMonth,
    getNextMonth,
    getPrevWeek,
    getNextWeek,
    daysShortArr,
    shortMonthName,
    daysArr,
    monthNamesArr,
  };
};

export default useCalendar;

// import { useState } from 'react';

// const daysShortArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
// const daysArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
// const monthNamesArr = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];
// const shortMonthName = [
//   'Jan',
//   'Feb',
//   'Mar',
//   'Apr',
//   'May',
//   'Jun',
//   'Jul',
//   'Aug',
//   'Sept',
//   'Oct',
//   'Nov',
//   'Dec',
// ];

// const useCalendar = (daysShort = daysShortArr, monthNames = monthNamesArr) => {
//   const today = new Date();
//   const todayFormatted = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
//   const daysInWeek = [1, 2, 3, 4, 5, 6, 0];
//   const [selectedDate, setSelectedDate] = useState(today);

//   const generateMonthCalendar = () => {
//     // Generate calendar for the month
//     const selectedMonthLastDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
//     const daysInMonth = selectedMonthLastDate.getDate();
//     const firstDayInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();
//     const startingPoint = daysInWeek.indexOf(firstDayInMonth) + 1;
//     let prevMonthStartingPoint = selectedMonthLastDate.getDate() - daysInWeek.indexOf(firstDayInMonth) + 1;
//     let currentMonthCounter = 1;
//     let nextMonthCounter = 1;
//     const rows = 6;
//     const cols = 7;
//     const calendarRows = {};

//     for (let i = 1; i < rows + 1; i++) {
//       for (let j = 1; j < cols + 1; j++) {
//         if (!calendarRows[i]) {
//           calendarRows[i] = [];
//         }
//         if (i === 1) {
//           if (j < startingPoint) {
//             calendarRows[i] = [
//               ...calendarRows[i],
//               {
//                 classes: 'in-prev-month',
//                 date: `${prevMonthStartingPoint}-${selectedDate.getMonth() === 0 ? 12 : selectedDate.getMonth()}-${selectedDate.getMonth() === 0 ? selectedDate.getFullYear() - 1 : selectedDate.getFullYear()}`,
//                 value: prevMonthStartingPoint,
//               },
//             ];
//             prevMonthStartingPoint++;
//           } else {
//             calendarRows[i] = [
//               ...calendarRows[i],
//               {
//                 classes: '',
//                 date: `${currentMonthCounter}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`,
//                 value: currentMonthCounter,
//               },
//             ];
//             currentMonthCounter++;
//           }
//         } else if (i > 1 && currentMonthCounter < daysInMonth + 1) {
//           calendarRows[i] = [
//             ...calendarRows[i],
//             {
//               classes: '',
//               date: `${currentMonthCounter}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`,
//               value: currentMonthCounter,
//             },
//           ];
//           currentMonthCounter++;
//         } else {
//           calendarRows[i] = [
//             ...calendarRows[i],
//             {
//               classes: 'in-next-month',
//               date: `${nextMonthCounter}-${selectedDate.getMonth() + 2 === 13 ? 1 : selectedDate.getMonth() + 2}-${selectedDate.getMonth() + 2 === 13 ? selectedDate.getFullYear() + 1 : selectedDate.getFullYear()}`,
//               value: nextMonthCounter,
//             },
//           ];
//           nextMonthCounter++;
//         }
//       }
//     }
//     return calendarRows;
//   };

//   const generateWeekCalendar = () => {
//     // Generate calendar for a week
// const calendarRows = {};
// const cols = 7;
// const daysInWeek = 7;
// const selectedDay = selectedDate.getDay();
// const firstDayOfWeek = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - selectedDay);

// for (let i = 1; i <= daysInWeek; i++) {
//   const date = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate() + i - 1);
//   calendarRows[i] = [{
//     classes: '',
//     date: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`,
//     value: date.getDate(),
//   }];
// }
//     return calendarRows;
//   };

//   const getPrevMonth = () => {
//     setSelectedDate((prevValue) => new Date(prevValue.getFullYear(), prevValue.getMonth() - 1, 1));
//   };

//   const getNextMonth = () => {
//     setSelectedDate((prevValue) => new Date(prevValue.getFullYear(), prevValue.getMonth() + 1, 1));
//   };

//   const getPrevWeek = () => {
//     setSelectedDate((prevValue) => new Date(prevValue.getFullYear(), prevValue.getMonth(), prevValue.getDate() - 7));
//   };

//   const getNextWeek = () => {
//     setSelectedDate((prevValue) => new Date(prevValue.getFullYear(), prevValue.getMonth(), prevValue.getDate() + 7));
//   };

//   return {
//     daysShort,
//     monthNames,
//     todayFormatted,
//     calendarRows: {
//       month: generateMonthCalendar(),
//       week: generateWeekCalendar()
//     },
//     selectedDate,
//     getPrevMonth,
//     getNextMonth,
//     getPrevWeek,
//     getNextWeek,
//     daysShortArr,
//     shortMonthName,
//     daysArr,
//     monthNamesArr
//   };
// };

// export default useCalendar;
