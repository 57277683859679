import React from 'react';
import '../../../../styles/overview.css';

// utils
import { Skeleton } from 'antd';

const OverviewCard = ({ list, isLoading, data }) => {
  const overViewCount = {
    Active: data?.active || 0,
    'In-review': data?.in_review || 0,
    Rejected: data?.rejected || 0,
    'Total Template': data?.total || 0,
  };

  return (
    <>
      <div className='px-[1.3vw] pt-[1.2vw] pb-[1.2vw] font-larger weight-bold text-[var(--textBlack)]'>
        Overview
      </div>
      <div className='w-full px-[3vw] pb-[1.5vw]'>
        {list?.length && (
          <div className='w-full border-[0.08vw] border-[var(--border-50) rounded-[0.85vw] flex-row align-center !px-0 py-[1.5vw] text-[var(--textBlack)]'>
            {list?.map((item) => (
              <div
                key={item?.id}
                className='w-[20vw] flex gap-[1.3vw] mx-[1vw] pl-[0.8vw] border-r-[0.08vw] border-[var(--border-50)] last:border-r-0'
              >
                <div className='flex-row justify-center'>
                  <img
                    src={item?.icon}
                    alt='performance'
                    className='w-[3vw] h-[3vw]'
                  />
                </div>
                <div className='flex-column pt-[0.2vw]'>
                  <span className='!mb-[0.2vw] font-md weight-semibold'>
                    {item?.title}
                  </span>
                  <Skeleton
                    active
                    paragraph={{ rows: 2 }}
                    style={{}}
                    loading={isLoading}
                    className='ant-skeleton-paragraphs'
                  >
                    <span className='font-largest weight-semibold'>
                      {overViewCount[item?.title]}
                    </span>
                  </Skeleton>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default OverviewCard;
