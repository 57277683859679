//
import React, { useEffect, useState } from 'react';
// import "../styles/Campaign.css";

import { Outlet, useNavigate } from 'react-router-dom';

import { SettingList } from '../../../constant/app/campaign/campaign';

import LeftList from '../../../components/commonComponents/leftListLayout/Index';

import BrandDetails from '../components/BrandDetails';

import { useAspDispatch } from '../../../test/jest-redux-hooks';
import { updateSelectedCampaignFilter } from '../../../reduxToolkit/CampaignSlice';

function Settings({ children }) {
  const [pageNavigate, setPageNavigate] = useState('Brand Settings');

  const navigate = useNavigate();

  const dispatch = useAspDispatch();

  const campaignFilter = (item) => {
    setPageNavigate(item?.name);
    navigate('/user/settings');
    dispatch(updateSelectedCampaignFilter(item?.name));
  };

  useEffect(() => {
    navigate('/user/settings/brand-settings');
  }, []);

  return (
    <div className='flex-row campaignWrapper'>
      <LeftList
        list={SettingList}
        activeNav={pageNavigate}
        onSelect={(item) => campaignFilter(item)}
      />
      <div className='campaign-rightLayout'>
        <Outlet />
        {children}
      </div>
      {/* <BrandDetails /> */}
    </div>
  );
}

export default Settings;
