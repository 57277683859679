import React, { useMemo } from 'react';

// assets
import Arrow from '../../../assets/customSVG/Arrow';

// utils
import moment from 'moment';

// components
import Date from './Date';
import { dateConverter } from '../../../helper/dateConverter';

export default function Calender({
  selectedDate,
  weeks,
  next,
  prev,
  onSelect,
  resetToToday,
}) {
  const activeDate = useMemo(() => {
    const d = dateConverter(selectedDate, false);

    return d?.formatedDate;
  }, [selectedDate]);

  return (
    <div className='bg-[var(--BG-25)] p-[1vw] rounded-[0.6vw]'>
      <div className='flex justify-between'>
        <div>
          <p className='text-[var(--contentText)] font-normal weight-semibold'>
            Schedule
          </p>
          <p className='font-md weight-medium text-[var(--font-400)]'>
            {activeDate}
          </p>
        </div>
        <div className='w-[4.8vw] h-[1.7vw] flex items-center justify-between gap-[0.35vw]'>
          <div className='w-[1.6vw] h-[1.6vw] flex items-center justify-center'>
            <button
              className='w-[1.5vw] h-[1.5vw] group flex items-center justify-center'
              onClick={() => {
                prev();
              }}
            >
              <Arrow
                width={'0.8vw'}
                height={'0.65vw'}
                className='stroke-[var(--font-600)] group-hover:stroke-[var(--primary)] transition-colors ease-linear duration-300'
                color=''
              />
            </button>
          </div>
          <div
            className='font-sm weight-medium cursor-pointer hover:text-[var(--primary)] transition-colors ease-linear duration-300'
            onClick={() => {
              resetToToday();
            }}
          >
            Today
          </div>
          <div className='w-[1.6vw] h-[1.6vw] flex items-center justify-center'>
            <button
              className='w-[1.5vw] h-[1.5vw] -rotate-180 group flex items-center justify-center'
              onClick={() => {
                next();
              }}
            >
              <Arrow
                width={'0.8vw'}
                height={'0.65vw'}
                className='stroke-[var(--font-600)] group-hover:stroke-[var(--primary)] transition-colors ease-linear duration-300'
                color=''
              />
            </button>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-between mt-[0.8vw]'>
        {weeks?.map((week, i) => {
          const selectedValue = moment(selectedDate)?.format('YYYY-MM-DD');

          const val1 = moment(selectedValue);
          const val2 = moment(week?.date);

          const isActive = val1.isSame(val2);
          return (
            <div
              key={i}
              onClick={() => {
                onSelect(week?.date);
              }}
            >
              <Date
                day={week.weekday}
                date={moment(week?.date)?.format('DD')}
                isActive={isActive}
                onSelect={onSelect}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
