import React from 'react';

export default function Xmark({
  width = 10,
  height = 10,
  color = '#898E99',
  strokeWidth = '2',
  className = '',
}) {
  return (
    <svg
      width={width ?? '10'}
      height={height ?? '10'}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M9 1L1 9M9 9L1 0.999998'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  );
}
