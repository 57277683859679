import { ICONS } from "assets/icons";
import { images } from "../../../assets/images/index";

export const sendTest = [
  {
    id: 1,
    label: 1,
    value: "Broadcast",
    selected: false,
  },
];

export const ContactDataList = [
  {
    id: 1,
    content: "Contact list - 1",
  },
  {
    id: 2,
    content: "Contact list - 2",
  },
  {
    id: 3,
    content: "Contact list - 3",
  },
];
export const contactTableList = [
  {
    id: 1,
    name: "Segment",
  },
  {
    id: 2,
    name: "Mobile number",
  },
  {
    id: 3,
    name: "status",
  },
];

export const ContactInfoList = [
  {
    id: 1,
    role: "J",
    color: "var(--fontRed)",
    name: "John Cris",
    LastName: "Cris",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Delivered",
  },

  {
    id: 2,
    // role: "R",
    // color: "var(--fontRed)",
    img: images?.BrandImageIcon,
    name: "Riya",
    LastName: "Magar",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Sent",
  },
  {
    id: 3,
    name: " -- ",
    LastName: " -- ",
    img: ICONS?.NoImageIcon,

    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Opened",
  },
  {
    id: 4,
    role: "G",
    color: "var(--a)",
    name: "Gurdeep",
    LastName: " -- ",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Lead",
  },
  {
    id: 5,
    role: "D",
    color: "var(--fontRed)",
    name: " -- ",
    LastName: "Dhev",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Bounced",
  },

  {
    id: 6,
    role: "J",
    color: "var(--approvedStatus)",
    name: " -- ",
    LastName: "Dhev",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Sending",
  },
  {
    id: 7,
    role: "J",
    color: "var(--fontRed)",
    name: " John Cris ",
    LastName: "Cris",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Sending",
  },
  {
    id: 8,
    role: "J",
    color: "var(--fontRed)",
    name: " Riya ",
    LastName: "Magar",
    segment: "Segment-1",
    Mobile_number: "(+91) 987 654 3210",
    status: "Sending",
  },
];

export const MessageStatus = [
  {
    Delivered: "var(--font-50)",
    Sent: "",
    Opened: "",
    Lead: "var(--primary)",
    Bounced: "var(--lightRed)",
  },
];
