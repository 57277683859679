export const countryList = [
  {
    label: 'United Arab Emirates',
    value: '+971',
    shortName: 'AE',
  },
  {
    label: 'Afghanistan',
    value: '+93',
    shortName: 'AF',
  },
  {
    label: 'Albania',
    value: '+355',
    shortName: 'AL',
  },
  {
    label: 'Armenia',
    value: '+374',
    shortName: 'AM',
  },
  {
    label: 'Angola',
    value: '+244',
    shortName: 'AO',
  },
  {
    label: 'Argentina',
    value: '+54',
    shortName: 'AR',
  },
  {
    label: 'Austria',
    value: '+43',
    shortName: 'AT',
  },
  {
    label: 'Australia',
    value: '+61',
    shortName: 'AU',
  },
  {
    label: 'Azerbaijan',
    value: '+994',
    shortName: 'AZ',
  },
  {
    label: 'Bangladesh',
    value: '+880',
    shortName: 'BD',
  },
  {
    label: 'Belgium',
    value: '+32',
    shortName: 'BE',
  },
  {
    label: 'Burkina Faso',
    value: '+226',
    shortName: 'BF',
  },
  {
    label: 'Bulgaria',
    value: '+359',
    shortName: 'BG',
  },
  {
    label: 'Bahrain',
    value: '+973',
    shortName: 'BH',
  },
  {
    label: 'Burundi',
    value: '+257',
    shortName: 'BI',
  },
  {
    label: 'Benin',
    value: '+229',
    shortName: 'BJ',
  },
  {
    label: 'Brunei Darussalam',
    value: '+673',
    shortName: 'BN',
  },
  {
    label: 'Bolivia',
    value: '+591',
    shortName: 'BO',
  },
  {
    label: 'Brazil',
    value: '+55',
    shortName: 'BR',
  },
  {
    label: 'Botswana',
    value: '+267',
    shortName: 'BW',
  },
  {
    label: 'Belarus',
    value: '+375',
    shortName: 'BY',
  },
  {
    label: 'Canada',
    value: '+1',
    shortName: 'CA',
  },
  {
    label: 'Congo',
    value: '+242',
    shortName: 'CG',
  },
  {
    label: 'Switzerland',
    value: '+41',
    shortName: 'CH',
  },
  {
    label: 'Ivory Coast',
    value: '+225',
    shortName: 'CI',
  },
  {
    label: 'Chile',
    value: '+56',
    shortName: 'CL',
  },
  {
    label: 'Cameroon',
    value: '+237',
    shortName: 'CM',
  },
  {
    label: 'China',
    value: '+86',
    shortName: 'CN',
  },
  {
    label: 'Colombia',
    value: '+57',
    shortName: 'CO',
  },
  {
    label: 'Costa Rica',
    value: '+506',
    shortName: 'CR',
  },
  {
    label: 'Czech Republic',
    value: '+420',
    shortName: 'CZ',
  },
  {
    label: 'Germany',
    value: '+49',
    shortName: 'DE',
  },
  {
    label: 'Denmark',
    value: '+45',
    shortName: 'DK',
  },
  {
    label: 'Dominican Republic',
    value: '+1',
    shortName: 'DO',
  },
  {
    label: 'Algeria',
    value: '+213',
    shortName: 'DZ',
  },
  {
    label: 'Ecuador',
    value: '+593',
    shortName: 'EC',
  },
  {
    label: 'Estonia',
    value: '+372',
    shortName: 'EE',
  },
  {
    label: 'Egypt',
    value: '+20',
    shortName: 'EG',
  },
  {
    label: 'Eritrea',
    value: '+291',
    shortName: 'ER',
  },
  {
    label: 'Spain',
    value: '+34',
    shortName: 'ES',
  },
  {
    label: 'Ethiopia',
    value: '+251',
    shortName: 'ET',
  },
  {
    label: 'Finland',
    value: '+358',
    shortName: 'FI',
  },
  {
    label: 'France',
    value: '+33',
    shortName: 'FR',
  },
  {
    label: 'Gabon',
    value: '+241',
    shortName: 'GA',
  },
  {
    label: 'United Kingdom',
    value: '+44',
    shortName: 'GB',
  },
  {
    label: 'Georgia',
    value: '+995',
    shortName: 'GE',
  },
  {
    label: 'French Guiana',
    value: '+594',
    shortName: 'GF',
  },
  {
    label: 'Ghana',
    value: '+233',
    shortName: 'GH',
  },
  {
    label: 'Gambia',
    value: '+220',
    shortName: 'GM',
  },
  {
    label: 'Greece',
    value: '+30',
    shortName: 'GR',
  },
  {
    label: 'Guatemala',
    value: '+502',
    shortName: 'GT',
  },
  {
    label: 'Guinea-Bissau',
    value: '+245',
    shortName: 'GW',
  },
  {
    label: 'Hong Kong',
    value: '+852',
    shortName: 'HK',
  },
  {
    label: 'Honduras',
    value: '+504',
    shortName: 'HN',
  },
  {
    label: 'Croatia',
    value: '+385',
    shortName: 'HR',
  },
  {
    label: 'Haiti',
    value: '+509',
    shortName: 'HT',
  },
  {
    label: 'Hungary',
    value: '+36',
    shortName: 'HU',
  },
  {
    label: 'Indonesia',
    value: '+62',
    shortName: 'ID',
  },
  {
    label: 'Ireland',
    value: '+353',
    shortName: 'IE',
  },
  {
    label: 'Israel',
    value: '+972',
    shortName: 'IL',
  },
  {
    label: 'India',
    value: '+91',
    shortName: 'IN',
  },
  {
    label: 'Iraq',
    value: '+964',
    shortName: 'IQ',
  },
  {
    label: 'Italy',
    value: '+39',
    shortName: 'IT',
  },
  {
    label: 'Jamaica',
    value: '+1',
    shortName: 'JM',
  },
  {
    label: 'Jordan',
    value: '+962',
    shortName: 'JO',
  },
  {
    label: 'Japan',
    value: '+81',
    shortName: 'JP',
  },
  {
    label: 'Kenya',
    value: '+254',
    shortName: 'KE',
  },
  {
    label: 'Cambodia',
    value: '+855',
    shortName: 'KH',
  },
  {
    label: 'South Korea',
    value: '+82',
    shortName: 'KR',
  },
  {
    label: 'Kuwait',
    value: '+965',
    shortName: 'KW',
  },
  {
    label: 'Laos',
    value: '+856',
    shortName: 'LA',
  },
  {
    label: 'Lebanon',
    value: '+961',
    shortName: 'LB',
  },
  {
    label: 'Sri Lanka',
    value: '+94',
    shortName: 'LK',
  },
  {
    label: 'Liberia',
    value: '+231',
    shortName: 'LR',
  },
  {
    label: 'Lesotho',
    value: '+266',
    shortName: 'LS',
  },
  {
    label: 'Lithuania',
    value: '+370',
    shortName: 'LT',
  },
  {
    label: 'Luxembourg',
    value: '+352',
    shortName: 'LU',
  },
  {
    label: 'Latvia',
    value: '+371',
    shortName: 'LV',
  },
  {
    label: 'Libya',
    value: '+218',
    shortName: 'LY',
  },
  {
    label: 'Morocco',
    value: '+212',
    shortName: 'MA',
  },
  {
    label: 'Monaco',
    value: '+377',
    shortName: 'MC',
  },
  {
    label: 'Moldova',
    value: '+373',
    shortName: 'MD',
  },
  {
    label: 'Madagascar',
    value: '+261',
    shortName: 'MG',
  },
  {
    label: 'Macedonia',
    value: '+389',
    shortName: 'MK',
  },
  {
    label: 'Mali',
    value: '+223',
    shortName: 'ML',
  },
  {
    label: 'Myanmar',
    value: '+95',
    shortName: 'MM',
  },
  {
    label: 'Mongolia',
    value: '+976',
    shortName: 'MN',
  },
  {
    label: 'Mauritania',
    value: '+222',
    shortName: 'MR',
  },
  {
    label: 'The Republic of Malta',
    value: '+356',
    shortName: 'MT',
  },
  {
    label: 'Malawi',
    value: '+265',
    shortName: 'MW',
  },
  {
    label: 'Mexico',
    value: '+52',
    shortName: 'MX',
  },
  {
    label: 'Malaysia',
    value: '+60',
    shortName: 'MY',
  },
  {
    label: 'Mozambique',
    value: '+258',
    shortName: 'MZ',
  },
  {
    label: 'Namibia',
    value: '+264',
    shortName: 'NA',
  },
  {
    label: 'Niger',
    value: '+227',
    shortName: 'NE',
  },
  {
    label: 'Nigeria',
    value: '+234',
    shortName: 'NG',
  },
  {
    label: 'Nicaragua',
    value: '+505',
    shortName: 'NI',
  },
  {
    label: 'Netherlands',
    value: '+31',
    shortName: 'NL',
  },
  {
    label: 'Norway',
    value: '+47',
    shortName: 'NO',
  },
  {
    label: 'Nepal',
    value: '+977',
    shortName: 'NP',
  },
  {
    label: 'New Zealand',
    value: '+64',
    shortName: 'NZ',
  },
  {
    label: 'Oman',
    value: '+968',
    shortName: 'OM',
  },
  {
    label: 'Panama',
    value: '+507',
    shortName: 'PA',
  },
  {
    label: 'Peru',
    value: '+51',
    shortName: 'PE',
  },
  {
    label: 'Papua New Guinea',
    value: '+675',
    shortName: 'PG',
  },
  {
    label: 'Philippines',
    value: '+63',
    shortName: 'PH',
  },
  {
    label: 'Pakistan',
    value: '+92',
    shortName: 'PK',
  },
  {
    label: 'Poland',
    value: '+48',
    shortName: 'PL',
  },
  {
    label: 'Puerto Rico',
    value: '+1',
    shortName: 'PR',
  },
  {
    label: 'Portugal',
    value: '+351',
    shortName: 'PT',
  },
  {
    label: 'Paraguay',
    value: '+595',
    shortName: 'PY',
  },
  {
    label: 'Qatar',
    value: '+974',
    shortName: 'QA',
  },
  {
    label: 'Romania',
    value: '+40',
    shortName: 'RO',
  },
  {
    label: 'Serbia',
    value: '+381',
    shortName: 'RS',
  },
  {
    label: 'Russia',
    value: '+7',
    shortName: 'RU',
  },
  {
    label: 'Rwanda',
    value: '+250',
    shortName: 'RW',
  },
  {
    label: 'Saudi Arabia',
    value: '+966',
    shortName: 'SA',
  },
  {
    label: 'Sudan',
    value: '+249',
    shortName: 'SD',
  },
  {
    label: 'Sweden',
    value: '+46',
    shortName: 'SE',
  },
  {
    label: 'Singapore',
    value: '+65',
    shortName: 'SG',
  },
  {
    label: 'Slovenia',
    value: '+386',
    shortName: 'SI',
  },
  {
    label: 'Slovakia',
    value: '+421',
    shortName: 'SK',
  },
  {
    label: 'Sierra Leone',
    value: '+232',
    shortName: 'SL',
  },
  {
    label: 'Senegal',
    value: '+221',
    shortName: 'SN',
  },
  {
    label: 'Somalia',
    value: '+252',
    shortName: 'SO',
  },
  {
    label: 'South Sudan',
    value: '+211',
    shortName: 'SS',
  },
  {
    label: 'El Salvador',
    value: '+503',
    shortName: 'SV',
  },
  {
    label: 'Eswatini',
    value: '+268',
    shortName: 'SZ',
  },
  {
    label: 'Chad',
    value: '+235',
    shortName: 'TD',
  },
  {
    label: 'Togo',
    value: '+228',
    shortName: 'TG',
  },
  {
    label: 'Thailand',
    value: '+66',
    shortName: 'TH',
  },
  {
    label: 'Tajikistan',
    value: '+992',
    shortName: 'TJ',
  },
  {
    label: 'Turkmenistan',
    value: '+993',
    shortName: 'TM',
  },
  {
    label: 'Tunisia',
    value: '+216',
    shortName: 'TN',
  },
  {
    label: 'Taiwan',
    value: '+886',
    shortName: 'TW',
  },
  {
    label: 'Tanzania',
    value: '+255',
    shortName: 'TZ',
  },
  {
    label: 'Ukraine',
    value: '+380',
    shortName: 'UA',
  },
  {
    label: 'Uganda',
    value: '+256',
    shortName: 'UG',
  },
  {
    label: 'United States',
    value: '+1',
    shortName: 'US',
  },
  {
    label: 'Uruguay',
    value: '+598',
    shortName: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: '+998',
    shortName: 'UZ',
  },
  {
    label: 'Venezuela',
    value: '+58',
    shortName: 'VE',
  },
  {
    label: 'Vietnam',
    value: '+84',
    shortName: 'VN',
  },
  {
    label: 'Yemen',
    value: '+967',
    shortName: 'YE',
  },
  {
    label: 'South Africa',
    value: '+27',
    shortName: 'ZA',
  },
  {
    label: 'Zambia',
    value: '+260',
    shortName: 'ZM',
  },
];
