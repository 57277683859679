import React, { useEffect, useState } from 'react';
import Button from '../../commonComponents/button';
import { crmFontColor, headingColor } from '../../../constants';
import Dropdown from '../../commonComponents/dropdown';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Tick from '../../../../../assets/customSVG/Check';
import { handleUploadedFieldsSelection } from '../../../../../reduxToolkit/crmSlice';
import Underline from '../../commonComponents/underline';
import { Popover } from 'antd';

const MapContacts = () => {
  const { uploadedFields, mappingFields } = useSelector((store) => store.crm);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    if (!isError) return;
    const to = setTimeout(() => {
      setIsError(null);
    }, 2500);
  }, [isError]);
  return (
    <div className="grid grid-rows-[10fr,1fr] h-[90%] relative z-[3000] overflow-hidden">
      <div
        className={`text-[${headingColor}] px-[1.5vw] pb-[1vw] overflow-scroll`}
      >
        <div className="text-[.75vw] font-[600] grid grid-cols-[2fr,3fr] border-b border-b-gray-100 mt-[2vh] pb-[.7vh] mb-[1vw]">
          <p>CRM Fields</p>
          <p className="">Mapping Fields</p>
        </div>

        <div className="mt-[1vh]- space-y-[4.7vh] px-[.5vw] ">
          {Object.entries(mappingFields).map(([key, val]) => {
            return (
              <div className="text-[.75vw] text-[#616874] font-[500] grid grid-cols-[2fr,3fr] items-center">
                <p className="capitalize">
                  {key.split('_').join(' ')}{' '}
                  {key === 'mobile_number' && (
                    <span className="text-red-700">*</span>
                  )}
                </p>

                <Dropdown
                  // open={}
                  align={{}}
                  // getPopupContainer={(trigger) => trigger.parentNode.parentNode}
                  // autoAdjustOverflow={true}
                  heading=""
                  width="min-w-[24.5vw]"
                  height="max-h-[32vh]"
                  containerClassNames="px-[1.6vw]"
                  content={uploadedFields.map((item, i) => {
                    // show this when all mapping fields are selected.
                    if (
                      i === uploadedFields.length - 1 &&
                      uploadedFields.length ===
                        uploadedFields.filter((item) => item.selected_by).length
                    ) {
                      return <p>No other fields to choose</p>;
                    }

                    // 1.1) item.selected_by will have the value same as the key here => if it has the same key then making it false so that it can skip the return
                    // 1.2) to hide the selected value of one from another, here iam checking if selected_by has any value in it
                    if (!(item.selected_by === key) && item.selected_by) return;

                    return (
                      <React.Fragment key={item.label}>
                        <div
                          className=" duration-200 bg-ed-300 py-[.7vw] px-[.5vw] cursor-pointer hover:bg-gray-100 rounded-lg flex justify-between items-center "
                          onClick={() => {
                            // console.log({ item, key, val });
                            // // editFilter(id);
                            dispatch(
                              handleUploadedFieldsSelection({
                                id: item.id,
                                selected_by: key,
                                prev_selected_by: val,
                              }),
                            );
                          }}
                        >
                          <div className="flex items-center gap-[.5vw]">
                            <p className="">{item.label}</p>
                          </div>

                          <span
                            className={`${
                              item.selected_by === key && item.selected
                                ? 'scale-100'
                                : 'scale-0'
                            } duration-200`}
                          >
                            <Tick />
                          </span>
                          {/* {selected && } */}
                        </div>
                        <Underline className="my-[.5vh]" />
                      </React.Fragment>
                    );
                  })}
                  button={
                    <div
                      className={`bg-red-400- border  rounded-md flex items-center justify-between px-[.5vw] py-[1vh] text-[#2D3036] ${
                        !mappingFields.mobile_number &&
                        isError &&
                        key === 'mobile_number'
                          ? 'border-red-400'
                          : 'border-gray-100'
                      }`}
                    >
                      <p>{val || 'Not selected'}</p>
                      <span
                        className={`duration-300 ${
                          false ? '-rotate-180' : 'rotate-0'
                        }`}
                      >
                        <div
                          className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-gray-400 translate-y-[25%]`}
                        />
                      </span>
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="sticky bottom-0 border-t border-t-slate-200 py-[.5vw] pe-[1vw] flex items-center justify-end text-[.75vw] text-red-500 font-[500] gap-[.5vw]">
        <p className={`duration-200 ${isError ? 'opacity-[1]' : 'opacity-0'}`}>
          {isError}
        </p>
        <div className="">
          <Button
            onClick={() => {
              if (!mappingFields.mobile_number) {
                setIsError('Please map all the mandatory fields *');
              }
            }}
            classNames=""
            label="Import"
          />
        </div>
      </div>
    </div>
  );
};

export default MapContacts;
