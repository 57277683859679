import React, { useCallback, useEffect } from 'react';

// assets
import User from 'assets/customSVG/User';
import Mail from 'assets/customSVG/Mail';

// API
import { SignUpApi } from '../../api/SignUp';

// utils
import { Link } from 'react-router-dom';

// helpers
import { cn } from '../../../../helper/cn';
import { validateEmail } from '../../../../helper/validation';

// hooks
import useToggle from '../../../../hooks/useToggle';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';
import { updateToggleToast } from '../../../../reduxToolkit/appSlice';

// components
import Google from '../../../../components/commonComponents/Google/Google';
import CheckBox from '../../../../components/commonComponents/checkbox';
import MidTileDivider from '../../../../components/commonComponents/divider/midTitleDivider';
import Loader from '../../../../components/commonComponents/Loader/Index';

export default function Step1({ data, token, onChange }) {
  const { toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);

  useEffect(() => {
    const email = localStorage.getItem('verify-email');
    onChange('update', 'mail', email);
  }, []);

  const validation = useCallback(() => {
    return (
      data?.firstname &&
      data?.lastname &&
      data?.mail &&
      data?.terms &&
      validateEmail(data?.mail)
    );
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      const res = await SignUpApi({
        first_name: data?.firstname,
        last_name: data?.lastname,
        email: data?.mail,
      });

      if (res.status === 200) {
        onChange('update', 'step1', true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    }
  };

  // styles
  const card = 'flex flex-col gap-3';
  const label = 'text-base weight-semibold leading-6 text-[#2D3036]';
  const inputContainer =
    'w-full h-12 flex items-center gap-2 py-2.5 px-3 border border-[#D1D3D8] rounded-lg';
  const input = 'w-full text-sm weight-medium';

  return (
    <div className='w-full max-w-[470px] mx-auto'>
      <div className='w-full text-2xl weight-bold text-center text-[#2D3036] mt-8 mb-12'>
        Let’s Ignite Growth with Conversational Relationship Platform.
      </div>
      {/* <div className='mb-2'>
        <Google />
      </div>
      <MidTileDivider title='Or' width={'100%'} /> */}
      <form action='' onSubmit={handleSubmit}>
        <div className='flex items-center justify-between gap-4 mt-2'>
          <div className={cn(card)}>
            <label htmlFor='firstname' className={cn(label)}>
              First Name <sup className='text-red-500'>*</sup>
            </label>
            <div className={cn(inputContainer)}>
              <div className=''>
                <User width='18px' height='18px' />
              </div>
              <input
                name='firstname'
                id='firstname'
                type='text'
                placeholder='Ex:- Ronald'
                className={cn(input)}
                value={data?.firstname}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange('update', name, value);
                }}
              />
            </div>
          </div>
          <div className={cn(card)}>
            <label htmlFor='lastname' className={cn(label)}>
              Last Name <sup className='text-red-500'>*</sup>
            </label>
            <div className={cn(inputContainer)}>
              <div className=''>
                <User width='18px' height='18px' />
              </div>
              <input
                name='lastname'
                id='lastname'
                type='text'
                placeholder='Ex:- Warren'
                className={cn(input)}
                value={data?.lastname}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange('update', name, value);
                }}
                // onPaste={(e) => {
                //   console.log(e);
                // }}
              />
            </div>
          </div>
        </div>
        <div className={cn(card, 'mt-4')}>
          <label htmlFor='email'>
            Email
            <sup className='text-red-500'>*</sup>
          </label>
          <div className={cn(inputContainer)}>
            <div>
              <Mail width='18px' height='18px' />
            </div>
            <input
              name='mail'
              id='mail'
              type='email'
              placeholder='Ex:- yourmail@email.com'
              className={cn(input)}
              value={data?.mail}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange('update', name, value);
              }}
            />
          </div>
        </div>
        <div className='flex items-center gap-3 mt-3'>
          <CheckBox
            // data={data?.terms}
            value={data?.terms}
            onChange={(e) => {
              onChange('update', 'terms', e);
            }}
            className='size-[18px]'
          />
          <p className='text-xs weight-medium'>
            By signing up, I accept the Gainwix.ai terms of service & privacy
            notice.
          </p>
        </div>
        <div className='flex justify-center mt-6'>
          <button
            type='submit'
            className={cn(
              'w-44 h-12 rounded-[10px] px-4 py-2 text-base weight-semibold flex items-center justify-center',

              validation()
                ? isLoading
                  ? 'cursor-not-allowed bg-[var(--primary)] text-[var(--white)]'
                  : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed'
            )}
          >
            {isLoading ? (
              <div className='size-[2vw] flex items-center justify-center'>
                <Loader
                  Width='20px'
                  Height='20px'
                  loaderBg='3px solid white'
                  loaderColor='3px solid var(--primary)'
                />
              </div>
            ) : (
              'Register Now'
            )}
          </button>
        </div>
      </form>

      <div className='flex items-center justify-center gap-1 text-base weight-medium border-t border-[var(--border-50)] mt-4 pt-4'>
        <p>Already have an account?</p>
        <Link to={'/login'} className='text-[var(--a)]'>
          Login
        </Link>
      </div>
    </div>
  );
}
