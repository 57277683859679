import React from 'react';

const BroadCastProfile = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.27409 7.62489C9.27409 8.82141 8.4387 9.67171 7.40821 9.67171C6.37771 9.67171 5.54233 8.82141 5.54233 7.62489C5.54233 6.42836 6.37771 5.71338 7.40821 5.71338C8.4387 5.71338 9.27409 6.42836 9.27409 7.62489Z"
        stroke="#25C277"
        strokeWidth="1.58333"
        strokeLinejoin="round"
      />
      <path
        d="M12.271 14.5007C12.271 15.7314 10.8316 16.7291 7.521 16.7291C4.21039 16.7291 2.771 15.7314 2.771 14.5007C2.771 13.2699 4.89764 12.2722 7.521 12.2722C10.1443 12.2722 12.271 13.2699 12.271 14.5007Z"
        stroke="#25C277"
        strokeWidth="1.58333"
        strokeLinejoin="round"
      />
      <path
        d="M14.0163 4.08072C14.0865 3.92179 14.312 3.92179 14.3822 4.08072L15.0473 5.58657C15.0756 5.65068 15.1354 5.6953 15.2049 5.70416L16.7889 5.90601C16.9548 5.92715 17.023 6.13051 16.9034 6.24743L15.7216 7.40257C15.6739 7.44923 15.6526 7.51662 15.6648 7.58225L15.9731 9.23621C16.0045 9.40464 15.8234 9.532 15.6755 9.44549L14.3002 8.64096C14.2378 8.60447 14.1606 8.60447 14.0982 8.64096L12.7229 9.44549C12.575 9.532 12.3939 9.40464 12.4253 9.23621L12.7336 7.58225C12.7459 7.51662 12.7246 7.44923 12.6768 7.40257L11.495 6.24743C11.3754 6.13051 11.4436 5.92715 11.6095 5.90601L13.1935 5.70416C13.263 5.6953 13.3229 5.65068 13.3512 5.58657L14.0163 4.08072Z"
        fill="#F6A723"
      />
    </svg>
  );
};

export default BroadCastProfile;
