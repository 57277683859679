import { ICONS } from "../../../assets/icons/index";

export const ContactDetailList = [
  {
    id: 1,
    name: "Mobile number",
    icon: ICONS?.PhoneIcon,
    content: "Ex:- 000 000 0000",
  },
  {
    id: 2,
    name: "Email",
    icon: ICONS?.EmailIcon,
    content: "Ex:-yourmail@email.com",
  },
  {
    id: 3,
    name: "Contact address",
    icon: ICONS?.AccountHomeIcon,
    content: "Enter address",
  },
  {
    id: 4,
    name: "Country",
    icon: ICONS?.LocationMarkIcon,
    content: "Enter state & city",
  },
];
export const PasswordList = [
  {
    id: 1,
    name: "Current Password",
    content: "Password",
  },
  {
    id: 2,
    name: "New Password",
    content: "Password",
  },
  {
    id: 3,
    name: "Re-enter  Password",
    content: "Password",
  },
];

export const account_center = [
  {
    id: 1,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
  {
    id: 2,
    title: "VVD & Sons Pvt Ltd",
    content: "01 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Vvd,
  },
  {
    id: 3,
    title: "Aura Properties",
    content: "06 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aura,
  },
  {
    id: 4,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
  {
    id: 5,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
  {
    id: 6,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
];
