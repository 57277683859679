import React from 'react';

export default function User({
  width,
  height,
  color,
  strokeWidth = '0.5',
  className,
}) {
  return (
    <svg
      width={width ?? '18'}
      height={height ?? '20'}
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00003 0.25C6.51475 0.25 4.50003 2.26472 4.50003 4.75C4.50003 7.23527 6.51475 9.25 9.00003 9.25C11.4853 9.25 13.5 7.23527 13.5 4.75C13.5 2.26472 11.4853 0.25 9.00003 0.25ZM6.00003 4.75C6.00003 3.09314 7.3432 1.75 9.00003 1.75C10.6569 1.75 12 3.09314 12 4.75C12 6.40683 10.6569 7.75 9.00003 7.75C7.3432 7.75 6.00003 6.40683 6.00003 4.75ZM9.00003 10C5.54733 10 3.41271 11.074 2.16322 12.6122C0.938138 14.1202 0.674325 15.9419 0.670665 17.2354C0.666263 18.7895 2.01724 19.75 3.3691 19.75H14.631C15.9828 19.75 17.3338 18.7895 17.3294 17.2354C17.3257 15.9419 17.0619 14.1202 15.8369 12.6122C14.5874 11.074 12.4527 10 9.00003 10ZM2.17066 17.2396C2.17385 16.1099 2.40964 14.6878 3.32748 13.5579C4.22092 12.4581 5.87253 11.5 9.00003 11.5C12.1275 11.5 13.7792 12.4581 14.6726 13.5579C15.5904 14.6878 15.8262 16.1099 15.8294 17.2396C15.8309 17.7687 15.3622 18.25 14.631 18.25H3.3691C2.63784 18.25 2.16916 17.7687 2.17066 17.2396Z'
        fill={color ?? '#2D3036'}
        stroke={color ?? '#2D3036'}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
