import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  InviteTokenValidateWithLogin,
  InviteTokenValidateWithoutLogin,
} from '../api/OnBoarding';

import Loader from '../../../components/commonComponents/Loader/Index';
import { useAspSelector } from '../../../test/jest-redux-hooks';

export default function VerifiyEmail() {
  const { currentUser } = useAspSelector((state) => state.app);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const email = queryParams?.get('email');

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    localStorage.setItem('verify-email', email);

    if (token && email) {
      if (currentUser && currentUser?.email === email) validateWithToken();
      else
        navigate({
          pathname: '/login',
          search: createSearchParams({
            token: queryParams?.get('token'),
            // email: queryParams?.get('email'),
          }).toString(),
        });
    } else {
      navigate({
        pathname: '/login',
        search: createSearchParams({
          token: queryParams?.get('token'),
          // email: queryParams?.get('email'),
        }).toString(),
      });
    }
  }, [queryParams]);

  const validateWithToken = async () => {
    setLoading(true);
    try {
      const res = await InviteTokenValidateWithLogin({
        invite_token: queryParams.get('token'),
      });

      if (res.status === 200) {
        setLoading(false);
        navigate('/user/home');
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.warn(e);
      setLoading(false);
    }
  };

  return (
    <div className='w-full h-screen'>
      {loading ? (
        <div className='w-full h-full size-[2vw] flex items-center justify-center'>
          <Loader
            Width='30px'
            Height='30px'
            loaderBg='4px solid var(--BG-50)'
            loaderColor='4px solid var(--primary)'
          />
        </div>
      ) : (
        'email'
      )}
    </div>
  );
}
