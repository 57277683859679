import React from 'react';

const SpeakerPhone = ({ width = '16', height = '16', color = 'none', stroke = '#4AACEA' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 5.33298C12.5304 5.33298 13.0391 5.54369 13.4142 5.91876C13.7893 6.29384 14 6.80254 14 7.33298C14 7.86341 13.7893 8.37212 13.4142 8.74719C13.0391 9.12226 12.5304 9.33298 12 9.33298M6.66667 5.33298V12.6663C6.66667 12.8431 6.59643 13.0127 6.4714 13.1377C6.34638 13.2627 6.17681 13.333 6 13.333H5.33333C5.15652 13.333 4.98695 13.2627 4.86193 13.1377C4.7369 13.0127 4.66667 12.8431 4.66667 12.6663V9.33298M8 5.33297L11.016 2.81964C11.1036 2.74665 11.2103 2.70014 11.3234 2.68558C11.4365 2.67101 11.5514 2.68899 11.6547 2.73741C11.758 2.78582 11.8453 2.86267 11.9065 2.95894C11.9676 3.05521 12.0001 3.16692 12 3.28097V11.385C12.0001 11.499 11.9676 11.6107 11.9065 11.707C11.8453 11.8033 11.758 11.8801 11.6547 11.9285C11.5514 11.977 11.4365 11.9949 11.3234 11.9804C11.2103 11.9658 11.1036 11.9193 11.016 11.8463L8 9.33297H2.66667C2.48986 9.33297 2.32029 9.26274 2.19526 9.13771C2.07024 9.01269 2 8.84312 2 8.66631V5.99964C2 5.82283 2.07024 5.65326 2.19526 5.52824C2.32029 5.40321 2.48986 5.33297 2.66667 5.33297H8Z'
        stroke={stroke}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SpeakerPhone;
