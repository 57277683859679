import React from "react";
import "../../../channels/styles/messageList.css";
import { ICONS } from "../../../../assets/icons";

const NoBroadcast = () => {
  return (
    <div className="no-broadcast-main-container w-100  flex-column align-center justify-center">
      <img src={ICONS?.noBroadcast} alt="noTemplate" />
      <span className="no-template-title">No Broadcast</span>
      <span className="no-template-content">
        No broadcast have been found. Click the button to create one.
      </span>
    </div>
  );
};

export default NoBroadcast;
