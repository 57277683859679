import React from 'react';
import { ICONS } from '../../../assets/icons';

import { cn } from 'helper/cn';
import { Flex } from 'antd';

export default React.memo(function Header(props) {
  const {
    title,
    subTitle,
    close = true,
    rootStyle,
    titleStyle,
    titleSize = 'normal',
    titleWeight = 'bold',
    subTitleStyle,
    subTitleSize = 'normal',
    subTitleWeight = 'medium',
    suffix,
    prefixStyle,
    suffixStyle,
    closeStyle,
    prefix = null,
    handleCancel,
  } = props;

  const TEXT_STYLE = {
    xs: 'font-xs',
    sm: 'font-sm',
    md: 'font-md',
    medium: 'font-medium',
    normal: 'font-normal',
    large: 'font-large',
    larger: 'font-larger',
    largest: 'font-largest',
  };

  const TEXT_WEIGHT = {
    small: 'weight-small',
    medium: 'weight-medium',
    semibold: 'weight-semibold',
    bold: 'weight-bold',
    extrabold: 'weight-extrabold',
  };

  return (
    <div
      className={cn(
        'w-full flex !items-center justify-between px-5 py-4 border-b-[0.08vw] border-[var(--border-50)]',
        rootStyle
      )}
    >
      {prefix && (
        <div className={cn(prefixStyle)}>
          {typeof prefix == 'string' ? (
            <img src={prefix} alt='icons' />
          ) : (
            prefix
          )}
        </div>
      )}
      <div
        className={cn(
          titleStyle,
          TEXT_STYLE[titleSize],
          TEXT_WEIGHT[titleWeight],
          'w-auto flex flex-1 flex-col gap-1'
        )}
      >
        <h3 className='text-[var(--contentText)]'>{title}</h3>
        {subTitle && (
          <p
            className={cn(
              subTitleStyle,
              TEXT_STYLE[subTitleSize],
              TEXT_WEIGHT[subTitleWeight]
            )}
          >
            {subTitle}
          </p>
        )}
      </div>
      {suffix ? (
        <div
          className={cn('flex items-center justify-center', suffixStyle)}
          onClick={() => {
            if (handleCancel) handleCancel();
          }}
        >
          {typeof suffix == 'string' ? (
            <img src={suffix} alt='icons' />
          ) : (
            suffix
          )}
        </div>
      ) : (
        close && (
          <button
            className={cn(
              'size-[1.2vw] font-larger weight-semibold rounded-full hover:bg-slate-100 flex items-center justify-center',
              closeStyle
            )}
            onClick={() => {
              if (handleCancel) handleCancel();
            }}
          >
            {/* &times; */}
            <img
              src={ICONS?.popupX}
              alt='icons'
              className='w-[1.2vw] h-[1.2vw]'
            />
          </button>
        )
      )}
    </div>
  );
});
