import { ICONS } from 'assets/icons';

export const ATTACHMENTS_TYPE = [
  {
    id: 1,
    label: 'IMAGE',
    format: '.jpg, .png',
    icon: ICONS?.step3EmojiIcon,
  },
  {
    id: 2,
    label: 'VIDEO',
    format: '.mp4',
    icon: ICONS?.step3EmojiIcon,
  },
  {
    id: 3,
    label: 'DOCUMENT',
    format: '.pdf',
    icon: ICONS?.step3EmojiIcon,
  },
];

export const INLINE_STYLES = [
  {
    label: <img src={ICONS?.Bold} alt='' className='size-[0.8vw]' />,
    style: 'BOLD',
  },
  {
    label: <img src={ICONS?.Italic} alt='' className='size-[0.8vw]' />,
    style: 'ITALIC',
  },
  {
    label: <img src={ICONS?.StrikeThrough} alt='' className='size-[1vw]' />,
    style: 'STRIKETHROUGH',
  },
];
