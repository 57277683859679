import React from 'react';

// assets
import { ICONS } from '../../../../assets/icons';

// utils
import { Popover } from 'antd';

// hooks
import useToggle from '../../../../hooks/useToggle';

export const addVariableText = [
  {
    title: 'first-name',
    id: 1,
  },
  {
    title: 'last-name',
    id: 2,
  },
  {
    title: 'shop-name',
    id: 3,
  },
  {
    title: 'email-id',
    id: 4,
  },
  {
    title: 'mobile-number',
    id: 5,
  },
  {
    title: 'custom-variable',
    id: 6,
  },
];

export default function AddVariable({ disable, onChange, allow }) {
  const [togglevariables, setTogglevariables] = useToggle(false);

  return (
    <Popover
      trigger={allow && !disable ? ['click'] : []}
      // arrow={false}
      open={togglevariables}
      arrow={false}
      onOpenChange={(value) => {
        if (allow) setTogglevariables(value);
      }}
      content={
        <div className='px-[0.5vw] py-[0.3vw]'>
          {addVariableText?.map((item) => {
            return (
              <div
                key={item?.id}
                className={`!py-[0.3vw] border-b-[0.08vw] border-[var(--border-50)] last:border-b-0 font-md weight-medium text-[var(--contentText)]`}
                onClick={() => {
                  if (allow) {
                    if (onChange) onChange(item);
                    setTogglevariables(false);
                  }
                }}
              >
                <div
                  className='w-[11.5vw] h-auto px-[0.6vw] py-[0.5vw] cursor-pointer rounded-[0.15vw] hover:bg-slate-100'
                  onClick={() => {
                    if (allow) setTogglevariables(true);
                  }}
                >
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      }
    >
      <div
        className={`flex items-center gap-[0.5vw] font-md weight-semibold px-[0.5vw] py-[0.5vw] rounded-[0.3vw] ${
          !allow || (allow && disable)
            ? 'bg-[var(--lightGrey)] text-[var(--darkgrey)] cursor-not-allowed'
            : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
        }`}
      >
        <img src={ICONS?.addBGwhite} alt='add' /> Add Variable
      </div>
    </Popover>
  );
}
