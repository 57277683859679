import React, { useState } from "react";
import { ICONS } from "../../../assets/icons";
import "../components/styles/cardcontent.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const CardContent = ({ handleClose, broadcastList, col, segmentId }) => {
  const navigate = useNavigate();
  // const segment = broadcastList?.find((seg) => seg.segment === segmentId);
  // if (!segment) return null;
  // const scheduleOn = new Date(broadcastList?.schedule_on);
  // const endDate = broadcastList?.end_date
  //   ? new Date(broadcastList.end_date)
  //   : null;
  const scheduleOn = moment(broadcastList?.schedule_on).utc();
  const endDate = broadcastList?.end_date
    ? moment(broadcastList.end_date).utc()
    : null;

  // const formattedDates = `${scheduleOn.toLocaleString("en-US", {
  //   month: "short",
  //   day: "2-digit",
  //   year: "numeric",
  // })} @ ${scheduleOn.toLocaleTimeString("en-US", {
  //   hour: "numeric",
  //   minute: "2-digit",
  // })}`;

  // let formattedEndDates;
  // if (endDate) {
  //   formattedEndDates = `${endDate.toLocaleString("en-US", {
  //     month: "short",
  //     day: "2-digit",
  //     year: "numeric",
  //   })} @ ${endDate.toLocaleTimeString("en-US", {
  //     hour: "numeric",
  //     minute: "2-digit",
  //   })}`;
  // } else {
  //   formattedEndDates = "No end date";
  // }

  const formattedDates = `${moment(scheduleOn)
    .utc()
    .format("MMM DD YYYY")} @ ${moment(scheduleOn).utc().format("hh:mm A")}`;

  let formattedEndDates;
  if (endDate) {
    formattedEndDates = `${moment(endDate)
      .utc()
      .format("MMM DD YYYY")} @ ${moment(endDate).utc().format("hh:mm A")}`;
  } else {
    formattedEndDates = "No end date";
  }

  const navigateFunction = (item) => {
    navigate(`/user/campaign/broadcast/segment_list/${item?.broadcast_id}`, {
      state: { item },
    });
  };

  return (
    <div className="calender-card-popup">
      <div className="calender-card-header">
        <div className="flex-row space-between">
          <div className="flex-row align-center pt-10">
            <div
              className="calender-border"
              style={{ background: broadcastList?.color }}
            ></div>
            <h2 className="calender-header pb-5">
              {broadcastList?.name
                ? broadcastList.name?.charAt(0)?.toUpperCase() +
                  broadcastList?.name?.slice(1)
                : ""}
            </h2>
          </div>
          <img
            className="calender-popup-exit pointer"
            src={ICONS.popupX}
            alt="popup"
            onCancel={handleClose}
          />
        </div>
        <div className="flex-row align-center pl-[25px]">
          {broadcastList?.status === 10 && (
            <div className="flex-row align-center">
              <img
                src={ICONS?.calenderScheduled}
                className="boardCardRunning"
              />
              <p className="calender-scheduled pl-5">
                Scheduled on {formattedDates}
              </p>
            </div>
          )}

          {broadcastList?.status === 11 && (
            <div className="flex-row align-center">
              <img
                src={ICONS?.CampaignRunningIcon}
                className="boardCardRunning "
              />
              <p className="calender-scheduled pl-5">Running</p>
            </div>
          )}

          {/* {broadcastList?.status === 0 && (
            <p className="calender-scheduled">Draft</p>
          )} */}

          {broadcastList?.status === 12 && (
            <div className="flex-row align-center">
              <img
                src={ICONS?.CampaignCompleteIcon}
                className="boardCardRunning"
              />
              <p className="calender-scheduled pl-5">
                Completed on {formattedDates}
              </p>
            </div>
          )}

          {/* <img src={ICONS?.edit} className="calender-edit" />
          <div
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              background: hover ? "#FFEEED" : "transparent",
              borderRadius: hover ? "6px" : "6px",
            }}
          >
            <CustomDelete
              color={hover ? "#F96056" : "#898E99"}
              className="calender-delete"
            />
          </div> */}
        </div>
      </div>

      <div className="">
        <div className="calender-data flex-row align-center">
          <img src={ICONS?.startEndDate} className="boardCardRunning" />
          <p className="calender-title pl-10 ">
            {formattedDates} - {formattedEndDates}
          </p>
        </div>
        <div className="flex-row calender-data">
          <img src={ICONS?.whatsApp} className="first-img" />
          <div className="flex-column">
            <p className="calender-title1">{broadcastList?.phone_name}</p>
            <p className="calender-phone1">{broadcastList?.phone_number}</p>
          </div>
        </div>

        <div className="flex-row calender-data align-center">
          <img src={ICONS?.uploadFile} className="boardCardSegment" />
          <p className="calender-title">
            {broadcastList?.uploaded_files_count} - Audience lists{" "}
            <span className="ml-10">/</span>
          </p>
          <img
            src={ICONS?.BroadcastSegmentIcons}
            className="boardCardSegment ml-10"
          />
          <p className="calendar-segments">
            {broadcastList?.uploaded_files_count} - Segments
          </p>
        </div>
        <div className="flex-row calender-data align-center">
          <img src={ICONS?.calenderContact} className="calender-popover-img" />
          <p className="calender-title">
            {broadcastList?.contacts ? broadcastList?.contacts : 0} Contacts
          </p>
        </div>
        <div className="flex-row calender-data align-center">
          <img src={ICONS?.calenderDinner} className="calender-popover-img" />
          <p className="calender-title ">{broadcastList?.template_name}</p>
        </div>
      </div>

      <div className="calender-button">
        <button onClick={() => navigateFunction(broadcastList)}>
          Go to Broadcast
        </button>
      </div>
    </div>
  );
};

export default CardContent;
