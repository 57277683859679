import React from 'react';

export default function Eye({ width, height, color, strokeWidth, className }) {
  return (
    <svg
      width={width ?? '16'}
      height={height ?? '15'}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.46527 13.6476H14.3499M1.6499 13.6476L5.20428 12.9315C5.39297 12.8934 5.56622 12.8005 5.70229 12.6644L13.6591 4.70321C14.0406 4.32152 14.0403 3.7028 13.6585 3.32143L11.973 1.6378C11.5913 1.25658 10.9729 1.25684 10.5916 1.63838L2.63396 9.60037C2.49816 9.73625 2.40543 9.90915 2.36738 10.0975L1.6499 13.6476Z"
        stroke={color ?? '#898E99'}
        strokeWidth={strokeWidth ?? '1.5'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
