import React from "react";
import "../../../styles/contact.css";

import { ICONS } from "../../../../../assets/icons";
import MessageStatus from "./MessageStatus";
import Avatar from "../../../../../components/commonComponents/avatar/Avatar";
import { Skeleton } from "antd";

const BrandStyle = {
  width: `calc(43vw / 3)`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const ContactTable = ({
  contactList,
  isFilter,
  selectedStatus,
  statusExists,
  isLoading,
}) => {
  const broadcastContactData = contactList?.broadcast_contacts;

  const formatPhoneNumber = (phoneNumber) => {
    return `(+${phoneNumber?.slice(0, 2)}) ${phoneNumber?.slice(2)}`;
  };

  return (
    <div className=" flex-column ">
      <div className="contact_table flex-column">
        <Skeleton active loading={isLoading} rootClassName="w-[98.5%]">
          <div className="flex-row">
            <div className=" flex-row px-7 pb-2  pt-1 ">
              <span className=" header_names w-[15vw] "> First name</span>
              <span className=" header_names w-[13vw]">Last name</span>
            </div>
            <div
              className="flex-row contact_left_top "
              style={{
                width: "70%",
              }}
            >
              <p style={BrandStyle}>Segment</p>
              <p style={BrandStyle}>Mobile number</p>

              <p style={BrandStyle}>Status</p>
            </div>
          </div>

          <div className="contact_data">
            {broadcastContactData?.map((item, id) => {
              return (
                ((selectedStatus?.length > 0 &&
                  selectedStatus?.includes(item?.status)) ||
                  selectedStatus?.length === 0) &&
                item?.to_number?.includes(isFilter) &&
                statusExists && (
                  <div
                    className="contact_table_wrapper flex-row align-center"
                    key={item?.id}
                  >
                    <div className=" flex-row align-center">
                      <div className=" w-[15vw] flex-row align-center">
                        <div className="">
                          {item?.role ? (
                            <Avatar
                              key={item?.id}
                              name={item?.role}
                              style={{
                                backgroundColor: item?.color,
                                color: "white",
                                padding: "2px",
                                fontSize: "22px",
                                fontWeight: "400",
                                borderRadius: "50%",
                                width: "38px",
                              }}
                            />
                          ) : (
                            <img src={ICONS?.NoImageIcon} />
                          )}
                        </div>
                        <span className=" header_right pl-2">
                          {item?.first_name === null
                            ? " -- "
                            : item?.first_name}
                        </span>
                      </div>
                      <span className=" header_right w-[15vw] pl-2">
                        {item?.last_name === null ? " -- " : item?.last_name}
                      </span>
                    </div>
                    <div
                      className="flex-row contact_left "
                      style={{
                        width: "70%",
                        display: "flex",
                      }}
                    >
                      <div
                        className="flex-row contact-segment align-center"
                        style={BrandStyle}
                      >
                        <img
                          src={ICONS?.BroadcastNewSegmentIcon}
                          className="contact-segment-img"
                        />

                        <p className="pl-10">
                          Segment -{item?.broadcast_part_segment_id}
                        </p>
                      </div>
                      <p
                        className=" flex-row justify-center align-center"
                        style={BrandStyle}
                      >
                        {formatPhoneNumber(item?.to_number)}
                      </p>

                      <p className=" flex-row " style={BrandStyle}>
                        <MessageStatus status={item?.status} />
                      </p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default ContactTable;
