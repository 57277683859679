import { primaryColor } from '../../../constants';
import Loader from '../loader/CircleLoader';
const index = ({
  label = '',
  loading = '',
  disabled = false,
  onClick = () => {},
  color = '',
  textColor = '',
  classNames = '',
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
      className={`
      bg-[${color || primaryColor}] min-w-[4vw]  text-[#fff]
      rounded-md px-[1vw] text-[.75vw] font-[500] h-[4.4vh] flex items-center duration-300 ${
        disabled && 'bg-slate-400'
      } ${classNames}`}
    >
      {loading && (
        <Loader className={`${loading ? 'scale-150' : 'scale-100'} `} />
      )}
      {label}
    </button>
  );
};

export default index;
