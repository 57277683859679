import React from "react";
import { ICONS } from "../../../assets/icons";

const Status = ({ status, iconsSize, style = {} }) => {
  switch (status) {
    case 11:
    case "RUNNING":
      return (
        <span
          style={{
            color: "var(--primary)",
            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.CampaignRunningIcon}
            alt="CampaignActiveIcon"
            className="pr-5"
            style={{ width: "25px", height: "25px" }}
          />
          Running
        </span>
      );
    case "COMPLETED":
    case 12:
      return (
        <span
          style={{
            color: "#4C90F5",
            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.CampaignCompleteIcon}
            alt="CampaignCompleteIcon"
            className="pr-5"
            // style={{ width: iconsSize ?? "23px" }}
            style={{ width: "25px", height: "25px" }}
          />
          Completed
        </span>
      );
    case "SCHEDULED":
    case 10:
      return (
        <span
          style={{
            color: "#F6A723",
            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.BroadCastActive}
            alt="CampaignCompleteIcon"
            className="pr-5"
            // style={{ width: iconsSize ?? "23px" }}
            style={{ width: "25px", height: "25px" }}
          />
          Scheduled
        </span>
      );
    case "DRAFT":
    case 0:
      return (
        <span
          style={{ color: "var(--font-600)", width: iconsSize ?? "10px" }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.CampaignDraftIcon}
            alt="CampaignActiveIcon"
            className="pr-5"
            // style={{ width: iconsSize ?? "23px" }}
            style={{ width: "25px", height: "25px" }}
          />
          Draft
        </span>
      );

    default:
      return "--";
  }
};

export default Status;
