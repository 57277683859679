import AspApi from '../../../api/ApiConfig';

// export const templateLists = async (brandId) => {
//   const res = await AspApi.get(`/api/v1/templates/${brandId}`);
//   return res;
// };

export const whatsappBusinessNumbers = async (brandId) => {
  const res = await AspApi.get(
    `/api/v1/whatsapp_business_numbers?brand_id=${brandId}`
  );
  return res;
};

export const templateLists = async (brandId, query) => {
  let qpm = [];

  if (brandId) qpm.push(`brand_id=${brandId}`);
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);
  if (query?.category) qpm.push(`category=${query?.category}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(`/api/v1/templates${qpmString}`);
  return res;
};

export const createAndUpdateTemplate = async (payLoad) => {
  const res = await AspApi.post(`/api/v1/template`, payLoad);
  return res;
};

export const UpdateTemplate = async (payLoad) => {
  const res = await AspApi.post(`/api/v1/template/update`, payLoad);
  return res;
};

export const checkTemplateExits = async (query) => {
  let qpm = [];
  if (query.brandId) qpm.push(`brand_id=${query.brandId}`);
  if (query.name) qpm.push(`name=${query.name}`);
  let qpmString = '';
  if (qpm?.length > 0) qpmString = `?${qpm.join('&')}`;

  if (query?.name?.length > 0) {
    const res = await AspApi.get(`/api/v1/check_template${qpmString}`);
    return res;
  }

  return null;
};

export const deleteTemplate = async (query) => {
  const qpm = [];
  if (query?.name) qpm.push(`template_name=${query?.name}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.delete(`/api/v1/template${qpmString}`);
  return res;
};

export const templateSyncApi = async (brandId) => {
  const res = await AspApi.get(`/api/v1/templates/sync?brand_id=${brandId}`);
  return res;
};

export const synctSingleTemplate = async (brandId, templateName) => {
  const res = await AspApi.get(
    `/api/v1/template/sync/single?brand_id=${brandId}&template_name=${templateName}`
  );
  return res;
};

export const templateMetrix = async (brandId) => {
  const res = await AspApi.get(`api/v1/templates/overview?brand_id=${brandId}`);
  return res;
};
