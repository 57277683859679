import React from 'react';
import { numberStatus } from '../../../../constant/phoneNumbers';

const NumberStatus = ({ id, status }) => {
  const statusItem = numberStatus?.find((item) => item.id === id);

  let statusStyles = {};

  const style =
    'px-[0.6vw] py-[0.2vw] br-5 flex-row align-center justify-center';

  if (status) {
    switch (status) {
      case 10:
        statusStyles = {
          color: 'var(--primary)',
          backgroundColor: 'var(--primaryBackground)',
        };
        return (
          <div
            style={{
              color: 'var(--primary)',
              backgroundColor: 'var(--primaryBackground)',
            }}
            className={style}
          >
            Active
          </div>
        );

      case 'Being Reviewed':
      case 'Pending':
      case 'Transferred':
        statusStyles = {
          color: 'var(--secondary)',
          backgroundColor: 'var(--primaryOpacity)',
        };
        break;
      case 'Offline':
      case 'Unavailable':
      case 'Unverified':
        statusStyles = {
          color: 'var(--font-400)',
          backgroundColor: 'var(--BG-25)',
        };
        break;
      case 'Warned':
      case 'Banned':
        statusStyles = {
          color: 'var(--fontRed)',
          backgroundColor: 'var(--lightRed)',
        };
        break;

      default:
        return (
          <div style={statusStyles} className={style}>
            Status not found
          </div>
        );
    }
  }

  return (
    <div
      style={statusStyles}
      className='px-[0.6vw] py-[0.2vw] br-5 flex-row align-center justify-center'
    >
      {status ? status : 'Status not found'}
    </div>
  );
};

export default NumberStatus;
