import React from 'react';

import { Modal } from 'antd';

import useToggle from 'hooks/useToggle';
import { useSelect } from 'hooks/useSelect';

import Header from 'components/commonComponents/modals/Header';

import { ICONS } from '../../assets/icons';
import { useAspSelector } from '../../test/jest-redux-hooks';

import Arrow from '../../assets/customSVG/Arrow';

import './usagePlan.css';

const UsagePlan = ({ handleCancel }) => {
  const { currentBrand } = useAspSelector((state) => state.app);

  const [isUpgrade, setIsUpgrade] = useToggle(false);
  const [selectedPrice, setSelectedPrice] = useSelect('Monthly');

  const plan = [
    {
      id: 1,
      title: 'Marketing',
      credits: '1000000',
    },
    {
      id: 2,
      title: 'Utility',
      credits: '1000',
    },
    {
      id: 3,
      title: 'Authentication',
      credits: '0',
    },
    {
      id: 4,
      title: 'Service',
      credits: '10000',
    },
  ];

  const pricing = [
    {
      id: 1,
      title: 'Starter',
      custom: false,
      popular: false,
      icon: ICONS?.Starter,
      monthly: {
        price: '1000',
        yearly: '12,000',
      },
      quarterly: {
        price: '1000',
        yearly: '12,000',
      },
      annual: {
        price: '10,000',
        yearly: '2',
      },
      content: (
        <>
          For startups and solopreneurs looking to
          <br /> build a business across digital platforms
        </>
      ),
      btnText: 'Get Started',
    },
    {
      id: 2,
      title: 'Growth',
      custom: false,
      popular: true,
      icon: ICONS?.Growth,
      monthly: {
        price: '2,500',
        yearly: '30,000',
      },
      quarterly: {
        price: '2,500',
        yearly: '30,000',
      },
      annual: {
        price: '25,000',
        yearly: '2',
      },
      content: (
        <>
          For small to mid-scale businesses looking to <br /> expand their
          business growth
        </>
      ),
      btnText: 'Get Started',
    },
    {
      id: 3,
      title: 'Magnum',
      custom: true,
      popular: false,
      icon: ICONS?.Magnum,
      monthly: {},
      quarterly: {},
      annual: {},
      content: (
        <>
          For enterprise businesses looking for a<br /> fully featured
          customizable business suite.
        </>
      ),
      btnText: 'Contact Sales',
    },
  ];

  const handleCancelUpgradePlans = () => setIsUpgrade(false);

  const Planscard = ({ id, plan }) => {
    return (
      <button
        className={`w-[8vw] px-[1vw] py-[0.5vw] rounded-[2vw] font-normal weight-semibold text-center transition-colors ease-linear duration-300 ${
          selectedPrice === plan
            ? 'text-[#0ACD95] bg-white'
            : 'bg-transparent text-[#202421]'
        }`}
        onClick={() => {
          setSelectedPrice(plan);
        }}
      >
        {plan}
      </button>
    );
  };

  return (
    <div className='usagePlan'>
      <div className='flex-row space-between usagePlan-popup'>
        <h2>Plans and usage</h2>
        <img
          className='usage-popup-exit pointer'
          src={ICONS.popupX}
          alt='popup'
          onClick={handleCancel}
        />
      </div>
      <div className='usagePlan-container'>
        <div className='flex-row space-between align-center'>
          <h4 className='growth-plan'>
            {currentBrand?.brand_billing_plan_name}
          </h4>
          <button className='plan-button'>Monthly</button>
        </div>
        <span className='plan-renews'>( Renews Apr 18, 2024 )</span>
        <div className=' pointer w-[55%] plan-animation'>
          <div className='flex-row align-center'>
            <p className='plan-credits'>Manage your plans & credits</p>
            <img
              className='upgrade-arrow pointer'
              src={ICONS.upgradeArrow}
              alt='popup'
              onClick={handleCancel}
            />
          </div>
          <div className='plan-sliders'>
            <div className='plan-slides'></div>
          </div>
        </div>

        <div className='whatsapp-credits'>
          <div className='growth-plan-whatsapp flex-column'>
            {/* <p>WhatsApp Credits</p>
            <div className="flex-row  space-between align-center">
              <p>₹ 520.2k</p>
              <span>Remaining Amount</span>
            </div> */}
          </div>
          {/* {plan?.map((data) => (
            <div key={data?.id} className="flex-row py-2">
              <p className="plan-title">{data?.title} - </p>
              <p className="plan-remaining-credits">
                {data?.credits} <span>Remaining credits</span>
              </p>
            </div>
          ))}
          <div className=" pointer w-[35%] plan-animation">
            <div className="flex-row align-center mt-2 pointer">
              <p className="plan-credits">Get more credits</p>
              <img
                className="upgrade-arrow pointer"
                src={ICONS.upgradeArrow}
                alt="popup"
                onClick={handleCancel}
              />
            </div>
            <div className="plan-sliders">
              <div className="plan-slides"></div>
            </div>
          </div> */}
        </div>

        <div className='flex-row align-center justify-center mt-[25px]'>
          {currentBrand?.brand_billing_plan_name !== 'Magnum Plan' && (
            <button
              className='upgrade-plan-button cursor-pointer'
              onClick={() => {
                setIsUpgrade(true);
                handleCancel();
              }}
            >
              Upgrade Plan
            </button>
          )}
          {currentBrand?.brand_billing_plan_name === 'Magnum Plan' && (
            <div className='upgrade-plan-button'>Contact Sales</div>
          )}
        </div>
      </div>
      <Modal
        open={isUpgrade}
        afterOpenChange={(open) => {
          setIsUpgrade(open);
        }}
        onCancel={handleCancelUpgradePlans}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={'bg-[#00000095]'}
        destroyOnClose
        width={'70vw'}
        style={{
          width: '100%',
          borderRadius: 20,
          boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
          overflow: 'hidden',
        }}
      >
        <Header
          title={'Pricing plan details'}
          titleStyle='font-large'
          handleCancel={handleCancelUpgradePlans}
        />
        <div className='bg-[#FFFFFF] p-[2vw]'>
          <div className='flex flex-col items-center justify-center pb-[2vw] relative'>
            <div className='w-fit relative'>
              <div className='flex flex-col items-center'>
                <div className='w-fit flex items-center justify-center px-[1vw] py-[0.6vw] bg-[#F1F4EE] rounded-[2vw]'>
                  <Planscard id={1} plan={'Monthly'} />
                  {/* <Planscard id={2} plan={'Quarterly'} /> */}
                  <Planscard id={3} plan={'Annual'} />
                </div>
                <p className='mt-[0.8vw] font-large weight-bold text-[#6940F2]'>
                  🎉 Get 2 months free with annual subscription
                </p>
              </div>
              <div className='absolute top-[1vw] -right-[2.1vw]'>
                <img
                  src={ICONS?.PricingArrow}
                  alt='arrow'
                  className='size-[3.7vw] '
                />
              </div>
            </div>
          </div>
          <div className='w-full h-fit flex justify-between gap-[1vw]'>
            {pricing?.map((p) => {
              return (
                <div
                  className={`min-w-[20vw] rounded-[1.5vw] p-[0.25vw] ${
                    p.popular
                      ? // 'shadow-[0px_4px_26px_0px_#CAC5FF] bg-gradient-to-r from-[var(--primary)] to-[#6940F2]'
                        'shadow-[0px_4px_26px_0px_#CAC5FF] bg-[#6940F2]'
                      : 'shadow-[0px_4px_16px_0px_#2D303630] bg-white'
                  }`}
                >
                  <div className='w-auto h-full bg-[var(--white)] rounded-[1.3vw] px-[1vw] py-[1.2vw]'>
                    <div className='flex items-center justify-between pb-[1vw]'>
                      <img src={p.icon} alt={p.title} className='size-[3vw]' />
                      {p.popular && (
                        <div className='px-[1vw] py-[0.25vw] bg-[#EDAB4033] rounded-[2vw] flex items-center gap-[0.4vw]'>
                          <img src={ICONS?.MostPopular} alt='most popular' />
                          <p className='font-md weight-bold text-[#EDAB40]'>
                            Most Popular
                          </p>
                        </div>
                      )}
                    </div>
                    <h3
                      className={`text-[1.5vw] weight-bold text-[#202421] mt-[0.2vw]`}
                    >
                      {p.title}
                    </h3>
                    <div className='mt-[0.7vw]'>
                      <span className='text-[2vw] weight-semibold text-[#202421]'>
                        {p?.custom
                          ? 'Custom'
                          : p?.[selectedPrice?.toLowerCase()]?.price}
                      </span>
                      {!p?.custom && (
                        <span className='font-normal weight-medium text-[#616874]'>
                          /{selectedPrice === 'Monthly' ? 'Month' : 'Yearly'}
                        </span>
                      )}
                    </div>
                    <div className='font-normal weight-medium text-[#616874] pb-[1.5vw] border-b-[0.08vw] border-[var(--border-100)]'>
                      {!p?.custom
                        ? selectedPrice === 'Monthly'
                          ? `${
                              p?.[selectedPrice?.toLowerCase()]?.yearly
                            } / Billed Yearly`
                          : `*${
                              p?.[selectedPrice?.toLowerCase()]?.yearly
                            } Months Free`
                        : 'Billed Monthly / Yearly'}
                    </div>

                    <p className='font-normal weight-small text-[#616874] mt-[2.5vw] leading-[1.4vw]'>
                      {p?.content}
                    </p>
                    <button className='w-full h-[2.5vw] flex items-center justify-center gap-[1vw] bg-[var(--primary)] font-normal weight-semibold text-[var(--white)] mt-[1.5vw] rounded-[0.5vw] tracking-[0.02vw]'>
                      <p>{p?.btnText}</p>
                      <div className='rotate-180'>
                        <Arrow color='#ffffff' />
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='flex flex-col items-center justify-center mt-[2vw]'>
            <a
              href='https://gainwix.ai/pricing'
              target='_blank'
              className='px-[0.8vw] py-[0.4vw] border-[0.08vw] border-[var(--border-50)] font-md weight-medium rounded-[0.3vw] !text-[var(--font-600)]'
            >
              Compare Plans
            </a>
            <p className='font-md weight-medium text-center py-[1vw]'>
              You'll be billed once a year for the entire year's access. This is
              a non-refundable plan, so please be sure it meets your needs
              before
              <br />
              subscribing. Taxes may apply depending on your location. For more
              information, please go to{' '}
              <a
                href='https://gainwix.ai/pricing'
                target='_blank'
                className='!text-[#6940F2]'
              >
                https://gianwix.ai/pricing
              </a>
            </p>
          </div>
        </div>{' '}
      </Modal>
    </div>
  );
};

export default UsagePlan;
