export const getLanguageCode = (data) => {
  let langCode = '';
  switch (data) {
    case 'Arabic':
      langCode = 'ar';
      break;
    case 'Bengali':
      langCode = 'bn';
      break;
    case 'Chinese-CHN':
      langCode = 'zh_CN';
      break;
    case 'Chinese-HKG':
      langCode = 'zh_HK';
      break;
    case 'Chinese-TAI':
      langCode = 'zh_TW';
      break;
    case 'Dutch':
      langCode = 'nl';
      break;
    case 'English':
      langCode = 'en';
      break;
    case 'English (US)':
      langCode = 'en_US';
      break;
    case 'English (UK)':
      langCode = 'en_GB';
      break;
    case 'German':
      langCode = 'de';
      break;
    case 'Hindi':
      langCode = 'hi';
      break;
    case 'Tamil':
      langCode = 'ta';
      break;
    case 'Afrikaans':
      langCode = 'af';
      break;
    case 'Albanian':
      langCode = 'sq';
      break;
    case 'Azerbaijani':
      langCode = 'az';
      break;
    case 'Bulgarian':
      langCode = 'bg';
      break;
    case 'Catalan':
      langCode = 'ca';
      break;
    case 'Croatian':
      langCode = 'hr';
      break;
    case 'English':
      langCode = 'en';
      break;
    case 'English - US':
      langCode = 'en_US';
      break;
    case 'English - UK':
      langCode = 'en_GB';
      break;
    case 'Czech':
      langCode = 'cs';
      break;
    case 'Danish':
      langCode = 'da';
      break;
    case 'Estonian':
      langCode = 'et';
      break;
    case 'Filipino':
      langCode = 'fil';
      break;
    case 'Finnish':
      langCode = 'fi';
      break;
    case 'French':
      langCode = 'fr';
      break;
    case 'Georgian':
      langCode = 'ka';
      break;
    case 'German':
      langCode = 'de';
      break;
    case 'Greek':
      langCode = 'el';
      break;
    case 'Gujarati':
      langCode = 'gu';
      break;
    case 'English (US)':
      langCode = 'en_US';
      break;
    case 'English (UK)':
      langCode = 'en_GB';
      break;
    case 'Hausa':
      langCode = 'ha';
      break;
    case 'Hebrew':
      langCode = 'he';
      break;
    case 'Hungarian':
      langCode = 'hu';
      break;
    case 'Indonesian':
      langCode = 'id';
      break;
    case 'Irish':
      langCode = 'ga';
      break;
    case 'Italian':
      langCode = 'it';
      break;
    case 'Japanese':
      langCode = 'ja';
      break;
    case 'Kannada':
      langCode = 'kn';
      break;
    case 'Kazakh':
      langCode = 'kk';
      break;
    case 'Kinyarwanda':
      langCode = 'rw_RW';
      break;
    case 'Korean':
      langCode = 'ko';
      break;
    case 'Kyrgyz-Kyrgyzstan':
      langCode = 'ky_KG';
      break;
    case 'Lao':
      langCode = 'lo';
      break;
    case 'Latvian':
      langCode = 'lv';
      break;
    case 'Lithuanian':
      langCode = 'lt';
      break;
    //
    case 'Macedonian':
      langCode = 'mk';
      break;
    case 'Malay':
      langCode = 'ms';
      break;
    case 'Malayalam':
      langCode = 'ml';
      break;
    case 'Marathi':
      langCode = 'mr';
      break;
    case 'Norwegian':
      langCode = 'nb';
      break;
    case 'Persian':
      langCode = 'fa';
      break;
    case 'Polish':
      langCode = 'pl';
      break;
    case 'Portuguese - BR':
      langCode = 'pt_BR';
      break;
    case 'Portuguese - POR':
      langCode = 'pt_PT';
      break;
    case 'Punjabi':
      langCode = 'pa';
      break;
    case 'Romanian':
      langCode = 'ro';
      break;
    case 'Russian':
      langCode = 'ru';
      break;
    case 'Serbian':
      langCode = 'sr';
      break;
    case 'Slovak':
      langCode = 'sk';
      break;
    case 'Slovenian':
      langCode = 'sl';
      break;
    case 'Spanish':
      langCode = 'es';
      break;
    case 'Spanish-ARG':
      langCode = 'es_AR';
      break;
    case 'Spanish-SPA':
      langCode = 'es_ES';
      break;
    case 'Spanish-MEX':
      langCode = 'es_MX';
      break;
    case 'Swahili':
      langCode = 'sw';
      break;
    case 'Swedish':
      langCode = 'sv';
      break;
    case 'Telugu':
      langCode = 'te';
      break;
    case 'Thai':
      langCode = 'th';
      break;
    case 'Turkish':
      langCode = 'tr';
    case 'Ukrainian':
      langCode = 'uk';
      break;
    case 'Urdu':
      langCode = 'ur';
      break;
    case 'Uzbek':
      langCode = 'uz';
      break;
    case 'Vietnamese':
      langCode = 'vi';
      break;
    case 'Zulu':
      langCode = 'zu';
      break;
    default:
      langCode = '';
      break;
  }
  return langCode;
};

export const getLanguageName = (data) => {
  switch (data) {
    case 'ar':
      return 'Arabic';
    case 'bn':
      return 'Bengali';
    case 'zh_CN':
      return 'Chinese-CHN';
    case 'zh_HK':
      return 'Chinese-HKG';
    case 'zh_TW':
      return 'Chinese-TAI';
    case 'nl':
      return 'Dutch';
    case 'en':
      return 'English';
    case 'en_US':
      return 'English (US)';
    case 'en_GB':
      return 'English (UK)';
    case 'de':
      return 'German';
    case 'hi':
      return 'Hindi';
    case 'ta':
      return 'Tamil';
    case 'af':
      return 'Afrikaans';
    case 'sq':
      return 'Albanian';
    case 'az':
      return 'Azerbaijani';
    case 'bg':
      return 'Bulgarian';
    case 'ca':
      return 'Catalan';
    case 'hr':
      return 'Croatian';
    case 'en':
      return 'English';
    case 'en_US':
      return 'English (US)';
    case 'en_GB':
      return 'English (UK)';
    case 'cs':
      return 'Czech';
    case 'da':
      return 'Danish';
    case 'et':
      return 'Estonian';
    case 'fil':
      return 'Filipino';
    case 'fi':
      return 'Finnish';
    case 'fr':
      return 'French';
    case 'ka':
      return ' Georgian';
    case 'de':
      return ' German';
    case 'el':
      return ' Greek';
    case 'gu':
      return ' Gujarati';
    case 'en_US':
      return ' English - US';
    case 'en_GB':
      return ' English - UK';
    case 'ha':
      return ' Hausa';
    case 'he':
      return ' Hebrew';
    case 'hu':
      return ' Hungarian';
    case 'id':
      return ' Indonesian';
    case 'ga':
      return ' Irish';
    case 'it':
      return ' Italian';
    case 'ja':
      return ' Japanese';
    case 'kn':
      return ' Kannada';
    case 'kk':
      return ' Kazakh';
    case 'rw_RW':
      return ' Kinyarwanda';
    case 'ko':
      return 'Korean';
    case 'ky_KG':
      return 'Kyrgyz-Kyrgyzstan';
    case 'lo':
      return 'Lao';
    case 'lv':
      return ' Latvian';
    case 'lt':
      return ' Lithuanian';
    case 'mk':
      return ' Macedonian';
    case 'ms':
      return ' Malay';
    case 'ml':
      return ' Malayalam';
    case 'mr':
      return ' Marathi';
    case 'nb':
      return ' Norwegian';
    case 'fa':
      return ' Persian';
    case 'pl':
      return ' Polish';
    case 'pt_BR':
      return ' Portuguese - BR';
    case 'pt_PT':
      return 'Portuguese - POR';
    case 'pa':
      return ' Punjabi';
    case 'ro':
      return ' Romanian';
    case 'ru':
      return ' Russian';
    case 'sr':
      return ' Serbian';
    case 'sk':
      return ' Slovak';
    case 'sl':
      return ' Slovenian';
    case 'es':
      return ' Spanish';
    case 'es_AR':
      return ' Spanish-ARG';
    case 'es_ES':
      return ' Spanish-SPA';
    case 'es_MX':
      return ' Spanish-MEX';
    case 'sw':
      return ' Swahili';
    case 'sv':
      return ' Swedish';
    case 'te':
      return ' Telugu';
    case 'th':
      return ' Thai';
    case 'tr':
      return ' Turkish';
      return ' Ukrainian';
    case 'ur':
      return ' Urdu';
    case 'uz':
      return ' Uzbek';
    case 'vi':
      return ' Vietnamese';
    case 'zu':
      return ' Zulu';
    default:
      return '';
  }
  return langCode;
};
