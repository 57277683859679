import React, { useState } from 'react';

// consts
import { overview, phoneNumbersList } from '../../../constant/insights';

// hooks
import { useSelect } from 'hooks/useSelect';

// utils
import moment from 'moment';

// comnponents
import Select from 'components/commonComponents/select/index';
import MonthCalender from 'components/commonComponents/calendar/MonthCalender';

export default function Insights() {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useSelect(
    phoneNumbersList[0]
  );

  const [selectedRange, setSelectedRange] = useState({
    start: moment().add(-7, 'days').startOf('days').toDate(),
    end: moment().add(-1, 'days').endOf('days').toDate(),
  });

  const onSelectRange = (data) => setSelectedRange(data);

  return (
    <div className='w-full h-full min-h-full bg-white'>
      <div className='flex items-center flex-wrap justify-between px-10 border-b border-[#F0F1F2]'>
        <div className='flex items-center gap-10 py-3'>
          <div className='w-52'>
            <Select
              value={selectedPhoneNumber}
              options={phoneNumbersList}
              position='bottom'
              rootClassName='py-1.5'
              optionClassName='py-1.5'
            />
          </div>
          <div className='w-28'>
            <Select
              value={'India'}
              position='bottom'
              allow={false}
              rootClassName='py-1.5'
              optionClassName='py-1.5'
            />
          </div>
        </div>
        <div className='flex items-center gap-10 py-3'>
          <MonthCalender
            startDate={selectedRange?.start}
            endDate={selectedRange?.end}
            onChange={onSelectRange}
            primary={true}
          />
        </div>
      </div>
      <div className='px-10 py-5 h-full max-h-[83%] flex flex-col gap-12 flex-1 overflow-y-scroll'>
        <div>
          <h2 className='pb-6 text-lg weight-bold'>Overview</h2>
          <div className='flex flex-wrap justify-between gap-5'>
            {overview?.map((c) => {
              return (
                <div
                  key={c.label}
                  className='w-[clamp(280px,100%,40%)] px-5 py-3 text-base border border-slate-200 rounded-2xl flex-1'
                >
                  <div className='w-full min-w-[200px] h-11 flex flex-1 items-center justify-between weight-semibold'>
                    <div className='flex items-center gap-5'>
                      <div className='size-2.5 rounded-[3px] bg-[#5C3BBF]' />
                      <div>{c.label}</div>
                    </div>
                    <div>{c.value}</div>
                  </div>
                  {c.values?.map((v) => {
                    return (
                      <div
                        key={v.label}
                        className='w-full h-11 flex items-center'
                      >
                        <div className='w-full h-auto flex items-center justify-between weight-normal'>
                          <div className='flex items-center gap-5'>
                            <div
                              className={`size-2.5 rounded-[3px]`}
                              style={{
                                background: v.color,
                              }}
                            />
                            <div className=''>{v.label}</div>
                          </div>
                          <div>{v.value}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <h2 className='pb-6 text-lg weight-bold'>
            Best Performing Templates
          </h2>
        </div>
        <div>
          <h2 className='pb-6 text-lg weight-bold'>All conversation</h2>
        </div>
        <div>
          <h2 className='pb-6 text-lg weight-bold'>Free conversation</h2>
        </div>
        <div>
          <h2 className='pb-6 text-lg weight-bold'>
            paid conversation & Approximate Charges
          </h2>
        </div>
      </div>
    </div>
  );
}
