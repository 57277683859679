import React, { useEffect, useState } from "react";
import { campaignBroadcastList } from "../../../../constant/app/campaign/campaign";
import BroadcastOverview from "../../../../components/commonComponents/broadcastoverview/BroadcastOverview";
import { getBroadcastCountApi } from "../../api/Api";
import { useParams } from "react-router-dom";
import { useAspSelector } from "../../../../test/jest-redux-hooks";

const CampaignOverview = ({ segment }) => {
  const { currentBrand } = useAspSelector((state) => state?.app);

  const { id } = useParams();
  const campaignId = id;

  const [Overview, setOverview] = useState(null);

  const BroadcastOverviewList = (brandId, campaignId) => {
    if (brandId && campaignId) {
      getBroadcastCountApi(brandId, campaignId).then((res) => {
        if (res?.data) {
          setOverview(res?.data);
        }
      });
    }
  };

  useEffect(() => {
    BroadcastOverviewList(currentBrand?.brand_id, campaignId);
  }, [currentBrand?.brand_id, campaignId]);
  return (
    <>
      <div className="Overview_container">
        <span className="main-headings-broadcast">Overview</span>
      </div>

      <div className="postMain-container1 w-100  pt-20 flex-end space-between">
        <BroadcastOverview
          list={campaignBroadcastList}
          segment={segment}
          Overview={Overview}
        />
      </div>
    </>
  );
};

export default CampaignOverview;
