
import React from 'react';

export default function Business({
  width,
  height,
  color,
  strokeWidth = '1.32609',
  className = '',
}) {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '17'}
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M0.717285 16.2484H19.2825M11.326 16.2485V1.66158C11.326 1.48573 11.2561 1.31708 11.1318 1.19274C11.0074 1.06839 10.8388 0.998535 10.6629 0.998535H2.70642C2.53057 0.998535 2.36192 1.06839 2.23757 1.19274C2.11323 1.31708 2.04337 1.48573 2.04337 1.66158V16.2485M17.9564 16.2485V6.96593C17.9564 6.79008 17.8866 6.62143 17.7622 6.49708C17.6379 6.37274 17.4692 6.30288 17.2934 6.30288H11.326M4.69555 4.31354H7.34772M6.02163 9.61788H8.67381M4.69555 12.9331H7.34772M13.9782 12.9331H15.3042M13.9782 9.61788H15.3042'
        stroke={color ?? '#2D3036'}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
