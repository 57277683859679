import { useState } from 'react';

export function useSearch(initialValue) {
  const [searchKey, setSearchKey] = useState(initialValue ?? '');

  const onChange = (val) => setSearchKey(val);

  const clearSearch = () => setSearchKey('');

  return {
    searchKey,
    setSearchKey,
    onChange,
    clearSearch,
  };
}
