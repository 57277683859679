import React from "react";
import { ICONS } from "../../../../../assets/icons/index";

const FileDownloadView = ({ response }) => {
  return (
    <div className="flex-column">
      {response?.map((response, index) => {
        return (
          <React.Fragment key={response.id || index}>
            {response.file_name && (
              <div className="FileMains flex-row ml-4">
                <div className=" File-container flex-row align-center ">
                  <div className=" File-wrapper flex-column">
                    <div className="flex-row align-center">
                      <img src={ICONS?.AudienceFileIcon} alt="csv icon" />
                      <div className="flex-column">
                        <p>{response.file_name}</p>
                        <div className="flex-row align-center pt-0.5">
                          <div className="flex-row align-center">
                            <img
                              src={ICONS?.BroadcastProfileIcon}
                              alt="BroadcastRedInfoIcon"
                              style={{
                                width: "20px",
                              }}
                            />
                            <div
                              className="flex-row"
                              style={{ padding: "0px 5px" }}
                            >
                              <p
                                style={{
                                  color: "var(--textBlack)",
                                  fontWeight: "700",
                                  fontSize: "0.75vw",
                                }}
                              >
                                {response?.valid_count}
                              </p>
                              <span
                                className=" pr-4 flex-row align-center"
                                style={{ borderRight: "1px solid #D1D3D8" }}
                              >
                                Unique Contacts
                              </span>
                            </div>
                          </div>
                          <div className="flex-row align-center">
                            <img
                              src={ICONS?.BroadcastCopyIcon}
                              alt="BroadcastRedInfoIcon"
                              style={{
                                width: "20px",
                              }}
                            />
                            <div
                              className="flex-row"
                              style={{ padding: "0px 5px" }}
                            >
                              <p
                                style={{
                                  color: "var(--textBlack)",
                                  fontWeight: "700",
                                  fontSize: "0.75vw",
                                }}
                              >
                                {response?.duplicate_count}
                              </p>
                              <span
                                className="pr-4"
                                style={{ borderRight: "1px solid #D1D3D8",paddingTop: '2px' }}
                              >
                                Duplicates
                              </span>
                            </div>
                          </div>
                          <div className="flex-row align-center">
                            <img
                              src={ICONS?.BroadcastRedInfoIcon}
                              alt="BroadcastRedInfoIcon"
                              style={{
                                width: "20px",
                              }}
                            />
                            <p
                              className="flex-row"
                              style={{
                                color: "var(--textBlack)",
                                fontWeight: "700",
                                fontSize: "0.75vw",
                                padding: "0px 10px",
                              }}
                            >
                              {response?.invalid_count}
                            </p>
                            <span className="">Invalids</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FileDownloadView;
