import React from 'react';
import { convertName } from '../../../helpers';
import Tags from '../contact/Tags';
import { Checkbox, Popover } from 'antd';
import Input from '../../commonComponents/input/Input';
import Dropdown from '../../commonComponents/dropdown';
import Calendar from '../../../../../components/commonComponents/calendar/Calendar';
import Label from '../../commonComponents/label';
import calenderIcon from '../../../../../assets/icons/campaignCalendar.svg';
import moment from 'moment';

const FilterModelContent = ({
  tags,
  type,
  // setTags,
  propsData,
  inputType,
  isOnlyCheckBox,
  selectedFilter,
  onlySelectFields,
  setSelectedFilter,
  filterKey: key = '',
}) => {
  return (
    <div>
      <div className="flex crm_filter gap-[.5vw]">
        <Checkbox
          className=""
          name={key}
          id={key}
          onChange={() => {
            if (isOnlyCheckBox) {
              setSelectedFilter((prev) => {
                return { ...prev, field: key, fieldValue: true };
              });
              return;
            }

            setSelectedFilter((prev) => {
              return {
                ...prev,
                field: key,
                fieldValue: ['dropdown', 'tags'].includes(type)
                  ? Array.isArray(prev.fieldValue)
                    ? prev.fieldValue
                    : []
                  : onlySelectFields.includes(prev.field)
                  ? ''
                  : prev.fieldValue,
              };
            });
          }}
          checked={selectedFilter.field === key}
        />
        <label
          htmlFor={key}
          className="capitalize cursor-pointer text-[.75vw] py-[.4vw]"
        >
          {convertName(key)}
        </label>
      </div>
      {type === 'date' && !isOnlyCheckBox && (
        <div
          className={`overflow-hidden duration-200 ${
            selectedFilter.field === key ? 'h-[2.5vw]' : 'h-[0]'
          }`}
        >
          <Popover
            placement="bottomLeft"
            trigger={'click'}
            arrow={false}
            content={
              <Calendar
                heading="Select duration"
                style={{
                  width: '100%',
                  minWidth: '22vw',
                  height: '13vw',
                }}
                singleSelect={true}
                clickedDateFunc={(d) => {
                  setSelectedFilter((prev) => {
                    return {
                      ...prev,
                      fieldValue: moment(d).format('DD/MM/YYYY'),
                    };
                  });
                }}
              />
            }
          >
            <div
              className={` flex justify-between items-center my-[2vh]- py-[1vh] ps-[.5vw] pe-[.7vw] cursor-pointer transition-all duration-100 text-[0.85vw] border border-gray-200 rounded-lg`}
            >
              <div className={`text-[.73vw] text-[#898E99] flex gap-[0.5vw]`}>
                <img src={calenderIcon} alt="" />
                <span className="tracking-widest--">
                  {(selectedFilter.fieldValue || 'DD/MM/YYYY')
                    .split('/')
                    .join(' / ')}
                </span>
              </div>
            </div>
          </Popover>
        </div>
      )}
      {type === 'text' && !isOnlyCheckBox && (
        <div
          className={`overflow-hidden duration-200 ${
            selectedFilter.field === key ? 'h-[2.5vw]' : 'h-[0]'
          }`}
        >
          <Input
            onChange={(e) =>
              setSelectedFilter((prev) => {
                return { ...prev, fieldValue: e.target.value };
              })
            }
            // placeholder={'01/01/2024'}
            type={inputType}
            value={selectedFilter.fieldValue}
            className={'py-[0.4vw_!important]'}
          />
        </div>
      )}
      {type === 'dropdown' && !isOnlyCheckBox && (
        <div
          className={`overflow-hidden duration-200 ${
            selectedFilter.field === key ? 'h-[2.5vw]' : 'h-[0]'
          }`}
        >
          <Dropdown
            onOpenChange={() => {
              setSelectedFilter((prev) => {
                return {
                  ...prev,
                  dropdownOpen: prev.dropdownOpen === key ? '' : key,
                };
              });
            }}
            heading=""
            data={propsData}
            showCheck={(cur) => {
              if (Array.isArray(selectedFilter.fieldValue)) {
                return selectedFilter?.fieldValue
                  ?.map((item) => item?.label)
                  ?.includes(cur?.label);
              }
            }}
            width="min-w-[22.5vw]"
            height="max-h-[32vh]"
            containerClassNames="px-[1.6vw]"
            editFilter={(id, data) => {
              const duplicate = selectedFilter?.fieldValue?.find(
                (item) => item?.id === id,
              );
              setSelectedFilter((prev) => {
                return {
                  ...prev,
                  fieldValue: duplicate
                    ? prev?.fieldValue?.filter((item) => item.id !== id)
                    : [...prev?.fieldValue, { id, label: data?.label }],
                };
              });
            }}
            button={
              <div
                className={`flex justify-between items-center py-[.5vh] ps-[.5vw] pe-[.7vw] cursor-pointer transition-colors duration-100 text-[0.85vw] border h-full border-gray-200 rounded-lg`}
              >
                <div
                  className={`text-[.73vw] text-[#898E99] *:min-w-[28%_!important] flex gap-[0.5vw] w-[90%]`}
                >
                  {selectedFilter?.fieldValue?.length ? (
                    <>
                      {selectedFilter?.fieldValue?.slice(0, 3)?.map((item) => {
                        return (
                          <Label
                            key={item.id}
                            labelClassNames={'line-clamp-1'}
                            closeAction={(e) => {
                              e.stopPropagation();
                              setSelectedFilter((prev) => {
                                return {
                                  ...prev,
                                  fieldValue: prev?.fieldValue?.filter(
                                    (filter) => filter?.id !== item?.id,
                                  ),
                                };
                              });
                            }}
                            label={item.label}
                          />
                        );
                      })}
                      <p className="w-[10%_!important]">
                        {!!selectedFilter?.fieldValue?.slice(
                          3,
                          selectedFilter?.fieldValue.length,
                        ).length && (
                          <span className="bg-gray-200 rounded-full size-6- size-6 text-[.7vw] flex items-center justify-center cursor-pointer ">
                            +
                            {
                              selectedFilter?.fieldValue?.slice(
                                3,
                                selectedFilter?.fieldValue?.length,
                              )?.length
                            }
                          </span>
                        )}
                      </p>
                    </>
                  ) : (
                    'Select Source'
                  )}
                </div>
                <span
                  className={`duration-300 ${
                    selectedFilter.dropdownOpen === key
                      ? 'rotate-0'
                      : '-rotate-180'
                  }`}
                >
                  <div
                    className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                  />
                </span>
              </div>
            }
          />
        </div>
      )}
      {type === 'tags' && !isOnlyCheckBox && (
        <Tag
          tags={tags}
          // setTags={setTags}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterKey={key}
        />
      )}
    </div>
  );
};

export default FilterModelContent;

function Tag({ tags, selectedFilter, setSelectedFilter, filterKey }) {
  const handleRemoveTag = (e, id) => {
    e.stopPropagation();
    setSelectedFilter((prev) => {
      return {
        ...prev,
        fieldValue: prev.fieldValue.filter((item) => item.id !== id),
      };
    });
  };

  const handleSelectTag = (id, e) => {
    setSelectedFilter((prev) => {
      return {
        ...prev,
        fieldValue: [...prev.fieldValue, tags.find((item) => item.id === id)],
      };
    });
  };

  const chosenTags = selectedFilter.fieldValue;

  return (
    <div
      className={`overflow-hidden duration-200 ${
        selectedFilter.field === filterKey ? 'h-[2.5vw]' : 'h-[0]'
      }`}
    >
      <Tags
        selectedTags
        tags={tags}
        mainWidth={'w-[22.8vw]'}
        // set-Tags={set-Tags}
        zIndex={2000}
        closeAction={handleRemoveTag}
        addAction={handleSelectTag}
        placeholder="Search"
        Button={
          <div
            className={`flex justify-between items-center py-[.5vh] ps-[.5vw] pe-[.7vw] cursor-pointer transition-colors duration-100 text-[0.85vw] border h-full border-gray-200 rounded-lg`}
          >
            <div
              className={`text-[.73vw] text-[#898E99] *:min-w-[28%_!important] gap-[0.5vw] w-[90%] grid grid-flow-col justify-start`}
            >
              {chosenTags.length ? (
                <>
                  {chosenTags.slice(0, 3).map((item, i) => (
                    <Label
                      color={item.color}
                      labelClassNames="truncate"
                      key={i}
                      id={item.id}
                      label={item.label}
                      closeAction={handleRemoveTag}
                    />
                  ))}

                  <p
                    className={`w-[10%_!important] duration-200 ${
                      chosenTags.length > 3 ? 'scale-100' : 'scale-0'
                    }`}
                  >
                    {chosenTags.length && (
                      <span className="bg-gray-200 text-gray-600 rounded-full size-6- size-6 text-[.7vw] flex items-center justify-center cursor-pointer ">
                        +{chosenTags.slice(3, chosenTags.length).length}
                      </span>
                    )}
                  </p>
                </>
              ) : (
                <span className="text-gray-400 min-w-full">Choose Tags</span>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
