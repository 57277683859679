import React, { useEffect, useRef, useState } from 'react';
import {
  contactData,
  headingColor,
  selectedContactData,
} from '../../../constants';
import SingleContact from './SingleContact';
import { Checkbox } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import LineLoader from '../../commonComponents/loader/LineLoader';
import { useSelector } from 'react-redux';

const MainContactList = () => {
  const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(null);
  const [selectedItems, setSelectedItems] = useState(selectedContactData);
  const [selectAll, setSelectAll] = useState(false);
  const [columns, setColumns] = useState(contactData);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);
  const firstColumnRef = useRef(null); // to add shadow to first column of columns
  const scrollContainerRef = useRef(null); // to hide tags popover on scroll

  const { dynamicColumns } = useSelector((store) => store.crm);
  // handle click on single column checkbox
  const handleSelect = (id) => {
    const updated = selectedItems.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    const isSelectAll = updated.filter((item) => !item.selected).length === 0;
    setSelectedItems(updated);
    setSelectAll(isSelectAll);
  };

  // handle visibility state of each columns Tags popover
  const toggleTagsDropdown = (id) => {
    setIsTagsDropdownOpen((prev) => {
      if (prev === id) {
        return null;
      }
      return id;
    });
  };

  // handle select all and unselect all scenario in columns
  const enableSelectAll = selectedItems.filter((item) => item.selected).length;
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems((prev) =>
      prev.map((item) => {
        return { ...item, selected: !selectAll };
      }),
    );
  };

  // to hide popover on scroll
  useEffect(() => {
    const handleScroll = (e) => {
      {
        isTagsDropdownOpen && setIsTagsDropdownOpen(null);
        const { scrollLeft, scrollWidth, clientWidth } = e.target;
        // const indication = Math.ceil(
        //   (scrollTop / (scrollHeight - clientHeight)) * 100,
        // );

        // indication > 50 &&
        //   columns.length <= 70 &&
        //   (console.log({ indication, columnslen: columns.length }),
        //   FetchColumns());
        const position = Math.ceil(
          (scrollLeft / (scrollWidth - clientWidth)) * 100,
        );
        const containers = firstColumnRef?.current?.children;

        position === 0 && handleScrollShadow('remove', containers);
        position > 0 && position <= 4 && handleScrollShadow('add', containers);
      }
    };
    // to show and hide single column scroll shadow
    function handleScrollShadow(
      method = 'add',
      collection,
      classList = 'scroll-shadow',
    ) {
      for (let i = 0; i < collection?.length; i++) {
        collection?.[i]?.children?.[0]?.classList?.[method]?.(classList);
      }
    }

    const refElement = scrollContainerRef.current;

    refElement.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      refElement.removeEventListener('scroll', handleScroll);
    };
  }, [isTagsDropdownOpen]);

  const fetchColumns = async () => {
    setIsColumnsLoading(true);
    try {
      await setTimeout(() => {
        setColumns((prev) => [...prev, ...contactData]);
        setIsColumnsLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error.message);
    } finally {
      // console.log('fetch success');
    }
  };

  return (
    <div className="overflow-scroll sm:w-[99.4%] bg-[#F4F6F8] 2xl:w-full relative">
      <LineLoader visible={isColumnsLoading} />
      <InfiniteScroll
        dataLength={columns.length}
        next={fetchColumns}
        hasMore={columns.length < 75}
        scrollableTarget="ddfr12345"
      >
        <div
          className="w-full-w-[calc(100vw-18vw-4.5vw+23px)] ms-[1vw] max-w-max overflow-scroll h-[72vh]  scroll_visible"
          ref={scrollContainerRef}
          id="ddfr12345"
        >
          {/* contacts header */}
          <div
            className={`grid grid-flow-col ps-[1vw] -ms-[1vw] text-[${headingColor}] bg-[#F4F6F8] sticky z-[90] min-w-max top-0 py-[2vh] [&>*:first-child]:min-w-[calc(13vw+1vw)] *:me-[2.5vw] duration-200`}
          >
            {dynamicColumns.map(({ label, id, selected }) => {
              // console.log({ selected });
              if (!selected) return;
              if (label === 'tags') {
                return (
                  <div
                    key={id}
                    className={` duration-200 ${
                      selected ? 'min-w-[15vw]' : 'w-0'
                    }`}
                  >
                    <p className="capitalize text-[.85vw] font-[500]">
                      {label}
                    </p>
                  </div>
                );
              }
              if (label === 'name') {
                return (
                  <div
                    key={id}
                    className={`max-w-min duration-200 text-[.85vw] bg-[#F4F6F8] sticky left-0 first:z-50 ps-[1vw] contact-select capitalize -hover:ps-[2.3vw]  ${
                      enableSelectAll && 'ps-[2.3vw] '
                    }`}
                  >
                    <span className={`absolute -z-10 left-[1vw]`}>
                      <Checkbox
                        name={id}
                        id={1000000}
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </span>

                    <p className="bg-[#F4F6F8]">{label}</p>
                  </div>
                );
              }
              return (
                <div
                  key={id}
                  className={`first:bg-gray-100 first:sticky first:left-0 first:z-50 first:ps-[1vw] duration-200 overflow-hidden ${
                    selected ? 'min-w-[10vw] scale-100' : 'scale-0 w-0'
                  }`}
                >
                  <p
                    className={`capitalize text-[.85vw] ${
                      label.includes('opt out') && 'text-center'
                    } font-medium`}
                  >
                    {label}
                  </p>
                </div>
              );
            })}
          </div>

          {/* contacts body (table) */}

          <div
            className="space-y-[1.2vh] min-w-[79.5vw] bg-red-300-"
            ref={firstColumnRef}
          >
            {columns.map((contact) => {
              return (
                <SingleContact
                  contact={contact}
                  key={contact.id}
                  toggleTagsDropdown={toggleTagsDropdown}
                  isTagsDropdownOpen={isTagsDropdownOpen}
                  selectedItems={selectedItems}
                  handleSelect={handleSelect}
                />
              );
            })}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default MainContactList;
