import React from "react";
import "../../../styles/Broadcastextend.css";
import { SegmentHeaderList } from "../../../../../constant/app/campaign/Broadcast";
import { ICONS } from "../../../../../assets/icons/index";
import { Skeleton } from "antd";
import AudienceSegmentReport from "./AudienceSegmentReport";

const BrandStyle = {
  width: `calc(70% / 7)`,
};

const SegmentFile = ({ segment, isLoad }) => {
  const broadcastSegments = segment?.settings?.segment_settings?.map((data) =>
    data?.segments?.map((data) => data)
  );

  const formatTimestamp = (timestamp) => {
    const dt = new Date(timestamp);
    const options = {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return dt.toLocaleString("en-US", options).toLowerCase();
  };

  return (
    <div className="w-auto flex-column">
      <div className="messageList-main-container w-100 pl-0 ">
        <div className="flex-row w-100 space-between align-center">
          <span className="main-headings" style={{ padding: "16px 0px" }}>
            Audience Segments Reports
          </span>
          {/* <img src={ICONS?.BroadcastDownloadIcon} alt="download" /> */}
        </div>

        <div className="messageList-container w-100 flex-row mt-10 ">
          <Skeleton
            active
            loading={isLoad}
            // rootClassName="w-[98.5%]"
          >
            {segment?.settings?.segment_mode === "merge" ? (
              <div className=" broadcast-left-wrapper  flex-row">
                <div className="flex-column w-20">
                  <span className="messageList-names">Segment Details</span>

                  {broadcastSegments?.[0]?.map((item) => (
                    <div
                      key={item?.id}
                      className="broadcast-main-left  w-100 flex-row align-center space-between pl-10"
                    >
                      <div className="broadcast-left w-100 flex-column ">
                        <div className="flex-row align-center">
                          <img
                            src={ICONS?.BroadcastSegmentIcons}
                            alt="segment"
                            className="w-[13px] mr-1"
                            style={{ width: "13px" }}
                          />
                          <span
                            style={{ fontSize: "0.78vw", fontWeight: "700" }}
                          >
                            Segment - {item?.id}
                          </span>
                          {/* {item?.id === 1 && (
                            <div className="asds flex-row align-center">
                              <img src={ICONS?.BroadcastStarIcon} alt="star" />
                              Best
                            </div>
                          )} */}
                        </div>
                        <div className="messageList-left-column flex-row align-center pt-10">
                          {item?.status === 12 && <span>Completed @</span>}
                          {item?.status === 11 && <span>Running</span>}
                          {item?.status === 10 && <span>Starts @</span>}

                          {item?.status === 12 && (
                            <img src={ICONS?.campaignCalendar} alt="" />
                          )}
                          {item?.status === 10 && (
                            <img src={ICONS?.campaignCalendar} alt="" />
                          )}

                          {item?.status === 12 && (
                            <span>{formatTimestamp(item?.updated_at)}</span>
                          )}
                          {item?.status === 10 && (
                            <span>{formatTimestamp(item?.scheduled_at)}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="broadcast-right-main-wrapper flex-column w-80">
                  <div className=" broadcast-right-wrap  flex-row space-between">
                    {SegmentHeaderList?.map((item, index) => (
                      <div
                        key={item?.id}
                        className={`broadcast-right-wrap flex-row space-between ${
                          index === 0 ? "" : ""
                        }`}
                      >
                        <span className="messageList-names messageList-names-center">
                          {item?.name}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="messageList-right-main-wrapper flex-column space-between">
                    {broadcastSegments?.[0]?.map((item) => (
                      <div
                        key={item?.id}
                        className="broadcast  w-100 flex-row align-center justify-center"
                      >
                        <div className="broadcast-right w-100 flex-row align-center ">
                          <span
                            className=" flex-row justify-center"
                            style={BrandStyle}
                          >
                            {item?.end}
                          </span>

                          <span
                            style={BrandStyle}
                            className=" flex-row align-center justify-center"
                          >
                            {item?.sent_count}
                          </span>
                          <span
                            style={BrandStyle}
                            className=" flex-row align-center justify-center"
                          >
                            {item?.delivered_count}
                          </span>
                          <span
                            style={BrandStyle}
                            className=" flex-row align-center justify-center"
                          >
                            {item?.opened_count}
                          </span>
                          <span
                            style={BrandStyle}
                            className=" flex-row align-center justify-center"
                          >
                            {item?.clicks_count}
                          </span>
                          <span
                            style={BrandStyle}
                            className=" flex-row align-center justify-center"
                          >
                            {item?.leads_count}
                          </span>
                          <span
                            style={BrandStyle}
                            className=" flex-row align-center justify-center"
                          >
                            {item?.bounced_count}
                          </span>
                          {/* <span
                        style={BrandStyle}
                        className=" flex-row align-center justify-center"
                      >
                        {item?.ApproximateCharges}
                      </span> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <AudienceSegmentReport segment={segment} />
            )}
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default SegmentFile;
