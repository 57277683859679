import React from 'react';
import { ICONS } from '../../../../../../assets/icons';

const NoTemplate = () => {
  return (
    <div className='w-full h-full flex-column align-center justify-center'>
      <img
        src={ICONS?.noTemplate}
        alt='noTemplate'
        className='w-[10vw] h-[10vw]'
      />
      <span className='font-larger weight-semibold text-[var(--textBlack)] py-[0.7vw]'>
        No Template
      </span>
      <span className='w-1/5 font-md text-[var(--font-600)] text-center'>
        No templates have been found. Click the button to create one.
      </span>
    </div>
  );
};

export default NoTemplate;
