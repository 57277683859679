import moment from 'moment';

var monthsFullName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

var monthsShortName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function dateConverter(value, utc = false) {
  const d = utc ? moment(value)?.utc() : moment(value);

  const date = d.date();
  const day = d.day();
  const month = d.month() + 1;
  const monthInFull = monthsFullName[d.month()];
  const monthInShort = monthsShortName[d.month()];
  const year = d.year();

  const hours = d.hour();
  const formattedHours = hours % 12 || 12;
  const minutes = d.minute();
  const milliSec = d.millisecond();
  const meridiem = hours > 12 ? 'PM' : 'AM';
  const formatedDate = d.format('MMMM DD, YYYY');
  const formatedDateWithYear = d.format('YYYY-MM-DD');
  const formatedDateWithoutYear = d.format('MMMM, DD');
  const formatedDateWithoutDate = d.format('MMM, YYYY');

  const timeWithMeridiem = d.format('hh:mm A');

  return {
    date,
    day,
    month,
    monthInFull,
    monthInShort,
    year,
    hours,
    formattedHours: formattedHours?.toString().padStart(2, '0'),
    minutes,
    milliSec,
    meridiem,
    timeWithMeridiem,
    formatedDate,
    formatedDateWithYear,
    formatedDateWithoutYear,
    formatedDateWithoutDate,
  };
}
