import React from 'react';
// import '../styles/inbox.css';

// assets
import NewConversation from 'assets/customSVG/NewConversation';

const ConversationsType = ({
  loading,
  conversations,
  conversationList,
  selectedConversationType,
  onConversationTypeChange,
}) => {
  return (
    <div className='w-full max-h-[5.5vw] bg-[var(--white)] p-[0.5vw]'>
      <div className='flex-row align-center border-b-[0.08vw] border-[var(--border-50)]'>
        {conversations?.map((item) => {
          return (
            <div
              key={item?.id}
              className={`w-1/2 h-[2vw] pr-[0.8vw] border-r-[0.08vw] border-r-[var(--border-50)] last:border-r-0 last:pr-0 flex-row align-center justify-center transition-colors ease-linear duration-200 ${
                loading ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (!loading) onConversationTypeChange(item);
              }}
            >
              <div
                key={item?.id}
                className={`w-full h-full border-b-[0.15vw] flex-row align-center justify-center transition-colors ease-linear duration-200 ${
                  selectedConversationType?.id === item?.id
                    ? 'bg-[var(--primaryBackground)] border-b-[var(--primary)]'
                    : 'bg-transparent border-b-transparent'
                }`}
              >
                <item.icon
                  stroke={
                    selectedConversationType?.id === item?.id
                      ? '#25C277'
                      : '#898E99'
                  }
                  color={
                    selectedConversationType?.id === item?.id
                      ? '#25C277'
                      : '#898E99'
                  }
                  width={'1.15vw'}
                  height={'1.15vw'}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className={`flex-row align-center px-[0.4vw] py-[0.5vw]`}>
        {
          <div
            className={`w-full flex-row align-center bg-[var(--primaryBackground)] rounded-[0.5vw] px-[0.8vw] py-[0.5vw]`}
          >
            <NewConversation
              stroke={'#25C277'}
              color={'#25C277'}
              width={'1.1vw'}
              height={'1.1vw'}
            />
            <span className='font-md weight-semibold text-[var(--primary)] pl-[0.4vw]'>
              {conversationList?.length} {selectedConversationType?.msg}
            </span>
          </div>
        }
      </div>
    </div>
  );
};

export default ConversationsType;
