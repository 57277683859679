import React from 'react';
import { Search } from './Search';
import { MainFilter } from './MainFilter';
import Contacts from './contact';
const index = () => {
  return (
    <div className="h-full bg-[var(--white)] w-[calc(100vw-18vw-2.28vw+23px)]">
      <Search />
      <MainFilter />
      <Contacts />
    </div>
  );
};

export default index;
