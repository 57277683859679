import React from 'react';

const CsvInfo = ({ Icon, label, isLast }) => {
  return (
    <div
      className={`flex items-center px-[1.5vw] first:ps-0  ${
        !isLast && 'border-r-[1px]'
      }`}
    >
      <Icon />
      <p className="text-[.6vw] text-gray-400 ms-[.3vw]">
        <span className="font-[500] text-[.7vw] text-gray-950">{5000} </span>
        {label}
      </p>

      {/* {!isLast && <UnderLine />} */}
    </div>
  );
};

export default CsvInfo;
