import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ContactViewFilterList } from "../../../constant/app/Filter/Filter";
import { ICONS } from "../../../assets/icons";
import RadioButton from "../../form/radio/RadioButton";

const ContactViewFilter = ({ onFilterChange, selectedStatus }) => {
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  useEffect(() => {
    const anyItemSelected = selectedStatus.length > 0;
    setIsFilterSelected(anyItemSelected);
  }, [selectedStatus]);

  const SetActionFilter = ({ SetActionList }) => {
    return (
      <>
        <div className="filter-wrapper-main align-center justify-center">
          {SetActionList?.map((data) => {
            return (
              <div key={data?.id}>
                <h4 className="">{data?.name}</h4>
                {data?.subFilter?.map((item) => (
                  <div
                    className="flex-row align-center space-between cursor-pointer rounded-md hover:!bg-slate-100"
                    key={item?.id}
                    style={{
                      borderBottom:
                        item?.id !== 6 ? "1px solid var(--font-50)" : "none",
                    }}
                    onClick={() => {
                      onFilterChange(item?.value);
                    }}
                  >
                    <div className="flex-row align-center">
                      <p>{item?.Filter_name}</p>
                    </div>
                    <div className="mr-5">
                      {selectedStatus?.includes(item.value) && (
                        <RadioButton selected={true} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="flex-row space-between mb-15 mt-15">
      <div className="w-40 flex-row ml-20"></div>
      <div className="flex-row pr-20">
        <span className="mr-10">
          <Popover
            placement="bottom"
            content={
              <SetActionFilter
                SetActionList={ContactViewFilterList}
                onFilterChange={onFilterChange}
              />
            }
            trigger="click"
          >
            {isFilterSelected ? (
              <div className="filter-container-selected flex-row align-center">
                <img src={ICONS.FilterSelectedIcon} alt="list" />
                <span>{selectedStatus?.length}</span>
              </div>
            ) : (
              <div className="filter-container flex-row align-center">
                <img src={ICONS.filterIcon} alt="list" />
                <span>Filter</span>
              </div>
            )}
          </Popover>
        </span>
      </div>
    </div>
  );
};

export default ContactViewFilter;
