import { contactsHeaderWithId, contactFilters } from '../modules/crm/constants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const initialState = {
  columns: [],
  dynamicColumns:
    JSON.parse(localStorage.getItem('dynamicClos')) || contactsHeaderWithId,
  selectedContactSource: '',
  // modelContactSources: contactFilters,
  files: [],
  filters: JSON.parse(localStorage.getItem('filters')) || [],
  // this is need to filled by an api
  uploadedFields: [
    {
      id: 1,
      label: 'first_name',
      selected: false,
      selected_by: '',
    },
    {
      id: 2,
      label: 'last_name',
      selected: false,
      selected_by: '',
    },
    {
      id: 3,
      label: 'email',
      selected: false,
      selected_by: '',
    },
    {
      id: 4,
      label: 'phone_number',
      selected: false,
      selected_by: '',
    },
    {
      id: 5,
      label: 'website',
      selected: false,
      selected_by: '',
    },
    {
      id: 6,
      label: 'others',
      selected: false,
      selected_by: '',
    },
  ],
  mappingFields: {
    first_name: '',
    last_name: '',
    email_ID: '',
    mobile_number: '',
    location: '',
    tags: '',
    country: '',
    alternative_mobile: '',
    work_email_ID: '',
    whatsApp_opt_out: '',
    email_opt_out: '',
    SMS_opt_out: '',
  },
  singleContactUploadDetails: {
    contact: {
      first_name: '',
      last_name: '',
      phone: { country_code: '', number: '' },
      email: '',
      alternative_mobile: { country_code: '', number: '' },
      contact_source: '',
      whatsApp_opt_out: false,
      email_opt_out: false,
      SMS_opt_out: false,
    },
    address: { address: '', city: '', country: '', zip_code: '' },
  },
  tags: [
    { id: 1, label: 'travel', color: 'blue' },
    { id: 2, label: 'location', color: 'orange' },
    { id: 3, label: 'Vlog-YoutubeReels', color: 'red' },
    { id: 4, label: 'Interested', color: 'violet' },
    { id: 8, label: 'Flight', color: '#265073' },
    { id: 5, label: 'entertainment-entertainment', color: 'black' },
    { id: 6, label: 'education', color: '#E88D67' },
    { id: 7, label: 'schools', color: '#41B06E' },
  ],

  singleContactDetails: {
    first_name: 'john',
    last_name: 'cris',
    phone_number: '+91 9787329942',
    alt_phone: '+91 345687526',
    wk_email: 'john@aptonworks.com',
    email: '',
    age: '30',
    gender: 'Male',
    profession: 'Software Engineer',
    contact_src: 'facebook_ads',
    lc_stage: 'lead',
    created_at: '2024-06-10T10:49:02.099Z',
    last_contacted: '2024-06-10T10:51:50.703Z',
    street: '2/28 RK street, Anna Nagar',
    city: 'chennai',
    state: 'TamilNadu',
    zip: '600234',
    selected_tags: [2, 4, 5, 7],
    country: 'India',
    opt_wap: false,
    opt_email: true,
    opt_sms: true,
    prof_fb: 'm.fb.com/john',
    prof_ig: 'insta.com/john',
    prof_x: 'x.app.us/john',
    prof_linkedin: 'in.linked-us/john',
    prof_tiktok: '',
  },
};

export const crmSlice = createSlice({
  name: 'CRM',
  initialState,
  reducers: {
    setColumns: (state, action) => {
      state.columns = action.payload;
    },

    handleDynamicContactSelect: (state, action) => {
      const { id } = action.payload;

      const updatedCols = state.dynamicColumns.map((item) => {
        if (
          item.id === id &&
          !['name', 'phone', 'email'].includes(item.label)
        ) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });

      if (updatedCols.filter((item) => item.selected).length >= 5)
        state.dynamicColumns = updatedCols;
    },
    // handleContactModelSelection: (state, action) => {
    //   const { id } = action.payload;
    //   state.modelContactSources = state.modelContactSources.map((item) => {
    //     if (item.id === id) {
    //       state.selectedContactSource = item.label;
    //       return { ...item, selected: true };
    //     }
    //     return { ...item, selected: false };
    //   });
    // },

    setFiles: (state, action) => {
      // console.log({ action });
      state.files.push(action.payload.file);
    },

    handelDeleteFile: (state, action) => {
      const { id } = action.payload;
      // console.log({ id });
      state.files = state.files.filter((item) => item.id !== id);
    },

    handleUploadedFieldsSelection: (state, action) => {
      const { id, selected_by, prev_selected_by } = action.payload;
      state.uploadedFields = state.uploadedFields.map((item) => {
        if (item.selected_by === selected_by) {
          state.mappingFields[selected_by] = '';
          return { ...item, selected: false, selected_by: '' };
        }
        if (item.id == id) {
          state.mappingFields[selected_by] = item.label;
          return { ...item, selected: true, selected_by };
        }
        return item;
      });
    },

    handleSingleColsAction: (state, action) => {
      // console.log({ action });
      const { key, value, field } = action.payload;
      state.singleContactUploadDetails[field][key] = value;
    },

    setFilters: (state, action) => {
      const { key, value, filter, operator } = action.payload;
      // console.log({ key, value, filter, operator });
      const item = { id: uuid(), [key]: filter, value };
      // if (state.filters.length === 1) {
      //   if (state.filters[0].hasOwnProperty('operator')) {
      //     delete state.filters[0].operator;
      //   }
      // }
      if (state.filters.length >= 1) {
        state.filters[state.filters.length - 1]['operator'] = operator;
      }
      state.filters.push(item);
    },

    updateFilterOperator: (state, action) => {
      const { id, operator } = action.payload;
      state.filters = state.filters.map((item) => {
        if (item.id === id) {
          return { ...item, operator };
        }
        return item;
      });
    },

    handleDeleteFilter: (state, action) => {
      const {
        id,
        clear_filter = false,
        remove_label = false,
        label_id,
      } = action.payload;
      if (clear_filter) {
        state.filters = [];
        return;
      }

      if (remove_label) {
        state.filters = state.filters.map((filter) => {
          // only remove an item from list if it has more than 1 label
          if (filter.id === id && filter.value.fieldValue.length >= 2) {
            return {
              ...filter,
              value: {
                ...filter.value,
                fieldValue: filter.value.fieldValue.filter(
                  (item) => item.id !== label_id,
                ),
              },
            };
          }
          return filter;
        });
        return;
      }

      state.filters = state.filters.filter((item) => item.id !== id);
      // if (state.filters.length === 1) {
      //   delete state.filters[0].operator;
      // }
      if (state.filters.length >= 1) {
        delete state.filters[state.filters.length - 1].operator;
      }
    },

    updateSingleContactDetails: (state, action) => {
      const { access_name, value } = action.payload;
      // console.log({ access_name, value });
      state.singleContactDetails[access_name] = value;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setColumns,
  handleDynamicContactSelect,
  // handleContactModelSelection,
  setFiles,
  handelDeleteFile,
  setFilters,
  handleDeleteFilter,
  updateFilterOperator,
  handleUploadedFieldsSelection,
  handleSingleColsAction,
  updateSingleContactDetails,
} = crmSlice.actions;

export default crmSlice.reducer;
