import React from 'react';
import './checkbox.css';

export default function CheckBox({ data, className, value, onChange }) {
  return (
    <label className={`custom-checkbox ${className}`}>
      <input
        type='checkbox'
        name='checkbox'
        checked={value}
        onChange={(e) => {
          if (data) onChange(data);
          else onChange(e.target.checked);
        }}
      />
    </label>
  );
}
