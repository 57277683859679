import React from 'react';

// assets
import FullArrow from 'assets/customSVG/FullArrow';

// utils
import { Link } from 'react-router-dom';

// API
import { EmailOTPValidation, ResendApi } from '../../api/SignUp';

// hooks
import useToggle from '../../../../hooks/useToggle';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';
import { updateToggleToast } from '../../../../reduxToolkit/appSlice';

// helper
import { cn } from 'helper/cn';
import { shortenEmail } from 'helper/validation';

// hooks
import useTimerCount from 'hooks/useTimerCount';

// components
import OtpInput from 'components/commonComponents/otp';
import Loader from '../../../../components/commonComponents/Loader/Index';

const otpLength = 6;
const minutes = 2;
const secounds = 0;

export default function Step2({ data, onChange }) {
  const { toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);

  const [
    remainingMinutes,
    remainingSeconds,
    setRemainingMinutes,
    setRemainingSeconds,
  ] = useTimerCount(minutes, secounds);

  console.log('data', data);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading && data?.otp.length === otpLength) {
      setIsLoading(true);
      const res = await EmailOTPValidation({
        email: data?.mail,
        email_verify_code: data?.otp,
      });

      if (res?.status === 200) {
        onChange('update_token', null, null, res?.data?.token);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    }
  };

  console.log('data-1', data);

  return (
    <div className='max-w-[470px] w-full mx-auto text-[#2D3036]'>
      <div className='mx-auto flex flex-col items-center text-center'>
        <h2 className='text-2xl weight-bold text-center mt-8 mb-16'>
          Verify your account
        </h2>
        <h4 className='text-base weight-medium'>
          An OTP has been sent to your email for your account verification.
        </h4>
        <p className='text-base weight-semibold mt-3'>
          {shortenEmail(data?.mail)}
        </p>
        <div
          className='mt-3 flex items-center justify-center gap-2 text-sm weight-semibold text-[var(--BrandLink)] cursor-pointer'
          onClick={() => {
            onChange('change_email');
          }}
        >
          Change Email
          <FullArrow color='#6940F2' />
        </div>
      </div>
      <div className='mt-9'>
        <p className='text-base weight-semibold'>
          Enter OTP to verify your email
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='w-full mt-4'>
          <OtpInput
            length={otpLength}
            onChange={(data) => {
              onChange('update', 'otp', data);
              console.log('datas', data);
            }}
            className='w-11 h-11'
          />
        </div>
        <div className='flex flex-col items-center mt-4'>
          <button
            type='submit'
            className={cn(
              'w-44 h-12 rounded-[10px] px-4 py-2 text-base weight-semibold flex items-center justify-center',
              data?.otp?.length === otpLength
                ? isLoading
                  ? 'cursor-not-allowed bg-[var(--primary)] text-[var(--white)]'
                  : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--BG-50)] text-[var(--font-600)] cursor-not-allowed'
            )}
          >
            {isLoading ? (
              <div className='size-[2vw] flex items-center justify-center'>
                <Loader
                  Width='20px'
                  Height='20px'
                  loaderBg='3px solid white'
                  loaderColor='3px solid var(--primary)'
                />
              </div>
            ) : (
              'Verify'
            )}
          </button>
          <div className='flex items-center justify-center text-sm weight-semibold gap-4 mt-4'>
            <p className='text-[var(--primary)]'>
              {remainingMinutes?.toString().padStart(2, '0')}:
              {remainingSeconds?.toString().padStart(2, '0')}
            </p>
            <p
              className={`${
                remainingMinutes === 0 && remainingSeconds === 0
                  ? 'text-[#2D3036] cursor-pointer'
                  : 'text-[var(--font-600)] cursor-not-allowed'
              }`}
              onClick={async () => {
                const res = await ResendApi({ email: data?.mail });
                if (remainingMinutes === 0 && remainingSeconds === 0) {
                  setRemainingMinutes(minutes);
                  setRemainingSeconds(secounds);
                }
              }}
            >
              Resend code?
            </p>
          </div>
        </div>
      </form>
      <div className='flex items-center justify-center gap-1 text-base weight-medium border-t border-[var(--border-50)] mt-4 pt-4'>
        <p>Already have an account?</p>
        <Link to={'/login'} className='text-[var(--a)]'>
          Login
        </Link>
      </div>
    </div>
  );
}
