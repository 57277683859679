import React from 'react';

const NewConversation = ({ width, height, color }) => {
  return (
    <>
      <svg
        width={width ?? 20}
        height={height ?? 21}
        viewBox='0 0 20 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.80909 16.8636H2.72727C2.00395 16.8636 1.31026 16.5763 0.7988 16.0648C0.287337 15.5534 0 14.8597 0 14.1364V3.22727C0 2.50395 0.287337 1.81026 0.7988 1.2988C1.31026 0.787337 2.00395 0.5 2.72727 0.5H13.6364C14.3597 0.5 15.0534 0.787337 15.5648 1.2988C16.0763 1.81026 16.3636 2.50395 16.3636 3.22727V10.2H14.5455V3.22727C14.5455 2.98617 14.4497 2.75494 14.2792 2.58445C14.1087 2.41396 13.8775 2.31818 13.6364 2.31818H2.72727C2.48617 2.31818 2.25494 2.41396 2.08445 2.58445C1.91396 2.75494 1.81818 2.98617 1.81818 3.22727V14.1364C1.81818 14.3775 1.91396 14.6087 2.08445 14.7792C2.25494 14.9497 2.48617 15.0455 2.72727 15.0455H9.80909V16.8636Z'
          fill={color}
        />
        <path
          d='M4.54581 5.95392C5.04789 5.95392 5.4549 5.54691 5.4549 5.04483C5.4549 4.54276 5.04789 4.13574 4.54581 4.13574C4.04373 4.13574 3.63672 4.54276 3.63672 5.04483C3.63672 5.54691 4.04373 5.95392 4.54581 5.95392Z'
          fill={color}
        />
        <path
          d='M11.8169 4.13574H8.18058C7.6785 4.13574 7.27148 4.54276 7.27148 5.04483C7.27148 5.54691 7.6785 5.95392 8.18058 5.95392H11.8169C12.319 5.95392 12.726 5.54691 12.726 5.04483C12.726 4.54276 12.319 4.13574 11.8169 4.13574Z'
          fill={color}
        />
        <path
          d='M14.5444 20.4999C13.4656 20.4999 12.411 20.18 11.514 19.5807C10.617 18.9813 9.91789 18.1294 9.50505 17.1327C9.09221 16.136 8.98419 15.0393 9.19465 13.9812C9.40512 12.9232 9.92461 11.9513 10.6874 11.1884C11.4503 10.4256 12.4222 9.9061 13.4803 9.69563C14.5383 9.48517 15.6351 9.59318 16.6318 10.006C17.6284 10.4189 18.4803 11.118 19.0797 12.015C19.679 12.912 19.9989 13.9666 19.9989 15.0454C19.9989 16.492 19.4243 17.8794 18.4013 18.9023C17.3784 19.9252 15.991 20.4999 14.5444 20.4999ZM14.5444 11.409C13.8252 11.409 13.1221 11.6223 12.5241 12.0218C11.9261 12.4214 11.4601 12.9893 11.1848 13.6538C10.9096 14.3183 10.8376 15.0494 10.9779 15.7548C11.1182 16.4602 11.4645 17.1081 11.9731 17.6167C12.4816 18.1252 13.1296 18.4715 13.835 18.6119C14.5404 18.7522 15.2715 18.6802 15.936 18.4049C16.6004 18.1297 17.1683 17.6636 17.5679 17.0656C17.9675 16.4676 18.1808 15.7646 18.1808 15.0454C18.1808 14.0809 17.7976 13.156 17.1157 12.4741C16.4337 11.7921 15.5088 11.409 14.5444 11.409Z'
          fill={color}
        />
        <path
          d='M15.4027 12.8518L14.172 14.1902C13.8321 14.5598 13.8562 15.1349 14.2258 15.4747C14.5954 15.8146 15.1705 15.7905 15.5103 15.4209L16.741 14.0825C17.0809 13.7129 17.0568 13.1378 16.6872 12.798C16.3176 12.4582 15.7425 12.4823 15.4027 12.8518Z'
          fill={color}
        />
      </svg>
    </>
  );
};

export default NewConversation;
