import AspApi from '../ApiConfig';

export const templateLists = async (brandId, query) => {
  let qpm = [];

  if (brandId) qpm.push(`brand_id=${brandId}`);
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);
  if (query?.category) qpm.push(`category=${query?.category}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(`/api/v1/templates${qpmString}`);
  return res;
};
