import React, { useState } from 'react';
import Arrow from '../../../../assets/customSVG/Arrow';
import { contactFilters, crmFontColor, primaryColor } from '../../constants';
export const ContactFilters = ({ activeFilter, setActiveFilter }) => {
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(true);
  return (
    <>
      <div
        className={`${
          activeFilter > 0
            ? `text-[${primaryColor}] font-[500]`
            : 'text-black font-[400]'
        } flex justify-between items-center my-[2vh] px-[.5vw] cursor-pointer transition-colors duration-100 text-[0.85vw]`}
        onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
      >
        <p> Contact Source</p>
        <span
          className={`duration-300 ${
            isFilterDropdownOpen ? '-rotate-90' : 'rotate-90'
          }`}
        >
          <Arrow color={primaryColor} />
        </span>
      </div>

      <div
        className={`space-y-[0.5vh]- duration-200  ${
          isFilterDropdownOpen ? 'h-full' : 'h-0'
        } overflow-hidden`}
      >
        {contactFilters.map((item) => {
          const Icon = item.icon;
          return (
            <div
              key={item.id}
              className={`flex ${
                activeFilter === item.id
                  ? `bg-[${primaryColor}] text-white font-[500]`
                  : `text-[${crmFontColor}] hover:bg-slate-100 font-[400]`
              }  items-center gap-[0.5vw] ps-[0.6vw]  rounded-[.5vw] py-[.8vh] mb-[1vh] cursor-pointer transition-colors duration-200`}
              onClick={() => setActiveFilter(item.id)}
            >
              {Icon && <Icon />}
              <p className={` text-[.8vw] `}>{item.label}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};
