import React from 'react';

// helper
import { dateConverter } from '../../../../helper/dateConverter';
import { replaceFormating } from '../../helper';

export default function LeftSideConversationCard({ data }) {
  const d = dateConverter(data?.created_at, false);

  return (
    <div className='w-full min-h-[3.5vh] h-full'>
      <div className='h-fit relative w-fit max-w-[30vw]'>
        <div className='w-0 h-0 bg-transparent border-l-[0.5vw] border-r-[0.5vw] border-b-[1vw] border-l-transparent border-r-transparent border-b-[#F4F6F8] absolute -left-[0.5vw] top-0 rotate-180' />
        <div
          className={`w-full h-full font-md weight-medium text-[var(--contentText)] bg-[#F4F6F8] rounded-[0.6vw] rounded-tl-none p-[0.5vw] py-[0.5vw]`}
          dangerouslySetInnerHTML={{
            __html: replaceFormating(data?.message?.body),
          }}
        ></div>
      </div>
      <div className='text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw]'>
        <p>{d?.timeWithMeridiem}</p>
      </div>
    </div>
  );
}
