import React from 'react';

const GoogleAds = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 15.001C18 16.401 16.9 17.501 15.5 17.501C14.55 17.501 13.75 17.001 13.3 16.201V16.151L7.85 6.25098C7.6 5.85098 7.5 5.45098 7.5 5.00098C7.5 3.60098 8.6 2.50098 10 2.50098C10.95 2.50098 11.75 3.00098 12.2 3.80098V3.85098L17.65 13.801C17.9 14.151 18 14.551 18 15.001Z"
        fill="#0284FE"
      />
      <path
        d="M6.69961 16.1516C6.89961 15.8016 6.99961 15.4016 6.99961 15.0016C6.99961 13.6016 5.89961 12.5016 4.49961 12.5016C3.54961 12.5016 2.74961 13.0016 2.34961 13.7516L7.79961 3.85156C7.59961 4.20156 7.49961 4.60156 7.49961 5.00156C7.49961 5.45156 7.59961 5.85156 7.79961 6.20156L9.94961 10.1516L6.69961 16.1516Z"
        fill="#FCBD02"
      />
      <path
        d="M4.5 17.501C5.88071 17.501 7 16.3817 7 15.001C7 13.6203 5.88071 12.501 4.5 12.501C3.11929 12.501 2 13.6203 2 15.001C2 16.3817 3.11929 17.501 4.5 17.501Z"
        fill="#03A846"
      />
    </svg>
  );
};

export default GoogleAds;
