import React, { useState } from 'react';
import Button from '../../commonComponents/button';
import { useSelector } from 'react-redux';
import { handleSingleColsAction } from '../../../../../reduxToolkit/crmSlice';
import { Checkbox } from 'antd';
import { convertName } from '../../../helpers';
import { useDispatch } from 'react-redux';
import Dropdown from '../../commonComponents/dropdown';
import Input from '../../commonComponents/input/Input';
import { contactFilters } from '../../../constants';

const AddSingleContact = () => {
  const {
    singleContactUploadDetails: { contact, address },
  } = useSelector((store) => store.crm);
  const dispatch = useDispatch();
  const handleChange = (e, field, key, val) => {
    let { name, value, type, dataset } = e?.target || {};
    if (type === 'checkbox') {
      value = !value;
    }
    dispatch(
      handleSingleColsAction({
        key: key || name,
        value: val || value,
        field: field || dataset?.contact,
      }),
    );
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [contactSources, setContactSources] = useState(contactFilters);

  const contacts = Object.entries(contact);
  const locationDetails = Object.entries(address);

  return (
    <div className="grid grid-rows-[10fr,1fr] h-[90%]">
      <form className="p-[1vw]">
        <div className="border-b border-b-gray-200 pb-[1vw] mb-[1vw]">
          <p className="text-[.85vw] font-[500]">Contact details:</p>
          <div className="grid grid-cols-2 py-[1vw] gap-x-[3vw] gap-y-[1vw]">
            {contacts.slice(0, contacts.length - 3).map(([key, val]) => {
              if (key.includes('contact_source')) {
                return (
                  <React.Fragment key={key}>
                    <Dropdown
                      // open={selectOpen}
                      heading=""
                      data={contactSources}
                      width="min-w-[20vw]"
                      height="max-h-[32vh]"
                      containerClassNames="px-[1.6vw]"
                      editFilter={(id) => {
                        setContactSources((prev) => {
                          return prev.map((item) => {
                            if (item.id === id) {
                              handleChange(null, 'contact', key, item.label);
                              return { ...item, selected: true };
                            }
                            return { ...item, selected: false };
                          });
                        });
                      }}
                      button={
                        <div>
                          <span className="text-[.7vw] font-[500] capitalize">
                            {convertName(key)}
                          </span>
                          <div
                            className={`flex justify-between items-center bg-gray-50- border border-solid border-gray-200 text-sm rounded-lg w-full px-[.8vw] py-[.5vw] placeholder:font-[400] mt-[.2vw]`}
                            onClick={(e) => setIsDropdownOpen((prev) => !prev)}
                          >
                            <p className={`text-[.8vw] text-[#898E99]`}>
                              {val || 'Select Source'}
                            </p>
                            <span
                              className={`duration-300 ${
                                isDropdownOpen ? '-rotate-180' : 'rotate-0'
                              }`}
                            >
                              <div
                                className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-slate-400 translate-y-[25%]`}
                              />
                            </span>
                          </div>
                        </div>
                      }
                    />
                  </React.Fragment>
                );
              } else if (['alternative_mobile', 'phone'].includes(key)) {
                return (
                  <div key={key}>
                    <Input
                      name={key}
                      type={'number'}
                      onChange={handleChange}
                      value={val}
                      label={key}
                      required={true}
                      showCountryDropDown
                      placeholder={`EX:- 9789 659 872`}
                      data-contact="contact"
                    />
                  </div>
                );
              } else {
                return (
                  <div key={key}>
                    <Input
                      name={key}
                      label={key}
                      type={key === 'email' ? 'email' : 'text'}
                      onChange={handleChange}
                      value={val}
                      required={true}
                      placeholder={`Enter ${convertName(key)}`}
                      data-contact="contact"
                    />
                  </div>
                );
              }
            })}

            <div className="flex col-span-2 font-[500] text-slate-500 [&>*:nth-child(2)]:mx-[6vw] mt-[.5vw]">
              {contacts
                .slice(contacts.length - 3, contact.length)
                .map(([key, val]) => {
                  if (key.includes('opt_out')) {
                    return (
                      <div
                        key={key}
                        className="opt_select flex items-center gap-[.5vw]"
                      >
                        <Checkbox
                          name={key}
                          id={key}
                          data-contact="contact"
                          onChange={(e) => handleChange(e, 'contact')}
                          value={val}
                          checked={val}
                        />
                        <label
                          htmlFor={key}
                          className="text-[.75vw] font-[500]- capitalize "
                        >
                          {convertName(key)}
                        </label>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div>
          <p className="text-[.85vw] font-[500] mt-[.5vw]">Address details:</p>
          <div className="grid grid-cols-2 py-[1vw] gap-x-[3vw] gap-y-[1vw]">
            {locationDetails.map(([key, val]) => {
              return (
                <div key={key}>
                  <Input
                    name={key}
                    label={key}
                    type={'text'}
                    onChange={(e) => handleChange(e, 'address')}
                    value={val}
                    // required={false}
                    placeholder={`Enter ${convertName(key)}`}
                    data-contact="address"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </form>
      <div className="border-t sticky bottom-0 py-[.5vw] border-t-gray-200 pe-[1vw]">
        <Button classNames="ms-auto" type="submit" label="Continue"></Button>
      </div>
    </div>
  );
};

export default AddSingleContact;
