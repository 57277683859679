import React from 'react';

const facebook = ({ width = '20', height = '20' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10128_47180)">
        <path
          d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
          fill="#1778F2"
        />
        <path
          d="M10.7974 15.644V10.2912H12.5942L12.8637 8.20507H10.7974V6.87315C10.7974 6.26922 10.9652 5.85761 11.8313 5.85761H12.936V3.99233C12.4013 3.93576 11.8639 3.90835 11.3263 3.91025C9.73351 3.91025 8.64315 4.88238 8.64315 6.66777V8.20507H6.8418V10.2912H8.64315V15.644H10.7974Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_10128_47180">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default facebook;
