import React from 'react';
function List({ color = '#616874', width = '1vw', height = '1vw' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.31372 4H14.9004M6.31372 8.32H14.9004M6.31372 12.64H14.9004M2.90039 4V4.00853M2.90039 8.32V8.32853M2.90039 12.64V12.6485'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export default List;
