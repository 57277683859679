import React from 'react';

export default function Arrow({
  width,
  height,
  color = '#616874',
  strokeWidth,
  className,
}) {
  return (
    <svg
      width={width ?? 7}
      height={height ?? 12}
      viewBox='0 0 7 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M6 11L1 6L6 1'
        stroke={color}
        strokeWidth={strokeWidth ?? '2'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
