import React, { useState } from "react";
import "./ListViewHeader.css";

import ListViewSearch from "./ListViewSearch";
import FilterActions from "./FilterActions";
import { Button } from "../../form/Button/Button";
import { Modal } from "antd";
import CampaignModal from "../campaign-popup/CampaignModal";
import CampaignFilter from "./CampaignFilter";
import { ICONS } from "../../../assets/icons";
import SearchBar from "../searchBar";

const ListViewHeader = ({
  onFilterChange,
  selectedStatus,
  tableList,
  isFilter,
  setIsFilter,
  templateSearch,
  setTableList,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="list-view-wrapper">
      {isModalVisible && <div className="modal-overlay" />}

      <div className="w-[15vw]">
        <SearchBar
          searchKey={isFilter}
          setSearchKey={setIsFilter}
          onChange={(text) => templateSearch(text)}
        />
      </div>
      <div className="flex-row">
        {tableList?.length > 0 && (
          <CampaignFilter
            onFilterChange={onFilterChange}
            selectedStatus={selectedStatus}
          />
        )}
        <div className="flex-row align-center pointer">
          <Button
            iconPrefix={ICONS?.addBGwhite}
            label={"Create Campaign"}
            size={"medium"}
            onClick={() => setIsModalVisible(true)}
          />

          <Modal
            open={isModalVisible}
            footer={null}
            className={"campaign-modal"}
            closable={false}
            centered={true}
            // onCancel={handleCancel}
            width="40%"
            style={{ borderRadius: "20px !important" }}
            destroyOnClose={true}
          >
            <CampaignModal
              handleCancel={handleCancel}
              setIsModalVisible={setIsModalVisible}
              tableList={tableList}
              setTableList={setTableList}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ListViewHeader;
