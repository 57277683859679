import React, { useCallback } from 'react';

// utils
import { getNumberOfButtons } from './utils';

// components
import CTAButton from './CTAButton';

export default function CalltoAction({ ctaButtons, updateButtons, allow }) {
  // disable when the select option reach the max length to add in call to action section
  const checkToAvailableToAdd = useCallback(
    (format) => getNumberOfButtons(ctaButtons, format),
    [ctaButtons]
  );

  return (
    <div className='border-[0.08vw] border-[var(--card-border)] rounded-[0.4vw] px-[0.4vw] py-[0.5vw]'>
      <h3 className='text-[var(--contentText)] font-default weight-semibold'>
        Call to action
      </h3>
      <div className=''>
        {ctaButtons?.map((d, i) => {
          return (
            <div
              key={`call-to-action-${i}`}
              className='w-full h-[3.5vw] flex items-center gap-[0.4vw] my-[0.6vw] rounded-[0.4vw] last:mb-0'
            >
              {/* <div className='w-[1vw] h-[1vw]'>
                <img
                  src={ICONS?.dragVertical}
                  alt='drag'
                  style={{
                    width: '1.2vw',
                    height: '1.2vw',
                  }}
                />
              </div> */}
              <div>
                <CTAButton
                  data={d}
                  allow={allow}
                  index={i}
                  checkToAvailableToAdd={checkToAvailableToAdd}
                  updateButtons={updateButtons}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
