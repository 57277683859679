import React from 'react';
import Close from '../../../../../assets/customSVG/Close';

const index = ({
  id = '',
  color = '#898E99',
  labelClassNames = '',
  mainClassNames = '',
  label = '',
  onClick = () => {},
  showClose = true,
  closeAction = () => {},
  closeColor = '',
}) => {
  return (
    <p
      onClick={(e) => onClick(id, e)}
      key={color}
      className={`max-w-max ps-[.5vw] pe-[0.2vw] rounded-full relative cursor-pointer ${mainClassNames}`}
    >
      <span
        style={{ backgroundColor: color }}
        className="z-10 rounded-full opacity-30 w-full h-full absolute top-0 left-0"
      />
      <span
        style={{ color: color }}
        className="relative z-10 bg-transparent min-h-[24px] flex items-center text-[.8vw] justify-start w-full"
      >
        <span
          className={`${
            !showClose && 'pe-[0.3vw]'
          } overflow-ellipsis overflow-hidden ${labelClassNames}`}
        >
          {label}
        </span>
        {showClose && (
          <span onClick={(e) => closeAction(e, id)} className="cursor-pointer">
            <Close
              strokeWidth="1.5"
              width="20"
              height="20"
              circle={false}
              stroke={closeColor || color}
            />
          </span>
        )}
      </span>
    </p>
  );
};

export default index;
