import React from 'react';
import Header from './Header';
import MainContactList from './MainContactList';

const index = () => {
  return (
    <div className="bg-gray-100 h-full w-full flex flex-col">
      <Header />
      <MainContactList />
    </div>
  );
};

export default index;
