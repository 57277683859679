import React from "react";
import "../../../../channels/styles/messageList.css"
import { images } from "../../../../../assets/images";

const ContactSelectView = () => {
  return (
    <div className="no-template-main-container w-100  flex-column align-center justify-center">
      <img src={images?.emptyImage} alt="noTemplate" />
      <span className="no-template-title">No Contact Selected</span>
      <span className="no-template-content">No contacts have been found.</span>
    </div>
  );
};

export default ContactSelectView;
