import React from 'react';

export default function Calender({
  width,
  height,
  color,
  className,
  strokeWidth = '1.5',
}) {
  return (
    <svg
      width={width ?? '14'}
      height={height ?? '16'}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M1.5625 5.68569H12.0625M2.91964 1.25V2.40728M10.5625 1.25V2.40714M12.8125 4.43214V12.725C12.8125 13.8434 11.9171 14.75 10.8125 14.75H2.8125C1.70793 14.75 0.8125 13.8434 0.8125 12.725V4.43214C0.8125 3.31376 1.70793 2.40714 2.8125 2.40714H10.8125C11.9171 2.40714 12.8125 3.31376 12.8125 4.43214Z'
        stroke={color ?? '#616874'}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
