import React from 'react';
import '../../../../../components/commonComponents/broadcastoverview/broadcast.css';

import { campaignBroadcastList } from '../../../../../constant/app/campaign/campaign';

const SegmentOverview = ({ segment }) => {
  const segments = segment?.settings;

  const getStatusCount = (item) => {
    switch (item?.title) {
      case 'Contact':
        return segments?.audience_count || 0;
      case 'Delivered':
        return segments?.delivered_count || 0;
      case 'Opened':
        return segments?.opened_count || 0;
      case 'Lead':
        return segments?.leads_count || 0;
      case 'Clicks':
        return segments?.clicks_count || 0;
      default:
        return 0;
    }
  };
  return (
    <>
      <div className='Overview_container'>
        <span className='main-headings-broadcast'>Overview</span>
      </div>

      <div className='postMain-container1 w-100  pt-20 flex-end space-between'>
        <div
          className=' flex-row space-between align-center '
          style={{ width: '100% ' }}
        >
          <div className='postMain  w-100 '>
            {campaignBroadcastList?.length && (
              <div className='broadCast-card flex-row space-between align-center '>
                {campaignBroadcastList?.map((item, index) => (
                  <React.Fragment key={item?.id}>
                    <div className='flex-row space-between align-center '>
                      <div className='broadcastImage flex-row justify-center'>
                        <img src={item?.icon} alt='performance' />
                      </div>
                      <div
                        className='postCard-left flex-column  '
                        style={{ padding: '10px 10px' }}
                      >
                        <span className=''>{item?.title}</span>
                        <span className=''>{getStatusCount(item)}</span>
                      </div>
                    </div>

                    <div
                      className={` ${
                        campaignBroadcastList?.length - 1 !== index
                          ? 'borderRight'
                          : ''
                      }`}
                    />
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SegmentOverview;
