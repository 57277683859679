import React, { useEffect, useState } from 'react';

// utils
import '../styles/inbox.css';
import { useNavigate } from 'react-router-dom';

// assets
import noData from '../../../assets/images/inbox/noData.svg';

// API
import { whatsappBusinessNumbers } from '../api/Api';

// hooks
import useToggle from 'hooks/useToggle';

// redux
import { useAspSelector } from '../../../test/jest-redux-hooks';

// components
import SideBar from '../components/sidebar';
import Conversation from '../components/conversation/index';
import NoData from '../../../components/commonComponents/Nodata/index';

const Inbox = () => {
  const { currentBrand } = useAspSelector((state) => state?.app);

  const navigate = useNavigate();

  // loading
  const [numberListLoading, setNumberListLoading] = useToggle(false);
  const [conversationListLoading, setConversationListLoading] =
    useToggle(false);

  // phone numbers list
  const [numbersList, setNumbersList] = useState(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  useEffect(() => {
    fetchWhatsappBusinessNumbers(currentBrand?.brand_id);
  }, []);

  const handleSelect = (item) => setSelectedPhoneNumber(item);

  // fetch whatsapp business numbers
  const fetchWhatsappBusinessNumbers = (brandId) => {
    if (brandId) {
      setNumberListLoading(true);

      whatsappBusinessNumbers(brandId)
        .then((res) => {
          res && res.data?.wapp_business_num?.length > 0
            ? setNumbersList(res?.data?.wapp_business_num)
            : setNumbersList([]);
        })
        .finally(() => {
          setNumberListLoading(false);
        });
    } else {
      setNumbersList([]);
    }
  };

  if (!numberListLoading && numbersList?.length === 0)
    return (
      <div className='w-full h-full flex-center flex-col'>
        <NoData
          icon={noData}
          title='No whatsapp numbers found.'
          content={
            <>
              You have not connected any account yet,
              <br /> click on the button to get started.
            </>
          }
          rootClassName='h-auto'
          iconStyle={'w-[8vw]'}
          style={'max-w-[20vw]'}
        />
        <button
          className='bg-[var(--primary)] text-[var(--white)] font-normal weight-bold px-[0.85vw] py-[0.5vw] rounded-[0.6vw] mt-[0.8vw]'
          onClick={() => {
            navigate('/user/channels/whatsapp');
          }}
        >
          Connect WhatsApp
        </button>
      </div>
    );

  return (
    <div className='flex-row w-full h-full'>
      <>
        <SideBar
          loading={numberListLoading}
          list={numbersList}
          selectedPhoneNumber={selectedPhoneNumber}
          handleSelect={handleSelect}
          className=''
        />

        <div className='w-full h-full m-[0.5vw]'>
          <div className='w-full h-full bg-[var(--white)]'>
            <Conversation
              primaryLoading={numberListLoading}
              loading={conversationListLoading}
              setLoading={setConversationListLoading}
              selectedPhoneNumber={selectedPhoneNumber}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default Inbox;
