// TimePickers.js
import React from "react";
import { ICONS } from "../../../../assets/icons";

const TimePickers = ({
  hours,
  minutes,
  periods,
  setHours,
  setMinutes,
  setPeriod,
}) => {
  const handleHoursChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 1 && value <= 12) {
      setHours(value < 10 ? `0${value}` : `${value}`);
    }
  };

  const handleMinutesChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 0 && value <= 59) {
      setMinutes(value < 10 ? `0${value}` : `${value}`);
    }
  };

  const handleIncrement = () => {
    let newHours = parseInt(hours) + 1;
    if (newHours > 12) {
      newHours = 1;
    }
    setHours(newHours < 10 ? `0${newHours}` : `${newHours}`);
  };

  const handleDecrement = () => {
    let newHours = parseInt(hours) - 1;
    if (newHours < 1) {
      newHours = 12;
    }
    setHours(newHours < 10 ? `0${newHours}` : `${newHours}`);
  };

  const handleIncrementMinutes = () => {
    let newMinutes = parseInt(minutes) + 1;
    if (newMinutes >= 60) {
      newMinutes = 0;
    }
    setMinutes(newMinutes < 10 ? `0${newMinutes}` : `${newMinutes}`);
  };

  const handleDecrementMinutes = () => {
    let newMinutes = parseInt(minutes) - 1;
    if (newMinutes < 0) {
      newMinutes = 59;
    }
    setMinutes(newMinutes < 10 ? `0${newMinutes}` : `${newMinutes}`);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <div
      className="flex-row   p-10"
      style={{
        position: "absolute",
        bottom: "120%",
        left: "21%",
        background: "var(--background)",
        boxShadow: " 0px 1.5px 2px 2px rgba(16, 24, 40, 0.1)",
        borderRadius: "7px",
        padding: "16px",
      }}
    >
      <div className="flex-row  ">
        <input
          min="1"
          max="12"
          value={hours}
          onChange={handleHoursChange}
          style={{
            width: "3vw",
            paddingRight: "10px",
            fontSize: "0.95vw",
            fontWeight: "600",
          }}
          dir="rtl"
        />
        <div className="flex-column mr-10 ">
          <img
            src={ICONS?.BroadcastChevronUpIcon}
            alt="upIcon"
            className="upIcon pointer"
            onClick={() => handleIncrement()}
          />
          <img
            src={ICONS?.BroadcastChevronDownIcon}
            alt="upIcon"
            className="downIcon pointer"
            onClick={() => handleDecrement()}
          />
        </div>
      </div>
      :
      <div className="flex-row  " style={{}}>
        <input
          min="0"
          max="59"
          value={minutes}
          onChange={handleMinutesChange}
          style={{
            width: "3vw",
            paddingRight: "10px",
            fontSize: "0.95vw",
            fontWeight: "600",
          }}
          dir="rtl"
        />
        <div className="flex-column  ">
          <img
            src={ICONS?.BroadcastChevronUpIcon}
            alt="upIcon"
            className="upIcon pointer"
            onClick={() => handleIncrementMinutes()}
          />
          <img
            src={ICONS?.BroadcastChevronDownIcon}
            alt="upIcon"
            className="downIcon pointer"
            onClick={() => handleDecrementMinutes()}
          />
        </div>
      </div>
      <select
        value={periods}
        onChange={handlePeriodChange}
        style={{
          fontSize: "0.95vw",
          fontWeight: "600",
          paddingLeft: "15px",
          cursor: "pointer",
        }}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

export default TimePickers;
