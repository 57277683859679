// export const validatePassword = (rule, value) => {
//   const notSpace = /^\S*$/;
//   const eightChar = /.{8,}/;
//   if (value === '') {
//     return Promise.reject('Please enter your password');
//   } else if (!eightChar.test(value)) {
//     return Promise.reject('Must contain 8 or more characters');
//   } else if (!notSpace.test(value)) {
//     return Promise.reject('Must not have any blank spaces');
//   } else if (!/.*\d/.test(value)) {
//     return Promise.reject('Min 8 chars 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special char');
//   } else if (!/.*[a-z]/.test(value)) {
//     return Promise.reject('Must contain a lower case character');
//   } else if (!/.*[A-Z]/.test(value)) {
//     return Promise.reject('Must contain an upper case character');
//   } else if (/^[a-zA-Z0-9 ]*$/.test(value)) {
//     return Promise.reject('Must contain a symbol');
//   } else {
//     return Promise.resolve();
//   }
// };

export const validatePassword = (rule, value) => {
  const notSpace = /^\S*$/;
  const eightChar = /.{8,}/;
  if (value === '') {
    return Promise.reject('');
  } else if (!eightChar.test(value)) {
    return Promise.reject('');
  } else if (!notSpace.test(value)) {
    return Promise.reject('');
  } else if (!/.*\d/.test(value)) {
    return Promise.reject('');
  } else if (!/.*[a-z]/.test(value)) {
    return Promise.reject('');
  } else if (!/.*[A-Z]/.test(value)) {
    return Promise.reject('');
  } else if (/^[a-zA-Z0-9 ]*$/.test(value)) {
    return Promise.reject('');
  } else {
    return Promise.resolve();
  }
};

export const passwordValidations = [
  {
    id: 1,
    type: 'min',
    msg: 'At least 8 characters',
    value: false,
  },
  {
    id: 2,
    type: 'numbers',
    msg: 'At least 1 numbers',
    value: false,
  },
  {
    id: 3,
    type: 'uppercase',
    msg: 'At least 1 upper case letter',
    value: false,
  },
  {
    id: 4,
    type: 'lowercase',
    msg: 'At least 1 lower case letter',
    value: false,
  },
  {
    id: 5,
    type: 'special',
    msg: 'At least 1 special characters',
    value: false,
  },
];

export const checkingValidation = (type, values, errors, value) => {
  let errorValues = [...errors];
  const min = /.{8,}/;
  const number = /[0-9]/;
  const lower = /[a-z]/;
  const upper = /[A-Z]/;
  const special = /[^A-Za-z0-9]/;
  const space = /^\S*$/;

  if (type === 'password') {
    // checking minimum 8 characters
    if (min?.test(value)) {
      const update = errorValues?.map((e) => {
        return e?.type === 'min' ? { ...e, value: true } : e;
      });
      errorValues = update;
    } else {
      const update = errorValues?.map((e) => {
        return e?.type === 'min' ? { ...e, value: false } : e;
      });
      errorValues = update;
    }

    // checking one uppercase
    if (upper?.test(value)) {
      const update = errorValues?.map((e) => {
        return e?.type === 'uppercase' ? { ...e, value: true } : e;
      });
      errorValues = update;
    } else {
      const update = errorValues?.map((e) => {
        return e?.type === 'uppercase' ? { ...e, value: false } : e;
      });
      errorValues = update;
    }

    // checking one lowercase
    if (lower?.test(value)) {
      const update = errorValues?.map((e) => {
        return e?.type === 'lowercase' ? { ...e, value: true } : e;
      });
      errorValues = update;
    } else {
      const update = errorValues?.map((e) => {
        return e?.type === 'lowercase' ? { ...e, value: false } : e;
      });
      errorValues = update;
    }

    // checking one number
    if (number?.test(value)) {
      const update = errorValues?.map((e) => {
        return e?.type === 'numbers' ? { ...e, value: true } : e;
      });
      errorValues = update;
    } else {
      const update = errorValues?.map((e) => {
        return e?.type === 'numbers' ? { ...e, value: false } : e;
      });
      errorValues = update;
    }

    // checking one special character
    if (special?.test(value)) {
      const update = errorValues?.map((e) => {
        return e?.type === 'special' ? { ...e, value: true } : e;
      });
      errorValues = update;
    } else {
      const update = errorValues?.map((e) => {
        return e?.type === 'special' ? { ...e, value: false } : e;
      });
      errorValues = update;
    }
  }

  if (type === 'confirm_password') {
    if (!space?.test(value)) {
      const update = errorValues?.map((e) => {
        return e?.type === 'space' ? { ...e, value: false } : e;
      });
      errorValues = update;
    } else {
      const update = errorValues?.map((e) => {
        return e?.type === 'space' ? { ...e, value: true } : e;
      });
      errorValues = update;
    }
  }

  if (
    values?.confirm_password &&
    values?.password === values?.confirm_password
  ) {
    const update = errorValues?.map((e) => {
      return e?.type === 'match' ? { ...e, value: true } : e;
    });
    errorValues = update;
  } else {
    const update = errorValues?.map((e) => {
      return e?.type === 'match' ? { ...e, value: false } : e;
    });
    errorValues = update;
  }

  // setErrors(errorValues);
  return errorValues;
};
