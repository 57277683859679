import React, { useEffect, useState } from "react";
import "./Index.css";

import { useNavigate } from "react-router-dom";
import { useAspSelector } from "test/jest-redux-hooks";
import Chevron from "assets/customSVG/Chevron";

function Index({ list, activeNav = "", onSelect, color }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const isSubmenuOpen = useAspSelector((state) => state.Channel.isSubmenuOpen);
  const { currentBrand } = useAspSelector((state) => state.app);

  const [toggle, setToggle] = useState(false);
  const handleItemClick = (item) => {
    if (item.subMenu) {
      onSelect(item);
      item.link && navigate(item.link);

      if (selectedItem === item) {
        setSelectedItem(null);
        setToggle(true);
      } else {
        setSelectedItem(item);
        setToggle(false);
      }
    } else {
      onSelect(item);
      item.link && navigate(item.link);

      setSelectedItem(item);
      setToggle(false);
    }
  };

  return (
    <div className="leftListLayout">
      {list?.map((item) => (
        <div className="flex-column justify-center" key={item?.id}>
          <div
            className={`listText flex-row align-center space-between ${
              activeNav === item?.name ? "active-listText" : ""
            }`}
            onClick={() => handleItemClick(item)}
          >
            <div className="flex-row align-center">
              <span> {item?.name}</span>
            </div>
            {isSubmenuOpen === false &&
              item?.icon1 &&
              currentBrand?.is_whatsapp_connected === true && (
                <div onClick={() => setToggle(!toggle)}>
                  <Chevron
                    className={`rotate-img ${toggle ? "rotate-img1" : ""}`}
                    color={activeNav === item?.name ? "white" : "#0ACD95"}
                  />
                </div>
              )}
          </div>

          {isSubmenuOpen === false &&
            !toggle &&
            currentBrand?.is_whatsapp_connected === true && (
              <div className="submenu-container flex-column ">
                {item?.subMenu?.map((subItem) => (
                  <span
                    className={`listText1 flex-row align-center space-between pointer ${
                      activeNav === subItem?.name ? "active-listText" : ""
                    }`}
                    key={subItem?.id}
                    onClick={() => handleItemClick(subItem)}
                  >
                    {subItem?.name}
                  </span>
                ))}
              </div>
            )}
        </div>
      ))}
    </div>
  );
}

export default Index;
