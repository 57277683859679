import React, { useState } from "react";
import "../../styles/Broadcast.css";
import "../../styles/Preview.css";

import CampaignHeader from "../campaigns/CampaignHeader";
import CampaignOverview from "../campaigns/CampaignOverview";
import BroadcastTable from "./BroadcastTable";
import { useLocation } from "react-router-dom";

const BroadCast = ({ tabList }) => {
  const location = useLocation();

  const campaignDetails = location?.state?.item;

  return (
    <>
      <CampaignHeader campaignDetails={campaignDetails} />
      <div className="broadcast-main-layout">
        <CampaignOverview />
        <BroadcastTable tabList={tabList} />
      </div>
    </>
  );
};

export default BroadCast;
