import React from 'react';

const index = ({
  weight = 'thin', // thickness of the underline (thin || bold)
  color = 'bg-[var(--border-50)]', // color
  flow = 'horizontal', // horizontal || vertical
  className = '',
}) => {
  // const height = `h-[${weight}]`;
  return (
    <div
      className={`${
        weight === 'thin'
          ? 'h-[0.01vh]'
          : weight === 'bold'
          ? 'h-[0.5vh]'
          : 'h-[.2vh]'
      } ${color} ${flow === 'vertical' && 'rotate-90'} ${className}`}
    />
  );
};

export default index;
