import React, { useCallback, useEffect, useRef, useState } from 'react';

// assets
import { ICONS } from '../../../../assets/icons';

// utils
import {
  addCtaButton,
  checkToAddOptout,
  getNumberOfButtons,
} from '../Buttons/utils';

// redux
import { useAspSelector } from '../../../../test/jest-redux-hooks';

// hooks
import useToggle from '../../../../hooks/useToggle';

export default function AddButtons({ buttons, updateButtons, allow }) {
  const templateDetails = useAspSelector((state) => state?.Template);
  const [isOpen, setIsOpen] = useToggle(false);
  const containerRef = useRef(null);

  // useEffect(() => {
  //   const handler = (e) => {
  //     if (e.target != containerRef.current) return;
  //     switch (e.code) {
  //       case 'Escape':
  //         setIsOpen(false);
  //         break;
  //     }
  //   };
  //   containerRef.current?.addEventListener('keydown', handler);

  //   return () => {
  //     containerRef.current?.removeEventListener('keydown', handler);
  //   };
  // }, [isOpen]);

  useEffect(() => {
    checkToAvailableToAdd();
  }, [buttons]);

  const length = useCallback(() => buttons?.length < 10, [buttons]);

  // add buttons
  const addButton = (type, format, text, url, code, number) => {
    const data = addCtaButton(type, format, text, url, code, number);
    const getIds = buttons.map(({ id }) => id);
    const removeDublicate = Math.max(...new Set(getIds));
    let newId = buttons?.length > 0 ? removeDublicate + 1 : buttons?.length + 1;

    if (data) {
      updateButtons({
        updatevalue: 'add',
        btnObj: {
          id: newId,
          ...data,
        },
      });
      setIsOpen(false);
    }
  };

  const checkToAvailableToAdd = useCallback(
    (format) => getNumberOfButtons(buttons, format),
    [buttons]
  );

  // styles
  const container = 'h-[1.5vw] font-md weight-semibold px-[0.5vw]';
  const itemContainer =
    'w-full px-[0.5vw] flex flex-col py-[0.5vw] my-[0.35vw] hover:bg-slate-100 rounded-[0.4vw] cursor-pointer';
  const itemTitle = 'font-md weight-medium text-[var(--contentText)]';
  const itemContent =
    'font-sm weight-medium mt-[0.15vw] text-[var(--font-600)]';
  const disabled = '!cursor-not-allowed opacity-50 hover:!bg-transparent';

  return (
    <div
      ref={containerRef}
      className={`relative font-md weight-medium rounded-[0.4vw] flex items-center gap-[0.6vw] outline-none ${
        allow ? 'cursor-pointer' : 'cursor-not-allowed'
      }`}
      onClick={() => {
        if (allow) setIsOpen(!isOpen);
      }}
      onBlur={() => {
        if (allow) setIsOpen(false);
      }}
    >
      <div
        className={`w-full h-[2vw] relative bg-[var(--primary)] text-[var(--white)] flex items-center justify-center gap-[0.4vw] rounded-[0.4vw] ${
          allow && length()
            ? 'opacity-100 cursor-pointer'
            : 'opacity-50 cursor-not-allowed'
        }`}
        onClick={() => {
          if (allow && length()) setIsOpen(!isOpen);
        }}
      >
        <img
          src={ICONS?.addBGwhite}
          alt='add'
          className='w-[0.8vw] h-[0.8vw]'
        />
        <div className='font-md'>Add Button</div>
        <img
          src={ICONS?.CaretDown}
          alt='dropdown'
          className='w-[0.8vw] h-[0.8vw]'
        />
      </div>

      <ul
        className={`w-[12vw] h-[35vh] py-[1vw] rounded-[0.4vw] flex-col absolute bottom-[calc(100%+0.5vw)] left-0 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-[0.5vw] shadow-[0px_4px_30px_0px_#2D30361A] ${
          allow && isOpen ? 'flex' : 'hidden'
        }`}
      >
        <li className={`${container}`}>Quick reply buttons</li>
        {templateDetails?.category !== 'UTILITY' && (
          <li
            className={`${
              checkToAddOptout(buttons) ? '' : disabled
            } ${itemContainer}`}
            onClick={() => {
              if (allow && length() && checkToAddOptout(buttons))
                addButton('OPT_OUT', 'OPT_OUT', '', null, null, null);
            }}
          >
            <span className={`${itemTitle}`}>Marketing opt-out</span>
            <span className={`${itemContent}`}>Recommended</span>
          </li>
        )}
        <li
          className={`${itemContainer}`}
          onClick={() => {
            if (allow && length())
              addButton('CUSTOM', 'CUSTOM', '', null, null, null);
          }}
        >
          <span className={`${itemTitle}`}>Custom</span>
        </li>
        <li
          className={`${container} py-[1vw] border-t-[0.08vw] border-[var(border-50)]`}
        >
          Call-to-action buttons
        </li>
        <li
          className={`${itemContainer} ${
            allow && checkToAvailableToAdd('URL') ? disabled : ''
          }`}
          onClick={() => {
            if (allow && !checkToAvailableToAdd('URL'))
              addButton('URL', 'URL', '', '', null);
          }}
        >
          <span className={`${itemTitle}`}>Visit website</span>
          <span className={`${itemContent}`}>2 buttons maximum</span>
        </li>
        <li
          className={`${itemContainer}  ${
            allow && checkToAvailableToAdd('PHONE_NUMBER') ? disabled : ''
          }`}
          onClick={() => {
            if (allow && !checkToAvailableToAdd('PHONE_NUMBER'))
              addButton('PHONE_NUMBER', 'PHONE_NUMBER', '', null, null);
          }}
        >
          <span className={`${itemTitle}`}>Call Phone Number</span>
          <span className={`${itemContent}`}>1 buttons maximum</span>
        </li>
        <li className={`${disabled} ${itemContainer}`}>
          <span className={`${itemTitle}`}>Complete Form</span>
          <span className={`${itemContent}`}>1 buttons maximum</span>
        </li>
        <li
          className={`${itemContainer} !mb-0 ${
            allow && checkToAvailableToAdd('COPY_CODE') ? disabled : ''
          }`}
          onClick={() => {
            if (allow && !checkToAvailableToAdd('COPY_CODE'))
              addButton('COPY_CODE', 'COPY_CODE', null, null, '');
          }}
        >
          <span className={`${itemTitle}`}>Copy Offer Code</span>
          <span className={`${itemContent}`}>1 buttons maximum</span>
        </li>
      </ul>
    </div>
  );
}
