export const INITIAL_STATE = {
  name: '',
  category: '',
  description: '',
  address: '',
  email: '',
  website: [
    {
      id: 1,
      value: '',
    },
    {
      id: 2,
      value: '',
    },
  ],
};

export function profileReducer(state, action) {
  const { type, key, value, data, id } = action;

  console.log('hgfhgfc', type, key, value, data, id, state);

  switch (type) {
    case 'update':
      return { ...state, [key]: value };
    case 'addWebsite':
      return { ...state, website: [...state.website, data] };
    case 'updateWebsite':
      return {
        ...state,
        website: state.website.map((d) => {
          if (d.id === id) return { ...d, value: value };
          return d;
        }),
      };

    case 'reset':
      return INITIAL_STATE;
  }
}
