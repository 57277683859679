import React from 'react';

const Tag = ({ width = '16', height = '16', fill = '#616874', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...rest}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0554 8.22549C14.5154 8.68547 14.5154 9.43125 14.0554 9.89123L9.89104 14.0556C9.43106 14.5156 8.68528 14.5156 8.2253 14.0556L1.94538 7.77568C1.70225 7.53255 1.57749 7.19531 1.60387 6.85247L1.90132 2.98557C1.94588 2.40632 2.40613 1.94607 2.98537 1.90151L6.85228 1.60406C7.19511 1.57769 7.53236 1.70244 7.77549 1.94558L14.0554 8.22549ZM5.09648 4.10255C5.64876 4.09974 6.09874 4.54518 6.10155 5.09746C6.10436 5.64973 5.65892 6.09972 5.10665 6.10253L5.10238 6.10255C4.5501 6.10536 4.10012 5.65992 4.09731 5.10764C4.0945 4.55537 4.53993 4.10538 5.09221 4.10257L5.09648 4.10255Z"
        fill={fill}
      />
    </svg>
  );
};

export default Tag;
