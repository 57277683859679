import React from 'react';

export default function Map({
  width = '24',
  height = '24',
  color = '#2D3036',
  classname,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classname}
    >
      <circle
        cx='12'
        cy='11'
        r='2'
        stroke={color ?? '#2D3036'}
        stroke-width='2'
      />
      <path
        d='M12.0001 22L17.5001 16.5C20.5377 13.4624 20.5377 8.53757 17.5001 5.50001C14.4625 2.46244 9.53766 2.46244 6.5001 5.50001C3.46253 8.53757 3.46253 13.4624 6.5001 16.5L12.0001 22Z'
        stroke={color ?? '#2D3036'}
        stroke-width='2'
        stroke-linejoin='round'
      />
    </svg>
  );
}
