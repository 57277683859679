import React, { useRef, useState } from 'react';

// antd
import { Skeleton } from 'antd';

// components
import ConversationListCard from './CustomerPhoneNumberCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const CustomerPhoneNumber = ({
  primaryLoading,
  loading,
  searchKey,
  conversationList,
  selectedPerson,
  selectedConversationType,
  onSelectPerson,
  onConversationStarChange,
  onCloseConversation,
  count = 0,
  loadNextPage,
}) => {
  const conversationContainerRef = useRef();
  return (
    <div className='w-full h-[80vh] overflow-y-scroll border-t-[0.3vw] border-[var(--BG-25)]'>
      <Skeleton
        rootClassName='w-full px-[1vw]'
        loading={loading}
        paragraph={{ rows: 15 }}
        active
      >
        <div
          className='min-w-full h-full pr-[0.2vw] overflow-hidden overflow-y-scroll listScroll'
          id='scrollableDiv'
          ref={conversationContainerRef}
        >
          <InfiniteScroll
            dataLength={conversationList?.length}
            next={() => {
              if (conversationList?.length < count && !loading) loadNextPage();
            }}
            hasMore={conversationList?.length < count}
            scrollableTarget='scrollableDiv'
          >
            {conversationList?.length > 0 ? (
              conversationList?.map((item) => {
                const nameFilter =
                  searchKey &&
                  item?.name?.toLowerCase().includes(searchKey?.toLowerCase());
                return (
                  ((searchKey && nameFilter) || (!searchKey && item)) && (
                    <ConversationListCard
                      key={item?.to_number}
                      item={item}
                      selectedPerson={selectedPerson}
                      onSelectPerson={onSelectPerson}
                      onConversationStarChange={onConversationStarChange}
                      onCloseConversation={onCloseConversation}
                    />
                  )
                );
              })
            ) : (
              <div className='w-full h-full flex items-center justify-center text-sm weight-medium'>
                No Conversations
              </div>
            )}
          </InfiniteScroll>
        </div>
      </Skeleton>
    </div>
  );
};

export default CustomerPhoneNumber;
