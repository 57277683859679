import React, { useEffect, useState } from "react";

import { campaignFooter } from "../../../../constant/app/campaign/campaign";
import useAddTags from "../../../../hooks/useAddTags";

import { ColorPicker } from "antd";

function FooterSection({
  onColorChange,
  campaignColorError,
  campaign_color,
  item,
}) {
  const [color, setColor] = useState(null);

  useEffect(() => {
    if (item) {
      setColor(campaign_color);
    } else if (color) {
      setColor(campaign_color);
    }
  }, [campaign_color, color]);

  return (
    <div className="flex-column space-between mt-10">
      <div className="flex-column space-between mt-10">
        {campaignFooter?.map((item) => (
          <div key={item?.id} className="">
            <div className="createCampaignLabel w-100">
              <span>{item?.label}</span>
            </div>
            <div className="footerBox flex-row align-center mt-5">
              {item?.name === "Choose color" ? (
                <ColorPicker
                  value={campaign_color}
                  format={"RGB"}
                  showText={true}
                  disabledAlpha={true}
                  className="ant-picker-panel-footer"
                  footerBox={false}
                  arrow={false}
                  onChange={(newColor) => {
                    setColor(newColor);
                    onColorChange(newColor);
                  }}
                >
                  {color ? (
                    <span
                      style={{
                        background: color,
                        width: 26,
                        height: 26,
                        borderRadius: "50%",
                        marginLeft: "3px",
                      }}
                    />
                  ) : (
                    <div className="color-container flex-row">
                      <img
                        src={item?.icons}
                        alt={item?.name}
                        className="footerImgBig"
                      />
                    </div>
                  )}
                </ColorPicker>
              ) : (
                <p style={{ color: "red" }}></p>
              )}
              <span
                className="color-name flex-row align-center pl-10"
                style={{ fontSize: "0.75vw", fontWeight: 500 }}
              >
                {item?.name}
              </span>
            </div>
          </div>
        ))}
        <div className="explain-error pl-10">{campaignColorError}</div>
      </div>
    </div>
  );
}

export default FooterSection;
