const { default: AspApi } = require('api/ApiConfig');

export const createWABA = async (payload) => {
  const res = await AspApi.post(`api/v1/waba/create`, payload);
  return res;
};

export const retryCreateWABA = async (payload) => {
  const res = await AspApi.post(`api/v1/waba/retry`, payload);
  return res;
};
