import React, { useEffect, useState } from "react";

import "../../styles/Campaign.css";

import { images } from "../../../../assets/images";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import dayjs from "dayjs";
import Status from "../../../../components/commonComponents/tables/Status";
import { setPageNavigate } from "../../../../reduxToolkit/CampaignSlice";

const campaignType = {
  1: "Broadcast",
};

const CampaignHeader = ({ campaignDetails }) => {
  const location = useLocation();
  const { tabList, pageNavigate } = useAspSelector((state) => state.Campaign);

  const { id } = useParams();
  const header = id;
  const dispatch = useAspDispatch();

  const navigate = useNavigate();
  const handleBackClick = (header, item) => {
    const currentPath = window.location.pathname;
    if (
      pageNavigate === "All Campaigns" ||
      pageNavigate === "Broadcast Campaigns"
    ) {
      const basePath = `/user/campaign/broadcast/settings/${header}`;
      const backPath = `/user/campaign/broadcast/${header}`;

      if (currentPath.startsWith(basePath)) {
        navigate(backPath, { state: { item } });
      } else if (currentPath.startsWith(backPath)) {
        navigate("/user/campaign/all");
        dispatch(setPageNavigate("All Campaigns"));
      } else {
        navigate("/user/campaign/all");
      }
    } else {
      const basePath = `/user/campaign/api/${header}`;
      if (currentPath.startsWith(basePath)) {
        navigate("/user/campaign/api");
      }
    }
  };

  return (
    <>
      <div className="campaignHeader-container w-100 flex-row align-center">
        <div className="campaignHeader-wrapper flex-row  align-center">
          <div className="flex-row align-center mr-5 ml-5">
            <img
              src={images?.CampaignBackIcon}
              alt="backIcon"
              onClick={() => handleBackClick(header, campaignDetails)}
              className="mr-10 ml-10 pointer"
            />
          </div>

          <div className="border-left flex-column p-10">
            <div className="flex-row align-center ">
              <span
                className="campaignBorderLeft"
                style={{ background: campaignDetails?.campaign_color }}
              />
              <span className="campaignHeader-campaign">
                {campaignDetails?.campaign_name &&
                  campaignDetails.campaign_name.charAt(0).toUpperCase() +
                    campaignDetails.campaign_name.slice(1)}
              </span>
              <div className="Awareness flex-row align-center">
                <span className="">
                  {campaignType[campaignDetails?.campaign_type_id]}
                </span>
                <span className="pl-5 pr-5">
                  {pageNavigate === "API Campaigns" && "API"}
                </span>
              </div>
            </div>
            <div className="campaignUpdate flex-row ">
              <span>Last updated</span>
              <span className="pl-5">
                {dayjs(campaignDetails?.updated_at).format("MMM DD, hh:mm a")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-column ">
          {(location.pathname.includes("/user/campaign/broadcast/") ||
            location.pathname.includes("/user/campaign/api/")) && (
            <div className="campaign-status flex-row align-center ">
              <Status status={campaignDetails?.status} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignHeader;
