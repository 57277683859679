import React, { useEffect, useRef, useState } from 'react';

// assets
import Edit from '../../../../assets/customSVG/Edit';
import Close from '../../../../assets/customSVG/Close';
import Tick from '../../../../assets/customSVG/Tick';

// hooks
import useToggle from '../../../../hooks/useToggle';

export default function ProfileInfoCard({
  label,
  editable = true,
  value,
  icon = null,
  count = null,
  iconStyle = '',
}) {
  const [isEditOpen, setIsEditOpen] = useToggle(false);
  const [inputValue, setInputValue] = useState('');

  const editRef = useRef();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [value]);

  const onChange = (value) => {
    setInputValue(value);
  };

  const handleClickOutside = (e) => {
    if (editRef.current && !editRef.current.contains(e.target)) {
      setIsEditOpen(false);
      setInputValue(value);
    }
  };

  return (
    <div
      className={`w-full h-auto leading-[0.8vw] py-[0.8vw] flex flex-col justify-start ${
        editable ? 'group/card' : ''
      }`}
    >
      <label className='font-md weight-medium text-[var(--contentText)]'>
        {label}
      </label>
      <div
        className={`w-full h-[2vw] flex items-center justify-between gap-[0.5vw] font-md weight-medium text-[var(--font-600)] mt-[0.6vw] p-[0.3vw] pl-0 border-[0.08vw] group-hover/card:border-[var(--border-50)] rounded-[0.37vw] ${
          isEditOpen ? 'border-[var(--border-50)]' : 'border-transparent'
        }`}
        ref={editRef}
      >
        {typeof icon === 'string' ? (
          <img
            src={icon}
            alt='icon'
            className='size-[1.4vw] rounded-[0.37vw]'
          />
        ) : (
          icon
        )}
        <div className={`${icon ? 'w-[14vw]' : 'w-[16vw]'}`}>
          {isEditOpen ? (
            <input
              type='text'
              value={inputValue ?? ''}
              className={`w-full h-[1.8vw] pl-[0.5vw] text-[var(--contentText)]`}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              maxLength={count}
              autoFocus={isEditOpen}
            />
          ) : (
            <div className={`h-[2vw] flex items-center truncate text-ellipsis`}>
              {inputValue}
            </div>
          )}
        </div>
        {isEditOpen && (
          <div className='w-[3.3vw] h-[2vw] flex items-center justify-between gap-[0.3vw]'>
            <button
              className='w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100'
              onClick={() => {
                setIsEditOpen(false);
                setInputValue(value);
              }}
            >
              <Close
                stroke='#616874'
                circle={false}
                width='1.5vw'
                height='1.5vw'
              />
            </button>
            <button
              className='w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100'
              onClick={() => {
                setIsEditOpen(false);
              }}
            >
              <Tick width='0.8vw' height='0.8vw' strokeWidth='3.2' />
            </button>
          </div>
        )}

        {!isEditOpen && (
          <button
            className='w-[1.5vw] h-[1.45vw] cursor-pointer hover:bg-slate-100 rounded-[0.37vw] flex items-center justify-center opacity-0 group-hover/card:opacity-100'
            onClick={() => {
              setIsEditOpen(true);
              setInputValue(inputValue);
            }}
          >
            <Edit
              width='0.85vw'
              height='0.85vw'
              color={''}
              strokeWidth={'1.9'}
              className='stroke-[#898E99] group-hover/eye:stroke-[var(--contentText)]'
            />
          </button>
        )}
      </div>
    </div>
  );
}
