import React from 'react';

const twitter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10128_47196)">
        <mask
          id="mask0_10128_47196"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path d="M20 0H0V20H20V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_10128_47196)">
          <path
            d="M17.6562 0H2.34375C1.04933 0 0 1.04933 0 2.34375V17.6562C0 18.9507 1.04933 20 2.34375 20H17.6562C18.9507 20 20 18.9507 20 17.6562V2.34375C20 1.04933 18.9507 0 17.6562 0Z"
            fill="black"
          />
          <path
            d="M13.9039 3.90625H15.9714L11.4545 9.06875L16.7683 16.0938H12.6076L9.34889 11.8331L5.62014 16.0938H3.55141L8.38264 10.5719L3.28516 3.90625H7.55139L10.497 7.80062L13.9039 3.90625ZM13.1783 14.8563H14.3239L6.92889 5.07875H5.69951L13.1783 14.8563Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10128_47196">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default twitter;
