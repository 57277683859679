import React from 'react';

const linkedin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10128_47192)">
        <path
          d="M9.99998 0C4.47711 0 0 4.47711 0 9.99998C0 15.5229 4.47711 20 9.99998 20C15.5229 20 20 15.5229 20 9.99998C20 4.47711 15.5228 0 9.99998 0ZM7.33197 14.8345H5.13637V7.74116H7.33197V14.8345ZM6.2237 6.81224C5.50665 6.81224 4.92534 6.22619 4.92534 5.50339C4.92534 4.78051 5.50673 4.19451 6.2237 4.19451C6.94068 4.19451 7.52199 4.78051 7.52199 5.50339C7.52203 6.22623 6.94072 6.81224 6.2237 6.81224ZM15.56 14.8345H13.375V11.1111C13.375 10.0899 12.9871 9.51981 12.1796 9.51981C11.3008 9.51981 10.8416 10.1135 10.8416 11.1111V14.8345H8.7358V7.74116H10.8416V8.69652C10.8416 8.69652 11.4751 7.5248 12.9791 7.5248C14.4833 7.5248 15.56 8.4432 15.56 10.3431L15.56 14.8345Z"
          fill="url(#paint0_linear_10128_47192)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10128_47192"
          x1="2.92892"
          y1="2.92892"
          x2="17.071"
          y2="17.071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2489BE" />
          <stop offset="1" stopColor="#0575B3" />
        </linearGradient>
        <clipPath id="clip0_10128_47192">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default linkedin;
