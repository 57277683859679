import React, { useEffect, useState } from 'react';
import '../style/auth.css';
import { useNavigate } from 'react-router-dom';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';
import { currentUserDetails } from '../../../api/Api';
import { ICONS } from '../../../assets/icons';
import useToggle from '../../../hooks/useToggle';
import Loader from '../../../components/commonComponents/Loader/Index';
import {
  updateAccountDetails,
  updateCurrentAccount,
} from '../../../reduxToolkit/appSlice';

const Accounts = () => {
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useToggle(false);
  const { currentUser } = useAspSelector((state) => state.app);

  const dispatch = useAspDispatch();

  const fetchCurrentUserDetails = async () => {
    setLoading(true);
    try {
      const res = await currentUserDetails();
      if (res && res?.data && res?.data) {
        setLoading(false);
        setBrandList(res?.data?.active_accounts);
      } else {
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching current user details:', error);
    }
  };

  useEffect(() => {
    fetchCurrentUserDetails();
  }, []);

  const handleClick = (account) => {
    const data = {
      currentAccount: account,
      currentBrand: null,
    };
    if (account?.brands?.[0]) data['currentBrand'] = account?.brands?.[0];
    console.log('acc', data);
    dispatch(updateAccountDetails(data));
    if (account?.brands?.length > 0) navigate('/user/home');
    else navigate('/on-boarding');
  };

  return (
    <>
      {!loading ? (
        <div className='account-card-main-container w-60 flex-column space-between listScroll mt-6'>
          {brandList?.map((account) => (
            <div
              className='account-card-g flex-row align-center space-between pointer'
              onClick={() => handleClick(account)}
            >
              <div className=' flex-row align-center w-100 h-50 '>
                {/* <img src={ICONS?.Aachi} alt="Aachi" /> */}
                <div className='account-initial'>{account.account_name[0]}</div>
                <div className='account-card-titles-g flex-column ml-10'>
                  <span>{account?.account_name}</span>
                  <div className='account-card-content-g flex-row align-center mt-10'>
                    <img src={ICONS?.brands} alt='brands' />
                    <span>{account?.active_brand_counts} Brands</span>
                  </div>
                </div>
              </div>
              <img src={ICONS?.accountsArrow} alt='icon1' />
              <img src={ICONS?.accountsArrowHover} alt='icon2' />
            </div>
          ))}
        </div>
      ) : (
        <div style={{ marginTop: '30px' }}>
          <Loader
            Width='20px'
            Height='20px'
            loaderBg='4px solid white'
            loaderColor='4px solid var(--primary)'
          />
        </div>
      )}
    </>
  );
};

export default Accounts;
