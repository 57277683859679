import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authToken: null,
  signUp: {
    professionalIdentity: "",
    email: "",
    password: "",
    termsAndService: "",
    verifyCode: "",
    step: 0,
  },

  stepper: {
    stepper1: 0,
  },

  account: {
    account_name: "",
    account_number: "",
  },

  brand: {
    brandName: "",
    website: "",
    category: "",
    location: "",
    howDidYouKnow: "",
    age: "",
  },

  signUpInitial: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  },

  // password: "",
  resetPasswordEmail: "",
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    updateSignUpSteps: (state, action) => {
      state.signUp = action.payload;
    },
    updateResetPasswordEmail: (state, action) => {
      state.resetPasswordEmail = action.payload;
    },

    setStepper: (state, action) => {
      state.stepper = action.payload;
    },

    setAccount: (state, action) => {
      state.account = action.payload;
    },

    setBrand: (state, action) => {
      state.brand = action.payload;
    },

    setSignUpInitial: (state, action) => {
      state.signUpInitial = action.payload;
    },
    // setPassword: (state, action) => {
    //   state.password = action.payload;
    // },
  },
});

export const {
  updateAuthToken,
  updateSignUpSteps,
  updateResetPasswordEmail,
  setStepper,
  setAccount,
  setBrand,
  setSignUpInitial,
} = authSlice.actions;

export default authSlice.reducer;
