import React, { useState } from "react";
import "../../styles/Campaign.css";

import { images } from "../../../../assets/images";
import { ICONS } from "../../../../assets/icons";

import { Button } from "../../../../components/form/Button/Button";
import CampaignModal from "../../../../components/commonComponents/campaign-popup/CampaignModal";
import { Modal } from "antd";

const NoData = ({}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div className="no-data-container flex-column align-center">
      {isModalVisible && <div className="modal-overlay" />}

      <div className="no-data-wrapper  flex-column align-center justify-center">
        <img src={images?.noCampaign} alt="noCampaign" />
        <span>No Campaign</span>
        <p>
          No campaigns have been found. Click the button below to create one.
        </p>
        <div className="campaign-button pointer">
          <Button
            label={"Create Campaign"}
            size={"medium"}
            iconPrefix={ICONS.speakerphoneWhite}
            onClick={() => setIsModalVisible(true)}
          />
          <Modal
            open={isModalVisible}
            footer={null}
            className={"campaign-modal"}
            closable={false}
            centered={true}
            // onCancel={handleCancel}
            width="40%"
          >
            {
              <CampaignModal
                handleCancel={handleCancel}
                setIsModalVisible={setIsModalVisible}
              />
            }
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default NoData;
