import React, { useEffect, useState } from "react";

// uils
import { Skeleton } from "antd";

// hooks
import useToggle from "hooks/useToggle";
import useMonthCalender from "hooks/calenders/useMonthCalender";

// API
import { conversationMetrix } from "../api/APi";

// redux
import { useAspSelector } from "../../../test/jest-redux-hooks";

// components
import Select from "components/commonComponents/select/index";

// constants
const conversationOverview = [
  {
    id: 1,
    label: "All conversations",
    key: "all_conversation",
    color: "#5C3BBF",
  },
  {
    id: 2,
    label: "Marketing",
    key: "marketing",
    color: "#32CDCD",
  },
  {
    id: 3,
    label: "Utility",
    key: "utility",
    color: "#AC2F82",
  },
  {
    id: 4,
    label: "Service",
    key: "service",
    color: "#0ACD95",
  },
  {
    id: 5,
    label: "Free Tier",
    key: "free_tier",
    color: "#6940F2",
  },
];

export default function ConversationOverview() {
  // redux
  const { currentBrand } = useAspSelector((state) => state?.app);

  // hooks
  const {
    selectedMonth,
    getLastMonths,
    onChange: onMonthChange,
  } = useMonthCalender();

  const [isMetrix, setIsMetrix] = useToggle(false);
  const [metrixData, setMetrixData] = useState(null);

  useEffect(() => {
    if (selectedMonth)
      getConversationMetrix(currentBrand?.brand_id, {
        year: selectedMonth?.year,
        month: selectedMonth?.month + 1,
      });
  }, [selectedMonth, currentBrand]);

  const getConversationMetrix = async (brandId, query) => {
    setIsMetrix(true);
    if (brandId) {
      try {
        const res = await conversationMetrix(brandId, query);
        setMetrixData(res?.data);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsMetrix(false);
      }
    }
  };

  return (
    <div className="w-full h-full min-h-[24vw] max-h-[50%] bg-white rounded-[1vw] py-[1.2vw] overflow-hidden">
      <h1 className="text-[var(--font-600)] font-normal weight-semibold px-[1vw] pb-[1vw]">
        Conversation Overview
      </h1>
      <div className="w-full h-full overflow-hidden pr-[0.5vw] pb-[1vw]">
        <div className="w-full h-auto">
          <div className="px-[1vw]">
            <Select
              value={selectedMonth}
              options={getLastMonths()}
              position="bottom"
              labelClassName="font-medium"
              optionsClassName={"gap-[0.4vw] max-h-[13.5vw]"}
              rootClassName="!rounded-[0.6vw]"
              onChange={(val) => {
                onMonthChange(val);
              }}
            />
          </div>
          <Skeleton
            rootClassName="px-[0.8vw]"
            loading={isMetrix}
            paragraph={{ rows: 5 }}
            active
          >
            <div className="w-full h-full flex-column px-[0.5vw] mt-[0.6vw]">
              {!isMetrix &&
                conversationOverview?.map((list) => {
                  return (
                    <Card
                      key={list?.id}
                      data={list}
                      count={metrixData?.[list?.key]}
                    />
                  );
                })}
            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  );
}

function Card({ data, count }) {
  const { label, color } = data;

  return (
    <div className="w-auto h-[2.8vw] bg-[var(--white)] px-[0.85vw] py-[0.3vw] gap-[0.5vw]] flex items-center justify-between">
      <div className="flex items-center gap-[0.4vw]">
        <div
          className={`contents-[""] size-[0.6vw] rounded-[0.2vw]`}
          style={{
            background: color ?? "#f00",
          }}
        />
        <p className="font-md text-[#4C515B] weight-medium">{label}</p>
      </div>
      <div className="font-normal weight-bold">{count ?? 0}</div>
    </div>
  );
}
