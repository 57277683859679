import React, { useState } from 'react';

// assets
import Chevron from 'assets/customSVG/Chevron';

// utils
import { useNavigate } from 'react-router-dom';

// redux
import { useAspSelector } from 'test/jest-redux-hooks';

export default function SubSideBar({
  isListShow = true,
  list,
  activeNav = '',
  onSelect,
  color,
}) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const isSubmenuOpen = useAspSelector((state) => state.Channel.isSubmenuOpen);
  const { currentBrand } = useAspSelector((state) => state.app);

  const [toggle, setToggle] = useState(false);
  const handleItemClick = (item) => {
    if (item.subMenu) {
      onSelect(item);
      item.link && navigate(item.link);

      if (selectedItem === item) {
        setSelectedItem(null);
        setToggle(true);
      } else {
        setSelectedItem(item);
        setToggle(false);
      }
    } else {
      onSelect(item);
      item.link && navigate(item.link);

      setSelectedItem(item);
      setToggle(false);
    }
  };

  return (
    <div className='w-[15vw] h-full border-[0.08vw] border-[var(--layoutBorder)] bg-[var(--background)] px-[1.2vw] py-[0.8vw]'>
      {list?.map((item) => (
        <div className='flex-column justify-center' key={item?.id}>
          <div
            className={`w-full font-normal weight-medium my-[0.5vw] tracking-wide text-[var(--text-black)] cursor-pointer flex items-center justify-between ${
              !isListShow
                ? ' !bg-[#0ACD95] weight-medium rounded-[0.6vw] py-[0.4vw]'
                : 'py-[0.7vw]'
            }`}
            onClick={() => setToggle(!toggle)}
          >
            <div
              className={`${
                isListShow ? '!text-[#0ACD95]' : '!text-[var(--white)] pl-[1vw]'
              }`}
              onClick={() => handleItemClick(item)}
            >
              {item?.name}
            </div>
            {isSubmenuOpen === false && item?.icon1 && isListShow && (
              <Chevron
                className={`transition-transform duration-300 ease-linear ${
                  toggle ? 'rotate-0' : 'rotate-180'
                }`}
                color={activeNav === item?.name ? 'white' : '#0ACD95'}
                width={'1.1vw'}
                height={'1.1vw'}
              />
            )}
          </div>

          {isSubmenuOpen === false && !toggle && isListShow && (
            <div className='flex-column gap-[0.7vw] font-normal weight-normal'>
              {item?.subMenu?.map((subItem) => (
                <div
                  className={`w-full rounded-[0.6vw] px-[1vw] py-[0.4vw] flex-row align-center space-between cursor-pointer ${
                    activeNav === subItem?.name
                      ? 'text-[var(--white)] !bg-[#0ACD95] weight-medium'
                      : 'hover:bg-slate-100 text-[var(--font-400)] hover:text-[var(--contentBlack)] hover:weight-medium'
                  }`}
                  key={subItem?.id}
                  onClick={() => handleItemClick(subItem)}
                >
                  {subItem?.name}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
