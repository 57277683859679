import React from 'react';

// assets
import { ICONS } from 'assets/icons';
import Delete from 'assets/customSVG/Delete';

// constant
import { BUTTONS_LIST } from 'constant/app/channel/templates';
import { isValidHttpsUrl } from 'helper/checkUrls';
import { countryList } from 'constant/app/countryList';

// utils
import { optionConvertion } from './utils';

// hooks
import useToggle from 'hooks/useToggle';

// components
import BtnInput from './BtnInput';
// import Select from 'components/form/select/selectCustom';
import Select from 'components/commonComponents/select/index';
import CountryCode from 'components/commonComponents/countryCode/index';

export default function CTAButton({
  data,
  index,
  checkToAvailableToAdd,
  updateButtons,
  allow = true,
}) {
  const [urlError, setUrlError] = useToggle(false);

  let optionsList = BUTTONS_LIST.map((l) => {
    if (checkToAvailableToAdd(l.label)) return { ...l, disabled: true };
    return l;
  });

  const urlOptionList = [
    {
      label: 'static',
      value: 'Static',
    },
    {
      label: 'dynamic',
      value: 'Dynamic',
    },
  ];

  const initialData = {
    PHONE_NUMBER: {
      type: 'PHONE_NUMBER',
      format: 'PHONE_NUMBER',
      text: '',
      phone_number: '',
    },
    URL: {
      type: 'URL',
      format: 'URL',
      text: '',
      url: '',
    },
    COPY_CODE: {
      type: 'COPY_CODE',
      format: 'COPY_CODE',
      text: 'Copy offer code',
      example: [''],
    },
  };

  const number =
    data?.phone_number?.length >= 13
      ? data?.phone_number?.slice(3)
      : data?.phone_number;

  return (
    <>
      <div className='w-full h-auto flex items-center justify-between gap-[0.6vw]'>
        <div className='w-[10.3vw] h-[2vw]'>
          <Select
            options={optionsList}
            value={optionConvertion[data?.type] ?? 'Visit Website'}
            dropdownIcon={ICONS?.SelectDropdown}
            wrapperClassName='!border-[0.08vw] !border-[var(--card-border)]'
            parentName='template'
            position='top'
            onChange={(item) => {
              if (allow)
                updateButtons({
                  updatevalue: 'replace',
                  id: data?.id,
                  btnObj: initialData[item?.label],
                });
            }}
          />
        </div>
        {data?.type !== 'COPY_CODE' && (
          <div className='w-[9.5vw]'>
            <BtnInput
              value={data?.text ?? ''}
              count={25}
              onChange={(value) => {
                if (allow)
                  updateButtons({
                    updatevalue: 'update',
                    id: data?.id,
                    key: 'text',
                    value: value,
                  });
              }}
            />
          </div>
        )}
        {data.type !== 'COPY_CODE' && (
          <div className='w-[6.5vw] h-[2vw]'>
            {data?.type === 'PHONE_NUMBER' ? (
              <CountryCode
                value={'+91'}
                options={countryList}
                onChange={(val) => {}}
              />
            ) : (
              // <Select
              //   style={{
              //     color: 'var(--textBlack)',
              //     fontSize: '0.75vw',
              //     fontWeight: '600',
              //   }}
              //   dropdownIcon={ICONS?.SelectDropdown}
              //   value={'Static'}
              //   options={data?.type === 'URL' ? urlOptionList : []}
              //   onChange={(val) => {}}
              // />
              <div className='w-full h-full font-md weight-medium flex items-center border-[0.08vw] border-[var(--border-100)]  pl-[0.4vw] py-[0.4vw] rounded-[0.4vw]'>
                Static
              </div>
            )}
          </div>
        )}
        {data?.type === 'COPY_CODE' && (
          <div className='w-[9.5vw] h-full bg-[#F4F6F8] px-[0.7vw] border-[0.08vw] py-[0.3vw] font-md weight-semibold border-[var(--card-border)] rounded-[0.4vw]'>
            {'Copy Offer Code'}
          </div>
        )}
        <div
          className={`${
            data?.type === 'COPY_CODE' ? 'w-[19.55vw]' : 'w-[12.5vw]'
          }`}
        >
          <BtnInput
            type={data?.type === 'PHONE_NUMBER' ? 'number' : 'text'}
            value={
              data?.type === 'COPY_CODE'
                ? data?.example?.[0]
                : data?.type === 'PHONE_NUMBER'
                ? data?.phone_number
                : data?.url ?? ''
            }
            error={urlError}
            count={
              data?.type === 'COPY_CODE'
                ? 15
                : data?.type === 'PHONE_NUMBER'
                ? 20
                : 2000
            }
            placeholder={
              data?.type === 'COPY_CODE'
                ? 'Enter sample'
                : data?.type === 'PHONE_NUMBER'
                ? '000 000 0000'
                : 'https://www.example.com'
            }
            onChange={(value) => {
              if (allow)
                if (data?.type === 'COPY_CODE') {
                  updateButtons({
                    updatevalue: 'update',
                    id: data?.id,
                    key: 'example',
                    value: [value],
                  });
                } else if (data?.type === 'PHONE_NUMBER') {
                  updateButtons({
                    updatevalue: 'update',
                    id: data?.id,
                    key: 'phone_number',
                    value: value,
                  });
                } else {
                  updateButtons({
                    updatevalue: 'update',
                    id: data?.id,
                    key: 'url',
                    value: value,
                  });
                  if (!isValidHttpsUrl(value)) setUrlError(true);
                  else setUrlError(false);
                }
            }}
            onBlur={() => {
              if (allow && data?.type === 'URL') {
                if (!isValidHttpsUrl(data?.url)) setUrlError(true);
                else setUrlError(false);
              }
            }}
          />
        </div>
        <button
          className={`w-[1.5vw] h-[1.5vw] group bg-transparent flex items-center justify-center rounded-[0.4vw] ${
            allow ? 'hover:bg-red-200' : ''
          }`}
          onClick={() => {
            if (allow)
              updateButtons({
                updatevalue: 'remove',
                id: data?.id,
              });
          }}
        >
          <Delete
            width='1.1vw'
            height='1.1vw'
            className={`stroke-[#898E99] ${
              allow ? ' group-hover:stroke-red-500' : ''
            }`}
            color=''
          />
        </button>
      </div>
      {data?.format === 'URL' && urlError && (
        <p className='font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw] px-[0.5vw]'>
          Enter a valid URL.
        </p>
      )}
    </>
  );
}
