import React from "react";
import FileDownloadView from "./FileDownloadView.jsx";

const SegmentDataFile = ({segment, response}) => {

  return (
    <div className="Broadcast-segments ">
      <div className="segment-header w-100 flex-row space-between align-center">
        <div className="w-70 flex-column">
          <p className="def">Define Audience</p>
          <span>
            Upload a single audience list or combine multiple lists to tailor
            your message to specific groups
          </span>
        </div>
      </div>

      <FileDownloadView  segment={segment} response={response}/>
    </div>
  );
};

export default SegmentDataFile;
