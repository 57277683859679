import React, { useEffect } from 'react';

// antd
import { Skeleton } from 'antd';

// components
import PhoneNumbersCard from './PhoneNumbersCard';

export default function SideBar({
  loading,
  list,
  selectedPhoneNumber,
  handleSelect,
  className,
}) {
  useEffect(() => {
    if (list?.length > 0) {
      handleSelect(list?.[0]);
    }
  }, [list]);

  return (
    <div
      className={`w-[17.2vw] h-full overflow-y-scroll bg-[var(--background)] border-[var(--layoutBorder)] px-[1vw] py-[0.5vw] ${className}`}
    >
      <Skeleton
        rootClassName='w-[12.8vw]'
        loading={loading}
        paragraph={{ rows: 15 }}
        active
      >
        {list?.map((item) => {
          return (
            <PhoneNumbersCard
              data={item}
              selectedPhoneNumber={selectedPhoneNumber}
              key={item?.id}
              handleSelect={handleSelect}
            />
          );
        })}
      </Skeleton>
    </div>
  );
}
