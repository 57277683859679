import React from 'react';

const index = ({ className }) => {
  return (
    <span
      className={`transition-all block border-2 border-transparent rounded-full  border-y-white size-5 m-auto ${className}`}
    ></span>
  );
};

export default index;
