import React from 'react';

// assets
import { images } from 'assets/images';

// constants
const aboutCardsList = [
  {
    id: 1,
    title: 'Advantages of generative AI',
    image: images?.About1,
    content: `Generative AI offers a wide range of advantages that can benefit various industries and applications. Here are some key benefits to consider:`,
  },
  {
    id: 2,
    title: 'Email vs WhatsApp marketing. which is best for B2C?',
    image: images?.About2,
    content: `Both email and WhatsApp marketing have their advantages and disadvantages for B2C communication, and the "best" choice depends on your specific goals and target audience. Here's a breakdown to help you decide:`,
  },
  {
    id: 3,
    title: 'Effect of WhatsApp on D2C business',
    image: images?.About3,
    content: `WhatsApp can have a significant positive impact on D2C (direct-to-consumer) businesses. Here's a breakdown of its effects on various aspects:`,
  },
];

export default function AboutCardsContainer() {
  return (
    <div className='w-full h-[15vw] bg-white rounded-[1vw] px-[2vw] py-[1vw]'>
      <div className='flex items-center justify-between'>
        <h1 className='text-[var(--font-600)] font-normal weight-semibold'>
          Get to know more about GainWix.ai
        </h1>
        <a
          href='https://gainwix.ai/'
          target='_blank'
          className='font-md weight-semibold text-[var(--font-600)] hover:text-[var(--BrandLink)] transition-colors duration-200 ease-linear'
        >
          See all
        </a>
      </div>

      <div className='flex items-start justify-between pt-[0.7vw] gap-[1.2vw]'>
        {aboutCardsList?.map((list) => {
          return (
            <a key={list.id} href='https://gainwix.ai/' target='_blank'>
              <Card data={list} />
            </a>
          );
        })}
      </div>
    </div>
  );
}

const Card = ({ data }) => {
  const { id, title, image, content } = data;
  return (
    <div className='w-[19vw] h-[10vw] border-[0.07vw] border[var(--BG-50)] relative rounded-[1vw] overflow-hidden cursor-pointer group'>
      <div className='w-full h-[7.45vw] overflow-hidden'>
        <img
          src={image}
          alt={`about images ${id}`}
          className='w-full h-full object-cover'
        />
      </div>
      <div className='w-full h-[5vw] translate-y-[2.7vw] absolute bottom-0 left-0 flex flex-col justify-center py-[0.5vw] px-[1vw] bg-[var(--white)] group-hover:translate-y-0 transition-transform duration-200 ease-linear'>
        <h3 className='font-normal weight-semibold text-ellipsis line-clamp-1'>
          {title}
        </h3>
        <p className='font-md weight-medium mt-[0.3vw] text-ellipsis line-clamp-2'>
          {content}
        </p>
      </div>
    </div>
  );
};
