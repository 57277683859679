import React, { useState } from "react";
import { Pagination, Popover, Select } from "antd";

import RadioButton from "../../../../../components/form/radio/RadioButton";
import { ICONS } from "../../../../../assets/icons";

const pageSizeOptions = [
  { id: 1, value: "10" },
  { id: 2, value: "25" },
  { id: 3, value: "50" },
  { id: 4, value: "100" },
  { id: 5, value: "1000" },
];

const Paginations = ({
  handlePageChange,
  page,
  count,
  pageSize,
  handleContactClick1,
  contactDetails,
  contactList,
}) => {
  const [inputPage, setInputPage] = useState("");
  const [popover, setPopover] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const maxPages = Math.ceil(count / pageSize);

    if (value === "" || parseInt(value) <= maxPages) {
      setInputPage(value);
      handlePageChange(e.target.value);
    }
  };

  const handleJumpToPage = (e) => {
    if (e.key === "Enter") {
      const pageNum = parseInt(e.target.value);
      const maxPages = Math.ceil(count / pageSize);

      if (pageNum > 0 && pageNum <= maxPages) {
        handlePageChange(e.target.value);
      }
    }
  };

  const handleContactClick = (item) => {
    handleContactClick1(item);
    setPopover(false);
  };

  const ContactData = ({ setContactList }) => {
    return (
      <div className="page-popover filter pointer">
        {setContactList.map((item, index) => {
          return (
            <div
              className="flex-row w-100 space-between align-center"
              key={item.id || index}
              onClick={() => handleContactClick(item)}
              style={{
                borderBottom:
                  index !== setContactList.length - 1
                    ? "1px solid var(--font-50)"
                    : "none",
              }}
            >
              <p> {item.value}</p>
              <div
                className=""
                style={{
                  transform: "translate(3px, 3px)",
                }}
              >
                {contactDetails?.value === item?.value && (
                  <RadioButton
                    selected={contactDetails?.value === item?.value}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="custom-pagination flex-row align-center">
      <div className="flex-row align-center">
        <p>Rows per page</p>

        <div className="select2 flex-row space-between ">
          <Popover
            placement="bottom"
            content={<ContactData setContactList={pageSizeOptions} />}
            trigger="click"
            open={popover}
            onOpenChange={(open) => setPopover(open)}
          >
            <div className="w-[10vw] flex-row space-between pointer">
              <p className="page-tag">{contactDetails?.value || pageSize}</p>

              <img src={ICONS.BroadcastChevronDownIcon} alt="Chevron" />
            </div>
          </Popover>
        </div>
      </div>
      <Pagination
        total={count}
        showSizeChanger={false}
        pageSize={pageSize}
        current={page}
        onChange={handlePageChange}
      />
      <div className="quick-jumper pl-10">
        <span>Go to</span>
        <input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          onKeyDown={handleJumpToPage}
          onWheel={(e) => e.target.blur()}
          className="no-arrows"
        />
      </div>
    </div>
  );
};

export default Paginations;
