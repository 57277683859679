import React, { useEffect, useState } from 'react';

// assets
import { ICONS } from '../../../assets/icons';

// hooks
import useToggle from '../../../hooks/useToggle';

// components
import TitleAndDescription from '../Title/TitleAndDescription';
import Radio from '../../../components/form/radio/RadioButton';

const CopyLink = ({ text }) => {
  const [selected, setSelected] = useToggle(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (selected) setSelected(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [selected]);

  const copyToClipboard = () => {
    setSelected(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <div className='flex items-center justify-between'>
      <TitleAndDescription size={'small'} gap={'2px'} title={text} />
      <div className='flex items-center'>
        {selected ? (
          <div className='size-[1.5vw] flex items-center justify-center cursor-not-allowed'>
            <img
              src={ICONS?.Selected}
              alt='selected'
              className='size-[1.2vw]'
            />
          </div>
        ) : (
          <div
            className={`size-[1.5vw] flex items-center justify-center cursor-pointer rounded-full hover:bg-gray-200`}
          >
            <img
              className='size-[1vw]'
              onClick={() => {
                if (!selected) copyToClipboard();
              }}
              src={ICONS?.CopyText}
              alt='copyLink'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CopyLink;
