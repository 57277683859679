import React, { useEffect, useState } from 'react';
import './appLayout.css';

import { Outlet, useNavigate } from 'react-router-dom';

import SideBar from './SideBar';
import Header from './Header';
// import Create from '../../components/commonComponents/create/Index';
import updateToken from '../../api/updateToken';

function Index({ children }) {
  const navigate = useNavigate();

  const TOKEN = localStorage.getItem('authToken');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNavigate, setPageNavigate] = useState('');

  useEffect(() => {
    if (TOKEN) {
      // navigate("/Accounts");
      updateToken(TOKEN);
      localStorage.removeItem('verify-email');
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <>
      {isModalVisible && <div className='modal-overlay' />}
      <div className='appWrapper'>
        <Header />
        <section>
          <SideBar
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            activeNav={pageNavigate}
          />
          <div className='mainLayout'>
            <Outlet />
            {children}
            <div className='campaignCreateWrapper'>
              <div className='CreateButtonWrapper flex-column space-between align-right'>
                {/* <Create /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Index;
