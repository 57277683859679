export const numberStatus = [
  {
    id: 1,
    status: 'Active',
  },
  {
    id: 2,
    status: 'Being Reviewed',
  },
  {
    id: 3,
    status: 'Pending',
  },
  {
    id: 4,
    status: 'Offline',
  },
  {
    id: 5,
    status: 'Warned',
  },
  {
    id: 6,
    status: 'Banned',
  },
  {
    id: 7,
    status: 'Connected',
  },
  {
    id: 8,
    status: 'Unavailable',
  },
  {
    id: 9,
    status: 'Message Limit Reached',
  },
  {
    id: 10,
    status: 'Pending',
  },
];

export const TemplateLeftSideList = [
  // {
  //   id: 1,
  //   name: 'Phone Number',
  // },
  {
    id: 1,
    name: 'Display Name',
  },
  {
    id: 2,
    name: 'Status',
  },
  {
    id: 3,
    name: 'Quality Rating',
  },
  {
    id: 4,
    name: 'Messaging Limit',
  },
  {
    id: 5,
    name: 'Country',
  },
];
