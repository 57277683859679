import React from 'react';

export default function FullArrow({
  width,
  height,
  color = '#616874',
  strokeWidth = '2',
  className = '',
}) {
  return (
    <svg
      width={width ?? '12'}
      height={height ?? '12'}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M6.83333 1.625L11 6M11 6L6.83333 10.375M11 6L1 6'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
