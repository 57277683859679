const variableRegEx = /{{(.*?)}}/g;

export const removeDoubleStyles = (str) => {
  if (str)
    return str
      ?.replace(/\*\*(.*?)\*\*/g, '*$1*')
      ?.replace(/__(.*?)__/g, '_$1_')
      ?.replace(/~~(.*?)~~/g, '~$1~');

  return '';
};

export const replaceFormating = (str) => {
  if (str) {
    const filterBold = str.match(/\*\*(.*?)\*\*/g);
    const filterItalic = str.match(/\__(.*?)_\_/g);
    const filterStrik = str.match(/\~~(.*?)~\~/g);

    if (filterBold?.length > 0)
      str = str.replaceAll(/\*\*(.*?)\*\*/g, `<b>*$1*</b>`);
    else str = str.replaceAll(/\*(.*?)\*/g, `<b>$1</b>`);
    if (filterItalic?.length > 0)
      str = str.replaceAll(/\__(.*?)_\_/g, `<i>_$1_</i>`);
    else str = str.replaceAll(/_(.*?)_/g, `<i>$1</i>`);
    if (filterStrik?.length > 0)
      str = str.replaceAll(/\~\~(.*?)\~\~/g, `<s>~$1~</s>`);
    else str = str.replaceAll(/~(.*?)~/g, `<s>$1</s>`);

    return str.replaceAll(/\\/g, '');
  }

  return null;
};

export const addLineBreake = (str) => str?.replaceAll(/\n/g, '<br/>');
export const checkMultipleNewLines = (str) => str?.match(/\n{2,}(?!\S)/);

export const checkVariable = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const replaceVariables = (str, value) => {
  const res = checkVariable(str);

  res?.map((r, i) => {
    str = str?.replaceAll(r, value?.[i]);
  });

  return str;
};
