import React from 'react';

const DocSvg = ({ width, height, color, bgColor }) => {
  return (
    <svg
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.40057 7.20002H15.6006M8.40057 10.8H15.6006M8.40057 14.4H12.0006M6.60029 2.40002H17.4005C18.726 2.40002 19.8005 3.47457 19.8005 4.80007L19.8002 19.2001C19.8002 20.5255 18.7257 21.6 17.4002 21.6L6.60019 21.6C5.2747 21.6 4.20019 20.5254 4.2002 19.2L4.20029 4.80001C4.2003 3.47453 5.27481 2.40002 6.60029 2.40002Z'
        // stroke='#2D3036'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default DocSvg;
