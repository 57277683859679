import { Popover } from 'antd';
import ModelContent from '../modal/ModelContent';

const Model = ({
  headerLabel,
  sideNavLabel,
  open,
  onOpenChange,
  Button,
  ModelSideNav,
  mainClassName = '', // to style the model content main div
  sideBarMainClassName = '',
  rootClass = '',
  onClick,
  children,
  ...rest
}) => {
  const isSideNavPresent = sideNavLabel || ModelSideNav;
  return (
    <Popover
      open={open}
      arrow={false}
      trigger={'click'}
      placement="rightTop"
      onOpenChange={onOpenChange}
      content={
        <ModelContent
          showSidebar={isSideNavPresent}
          sideNavLabel={sideNavLabel}
          ModelSideNav={ModelSideNav}
          headerLabel={headerLabel}
          Toggle={onClick}
          children={children}
          mainClassName={mainClassName}
          rootClassName={rootClass}
          sideBarMainClassName={sideBarMainClassName}
          {...rest}
        />
      }
    >
      {Button}
    </Popover>
  );
};

export default Model;
