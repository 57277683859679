import React from 'react';

const DefaultAvatar = ({ width = '40', height = '40', fill = '#D1D3D8' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10444_71882)">
        <path
          d="M19.9785 0.217773C9.06649 0.217773 0.220703 9.06356 0.220703 19.9756C0.220703 30.8876 9.06649 39.7334 19.9785 39.7334C30.8905 39.7334 39.7363 30.8876 39.7363 19.9756C39.7363 9.06356 30.8905 0.217773 19.9785 0.217773ZM19.9785 9.88022C23.8857 9.88022 27.065 13.0595 27.065 16.9667C27.065 20.8743 23.8857 24.0532 19.9785 24.0532C16.0709 24.0532 12.892 20.8743 12.892 16.9667C12.892 13.0595 16.0709 9.88022 19.9785 9.88022ZM19.9785 37.0301C15.0895 37.0301 10.7042 34.8853 7.70364 31.4878C9.09854 28.9632 11.2794 26.8882 13.8725 25.6303C14.7545 25.2026 15.7841 25.2096 16.6978 25.6483C17.7266 26.1426 18.8304 26.3929 19.9789 26.3929C21.1271 26.3929 22.2313 26.1422 23.2601 25.6483C24.1746 25.2092 25.2042 25.2026 26.0854 25.6303C28.6785 26.8877 30.8589 28.9632 32.2538 31.4878C29.2528 34.8848 24.8675 37.0301 19.9785 37.0301Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_10444_71882">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DefaultAvatar;
