import React from 'react';

const store = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75974 8.50098C4.56173 8.50138 4.37193 8.58018 4.23177 8.72016C4.09162 8.86015 4.01251 9.04994 4.01172 9.2481V15.2515C4.01172 16.4853 5.0258 17.4987 6.25872 17.4987H13.7551C14.988 17.4987 16.0021 16.4853 16.0021 15.2515V9.2481C16.0013 9.04994 15.9222 8.86015 15.782 8.72016C15.6419 8.58018 15.452 8.50138 15.254 8.50098H4.75974Z"
        fill="#82BBFB"
      />
      <path
        d="M7.01401 2.49805C5.80892 2.49805 4.71059 3.20417 4.21368 4.30286L3.55055 5.77074C2.91789 6.31741 2.50391 7.12424 2.50391 8.02969C2.50391 9.67781 3.85649 11.0314 5.50332 11.0314C6.45568 11.0314 7.20426 10.5185 7.75471 9.8184C8.30516 10.5187 9.05948 11.0314 10.012 11.0314C10.9599 11.0314 11.7083 10.5232 12.259 9.82865C12.8096 10.5234 13.5564 11.0314 14.5045 11.0314C16.1513 11.0314 17.5039 9.67781 17.5039 8.02969C17.5039 7.14585 17.1011 6.36831 16.4953 5.82348L15.7546 4.18127C15.2912 3.1566 14.2685 2.49805 13.1446 2.49805H7.01401Z"
        fill="#007FF9"
      />
      <path
        d="M10.0131 12.249C8.7802 12.249 7.76172 13.2624 7.76172 14.4963V16.7508C7.76251 16.9497 7.84223 17.1402 7.98333 17.2803C8.12443 17.4204 8.31536 17.4987 8.51413 17.4979H11.5135C11.7113 17.4971 11.9007 17.4182 12.0406 17.2782C12.1804 17.1383 12.2593 16.9487 12.2601 16.7508V14.4963C12.2601 13.2624 11.246 12.249 10.0131 12.249Z"
        fill="#007FF9"
      />
    </svg>
  );
};

export default store;
