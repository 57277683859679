import React, { useEffect, useState } from 'react';

export default function TemplateFooter({
  footer,
  dispatch,
  disabled,
  allow,
  text,
  setText,
}) {
  return (
    <div className=''>
      <div>
        <h3 className='weight-semibold text-[var(--contentText)] flex items-center gap-[0.2vw]'>
          <span className='font-default'>Footer</span>
          <span className='font-md'>(Optional)</span>
        </h3>
        <p className='w-11/12 font-md weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.5vw]'>
          Add a short line of text to the bottom of your message template. If
          you add the marketing opt-out button, the associated footer will be
          shown here by default.
        </p>
      </div>
      <div
        className={`w-full h-[2.23vw] flex items-center font-md weight-medium border-[0.08vw] border-[var(--card-border)] px-[0.5vw] py-[0.3vw] rounded-[0.4vw] mt-[1.5vw] ${
          disabled
            ? 'bg-[var(--BG-25)] text-[var(--font-600)] cursor-not-allowed'
            : 'bg-transparent cursor-text'
        }`}
      >
        {disabled ? (
          <p className='w-full font-md weight-smal px-[0.5vw] text-[var(--font-600)] py-[0.5vw]'>
            Not interested? Tap Stop promotions
          </p>
        ) : (
          <input
            type='text'
            className={`w-[95%] font-md weight-small ${
              !allow || (allow && disabled)
                ? 'cursor-not-allowed'
                : 'cursor-text'
            }`}
            maxLength={60}
            value={text}
            placeholder='Footer message'
            disabled={disabled}
            onChange={(e) => {
              if (allow) {
                setText(e.target.value);
                dispatch({
                  type: 'footer',
                  text: e.target.value,
                });
              }
            }}
          />
        )}
        <div
          className={`w-[5%] flex items-center justify-end font-sm weight-medium text-[var(--font-400)] mt-[0.3vw] text-center align-middle`}
        >
          {footer?.text ? footer?.text?.length : 0}/60
        </div>
      </div>
    </div>
  );
}
