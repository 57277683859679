import React from 'react';

const instagram = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10128_47187)">
        <path
          d="M1.24979 1.36059C-0.321875 2.99309 -0.000208687 4.72725 -0.000208687 9.99559C-0.000208687 15.2756 -0.131875 16.2448 0.501458 17.5581C1.03062 18.6564 2.04146 19.4814 3.23146 19.7889C4.18479 20.0339 4.81812 19.9998 9.98146 19.9998C14.3098 19.9998 15.6565 20.0773 16.779 19.7873C18.4423 19.3581 19.7956 18.0089 19.9806 15.6564C20.0065 15.3281 20.0065 4.66892 19.9798 4.33392C19.7831 1.82809 18.2406 0.383921 16.2081 0.0914205C15.7415 0.0239205 15.6481 0.0039205 13.2581 -0.000246167C4.78062 0.0039205 2.92229 -0.37358 1.24979 1.36059Z"
          fill="url(#paint0_linear_10128_47187)"
        />
        <path
          d="M9.99883 2.61553C6.973 2.61553 4.09966 2.34636 3.00216 5.16303C2.54883 6.32636 2.61466 7.83719 2.61466 10.0005C2.61466 11.8989 2.55383 13.683 3.00216 14.8372C4.09716 17.6555 6.99383 17.3855 9.99716 17.3855C12.8947 17.3855 15.8822 17.6872 16.993 14.8372C17.4472 13.6622 17.3805 12.1739 17.3805 10.0005C17.3805 7.11553 17.5397 5.25303 16.1405 3.85469C14.7238 2.43803 12.808 2.61553 9.9955 2.61553H9.99883ZM9.33716 3.94636C12.9588 3.94053 15.8463 3.44052 16.0088 7.01553C16.0688 8.33969 16.0688 11.658 16.0088 12.9822C15.8513 16.4297 13.2263 16.0514 9.99966 16.0514C7.15633 16.0514 5.74633 16.1522 4.79633 15.2014C3.83216 14.2372 3.94716 12.8589 3.94716 9.99719C3.94716 6.60469 3.62633 4.14219 7.01633 3.98636C7.69716 3.95553 7.96133 3.94636 9.33716 3.94469V3.94636ZM13.9405 5.17219C13.4513 5.17219 13.0547 5.56886 13.0547 6.05803C13.0547 6.54719 13.4513 6.94386 13.9405 6.94386C14.4297 6.94386 14.8263 6.54719 14.8263 6.05803C14.8263 5.56886 14.4297 5.17219 13.9405 5.17219ZM9.99883 6.20803C7.90466 6.20803 6.20716 7.90636 6.20716 10.0005C6.20716 12.0947 7.90466 13.7922 9.99883 13.7922C12.093 13.7922 13.7897 12.0947 13.7897 10.0005C13.7897 7.90636 12.093 6.20803 9.99883 6.20803ZM9.99883 7.53886C11.358 7.53886 12.4597 8.64136 12.4597 10.0005C12.4597 11.3597 11.358 12.4622 9.99883 12.4622C8.63966 12.4622 7.538 11.3597 7.538 10.0005C7.538 8.64053 8.63966 7.53886 9.99883 7.53886Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10128_47187"
          x1="1.30359"
          y1="18.7025"
          x2="19.8685"
          y2="2.6341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDD55" />
          <stop offset="0.5" stopColor="#FF543E" />
          <stop offset="1" stopColor="#C837AB" />
        </linearGradient>
        <clipPath id="clip0_10128_47187">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default instagram;
