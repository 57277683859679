import React from 'react';

export default function Send({ width, height, stroke, strokeWidth }) {
  return (
    <svg
      width={width ?? '19'}
      height={height ?? '18'}
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 9L2.02785 4.75066C1.79729 2.6756 3.93292 1.15243 5.81977 2.04621L15.4123 6.59003C17.4458 7.55325 17.4458 10.4467 15.4123 11.41L5.81977 15.9538C3.93292 16.8476 1.79729 15.3244 2.02785 13.2493L2.5 9ZM2.5 9H9.5'
        stroke={stroke ?? 'white'}
        stroke-width={strokeWidth ?? '2.66667'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
