import React from 'react';
import Check from '../../../../../assets/customSVG/TickCricle';
import Tick from '../../../../../assets/icons/TickIcon.svg';

const SelectMergeOption = ({ selected = 'MER', setSelected }) => {
  const options = [
    { id: 1, key: 'REM', label: 'Remove, Duplicates' },
    { id: 2, key: 'MER', label: 'Merge Contact' },
  ];
  return (
    <div>
      <p className="flex items-center gap-[.7vw] mt-[.6vw] mb-[.9vw]">
        {/* <Tick /> */}
        <img src={Tick} alt="" />
        <span className="text-[#666e7d] text-[.6vw]-">
          Some contacts are part of other csv file you have added, how would
          like to proceed?
        </span>
      </p>
      <div className="bg-red-200- flex ms-[1vw] gap-[1vw] text-[#898E99]">
        {options.map((item) => {
          return (
            <p
              key={item.id}
              onClick={() => {
                setSelected(item.key);
              }}
              className="flex items-center gap-[.7vw] cursor-pointer"
            >
              <Check
                circle={selected !== item.key}
                tick={selected === item.key}
                fill={selected === item.key ? '#25C277' : 'white'}
                stroke={selected === item.key ? 'white' : 'gray'}
              />
              <span>{item.label}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default SelectMergeOption;
