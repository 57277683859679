import React from 'react';

export default function Mail({
  width,
  height,
  color,
  strokeWidth = '2',
  className,
}) {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '16'}
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M2.6875 2.75L9.35964 7.54025C9.74489 7.81684 10.2551 7.81684 10.6404 7.54025L17.3125 2.75M3.25 15H16.75C17.9926 15 19 13.9553 19 12.6667V3.33333C19 2.04467 17.9926 1 16.75 1H3.25C2.00736 1 1 2.04467 1 3.33333V12.6667C1 13.9553 2.00736 15 3.25 15Z'
        stroke={color ?? '#2D3036'}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
