import React, { useEffect, useRef, useState } from "react";
import "../../styles/Broadcast.css";

import FileDownload from "./FileDownload";
import Loader from "../../../../components/commonComponents/Loader/Index";
// import * as XLSX from "xlsx";
import { read, utils } from "xlsx";
import { uploadFile } from "../../../../helper/uploadFile";

import { broadcastPartApi, broadcastPartFileData } from "../../api/Api";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import { ICONS } from "../../../../assets/icons";
import {
  setDelayValue1,
  setError,
  setSelectedDate,
  setSelectedDate1,
  setSelectedDateMerge,
  setSelectedTime,
  setSelectedTime1,
  setSelectedTimeMerge,
} from "../../../../reduxToolkit/broadcastSlice";

const Define_Audience = ({
  response,
  setResponse,
  selectedIndex,
  broadCasts,
  setSelectedId1,
  broadcastId,
  response1,
  setResponse1,
  setIndexRem,
  indexRem,
  setSelectedIndexSegments,
}) => {
  const [fileLoader, setFileLoader] = useState(false);
  const [mobile, setMobile] = useState("");
  const [filled, setFilled] = useState(0);
  const [data, setData] = useState({
    file_date_valid: [],
    file_date_in_valid: [],
    file_date_duplicate: [],
  });

  const dispatch = useAspDispatch();
  const { toggleToast, currentBrand } = useAspSelector((state) => state.app);
  const { dateCombine, dateCombine1 } = useAspSelector(
    (state) => state?.Campaign
  );

  const [broadcastPartId, setBroadcastPartId] = useState([]);
  const id = broadcastId;

  const downloadUrl =
    "https://assets.preprod.gainwix.app/assets/sample_customers.xlsx";

  useEffect(() => {
    if (filled < 100) {
      setTimeout(() => setFilled((prev) => (prev += 5)), 50);
    }
    if (response?.length === 0) {
      setSelectedId1(false);
    }
  }, [filled, response]);
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    const selectedFileName = selectedFile?.name?.trim();

    const isDuplicate = response?.some((file) => {
      const file_name = file?.file_name?.trim();

      const lastDotIndex = selectedFileName.lastIndexOf(".");
      const selectedFileNameWithoutExtension =
        lastDotIndex !== -1
          ? selectedFileName.substring(0, lastDotIndex)
          : selectedFileName;

      return file_name === selectedFileNameWithoutExtension;
    });

    if (response?.length < 2) {
      if (!isDuplicate) {
        handleFile(selectedFile);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "File with the same name already exists",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    } else {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: "You can only upload up to 2 files",
            status: "Error",
            duration: "",
          },
        ])
      );
    }
    e.target.value = "";
    if (indexRem) {
      response1 = response1.map((item) => {
        if (item.segments.length !== 1) {
          const updatedSegment = {
            ...item.segments[1],
            end: 0,
            start: 0,
            id: 1,
          };
          return { ...item, segments: [updatedSegment] };
        }
        return item;
      });
    }
    setIndexRem(false);
  };

  const handleFile = async (file) => {
    const filesName = file?.name?.split(".")[0];
    setFileLoader(true);
    setSelectedId1(false);

    dispatch(setSelectedDateMerge({ type: "clear" }));
    dispatch(setSelectedTimeMerge({ type: "clear" }));

    dispatch(setSelectedDate({ type: "clear" }));
    dispatch(setSelectedTime({ type: "clear" }));
    dispatch(setSelectedDate1({ type: "clear" }));
    dispatch(setSelectedTime1({ type: "clear" }));
    dispatch(setDelayValue1(0));

    setSelectedIndexSegments(false);
    dispatch(setError(null));

    const phoneRegex = /^91[0-9]{10}$/;

    try {
      const responseFile = await uploadFile(file);

      const data = await file.arrayBuffer();
      const workbook = read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const contacts = utils.sheet_to_json(worksheet);

      if (Object.keys(contacts).length === 0) {
        setFileLoader(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content:
                "No contacts found. Add at least one contact to send a broadcast.",
              status: "Error",
              duration: "",
            },
          ])
        );
        if (response?.length > 0) {
          setSelectedId1(true);
        }
      }

      const {
        validData,
        invalidData,
        duplicateData,
        validContacts,
        duplicateContacts,
        invalidContacts,
      } = processContacts(contacts, phoneRegex);

      if (!contacts[0]["mobile-number"]) {
        setFileLoader(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Can't Read File. Use Google Sheets / Office Download.",
              status: "Error",
              duration: "",
            },
          ])
        );
        if (response?.length > 0) {
          setSelectedId1(true);
        }
        return;
      }

      const newId = 1;
      let newData;

      if (selectedIndex === "send_now") {
        newData = {
          id: newId,
          start: 0,
          end: 0,
          delay: response?.length > 0 ? 0 : 0,
        };
      } else {
        if (response.length > 0) {
          newData = {
            id: newId,
            start: 0,
            end: 0,
            schedule_on: "",
          };
        } else {
          newData = { id: newId, start: 0, end: 0, schedule_on: dateCombine };
        }
      }

      if (responseFile) {
        const newFileData = {
          id: response.length + 1,
          file_name: filesName,
          file_path: responseFile,
          broadcastPartId: null,
          valid_count: validContacts.length,
          duplicate_count: duplicateContacts.length,
          invalid_count: invalidContacts.length,
          segments: [newData],
        };
        setResponse([...response, newFileData]);

        setResponse1([...response1, newFileData]);

        setFileLoader(false);

        const payLoad = {
          file_name: filesName,
          file_path: responseFile,
        };

        const createBroadcastPartResponse = await broadcastPartApi(id, payLoad);

        if (createBroadcastPartResponse?.status === 200)
          if (createBroadcastPartResponse.status === 200) {
            const broadcastPartId = createBroadcastPartResponse.data.id;
            setBroadcastPartId((prevIds) => [...prevIds, broadcastPartId]);
            newFileData.broadcastPartId = broadcastPartId;

            const updatedData = {
              broadcast_part_id: broadcastPartId,
              valid_count: validContacts.length,
              duplicate_count: duplicateContacts.length,
              invalid_count: invalidContacts.length,
              file_date_valid: validData,
              file_date_in_valid: invalidData,
              file_date_duplicate: duplicateData,
            };

            setData(updatedData);

            const fileDataResponse = await broadcastPartFileData(updatedData);
          }
      }
    } catch (error) {}
  };

  const processContacts = (contacts, phoneRegex) => {
    const uniquePhoneNumbers = new Set();
    const validContacts = [];
    const duplicateContacts = [];
    const invalidContacts = [];

    const brandPlanLimits = {
      "Magnum Plan": 100000,
      "Growth Plan": 50000,
      "Starter Plan": 25000,
    };

    const limit =
      brandPlanLimits[currentBrand?.brand_billing_plan_name] || contacts.length;
    const contactsToValidate = contacts.slice(0, limit);

    contactsToValidate.forEach((contact) => {
      const countryCode = Number(contact["country-code"]);
      const phoneNumber = Number(contact["mobile-number"]);
      setMobile(phoneNumber);
      const fullPhoneNumber = countryCode
        ? `${countryCode}${phoneNumber}`
        : phoneNumber;

      const isValid = phoneRegex.test(fullPhoneNumber);
      const isDuplicate = uniquePhoneNumbers.has(phoneNumber);

      if (isValid) {
        setSelectedId1(true);
        if (!isDuplicate) {
          uniquePhoneNumbers.add(phoneNumber);
          validContacts.push(contact);
        } else {
          duplicateContacts.push(contact);
        }
      } else {
        invalidContacts.push(contact);
      }
    });

    const mapContactData = (contactList) =>
      contactList.map((contact) => ({
        row: contact.__rowNum__ || null,
        mobile1: Number(
          contact["country-code"]
            ? `${contact["country-code"]}${contact["mobile-number"]}`
            : `${contact["mobile-number"]}`
        ),

        details: {
          ["email-id"]: contact["email-id"] || null,
          ["last-name"]: contact["last-name"] || null,
          ["media-url"]: contact["media-url"] || null,
          ["shop-name"]: contact["shop-name"] || null,
          ["first-name"]: contact["first-name"] || null,
          ["website-url"]: contact["website-url"] || null,
          ["mobile-number"]: Number(contact["mobile-number"]) || null,
          ["custom-variable"]: contact["custom-variable"] || null,
          ["custom-variable1"]: contact["custom-variable1"] || null,
          ["custom-variable2"]: contact["custom-variable2"] || null,
          ["custom-variable3"]: contact["custom-variable3"] || null,
          ["custom-variable4"]: contact["custom-variable4"] || null,
          ["custom-variable5"]: contact["custom-variable5"] || null,
        },
      }));

    if (validContacts?.length === 0 && contacts[0]["mobile-number"]) {
      setFileLoader(false);
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content:
              "No contacts found. Add at least one contact to send a broadcast.",
            status: "Error",
            duration: "",
          },
        ])
      );
      if (response?.length > 0) {
        setSelectedId1(true);
      }
      return;
    }

    const validData =
      validContacts?.length > 0 ? mapContactData(validContacts) : 0;
    const invalidData =
      invalidContacts?.length > 0 ? mapContactData(invalidContacts) : 0;
    const duplicateData =
      duplicateContacts?.length > 0 ? mapContactData(duplicateContacts) : 0;

    return {
      validData,
      invalidData,
      duplicateData,
      validContacts,
      duplicateContacts,
      invalidContacts,
    };
  };

  return (
    <div className="Broadcast-segments ">
      <div className="flex-column">
        <span className="audience">Define Audience</span>
        <div className=" Audience_download flex-row w-100 space-between align-center">
          <span className="audience-content ">
            Upload a single audience list or combine multiple lists to tailor
            your message to specific groups
          </span>
          <a href={downloadUrl}>
            <div className="flex-row align-center pointer">
              <img
                src={ICONS?.AudienceDownloadIcon}
                alt=""
                style={{ width: "16px", height: "16px" }}
              />

              <span>Download Sample</span>
            </div>
          </a>
        </div>
      </div>

      <div
        className="upload-container "
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <label className="uploadLabel flex-column justify-center align-center">
          <input
            className="input"
            type="file"
            onChange={handleChange}
            //   ref={fileInputRef}
            accept=".xlsx"
            // multiple
          />
          <div className="duplicateButton flex-row align-center justify-center">
            {fileLoader ? (
              <div
                className="loaderWrapper flex-row align-center justify-center pt-10 "
                style={{ width: "60px" }}
              >
                <Loader
                  Width="20px"
                  Height="20px"
                  loaderBg="3px solid white"
                  loaderColor="3px solid var(--primary)"
                />
              </div>
            ) : (
              "Choose file"
            )}
          </div>
          <div className="flex-column">
            <p>( Drag & Drop the file to upload )</p>
            <p>.xlsx</p>
          </div>
        </label>
      </div>

      <FileDownload
        key={response?.id}
        file_name={response?.file_name}
        broadCasts={broadCasts}
        broadcastId={broadcastId}
        response={response}
        setResponse={setResponse}
        response1={response1}
        setResponse1={setResponse1}
        broadcastPartId={broadcastPartId}
        setSelectedId1={setSelectedId1}
        mobile={mobile}
        setIndexRem={setIndexRem}
        indexRem={indexRem}
        setSelectedIndexSegments={setSelectedIndexSegments}
      />
    </div>
  );
};

export default Define_Audience;
