import React from 'react';
import { contactData, singleContactNav } from '../../constants';
import back from '../../../../assets/images/Back.svg';
import ProfileInfo from './ProfileInfo';
import { useNavigate, useParams } from 'react-router-dom';
const index = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const currentContact = contactData.find((item) => item.id === Number(id));

  return (
    //
    <div className="grid grid-rows-[8.5vh,calc(100vh_-_16.5vh)]">
      <div className="bg-white border-b border-b-slate-100 flex items-center">
        <div className="h-full flex items-center border-r border-r-gray-100 px-[.85vw]">
          <span
            className="cursor-pointer rounded-md hover:bg-gray-100 duration-200"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img className="w-[2vw]" src={back} alt="" />
          </span>
        </div>
        <div className="px-[.85vw]">
          <div className="flex items-center gap-[1vw]">
            <p className="size-[2vw] text-white grid place-content-center bg-[#8B5CF6] border border-gray-100 rounded-full overflow-hidden">
              {currentContact?.name?.url ? (
                <img
                  src={currentContact?.name?.url}
                  className="size-full object-cover"
                  alt=""
                />
              ) : (
                <span className="text-[1vw] font-[600]">
                  {currentContact?.name?.name
                    ? currentContact?.name?.name.toUpperCase().at(0)
                    : '--'}
                </span>
              )}
            </p>
            <div className="flex flex-col ">
              <h2 className="text-black font-[600] text-[1.3vw]">
                {currentContact?.name?.name}
              </h2>
              <span className="text-[.65vw] text-[#616874]">
                Added via file import from CSV on March 18, 2024
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-[3fr,10fr] bg-gray-100">
        <div className="p-[1vw] ">
          <div className="size-full rounded-lg text-[.85vw] text-[#616874] bg-white custom-shadow-1 p-[1vw] px-[1.5vw]">
            <div className="flex flex-col items-center mb-[1vw]">
              <p className="size-[5vw] text-white grid place-content-center bg-[#8B5CF6] border border-gray-100 rounded-full overflow-hidden">
                {currentContact?.name?.url ? (
                  <img
                    src={currentContact?.name?.url}
                    className="size-full object-cover"
                    alt=""
                  />
                ) : (
                  <span className="text-[2.5vw] font-[700]">
                    {currentContact?.name?.name
                      ? currentContact?.name?.name.toUpperCase().at(0)
                      : '--'}
                  </span>
                )}
              </p>

              <h2 className="text-black font-[600] text-[1.3vw] my-[1vw]">
                {currentContact?.name?.name}
              </h2>
              <div className="border w-full text-[.75vw] font-[500] px-[.7vw] py-[.4vw] border-slate-300 rounded-[.3vw]">
                New Messages
              </div>
            </div>

            <div>
              <h3 className="font-[600] text-black mb-[1vw]">Information</h3>
              <div className="space-y-[1.2vw]">
                {singleContactNav.map((item) => {
                  return (
                    <p
                      key={item.id}
                      className={`px-[1vw] cursor-pointer capitalize py-[0.5vw] rounded-md duration-200 ${
                        item.selected
                          ? 'bg-gray-100 font-[600] text-black'
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      {item.label}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[calc(100vh_-_16.5vh)]">
          <div className="h-full overflow-scroll scroll_visible flex flex-col gap-[1vw] p-[1vw] pb-[.8vw] ps-[.5vw] *:p-[1.5vw]">
            <div className="w-full rounded-lg bg-white custom-shadow-1">
              <ProfileInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
