import React from "react";

// assets
import { ICONS } from "../../../assets/icons/index";

// helper
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";

// components
import Status from "../../../components/commonComponents/tables/Status";
import { useNavigate } from "react-router-dom";

export default function CampaignListCard({ data }) {
  const {
    id,
    campaign_name,
    campaign_type_id,
    status,
    settings,
    campaign_color,
    slug,
    created_at,
    start_date,
    end_date,
    leads_count,
    opened_count,
    contacts_count,
    bounced_count,
    delivered_count,
  } = data;

  const navigate = useNavigate();

  const dataList = [
    {
      id: 1,
      title: "Contacts",
      count: contacts_count ?? 0,
      iconType: "svg",
      icon: ICONS?.Profile,
    },
    {
      id: 2,
      title: "Delivered",
      count: delivered_count ?? 0,
      iconType: "icon",
      icon: ICONS?.Delivered,
    },
    {
      id: 3,
      title: "Opened",
      count: opened_count ?? 0,
      iconType: "icon",
      icon: ICONS?.Opened,
    },
    {
      id: 4,
      title: "Lead",
      count: leads_count ?? 0,
      iconType: "svg",
      icon: ICONS?.Lead,
    },
    {
      id: 5,
      title: "Clicks",
      count: 0,
      iconType: "svg",
      icon: ICONS?.Click,
    },
  ];

  return (
    <div
      key={id}
      className="w-auto h-[4.5vw] flex items-center justify-between border-[0.1vw] border-[var(--border-50)] bg-[var(--white)] shadow-[0px_1.5px_2px_0px_#1018281A] rounded-[1vw] px-[0.6vw] py-[1vw] gap-[0.5vw] cursor-pointer"
      onClick={() => {
        if (campaign_type_id === 1) {
          navigate(`/user/campaign/broadcast/${id}`, { state: { item: data } });
        }
        if (campaign_type_id === 3) {
          navigate(`/user/campaign/api/${id}`, { state: { item: data } });
        }
      }}
    >
      <div
        className={`contents-[""] w-[0.25vw] h-[2vw] rounded-full`}
        style={{
          backgroundColor: campaign_color,
        }}
      />

      <div className="w-full flex items-center justify-between gap-[1vw]">
        <div className="w-[12vw]">
          <h3 className="font-md weight-medium text-ellipsis line-clamp-1 text-[var(--contentText)]">
            {capitalizeFirstLetter(campaign_name)}
          </h3>
          <p className="font-sm weight-semibold text-[var(--primary)] pt-[0.6vw] capitalize">
            {/* {status === 10 ? 'Active' : status === 1 ? 'Completed' : status} */}
            <Status
              status={status}
              iconsSize={17}
              style={{
                fontWeight: 600,
              }}
            />
          </p>
        </div>
        <div className="w-[42vw] flex items-center">
          {dataList?.map((data) => {
            return (
              <div
                key={data?.id}
                className="w-[9vw] border-r-[0.08vw] border-[#E8E8EA] last:border-r-0 px-[2.5vw] text-ellipsis"
              >
                <div className="w-full flex items-center gap-[0.4vw]">
                  <img
                    src={data?.icon}
                    alt="icons"
                    className="w-[1vw] h-[1vw]"
                  />
                  <h3 className="text-[var(--font-600)] font-sm weight-medium">
                    {data?.title}
                  </h3>
                </div>
                <p className="text-[var(--contentText)] font-normal weight-semibold pt-[0.5vw]">
                  {data?.count}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
