import React from "react";

const Upload = ({ width = "20", height = "20", fill = "#2D3036" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83577 12.9521C8.83577 13.4123 9.20886 13.7854 9.6691 13.7854C10.1293 13.7854 10.5024 13.4123 10.5024 12.9521H8.83577ZM9.6691 3L10.235 2.38828C9.9156 2.09279 9.42261 2.09279 9.1032 2.38828L9.6691 3ZM4.99269 6.19093C4.65485 6.50347 4.63434 7.03071 4.94688 7.36855C5.25942 7.70639 5.78665 7.72691 6.1245 7.41437L4.99269 6.19093ZM13.2137 7.41437C13.5516 7.72691 14.0788 7.70639 14.3913 7.36855C14.7039 7.03071 14.6834 6.50347 14.3455 6.19093L13.2137 7.41437ZM10.5024 12.9521L10.5024 3L8.83577 3L8.83577 12.9521H10.5024ZM9.1032 2.38828L4.99269 6.19093L6.1245 7.41437L10.235 3.61172L9.1032 2.38828ZM14.3455 6.19093L10.235 2.38828L9.1032 3.61172L13.2137 7.41437L14.3455 6.19093Z"
        fill={fill}
      />
      <path
        d="M3 16.485H2.16667C2.16667 16.7094 2.2572 16.9244 2.41776 17.0812L3 16.485ZM16.3333 16.485L16.9156 17.0812C17.0761 16.9244 17.1667 16.7094 17.1667 16.485H16.3333ZM3.83333 15.3047C3.83333 14.8445 3.46024 14.4714 3 14.4714C2.53976 14.4714 2.16667 14.8445 2.16667 15.3047H3.83333ZM17.1667 15.3047C17.1667 14.8445 16.7936 14.4714 16.3333 14.4714C15.8731 14.4714 15.5 14.8445 15.5 15.3047H17.1667ZM2.41776 17.0812C2.91273 17.5646 3.57994 17.8327 4.27162 17.8327V16.1661C4.0094 16.1661 3.76178 16.0642 3.58224 15.8888L2.41776 17.0812ZM4.27162 17.8327H15.0617V16.1661H4.27162V17.8327ZM15.0617 17.8327C15.7534 17.8327 16.4206 17.5646 16.9156 17.0812L15.7511 15.8888C15.5716 16.0642 15.3239 16.1661 15.0617 16.1661V17.8327ZM3.83333 16.485V15.3047H2.16667V16.485H3.83333ZM17.1667 16.485V15.3047H15.5V16.485H17.1667Z"
        fill={fill}
      />
    </svg>
  );
};

export default Upload;
