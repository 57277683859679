import AspApi from "api/ApiConfig";

// export const campaignListApi = async (brandId) => {
//   const res = await AspApi.get(`/api/v1/campaigns?brand_id=${brandId}`);
//   return res;
// };

export const campaignListApi = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/campaigns?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

export const campaignBroadcastListApi = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/campaigns/broadcast?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

export const createCampaignApi = async (params, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/campaign/create?brand_id=${params}`,
    payLoad
  );
  return res;
};

export const updateCampaignApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/campaign/update?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const checkCampaignExits = async (id, params) =>
  await AspApi.get(`/api/v1/check_campaign?brand_id=${id}&name=${params}`);

export const createSendApi = async (brandId, id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/broadcast/send/test_message?brand_id=${brandId}&meta_template_id=${id}`,
    payLoad
  );
  return res;
};

export const campaignDelete = async (id, payLoad) => {
  const res = await AspApi.delete(
    `/api/v1/campaign?campaign_id=${id}`,
    payLoad
  );
  return res;
};

export const campaignDeleteBulk = async (payLoad) => {
  const res = await AspApi.delete(
    `/api/v1/campaign/bulk?brand_id=${payLoad?.brand_id}&bulk_delete=${payLoad?.bulk_delete}&except_campaign_ids=${payLoad?.except_campaign_ids}`
  );
  return res;
};

// CAMPAIGN/API - API

export const campaignAPI = async (brandId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");
  const res = await AspApi.get(
    `/api/v1/campaigns/api?brand_id=${brandId}&${qpmString}`
  );

  return res;
};

export const getApiCount = async (brandId, campaignId) => {
  const res = await AspApi.get(
    `/api/v1/conversations/metrics?brand_id=${brandId}&campaign_id=${campaignId}`
  );
  return res;
};

export const ApiContactList = async (brandId, campaignId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/conversations/contacts?brand_id=${brandId}&campaign_id=${campaignId}&${qpmString}`
  );
  return res;
};

// BROADCAST - API ----------------------------------------

export const broadCastCreateApi = async (brandId, id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/broadcast/create?brand_id=${brandId}&campaign_id=${id}`,
    payLoad
  );
  return res;
};

export const broadCastListApi = async (brandId, campaignId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/broadcasts?brand_id=${brandId}&campaign_id=${campaignId}&${qpmString}`
  );
  return res;
};

export const broadcastSingleApi = async (brandId, campaignId, broadcastId) => {
  const res = await AspApi.get(
    `/api/v1/broadcast?brand_id=${brandId}&campaign_id=${campaignId}&broadcast_id=${broadcastId}`
  );
  return res;
};

export const broadcastDeleteApi = async (brandId, campaignId, id) => {
  const res = await AspApi.delete(
    `/api/v1/broadcast?brand_id=${brandId}&campaign_id=${campaignId}&broadcast_id=${id}`
  );

  return res;
};
export const broadcastSegmentApi = async (brandId, campaignId, id) => {
  const res = await AspApi.get(
    `/api/v1/broadcast?brand_id=${brandId}&campaign_id=${campaignId}&broadcast_id=${id}`
  );
  return res;
};

export const getBroadcastCountApi = async (brandId, campaignId) => {
  const res = await AspApi.get(
    `/api/v1/campaign/metrics?brand_id=${brandId}&campaign_id=${campaignId}`
  );
  return res;
};

export const broadCastContactListApi = async (
  brandId,
  broadcastId,
  broadcastPartId,
  query
) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/broadcast/contacts?brand_id=${brandId}&broadcast_id=${broadcastId}&broadcast_part=${broadcastPartId}&${qpmString}`
  );
  return res;
};

// BROADCAST PART - API ----------------------------------------

export const broadcastPartApi = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/broadcastpart/create?broadcast_id=${id}`,
    payLoad
  );
  return res;
};

export const broadcastPartFileData = async (payLoad) => {
  const res = await AspApi.post(`/api/v1/broadcastpart/file_data`, payLoad);
  return res;
};

export const getBroadcastPastApi = async (id) => {
  const res = await AspApi.get(`/api/v1/broadcastparts?broadcast_id=${id}`);
  return res;
};

export const broadcastPartDeleteApi = async (responseId, responseFromApi) => {
  const res = await AspApi.delete(
    `/api/v1/broadcastpart?broadcast_id=${responseId}&broadcast_part_id=${responseFromApi}`
  );
  return res;
};
export const broadcastTemplateListApi = async (brandId, status, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/templates?brand_id=${brandId}&status=${status}&${qpmString}`
  );
  return res;
};
export const createActivateApi = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/broadcast/activate?brand_id=${id}`,
    payLoad
  );
  return res;
};

export const templateList = async (brandId, templateId) => {
  const res = await AspApi.get(
    `api/v1/single_template?template_id=${templateId}&brand_id=${brandId}`
  );
  return res;
};

export const draftApi = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/broadcast/draft?brand_id=${id}`,
    payLoad
  );
  return res;
};
