import React from 'react';

function navChannels({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.0909 16.1895C19.9814 15.5301 18.6214 15.7148 17.7013 16.6533L16.3734 15.8691C16.5132 15.4766 16.5831 15.0133 16.5831 14.5499C16.5831 12.6251 15.2202 11.0211 13.4381 10.7002V9.09623C14.6262 8.77543 15.5347 7.67044 15.5347 6.35158C15.5347 4.78321 14.2767 3.5 12.7392 3.5C11.2016 3.5 9.94363 4.78321 9.94363 6.35158C9.94363 7.67044 10.8522 8.77543 12.0403 9.09623V10.7002C10.2581 11.0211 8.8953 12.6247 8.8953 14.5499C8.8953 15.0133 8.96519 15.441 9.10497 15.8687L7.77708 16.6529C6.89508 15.7533 5.53399 15.5077 4.38747 16.1892C3.05958 16.9734 2.6053 18.7203 3.37408 20.0745C4.0852 21.3702 5.77895 21.9426 7.18302 21.1082C8.30124 20.4309 8.79047 19.1117 8.47597 17.9001L9.8388 17.116C10.5377 17.9711 11.586 18.5057 12.7741 18.5057C13.9622 18.5057 15.0106 17.9711 15.7095 17.116L17.0723 17.9001C16.7578 19.1117 17.247 20.4662 18.3649 21.1082C19.7061 21.9052 21.4187 21.4496 22.1738 20.0745C22.8731 18.7203 22.4188 16.9737 21.0909 16.1895ZM6.48413 19.8966C6.07318 20.1761 5.08146 20.2228 4.56219 19.3619C4.1778 18.6847 4.42241 17.7936 5.08635 17.4015C5.8408 17.0169 6.61447 17.2838 7.0083 17.9361C7.39269 18.6134 7.14808 19.5045 6.48413 19.8966ZM11.3414 6.35158C11.3414 5.5674 11.9704 4.92579 12.7392 4.92579C13.508 4.92579 14.137 5.5674 14.137 6.35158C14.137 7.13577 13.508 7.77737 12.7392 7.77737C11.9704 7.77737 11.3414 7.13577 11.3414 6.35158ZM12.7392 17.045C11.3764 17.045 10.2931 15.94 10.2931 14.5499C10.2931 13.1597 11.3764 12.0547 12.7392 12.0547C14.102 12.0547 15.1853 13.1597 15.1853 14.5499C15.1853 15.94 14.102 17.045 12.7392 17.045ZM20.9162 19.3619C20.5951 19.97 19.7833 20.299 18.9942 19.8966C18.3303 19.5045 18.0853 18.6134 18.4701 17.9361C18.8646 17.2824 19.6383 17.0179 20.392 17.4015C21.056 17.7936 21.3006 18.6847 20.9162 19.3619Z'
        fill={color}
        stroke={color}
        strokeWidth='0.75'
      />
    </svg>
  );
}

export default navChannels;
