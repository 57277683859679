import React from 'react';

// assets
import { ICONS } from '../../../../assets/icons';

export default function CTAButtonPreview({ ctaButtons, type = 'normal' }) {
  const btnStyle1 = `flex items-center gap-[0.5vw] ${
    type === 'small' ? 'h-[1.7vw]' : type === 'medium' ? 'h-[2vw]' : 'h-[2.5vw]'
  }`;
  return (
    <>
      {ctaButtons?.length > 0 && (
        <div
          className={`h-auto px-[0.5vw] bg-white rounded-b-[0.7vw] text-[#007AFF] font-md weight-medium ${
            type === 'small'
              ? 'pb-[0.3vw]'
              : type === 'medium'
              ? 'pb-[0.4vw]'
              : 'pb-[0.5vw]'
          }`}
        >
          {ctaButtons?.map((c, i) => {
            return (
              <div key={`cta-button-${i}`} className=''>
                {c.type === 'URL' && (
                  <button className={`${btnStyle1}`}>
                    <img
                      src={ICONS?.nounLink}
                      alt='urlIcon'
                      className='size-[0.9vw]'
                    />
                    {c.text}
                  </button>
                )}
                {c.type === 'PHONE_NUMBER' && (
                  <button className={`${btnStyle1}`}>
                    <img
                      src={ICONS?.call}
                      alt='phoneNumberIcon'
                      className='size-[0.95vw]'
                    />
                    {c.text}
                  </button>
                )}

                {c.type === 'COPY_CODE' && (
                  <button className={`${btnStyle1}`}>
                    <img
                      src={ICONS?.CopyLeft}
                      alt='copeCodeIcon'
                      className='size-[0.95vw]'
                    />
                    <span>Copy Offer Code</span>
                  </button>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
