export function validateURL(url) {
  const urlWithQueryParamsRegex =
    /^(https?:\/\/)?((([a-zA-Z0-9\-_]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:\d{1,5})?(\/[^\s]*)?(\?[^\s]*)?$/i;

  return urlWithQueryParamsRegex.test(url);
}

export function isValidHttpsUrl(str) {
  // const pattern = new RegExp(
  //   '^(https:\\/\\/)' + // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //     '(\\#[-a-z\\d_]*)?$', // fragment locator
  //   'i'
  // );
  const pattern =
    /^(https:\/\/)((([a-zA-Z0-9\-_]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:\d{1,5})?(\/[^\s]*)?(\?[^\s]*)?$/i;
  return pattern.test(str);
}
