import { ICONS } from '../../assets/icons/index.js';

export const navigationConst = [
  {
    id: 1,
    navName: 'Home',
    selected: false,
    routeUrl: '/user/home',
  },
  {
    id: 2,
    navName: 'Campaigns',
    selected: false,
    routeUrl: '/user/campaign/all',
  },

  {
    id: 3,
    navName: 'Calendar',
    selected: false,
    routeUrl: '/user/calendar',
  },

  {
    id: 4,
    navName: 'Inbox',
    selected: false,
    routeUrl: '/user/inbox',
  },

  // {
  //   id: 5,
  //   navName: "CRM",
  //   selected: false,
  //   routeUrl: "/user/crm",
  // },

  {
    id: 6,
    navName: 'Channels',
    selected: false,
    routeUrl: '/user/channels/whatsapp',
  },
  // {
  //   id: 6,
  //   navName: 'Settings',
  //   selected: false,
  //   routeUrl: '/user/settings',
  // },
];

export const profileBoxList = [
  {
    id: 2,
    icon: ICONS?.logout,
    name: 'Logout',
  },
];

export const WhatsappList = [
  {
    id: 1,
    name: 'Business account01',
  },
  {
    id: 2,
    name: 'Business account02',
  },
  {
    id: 3,
    name: 'Business account03',
  },
];
export const BusinessAccountList = [
  {
    id: 1,
    name: 'WhatsApp Account 1',
    subtitle: 'WhatApp Account ID: 123456789012345',
  },
  {
    id: 2,
    name: 'WhatsApp Account2',
    subtitle: 'WhatApp Account ID: 123456789012345',
  },
];

export const SignList = [
  {
    id: 1,
    name: 'First Name',
  },
  {
    id: 2,
    name: 'Last Name',
  },
];
