import React, { useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import SearchBar from '../../../../components/commonComponents/searchBar';
import Button from '../commonComponents/button';
import addColIcon from '../../../../assets/icons/add_column.svg';
import Modal from '../commonComponents/modal';
import { Popover } from 'antd';
import { ModelHeadings, headingColor } from '../../constants';
import Dropdown from '../commonComponents/dropdown';
import UploadFromCsv from './contactModel/UploadFromCsv';
import AddSingleContact from './contactModel/AddSingleContact';
import { useDispatch } from 'react-redux';
import { handleDynamicContactSelect } from '../../../../reduxToolkit/crmSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const Search = () => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [importFrom, setImportFrom] = useState('csv');
  const [importStep, setImportStep] = useState(1);

  const { searchKey, setSearchKey, onChange } = useSearch('');
  const { dynamicColumns } = useSelector((store) => store.crm);

  const dispatch = useDispatch();

  function handleClick() {
    setActive(!active);
    setSelectOpen(false);
  }

  useEffect(() => {
    localStorage.setItem('dynamicClos', JSON.stringify(dynamicColumns));
  }, [dynamicColumns]);
  return (
    <div className="w-full border-b-[0.1vw] border-[var(--border-50)] p-[0.5vw] flex justify-between items-center overflow-hidden">
      <div className="w-[19.5vw] h-[2.1vw]">
        <SearchBar
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          onChange={(value) => setSearchKey(value)}
        />
      </div>
      <div className="flex transition-all items-center me-[1vw] gap-[.6vw]">
        <Dropdown
          editFilter={(id) => {
            dispatch(handleDynamicContactSelect({ id }));
          }}
          data={dynamicColumns}
          button={
            <Popover
              placement="top"
              color={'black'}
              content={
                <p className="py-[.3vh] px-[1.2vw] rounded-md bg-black text-white ">
                  Columns
                </p>
              }
            >
              <img
                src={addColIcon}
                alt=""
                className="hover:bg-gray-100 duration-200 cursor-pointer rounded-md me-[1vw]- size-[2.2vw]"
              />
            </Popover>
          }
        />

        <Modal
          active={active}
          onClick={handleClick}
          headerLabel={ModelHeadings[importFrom].mainLabel?.[importStep - 1]}
          sideNavLabel={'Import contacts'}
          Button={
            <Button label="New content" onClick={handleClick} classNames="" />
          }
          showBack={importStep > 1}
          backAction={() => setImportStep((prev) => prev - 1)}
          ModelSideNav={
            <div className="space-y-[1.5vh]">
              {Object.keys(ModelHeadings).map((item) => {
                const { Icon, sideNavLabel } = ModelHeadings[item];
                const isSelected = item === importFrom;
                return (
                  <div
                    onClick={() => {
                      setImportFrom(item);
                      setImportStep(1);
                    }}
                    key={item}
                    className={`flex gap-3 items-center py-[0.5vh] rounded-md px-[.5vw] text-[.8vw] font-[400] cursor-pointer ${
                      isSelected && 'bg-[var(--primary)] transition-all'
                    }`}
                  >
                    <Icon
                      width={'1vw'}
                      fill={isSelected ? '#fff' : headingColor}
                    />
                    <p
                      className={`text-[${
                        isSelected ? '#fff' : headingColor
                      }] font-[${isSelected && '500'}]`}
                    >
                      {sideNavLabel}
                    </p>
                  </div>
                );
              })}
            </div>
          }
        >
          {importFrom === 'csv' && (
            <UploadFromCsv
              selectOpen={selectOpen}
              setSelectOpen={setSelectOpen}
              importStep={importStep}
              setImportStep={setImportStep}
            />
          )}

          {importFrom === 'contact' && <AddSingleContact />}
        </Modal>
      </div>
    </div>
  );
};
