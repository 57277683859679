import React from 'react';

// helper
import { dateConverter } from '../../../../helper/dateConverter';

// components
import ConversationCard from './ConversationCard';
import { replaceFormating } from '../../helper';

export default function RightSideConversationCard({ data }) {
  const d = dateConverter(data?.created_at, false);

  const objData = !Array?.isArray(data?.message) && data?.message;

  const parseObj = (obj) => {
    if (typeof obj === 'string') {
      const remove = obj?.replaceAll('/', '');
      const parsed = JSON.parse(remove);

      return parsed;
    }

    return '';
  };

  return (
    <div>
      <div className='relative w-auto max-w-[26vw] min-h-[3.5vh] bg-[#D8FBD2] rounded-[0.6vw] rounded-tr-none p-[0.5vw]'>
        <div className='w-0 h-0 bg-transparent border-l-[0.5vw] border-r-[0.5vw] border-b-[1vw] border-l-transparent border-r-transparent border-b-[#D8FBD2] absolute -right-[0.5vw] top-0 rotate-180' />
        {data?.is_customer_msg === false && Array?.isArray(data?.message) ? (
          <ConversationCard data={data} />
        ) : (
          <div
            className={`w-full font-md weight-medium text-[var(--contentText)] rounded-[0.6vw] rounded-tl-none py-[0vw]`}
            dangerouslySetInnerHTML={{
              __html: replaceFormating(parseObj(data?.message)?.body),
            }}
          >
            {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
            {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
          </div>
        )}
      </div>
      <div className='flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw]'>
        <p>{d?.timeWithMeridiem}</p>
      </div>
    </div>
  );
}
