import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import moment from 'moment';
import useTags from '../../../../hooks/useTags';
import { convertName } from '../../helpers/index';
import { singleContactFields } from '../../constants';
import { updateSingleContactDetails } from '../../../../reduxToolkit/crmSlice';
import ProfileCard from './ProfileCard';
import Dropdown from '../commonComponents/dropdown';
import Label from '../commonComponents/label';
import AddCircle from '../../../../assets/customSVG/AddCircle';
import Tags from '../main/contact/Tags';
import { useDispatch } from 'react-redux';

const ProfileInfo = () => {
  const { singleContactDetails } = useSelector((store) => store.crm);
  const selectedTags = useMemo(() => {
    return singleContactDetails['selected_tags'];
  }, [singleContactDetails.selected_tags]);
  const { tags, setTags } = useTags(selectedTags);

  const dispatch = useDispatch();
  const handleChange = (access_name, value) => {
    dispatch(updateSingleContactDetails({ access_name, value }));
  };
  return (
    <div className="*:mb-[1vw] *:pb-[1vw] ">
      {Object.entries(singleContactFields).map(([section, fields]) => {
        return (
          <div
            key={section}
            className="last:pb-0 last:mb-0 last:border-0 border-b border-b-gray-300"
          >
            <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw]">
              {section}
            </h3>
            <RenderCols
              setTags={setTags}
              section={section}
              tags={tags}
              fields={fields}
              onChange={handleChange}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProfileInfo;

function RenderCols({ section, tags, setTags, fields, onChange }) {
  const { singleContactDetails } = useSelector((store) => store.crm);
  const dispatch = useDispatch();

  if (section === 'tags') {
    const closeAction = (w, id) => {
      dispatch(
        updateSingleContactDetails({
          access_name: 'selected_tags',
          value: singleContactDetails['selected_tags'].filter(
            (item) => item !== id,
          ),
        }),
      );
    };
    const addAction = (id, e) => {
      if (!singleContactDetails['selected_tags'].includes(id)) {
        dispatch(
          updateSingleContactDetails({
            access_name: 'selected_tags',
            value: [...singleContactDetails.selected_tags, id],
          }),
        );
      }
    };
    return (
      <div className="">
        <div className="flex flex-wrap w-full *:min-w-max gap-[1vw] my-[1vw] ">
          {tags
            .filter((item) => item.selected)
            .map((item) => {
              return (
                <Label
                  id={item.id}
                  closeAction={closeAction}
                  label={item.label}
                  key={item.id}
                  color={item.color}
                />
              );
            })}
        </div>
        <Tags
          tags={tags}
          addAction={addAction}
          closeAction={closeAction}
          setTags={setTags}
          Button={
            <p className="flex text-[#616874] text-[.75vw] items-center font-[500] gap-[.4vw] cursor-pointer">
              <AddCircle width="1vw" strokeWidth="2.5" stroke="#616874" />
              <span>Add a Tag</span>
            </p>
          }
        />
      </div>
    );
  } else if (section === 'opt out') {
    const selectLabel = (label, selected) => {
      return label === 'email'
        ? selected
          ? 'subscribed'
          : 'unsubscribe'
        : selected
        ? 'opted in'
        : 'opted out';
    };
    return (
      <div className="grid grid-cols-3">
        {fields.map(({ label, date, access_name }) => {
          return (
            <div key={label} className="opt-out space-y-[.4vw]">
              <div className="flex gap-[.4vw] items-center">
                <Switch
                  onChange={(e) => {
                    onChange(access_name, e);
                  }}
                  checked={singleContactDetails[access_name]}
                  size="small"
                  className=""
                />
                <p className="font-[500] text-[.9vw]">{label}</p>
                <span
                  className={`capitalize  px-[.3vw] py-[.2vw] rounded-sm text-[.6vw] ${
                    singleContactDetails[access_name]
                      ? 'bg-green-100 text-green-500'
                      : 'bg-red-100 text-red-500'
                  }`}
                >
                  {selectLabel(label, singleContactDetails[access_name])}
                </span>
              </div>
              <p className="py-[.2vw] font-[500]- rounded-sm text-[.65vw] text-gray-500">
                <span className="capitalize">
                  {selectLabel(label, singleContactDetails[access_name])}
                </span>{' '}
                on <span>{moment(date).format('MMMM Do YYYY')}</span>
              </p>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-3 gap-4">
        {fields &&
          fields.map((item) => {
            if (item?.type === 'dropdown') {
              const selectCheckedFields = item?.data?.map((field) => {
                if (
                  field.access_name === singleContactDetails[item.access_name]
                ) {
                  return { ...field, selected: true };
                }
                return field;
              });

              const [dropdownData, setDropdownData] = useState(
                selectCheckedFields || [],
              );
              const [isOpen, setIsOpen] = useState(false);
              const handleChange = (id) => {
                setDropdownData((prev) => {
                  return prev.map((data) => {
                    if (data.id === id) {
                      onChange(item.access_name, data.access_name);
                      return { ...data, selected: true };
                    }
                    return { ...data, selected: false };
                  });
                });
              };
              const selected = dropdownData.find((item) => item.selected);
              const Icon = selected?.icon;

              return (
                <div key={item.id}>
                  <p className="capitalize text-[.85vw] font-[500] text-gray-800 mb-[.4vw]">
                    {convertName(item.label)}
                  </p>

                  <Dropdown
                    open={isOpen}
                    onOpenChange={(state) => setIsOpen(state)}
                    heading=""
                    data={dropdownData}
                    width="min-w-[18.7vw]"
                    height="max-h-[32vh]"
                    containerClassNames="px-[1.6vw]"
                    editFilter={handleChange}
                    button={
                      <div className="">
                        <div
                          className={` flex justify-between items-center my-[2vh]- py-[1vh] pe-[.7vw] cursor-pointer transition-all text-[0.85vw] rounded-lg duration-500 border ${
                            isOpen
                              ? 'ps-[.5vw] border-[#8B5CF6]'
                              : 'contact-edit-cont'
                          }`}
                          onClick={() => setIsOpen(false)}
                        >
                          <span className="flex items-center gap-[.8vw]">
                            {Icon && <Icon />}
                            <p
                              className={`text-[.75vw] text-[#898E99] capitalize`}
                            >
                              {selected?.label || '--'}
                            </p>
                          </span>
                          <span
                            className={`duration-300 input-editIcon ${
                              isOpen ? '-rotate-180' : 'rotate-0'
                            }`}
                          >
                            <div
                              className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                            />
                          </span>
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            }
            return (
              <ProfileCard
                key={item.id}
                label={convertName(item.label || '')}
                value={
                  ['created_date', 'last_contacted'].includes(item.label)
                    ? moment(singleContactDetails[item.access_name]).format(
                        'lll',
                      )
                    : singleContactDetails[item.access_name]
                }
                Icon={item.icon}
                editable={item.editable}
                onChange={(e) => {
                  onChange(item.access_name, e.target.value);
                }}
              />
            );
          })}
      </div>
    );
  }
}
