import React from 'react';

const threeDots = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.833333"
        y="0.833333"
        width="18.3333"
        height="18.3333"
        rx="9.16667"
        stroke="#616874"
        strokeWidth="1.66667"
      />
      <path
        d="M14.166 10.0003C14.166 9.54009 14.5391 9.16699 14.9993 9.16699C15.4596 9.16699 15.8327 9.54009 15.8327 10.0003C15.8327 10.4606 15.4596 10.8337 14.9993 10.8337C14.5391 10.8337 14.166 10.4606 14.166 10.0003Z"
        stroke="#616874"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16602 10.0003C9.16602 9.54009 9.53911 9.16699 9.99935 9.16699C10.4596 9.16699 10.8327 9.54009 10.8327 10.0003C10.8327 10.4606 10.4596 10.8337 9.99935 10.8337C9.53911 10.8337 9.16602 10.4606 9.16602 10.0003Z"
        stroke="#616874"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16602 10.0003C4.16602 9.54009 4.53911 9.16699 4.99935 9.16699C5.45959 9.16699 5.83268 9.54009 5.83268 10.0003C5.83268 10.4606 5.45959 10.8337 4.99935 10.8337C4.53911 10.8337 4.16602 10.4606 4.16602 10.0003Z"
        stroke="#616874"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default threeDots;
