import React from 'react';

const Chevron = ({ width = '24', height = '15', color, className }) => {
  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7 10L12.0008 14.58L17 10'
          stroke={color}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export default Chevron;
