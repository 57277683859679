import {
  checkVariables,
  updateVariablesOrder,
} from "modules/channels/components/templates";

import { getFirtstTypeOfButton } from "components/commonComponents/templates/Buttons/utils";

import { checkVariable, getVariablesFromStr } from "helper/templates";

import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
} from "components/commonComponents/templates/Buttons/utils";

export const INITIAL_STATE = {
  header: null,
  headerVariable: null,
  body: {
    type: "BODY",
    text: "",
  },
  bodyVariables: [],
  footer: null,
  buttons: [],
  ctaButtons: [],
  customButtons: [],
  buttonsOrder: null,
};

const HEADER_TYPES_INITIAL_VALUES = {
  NONE: null,
  TEXT: {
    type: "HEADER",
    format: "TEXT",
    text: "",
  },
  MEDIA: {
    type: "HEADER",
    format: "MEDIA",
    example: {
      header_handle: [""],
    },
  },
  IMAGE: {
    type: "HEADER",
    format: "IMAGE",
    example: {},
  },
  VIDEO: {
    type: "HEADER",
    format: "VIDEO",
    example: {},
  },
  DOCUMENT: {
    type: "HEADER",
    format: "DOCUMENT",
    example: {},
  },
};

const checkSpace = (text) => {
  return text?.length !== 0 ? text?.toString()?.endsWith(" ") : true;
};

export const templateReducer = (state, action) => {
  const {
    type,
    updateType,
    format,
    text,
    variableId,
    variableTitle,
    variableValue,
    mediaUrl,
    updateVariableObj,
    btnObj,
    data,
  } = action;

  const checkBody = state?.bodyVariables?.filter(
    (v) => v.title === variableTitle
  );

  switch (type) {
    // header
    case "header":
      if (format === "NONE") state.headerVariable = null;
      if (updateType === "format") {
        return {
          ...state,
          header: HEADER_TYPES_INITIAL_VALUES[format],
          headerVariable: null,
        };
      }
      if (updateType === "text") {
        const newvariables = updateVariablesOrder(text, [state.headerVariable]);
        const body = state?.bodyVariables?.filter(
          (v) => v.title === newvariables?.[0]?.title
        );
        const update = newvariables?.map((v) => {
          if (v.title === body?.[0]?.title)
            return { ...v, value: body?.[0]?.value };
          return v;
        });
        if (newvariables?.length > 0) {
          return {
            ...state,
            headerVariable: update?.[0],
            header: {
              ...state.header,
              text: text,
              example: {
                header_text: [update?.[0]?.value],
              },
            },
          };
        }

        return {
          ...state,
          headerVariable: null,
          header: {
            ...state.header,
            text: text,
          },
        };
      }

      if (updateType === "variable")
        return {
          ...state,
          headerVariable: {
            title: variableTitle,
            // id: checkBody?.[0]?.id ?? variableId,
            value: checkBody?.[0]?.value ?? "",
          },
          header: {
            ...state.header,
            text: `${state?.header?.text}${
              checkSpace(state?.header?.text)
                ? variableTitle
                : ` ${variableTitle}`
            }`,
            example: {
              header_text: [checkBody?.[0]?.value ?? ""],
            },
          },
        };

      if (updateType === "mediaUrl")
        return {
          ...state,
          header: {
            ...state.header,
            example: {
              header_handle: [mediaUrl],
            },
          },
        };

      break;

    // body
    case "body":
      if (updateType === "text") {
        const newvariables = updateVariablesOrder(text, state.bodyVariables);
        if (newvariables?.length > 0) {
          return {
            ...state,
            body: {
              ...state.body,
              text: text,
              example: {
                body_text: [
                  newvariables?.map((r) => {
                    return r.value;
                  }),
                ],
              },
            },
          };
        } else {
          return {
            ...state,
            body: {
              type: "BODY",
              text: text,
            },
          };
        }
      }

      if (updateType === "variable") {
        const checkVariable = checkVariables(text, variableTitle);

        const check = state?.bodyVariables?.filter(
          (v) => v?.title === variableTitle
        );
        const variables =
          state?.bodyVariables?.length > 0
            ? state?.bodyVariables
            : [{ title: variableTitle, value: variableValue }];

        // if (checkVariable) {
        const newvariables = updateVariablesOrder(text, variables);
        const update = newvariables?.map((v) => {
          if (v?.title === state?.headerVariable?.title)
            return { ...v, value: state?.headerVariable?.value };
          return v;
        });
        if (update?.length > 0)
          return {
            ...state,
            body: {
              ...state.body,
              text: text,
              example: {
                body_text: [
                  update?.map((r) => {
                    return r.value;
                  }),
                ],
              },
            },
            bodyVariables: update,
          };

        return {
          ...state,
          body: {
            type: "BODY",
            text: text,
          },
          bodyVariables: [],
        };
      }

      break;

    // variables update (header, body)
    case "update_variable":
      const res = state?.bodyVariables?.map((v) => {
        if (v.title === variableTitle)
          return {
            ...v,
            value: variableValue,
          };
        return v;
      });
      const newvariables = updateVariablesOrder(state.body.text, res);
      if (state.headerVariable?.title === variableTitle) {
        return {
          ...state,
          headerVariable: {
            ...state.headerVariable,
            value: variableValue,
          },
          header: {
            ...state.header,
            example: {
              header_text: [variableValue],
            },
          },
          body: {
            ...state.body,
            example: {
              body_text: [
                newvariables?.map((r) => {
                  return r.value;
                }),
              ],
            },
          },
          bodyVariables: newvariables,
        };
      } else {
        return {
          ...state,
          body: {
            ...state.body,
            example: {
              body_text: [
                newvariables?.map((r) => {
                  return r.value;
                }),
              ],
            },
          },
          bodyVariables: newvariables,
        };
      }

    // footer
    case "footer":
      if (text?.length > 0) {
        if (state?.footer?.text?.length > 0)
          return {
            ...state,
            footer: {
              type: "FOOTER",
              text: text,
            },
          };

        return {
          ...state,
          footer: {
            type: "FOOTER",
            text: text,
          },
        };
      }

      return { ...state, footer: null };

    // buttons
    case "buttons":
      let newButtons = [];
      let order = null;
      if (updateType === "add") {
        newButtons = [...state.buttons, btnObj];
      } else if (updateType === "update") {
        newButtons = state.buttons.map((b) => {
          if (b.id === btnObj?.id)
            return { ...b, [btnObj?.key]: btnObj?.value };
          return b;
        });
      } else if (updateType === "remove") {
        newButtons = state.buttons.filter((b) => b.id !== btnObj?.id);
      } else if (updateType === "replace") {
        newButtons = state.buttons.map((b) => {
          if (b.id === btnObj?.id) return btnObj;
          return b;
        });
      } else newButtons = state.buttons;

      const cta = newButtons?.filter((b) =>
        allowedCtaButtonTypes.includes(b?.type)
      );

      const custom = newButtons?.filter((b) =>
        allowedCustomButtonTypes.includes(b?.type)
      );

      const newUpdateCustomButton = custom?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text !== "Stop promotions"
      );
      const newCustomButtonsWithOptout = custom?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text === "Stop promotions"
      );

      const newCustomButtons = [
        ...newUpdateCustomButton,
        ...newCustomButtonsWithOptout,
      ];

      if (newButtons.length === 0) {
        order = allowedCtaButtonTypes.includes(btnObj?.type)
          ? "ctaButtons"
          : allowedCustomButtonTypes?.includes(btnObj?.type)
          ? "custom"
          : "";
      } else {
        if (state.buttonsOrder === "ctaButtons" && cta.length > 0)
          order = "ctaButtons";
        else order = "custom";

        if (state.buttonsOrder === "custom" && custom.length > 0)
          order = "custom";
        else order = "ctaButtons";
      }

      return {
        ...state,
        buttons:
          order === "custom"
            ? [...newCustomButtons, ...cta]
            : [...cta, ...newCustomButtons],
        ctaButtons: cta,
        customButtons: newCustomButtons,
        buttonsOrder: order,
      };

    // update the data for edit and duplicate template
    case "update_template":
      const header = data?.components?.filter((c) => c.type === "HEADER");
      const body = data?.components?.filter((c) => c.type === "BODY");
      const footer = data?.components?.filter((c) => c.type === "FOOTER");
      const buttons = data?.components?.filter((c) => c.type === "BUTTONS");
      const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
        if (b.type === 'PHONE_NUMBER')
          return {
            ...b,
            id: i + 1,
            format: b.type,
            phone_number: b?.phone_number?.slice(3),
          };
        else
          return {
            id: i + 1,
            format:
              b.type === 'QUICK_REPLY'
                ? b.text !== 'Stop promotions'
                  ? 'CUSTOM'
                  : 'OPT_OUT'
                : b.type,
            ...b,
          };
      });
      // const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
      //   return {
      //     id: i + 1,
      //     format:
      //       b.type === 'QUICK_REPLY'
      //         ? b.text !== 'Stop promotions'
      //           ? 'CUSTOM'
      //           : 'OPT_OUT'
      //         : b.type,
      //     ...b,
      //   };
      // });

      let headerVariable = null;
      let bodyVariables = null;
      let updateButtonOrder = null;
      let updateCtaButton = [];
      let updateCustomButton = [];
      let customButtonsWithOptout = [];

      if (body?.[0]?.example?.body_text?.[0]?.length > 0) {
        bodyVariables = getVariablesFromStr(
          body?.[0].text,
          body?.[0]?.example?.body_text?.[0]
        );
      }

      if (header?.[0]?.example?.header_text?.[0]?.length > 0) {
        const res = checkVariable(header?.[0]?.text);
        if (res?.length > 0) {
          const check = bodyVariables?.filter((v) => v.title === res?.[0]);
          headerVariable = {
            title: res?.[0],
            id: check?.length > 0 ? check?.[0]?.id : 1,
            value:
              check?.length > 0
                ? check?.[0]?.value
                : header?.[0]?.example?.header_text?.[0],
          };
        } else headerVariable = null;
      }

      if (updatedButtons?.length > 0) {
        updateButtonOrder = getFirtstTypeOfButton(updatedButtons);
        updateCtaButton = updatedButtons?.filter((b) =>
          allowedCtaButtonTypes.includes(b.type)
        );
        updateCustomButton = updatedButtons?.filter(
          (b) =>
            allowedCustomButtonTypes.includes(b.type) &&
            b.text !== "Stop promotions"
        );
        customButtonsWithOptout = updatedButtons?.filter(
          (b) =>
            allowedCustomButtonTypes.includes(b.type) &&
            b.text === "Stop promotions"
        );

        const customButtons = [
          ...updateCustomButton,
          ...customButtonsWithOptout,
        ];

        if (updateButtonOrder === "ctaButtons" && updateCtaButton?.length > 0)
          updateButtonOrder = "ctaButtons";
        if (updateButtonOrder === "custom" && customButtons?.length > 0)
          updateButtonOrder = "custom";
      }

      const customButtons = [...updateCustomButton, ...customButtonsWithOptout];

      return {
        header: header?.length > 0 ? header?.[0] : null,
        headerVariable: headerVariable,
        body: body?.length > 0 ? body?.[0] : null,
        bodyVariables: bodyVariables,
        footer: footer?.length > 0 ? footer?.[0] : null,
        buttons: updatedButtons?.length > 0 ? updatedButtons : [],
        ctaButtons: updatedButtons?.length > 0 ? updateCtaButton : [],
        customButtons: updatedButtons?.length > 0 ? customButtons : [],
        buttonsOrder: updatedButtons?.length > 0 ? updateButtonOrder : null,
      };

    case "reset":
      return INITIAL_STATE;

    default:
      break;
  }
};
