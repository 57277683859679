export const BUTTONS_LIST = [
  {
    value: 'Visit Website',
    label: 'URL',
    disabled: false,
    length: 2,
  },
  {
    value: 'Call Phone Number',
    label: 'PHONE_NUMBER',
    disabled: false,
    length: 1,
  },
  // { value: 'Complete form', label: 'numberButton', disabled: true, length: 1 },
  {
    value: 'Copy Offer Code',
    label: 'COPY_CODE',
    disabled: false,
    length: 1,
  },
];

export const CUSTOM_BUTTONS_LIST = [
  {
    label: 'Marketing opt-out',
    value: 'Opt-out',
    type: 'OPT_OUT',
    disabled: false,
    length: 1,
  },
  {
    label: 'Custom',
    value: 'Custom',
    type: 'CUSTOM',
    disabled: false,
    length: null,
  },
  // { value: 'Complete form', label: 'numberButton', disabled: true, length: 1 },
];
