import { createSlice } from "@reduxjs/toolkit";

import {
  BroadcastHeaderList,
  SegmentHeaderList,
} from "constant/app/campaign/Broadcast";
import { contactTableList } from "constant/app/campaign/BroadcastExtended";
import { campaignTableHeaderList } from "constant/app/campaign/campaign";

const initialState = {
  switchingComponents: "Calender",
  tabList: [{ id: 1, tabName: "campaignList" }],
  activeTab: "campaignList",
  tableColumn: campaignTableHeaderList,
  tableColumn1: BroadcastHeaderList,
  audienceColumn: SegmentHeaderList,
  contactColumn: contactTableList,
  pageNavigate: "All Campaigns",

  selectedCampaignCount: 0,
  selectedCampaignFilter: "All Campaigns",
  templatePreview: {},
  taskTitle: `Task ${Math.ceil(Math.random())}`,
  savedTaskId: "",
  broadcastPartId: null, //part-id
  broadcastId: null,
  broadcast: [],
  values: null,
  segment_settings: [],
  dateCombine: null,
  dateCombine1: null,
  broadcasts: [],
};

export const authSlice = createSlice({
  name: "Campaign",
  initialState,
  reducers: {
    updateComponentsSwitching: (state, action) => {
      state.switchingComponents = action.payload;
    },
    updateTabList: (state, action) => {
      state.tabList = action.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateTabColumn: (state, action) => {
      state.tableColumn = action.payload;
    },
    updateTabColumn1: (state, action) => {
      state.tableColumn1 = action.payload;
    },
    updateTabAudience: (state, action) => {
      state.audienceColumn = action.payload;
    },
    updateTabContact: (state, action) => {
      state.contactColumn = action.payload;
    },
    setPageNavigate: (state, action) => {
      state.pageNavigate = action.payload;
    },
    updateCampaignCount: (state, action) => {
      state.selectedCampaignCount = action.payload;
    },
    updateSelectedCampaignFilter: (state, action) => {
      state.selectedCampaignFilter = action.payload;
    },
    updateTemplatePreview: (state, action) => {
      state.templatePreview = action.payload;
    },
    updateTaskTitle: (state, action) => {
      state.taskTitle = action.payload;
    },
    updateSavedTaskId: (state, action) => {
      state.savedTaskId = action.payload;
    },
    setBroadcastPartId: (state, action) => {
      state.broadcastPartId = action.payload;
    },
    setBroadcastId: (state, action) => {
      state.broadcastId = action.payload;
    },
    setBroadcast: (state, action) => {
      state.broadcast = action.payload;
    },
    setGetFileData: (state, action) => {
      state.getFileData = action.payload;
    },
    setValue: (state, action) => {
      state.segment_settings = action.payload;
    },
    setDateCombine: (state, action) => {
      state.dateCombine = action.payload;
    },
    setDateCombine1: (state, action) => {
      state.dateCombine1 = action.payload;
    },
    setBroadcasts: (state, action) => {
      state.broadcasts = action.payload;
    },
  },
});

export const {
  updateComponentsSwitching,
  updateTabList,
  updateActiveTab,
  updateTabColumn,
  updateTabColumn1,
  updateTabAudience,
  setPageNavigate,
  updateCampaignCount,
  updateSelectedCampaignFilter,
  updateTemplatePreview,
  updateTaskTitle,
  updateSavedTaskId,
  setBroadcastPartId,
  setBroadcastId,
  setBroadcast,
  setGetFileData,
  setValue,
  setDateCombine,
  setDateCombine1,
  setBroadcasts,
  updateTabContact,
} = authSlice.actions;

export default authSlice.reducer;
