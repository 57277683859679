import React from 'react';

export default function Filter({ width, height, color, className }) {
  return (
    <svg
      width={width ?? '16'}
      height={height ?? '12'}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M3.38478 5.99992H12.6155M1.3335 1.83325H14.6668M6.4617 10.1666H9.53862'
        stroke={color ?? '#2D3036'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
