import React from 'react';

const AddCircle = ({
  width = '24',
  stroke = '#333843',
  height = '24',
  strokeWidth = '2',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M15.375 11.9995H12M12 11.9995H8.625M12 11.9995V15.3745M12 11.9995L12 8.62445M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default AddCircle;
