import React, { useEffect, useState } from 'react';

import moment from 'moment';

var monthNames = new Array(
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
);
var today = new Date();

export default function useMonthCalender() {
  const current = moment().month();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().toDate());

  useEffect(() => {
    var d = new Date();
    const data = {
      date: d.getDate(),
      month: d.getMonth(),
      label: `${monthNames[d.getMonth()]} ${d.getFullYear()}`,
      year: d.getFullYear(),
      value: d.getFullYear(),
    };
    setSelectedMonth(data);
  }, []);

  const allMonths = () => {
    var months = new Array();
    var tmpDate = new Date();
    var tmpYear = tmpDate.getFullYear();
    var tmpMonth = tmpDate.getMonth();
    var monthLiteral;

    for (var i = 1; i < 12; i++) {
      monthLiteral = monthNames[new Date(tmpDate).getMonth()];
      tmpDate.setYear(new Date(tmpDate).getFullYear());
      tmpDate.setMonth(tmpMonth + 1);
      tmpYear = tmpDate?.getFullYear();
      months.push(monthLiteral + ' ' + tmpYear);
    }

    return months;
  };

  const getLastMonths = () => {
    var d = new Date();
    let monthList = [];
    d.setDate(1);
    for (var i = 0; i <= 11; i++) {
      const data = {
        date: d.getDate(),
        month: d.getMonth(),
        label: `${monthNames[d.getMonth()]} ${d.getFullYear()}`,
        year: d.getFullYear(),
        raw: d,
      };
      monthList.push(data);
      d.setMonth(d.getMonth() - 1);
    }

    return monthList;
  };

  const onChange = (value) => {
    setSelectedMonth(value);
  };

  return { allMonths, selectedMonth, getLastMonths, onChange };
}
