import React from 'react';

const website = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10128_47227)">
        <path
          d="M6.13875 7.38109H7.24961C7.3773 6.84519 7.54297 6.35246 7.73965 5.91684C7.35919 6.12859 7.01011 6.39234 6.70246 6.70047C6.49365 6.90918 6.30492 7.13706 6.13875 7.38109ZM6.13875 12.6179C6.30493 12.8619 6.49366 13.0898 6.70246 13.2985C7.01011 13.6066 7.35919 13.8704 7.73965 14.0821C7.54293 13.6465 7.3773 13.1538 7.24961 12.6179H6.13875ZM13.8642 7.38109C13.698 7.13707 13.5093 6.90919 13.3005 6.70047C12.9929 6.39234 12.6438 6.12859 12.2633 5.91684C12.46 6.35246 12.6257 6.84519 12.7534 7.38109H13.8642ZM10.8785 6.3293C10.5025 5.57727 10.1253 5.33398 10.0015 5.33398C9.8777 5.33398 9.50047 5.57727 9.12445 6.3293C8.96871 6.64082 8.83504 6.99566 8.7257 7.38109H11.2773C11.1679 6.99566 11.0343 6.64078 10.8785 6.3293ZM9.12445 13.6697C9.50047 14.4217 9.8777 14.665 10.0015 14.665C10.1253 14.665 10.5025 14.4217 10.8785 13.6697C11.0343 13.3582 11.1679 13.0033 11.2773 12.6179H8.7257C8.83508 13.0033 8.96871 13.3582 9.12445 13.6697ZM6.95461 9.99949C6.95461 9.59215 6.97477 9.19426 7.01289 8.8093H5.48863C5.38812 9.19309 5.33594 9.59223 5.33594 9.99949C5.33594 10.4068 5.38809 10.8059 5.48863 11.1897H7.01289C6.97391 10.7942 6.95446 10.3969 6.95461 9.99949ZM13.0484 9.99949C13.0484 10.4068 13.0282 10.8047 12.9901 11.1897H14.5143C14.6148 10.8059 14.667 10.4068 14.667 9.99949C14.667 9.59223 14.6149 9.19309 14.5143 8.8093H12.9901C13.0291 9.20483 13.0485 9.60204 13.0484 9.99949Z"
          fill="#6940F2"
        />
        <path
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10 16.0938C6.63453 16.0938 3.90625 13.3655 3.90625 10C3.90625 6.63453 6.63453 3.90625 10 3.90625C13.3655 3.90625 16.0938 6.63453 16.0938 10C16.0938 13.3655 13.3655 16.0938 10 16.0938Z"
          fill="#6940F2"
        />
        <path
          d="M8.44977 8.81055C8.40582 9.1973 8.38281 9.59676 8.38281 10.0007C8.38281 10.4047 8.40582 10.8042 8.44977 11.1909H11.5532C11.5971 10.8042 11.6201 10.4047 11.6201 10.0007C11.6201 9.59676 11.5971 9.1973 11.5532 8.81055H8.44977ZM12.2633 14.0834C12.6437 13.8716 12.9928 13.6079 13.3005 13.2998C13.5093 13.091 13.698 12.8632 13.8642 12.6191H12.7533C12.6256 13.155 12.46 13.6478 12.2633 14.0834Z"
          fill="#6940F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_10128_47227">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default website;
