import React from "react";

const Flower = ({ width = "20", height = "20", fill = "#2D3036" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49969 2.70724C9.776 2.43092 10.224 2.43092 10.5003 2.70724L12.5186 4.72548C12.7949 5.0018 12.7949 5.44979 12.5186 5.7261L10.5003 7.74435C10.224 8.02066 9.776 8.02066 9.49969 7.74435L7.48144 5.7261C7.20513 5.44979 7.20513 5.0018 7.48144 4.72548L9.49969 2.70724Z"
        stroke={fill}
        strokeWidth="1.66667"
      />
      <path
        d="M14.2739 7.48144C14.5502 7.20513 14.9982 7.20513 15.2745 7.48144L17.2928 9.49969C17.5691 9.776 17.5691 10.224 17.2928 10.5003L15.2745 12.5186C14.9982 12.7949 14.5502 12.7949 14.2739 12.5186L12.2557 10.5003C11.9793 10.224 11.9793 9.776 12.2557 9.49969L14.2739 7.48144Z"
        stroke={fill}
        strokeWidth="1.66667"
      />
      <path
        d="M4.72548 7.48144C5.0018 7.20513 5.44979 7.20513 5.7261 7.48144L7.74435 9.49969C8.02066 9.776 8.02066 10.224 7.74435 10.5003L5.7261 12.5186C5.44979 12.7949 5.0018 12.7949 4.72548 12.5186L2.70724 10.5003C2.43092 10.224 2.43092 9.776 2.70724 9.49969L4.72548 7.48144Z"
        stroke={fill}
        strokeWidth="1.66667"
      />
      <path
        d="M9.49969 12.2556C9.776 11.9793 10.224 11.9793 10.5003 12.2557L12.5186 14.2739C12.7949 14.5502 12.7949 14.9982 12.5186 15.2745L10.5003 17.2928C10.224 17.5691 9.776 17.5691 9.49969 17.2928L7.48144 15.2745C7.20513 14.9982 7.20513 14.5502 7.48144 14.2739L9.49969 12.2556Z"
        stroke={fill}
        strokeWidth="1.66667"
      />
    </svg>
  );
};

export default Flower;
