import React from 'react';

export default function Date({ day, date, isActive }) {
  return (
    <div
      className={`w-[2vw] px-[0.4vw] py-[0.15vw] text-center rounded-[0.4vw] gap-[0.4vw] flex flex-col items-center cursor-pointer weight-semibold transition-colors ease-linear duration-200 ${
        isActive
          ? 'bg-[var(--primary)] text-[var(--white)]'
          : 'hover:bg-[#0acd9525]'
      }`}
    >
      <div className={`font-xs`}>{day}</div>
      <div className={`font-md`}>{date}</div>
    </div>
  );
}
